var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"align-self":"baseline"}},[_c('v-breadcrumbs',{staticClass:"lot-breadcrumbs pa-0",attrs:{"items":_vm.breadcrumbItems,"divider":"/"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',{staticClass:"align-center"},[(_vm.isSuperAdmin)?_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-at","placeholder":"User Email ID","single-line":"","clearable":"","hide-details":""},on:{"click:clear":function($event){(_vm.filters.orgUserEmail = ''), _vm.fetchData()},"keydown":_vm.fetchData},model:{value:(_vm.filters.orgUserEmail),callback:function ($$v) {_vm.$set(_vm.filters, "orgUserEmail", $$v)},expression:"filters.orgUserEmail"}})],1):_vm._e(),(_vm.isSuperAdmin)?_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-text-field',{attrs:{"type":"number","prepend-icon":"mdi-magnify","placeholder":"Search Lot ID","single-line":"","clearable":"","hide-details":""},on:{"click:clear":function($event){(_vm.filters.parkingLots.selectedId = null), _vm.fetchData()},"keydown":_vm.fetchData,"keypress":function($event){return _vm.isDigit($event)}},model:{value:(_vm.filters.parkingLots.selectedId),callback:function ($$v) {_vm.$set(_vm.filters.parkingLots, "selectedId", $$v)},expression:"filters.parkingLots.selectedId"}})],1):_vm._e(),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-row',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar-outline","clearable":"","label":"Filter by Date range","readonly":"","hide-details":""},on:{"click:clear":_vm.fetchData},model:{value:(_vm.filters.dateMenu.value),callback:function ($$v) {_vm.$set(_vm.filters.dateMenu, "value", $$v)},expression:"filters.dateMenu.value"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.filters.dateMenu.showMenu),callback:function ($$v) {_vm.$set(_vm.filters.dateMenu, "showMenu", $$v)},expression:"filters.dateMenu.showMenu"}},[_c('v-date-picker',{attrs:{"range":"","min":_vm.filters.dateMenu.value
                        ? _vm.filters.dateMenu.value[0]
                        : null,"max":new Date(
                        Date.now() - new Date().getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 10)},on:{"change":_vm.filterByDateChanged},model:{value:(_vm.filters.dateMenu.value),callback:function ($$v) {_vm.$set(_vm.filters.dateMenu, "value", $$v)},expression:"filters.dateMenu.value"}})],1)],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"sm":"6","md":"4","lg":"2"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","block":"","color":"primary","disabled":_vm.filters.orgUserEmail == '' || _vm.filters.orgUserEmail == null},on:{"click":_vm.fetchData}},[_vm._v("Search")])],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"align-center justify-center"},[_c('v-col',[_c('p',{staticClass:"font-weight-bold ma-0"},[_vm._v(" Showing "+_vm._s(_vm.pagination.page * _vm.pagination.itemsPerPage - _vm.pagination.itemsPerPage + 1)+" to "+_vm._s(_vm.pagination.page * _vm.pagination.itemsPerPage > _vm.pagination.itemsCount ? _vm.pagination.itemsCount : _vm.pagination.page * _vm.pagination.itemsPerPage)+" of "+_vm._s(_vm.pagination.itemsCount)+" Items ")])])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.activityLogs,"item-key":"id","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.organization_user_email",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.organization_user_email)+" ")]}},{key:"item.data",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.text)+" ")]}},{key:"item.created_at",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatLocalTimestamp")(item.created_at))+" ")]}}])})],1)],1),_c('v-row',{attrs:{"dense":"","justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-pagination',{attrs:{"length":Math.ceil(_vm.pagination.itemsCount / _vm.pagination.itemsPerPage),"total-visible":7},on:{"input":_vm.fetchData},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"prefix":"Page No.","single-line":"","outlined":"","dense":"","type":"number","hide-details":"auto"},on:{"input":_vm.fetchData},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", _vm._n($$v))},expression:"pagination.page"}})],1)],1),_c('v-overlay',{attrs:{"value":_vm.isLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }