





























import Vue from "vue";
import { mapGetters } from "vuex";
import ParkingLotForm from "@/components/forms/ParkingLotForm.vue";
import { ParkingLotBasic, OrganizationUserAuthorization } from "@/api/models";
import api from "@/api/api";

export default Vue.extend({
  name: "ParkingLotCreate",

  components: {
    ParkingLotForm,
  },

  data: () => ({
    breadcrumbItems: [
      {
        text: "Home",
        disabled: false,
        to: { name: "Home" },
      },
      {
        text: "Parking Lots",
        disabled: true,
      },
      {
        text: "Create",
        disabled: true,
      },
    ],
    showParkingLotForm: false,
    isLoading: false,
    selectedLotDetails: null as ParkingLotBasic | null,
  }),

  computed: {
    ...mapGetters("user", [
      "isLoggedIn",
      "isSuperAdmin",
      "isOperator",
      "getCurrentUserData",
    ]),
  },

  async mounted() {
    if (this.isOperator) {
      this.$router.push({ name: "UntrackedZones" });
    }
    if (!this.isLoggedIn) {
      this.$router.push({ name: "Login" });
    }
  },

  methods: {
    redirectToHome() {
      window.open(`${process.env.VUE_APP_3_BASE_URL_PATH}/home`, "_self");
    },
  },
});
