var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[(_vm.isLoggedIn && _vm.hasAccessLevelDashboardMonitoring)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('v-select',{attrs:{"items":_vm.chart.duration,"item-text":"label","item-value":"value","menu-props":{ maxHeight: '400' },"label":"Select Duration","hint":"Select a Duration, or choose a custom date range","persistent-hint":"","role":"combobox","aria-label":"Select a duration, or choose a custom date range"},on:{"change":_vm.setChartDates},model:{value:(_vm.chart.selectedDuration),callback:function ($$v) {_vm.$set(_vm.chart, "selectedDuration", $$v)},expression:"chart.selectedDuration"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.chart.granularity,"item-text":"label","item-value":"value","menu-props":{ maxHeight: '400' },"label":"Select Granularity","hint":"Select the granularity visualized","persistent-hint":"","role":"combobox","aria-label":"Select the granularity visualized"},model:{value:(_vm.chart.selectedGranularity),callback:function ($$v) {_vm.$set(_vm.chart, "selectedGranularity", $$v)},expression:"chart.selectedGranularity"}})],1)],1):_vm._e(),(_vm.isLoggedIn && _vm.hasAccessLevelDashboardMonitoring)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[(_vm.chart.selectedDuration == 4)?_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","aria-haspopup":"dialog"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date","prepend-icon":"mdi-calendar","readonly":"","role":"combobox","aria-label":"Start Date"},model:{value:(_vm.chart.startDate),callback:function ($$v) {_vm.$set(_vm.chart, "startDate", $$v)},expression:"chart.startDate"}},'v-text-field',attrs,false),on))]}}],null,false,1438827113),model:{value:(_vm.chart.showStartDate),callback:function ($$v) {_vm.$set(_vm.chart, "showStartDate", $$v)},expression:"chart.showStartDate"}},[_c('v-date-picker',{attrs:{"min":_vm.parkingLotData.created_at,"max":_vm.todaysDate,"role":"dialog","aria-labelledby":"start-date-label"},on:{"input":function($event){_vm.chart.showStartDate = false},"change":_vm.setChartGranularity},model:{value:(_vm.chart.startDate),callback:function ($$v) {_vm.$set(_vm.chart, "startDate", $$v)},expression:"chart.startDate"}})],1)],1):_vm._e(),(_vm.chart.selectedDuration == 4)?_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","aria-haspopup":"dialog"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date","prepend-icon":"mdi-calendar","readonly":"","role":"combobox","aria-label":"End Date"},model:{value:(_vm.chart.endDate),callback:function ($$v) {_vm.$set(_vm.chart, "endDate", $$v)},expression:"chart.endDate"}},'v-text-field',attrs,false),on))]}}],null,false,3767921702),model:{value:(_vm.chart.showEndDate),callback:function ($$v) {_vm.$set(_vm.chart, "showEndDate", $$v)},expression:"chart.showEndDate"}},[_c('v-date-picker',{attrs:{"min":_vm.chart.startDate,"max":_vm.todaysDate,"role":"dialog","aria-labelledby":"end-date-label"},on:{"input":function($event){_vm.chart.showEndDate = false},"change":_vm.setChartGranularity},model:{value:(_vm.chart.endDate),callback:function ($$v) {_vm.$set(_vm.chart, "endDate", $$v)},expression:"chart.endDate"}})],1)],1):_vm._e(),_c('v-spacer'),_c('v-col',{staticClass:"justify-end",attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":""},on:{"click":_vm.fetchAppUsageChartData}},[_c('v-icon',[_vm._v("mdi-filter-outline")]),_vm._v("Submit")],1),(_vm.chart.selectedDuration == 0)?_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.refreshPage}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v("Refresh")],1):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":""},on:{"click":function($event){return _vm.$openAdminNextLink(
                ("dashboard/reports/" + _vm.lotId + "?schedule=true&report_type=app_usage&duration=" + (_vm.chart.selectedDuration) + "&granularity=" + (_vm.chart.selectedGranularity))
              )}}},[_c('v-icon',[_vm._v("mdi-email-outline")]),_vm._v(" Schedule ")],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"chart-container",staticStyle:{"position":"relative","height":"fit-content","width":"96vw","padding":"20px"}},[_c('canvas',{attrs:{"id":"app-usage-chart","width":"1600","height":"600"}})])])],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.chart.isLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),(!_vm.chart.isLoading)?_c('div',{attrs:{"id":"loaded"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }