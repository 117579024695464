

































































































































import Vue from "vue";

export default Vue.extend({
  name: "TimePicker",

  props: {
    timeHr: {
      type: String,
      required: true,
    },
    timeFormat: {
      type: String,
      required: false,
    },
  },

  data: () => ({
    time: "12:00",
    hour: "12",
    minute: "00",
    ampm: "AM",
    activePicker: "HOUR", // HOUR, MINUTE
  }),

  mounted() {
    this.time = this.timeHr;
    this.setAmPm(this.timeHr);
  },

  methods: {
    setAmPm(val: string) {
      const time = val.split(":");
      if (parseInt(time[0]) >= 12) {
        this.ampm = "PM";
      }
    },
    incrementHour() {
      this.hour = (parseInt(this.hour) + 1).toString();
      this.formatHour();
    },
    decrementHour() {
      this.hour = (parseInt(this.hour) - 1).toString();
      this.formatHour();
    },
    incrementMinute() {
      this.minute = (parseInt(this.minute) + 1).toString();
      this.formatMinute();
    },
    decrementMinute() {
      this.minute = (parseInt(this.minute) - 1).toString();
      this.formatMinute();
    },
    fixHour() {
      this.hour = this.hour.slice(-2);
      this.hour =
        parseInt(this.hour) > 12
          ? "01"
          : parseInt(this.hour) < 1
          ? "0"
          : this.hour;
      this.formatHour();
    },
    fixMinute() {
      this.minute = this.minute.slice(-2);
      this.minute =
        parseInt(this.minute) > 59
          ? "59"
          : parseInt(this.minute) < 0
          ? "0"
          : this.minute;
      this.formatMinute();
    },
    formatHour() {
      if (this.hour == "") {
        return;
      }
      this.hour = this.hour.toString();
      if (parseInt(this.hour) < 10) {
        this.hour = "0" + parseInt(this.hour).toString();
      }
      this.set24HourTime();
    },
    formatMinute() {
      if (this.minute == "") {
        return;
      }
      if (parseInt(this.minute) < 0) {
        this.minute = "59";
      } else if (parseInt(this.minute) > 59) {
        this.minute = "00";
      }
      this.minute = this.minute.toString();
      if (parseInt(this.minute) < 10) {
        this.minute = "0" + parseInt(this.minute).toString();
      }
      this.set24HourTime();
    },
    changeAmPm(val: string) {
      this.ampm = val;
      this.set24HourTime();
    },
    set24HourTime() {
      if (this.ampm == "AM") {
        this.time = this.hour + ":" + this.minute;
      } else {
        this.time =
          (parseInt(this.hour) > 12 ? parseInt(this.hour) + 12 : this.hour) +
          ":" +
          this.minute;
      }
    },
    resetHour() {
      this.activePicker = "HOUR";
      // @ts-expect-error Directly set selectingHour so Hour dial shows up
      this.$refs.timePicker.selectingHour = true;
    },
    resetMinute() {
      this.activePicker = "MINUTE";
      // @ts-expect-error Directly set selectingMinute so Minute dial shows up
      this.$refs.timePicker.selectingMinute = false;
    },
    changeHour(val: string) {
      if (parseInt(val) < 0) {
        this.hour = "12";
      } else if (parseInt(val) > 12) {
        this.hour = "01";
      }
      if (parseInt(this.hour) < 10) {
        this.hour = "0" + parseInt(this.hour).toString();
      }
    },
    changeMinute(val: string) {
      if (parseInt(val) < 0) {
        this.minute = "00";
      } else if (parseInt(val) > 59) {
        this.minute = "01";
      }
      if (parseInt(this.minute) < 10) {
        this.minute = "0" + parseInt(this.minute).toString();
      }
    },
    convertTo24Hour(time12h: string) {
      const [time, modifier] = time12h.split(" ");
      let [hours, minutes] = time.split(":");

      let new_hours: string | number = parseInt(hours);
      if (modifier === "PM" && hours !== "12") {
        new_hours = parseInt(hours, 10) + 12;
      }
      new_hours = new_hours.toString();
      if (new_hours.toString().length == 1) {
        new_hours = "0" + new_hours;
      }
      if (new_hours === "12" && modifier === "AM") {
        new_hours = "00";
      }

      return `${new_hours}:${minutes}`;
    },
    emitTime() {
      if (this.errorInvalidTime) {
        return;
      }
      // this.setAmPm(this.time);
      if (parseInt(this.time.split(":")[0]) <= 12) {
        this.time = this.convertTo24Hour(`${this.time} ${this.ampm}`);
      }
      if (parseInt(this.time.split(":")[0]) >= 24) {
        this.time = "23:59";
      }
      this.$emit("set-time-picker", this.time);
    },
  },

  computed: {
    errorInvalidTime() {
      if (this.timeFormat == "24_hr") {
        return (
          this.hour == "" ||
          this.minute == "" ||
          parseInt(this.minute) < 0 ||
          parseInt(this.minute) > 59
        );
      } else {
        return (
          this.hour == "" ||
          this.minute == "" ||
          parseInt(this.hour) < 1 ||
          parseInt(this.hour) > 12 ||
          parseInt(this.minute) < 0 ||
          parseInt(this.minute) > 59
        );
      }
    },
  },

  watch: {
    // hour(val) {
    //   this.changeHour(val);
    // },
    // minute(val) {
    //   this.changeMinute(val);
    // },
    time(val) {
      const time = val.split(":");
      if (this.timeFormat != "24_hr") {
        // convert hour in 24 hour format to 12 hour format
        if (parseInt(time[0]) > 12) {
          // this.ampm = "PM";
          this.hour = time[0].isNaN
            ? "00"
            : (parseInt(time[0]) - 12).toString();
        } else {
          // this.ampm = "AM";
          this.hour = time[0].isNaN ? "00" : time[0] == "00" ? "12" : time[0];
        }
        if (parseInt(this.hour) < 10) {
          this.hour = "0" + parseInt(this.hour).toString();
        }
        this.minute = time[1] === undefined ? "00" : time[1];
      } else {
        this.hour = time[0];
        this.minute = time[1];
      }
    },
  },
});
