






























































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import api from "@/api/api";
import { LprBlacklistWhitelistRecord } from "@/api/models";

type VForm = Vue & { resetValidation: () => boolean };

export default Vue.extend({
  name: "LprAlertListRecordForm",

  props: {
    lotId: {
      type: Number,
      required: true,
    },
    lpAlertListRecord: {
      type: Object as () => LprBlacklistWhitelistRecord | null,
    },
    needsInit: {
      type: Boolean,
      required: true,
    },
    parkingZonesItems: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      allFieldsValid: false,

      licensePlateNumber: "",
      selectedParkingZoneId: null as number | null,
      overrideWaitTimeMins: null as number | null,
      alertEnabled: true,
      emailReceivers: "",
      comment: "",
      listType: {
        selected: "never_allowed" as string,
        items: [
          {
            name: "Never Allowed",
            value: "never_allowed",
          },
          {
            name: "Always Allowed",
            value: "always_allowed",
          },
          {
            name: "Watch List",
            value: "watch_list",
          },
          {
            name: "VIP List",
            value: "vip_list",
          },
        ],
      },

      IS_FEATURE_4761_UNTRACKED_ZONES_LPR_ALERTS_ENABLED:
        process.env.VUE_APP_IS_FEATURE_4761_UNTRACKED_ZONES_LPR_ALERTS_ENABLED,
    };
  },

  computed: {
    emailReceiversList(): Array<string> | null {
      if (this.emailReceivers == null || this.emailReceivers.length <= 0) {
        return null;
      }
      return this.emailReceivers.split(",");
    },
  },

  mounted() {
    this.initData();
  },

  methods: {
    initData() {
      console.log("Init from", this.lpAlertListRecord);
      if (this.lpAlertListRecord) {
        this.licensePlateNumber = this.lpAlertListRecord.license_plate;
        this.selectedParkingZoneId = this.lpAlertListRecord.parking_zone_id;
        this.overrideWaitTimeMins = this.lpAlertListRecord
          .override_wait_time_secs
          ? this.lpAlertListRecord.override_wait_time_secs / 60
          : null;
        this.listType.selected = this.lpAlertListRecord.list_type;
        this.alertEnabled = this.lpAlertListRecord.alert_enabled;
        this.emailReceivers = this.lpAlertListRecord.extra_email_alert_receivers
          ? this.lpAlertListRecord.extra_email_alert_receivers.join(",")
          : "";
        this.comment = this.lpAlertListRecord.comment || "";
      }
    },

    resetFields() {
      this.licensePlateNumber = "";
      this.selectedParkingZoneId = null;
      this.overrideWaitTimeMins = null;
      this.listType.selected = this.listType.items[0].value;
      this.alertEnabled = true;
      this.comment = "";
      this.emailReceivers = "";
    },

    async saveRecord() {
      this.isLoading = true;
      if (this.licensePlateNumber == null) return;
      const lpRecord = {
        license_plate: this.licensePlateNumber,
        parking_zone_id: this.selectedParkingZoneId,
        override_wait_time_secs: this.overrideWaitTimeMins
          ? this.overrideWaitTimeMins * 60
          : null,
        list_type: this.listType.selected,
        alert_enabled: this.alertEnabled,
        extra_email_alert_receivers: this.emailReceiversList,
        comment: this.comment,
      };
      let savedRecordResp: LprBlacklistWhitelistRecord | string | null;
      if (this.lpAlertListRecord == null) {
        // create
        savedRecordResp = await api.addLpToBlacklistWhitelist(
          this.lotId,
          lpRecord
        );
      } else {
        // update
        let lpRecordToUpdate = { ...lpRecord, id: this.lpAlertListRecord.id };
        savedRecordResp = await api.updateLpToBlacklistWhitelist(
          this.lotId,
          lpRecordToUpdate
        );
      }
      if (savedRecordResp == null || typeof savedRecordResp == "string") {
        this.$dialog.message.error(
          savedRecordResp ||
            "Error, save LP details to list. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      } else {
        this.closeForm();
        this.$emit("refresh-data");
      }
      this.isLoading = false;
    },

    closeForm() {
      this.resetFields();
      this.$emit("close-form");
    },
  },

  watch: {
    needsInit(showingData) {
      if (showingData) {
        this.initData();
      } else {
        this.resetFields();
      }
    },
  },
});
