











































































import Vue from "vue";
import { mapGetters } from "vuex";
import { NetworkDevices } from "@/api/models";
import NetworkDevicesForm from "@/components/forms/NetworkDevicesForm.vue";
import api from "@/api/api";

export default Vue.extend({
  name: "NetworkDevicesTable",

  components: {
    NetworkDevicesForm,
  },

  props: {
    parkingLotId: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    isLoading: false,
    showNetworkDevicesForm: false,
    selectedNetworkDevicesDetails: null as NetworkDevices | null,
    networkDevices: {
      headers: [
        { text: "Name", value: "name" },
        { text: "Device Type", value: "device_type" },
        { text: "Local IP Address", value: "local_ip_address" },
        { text: "Mac Address", value: "mac_address" },
        { text: "Manufacturer", value: "manufacturer" },
        { text: "Model", value: "model" },
        { text: "Location", value: "location" },
        { text: "Status", value: "status" },
      ],
      data: [] as Array<NetworkDevices>,
    },
  }),

  computed: {
    ...mapGetters("user", ["isSuperAdmin", "isTechnician"]),
  },

  mounted() {
    this.getNetworkDevicesData();
  },

  methods: {
    getRowClass() {
      return {
        "focusable-row": true,
      };
    },
    enhanceRowsAccessibility() {
      this.$nextTick(() => {
        const rows = document.querySelectorAll(".focusable-row");
        rows.forEach((row, index) => {
          row.setAttribute("tabindex", "0");
          row.addEventListener("keydown", (event) => {
            if (event instanceof KeyboardEvent) {
              const item = this.networkDevices.data[index];
              if (event.key === "Enter" || event.key === " ") {
                event.preventDefault();
                this.showNetworkDevicesDetailsInForm(item);
              }
            }
          });
        });
      });
    },
    async getNetworkDevicesData() {
      this.isLoading = true;
      let networkDevices = await api.getAllNetworkDevices(this.parkingLotId);
      if (networkDevices !== null) {
        this.networkDevices.data = networkDevices;
      } else {
        console.log(
          "Unable to load list of Network Devices for lot id",
          this.parkingLotId
        );
      }
      this.isLoading = false;
    },
    showNetworkDevicesDetailsInForm(
      networkDevicesOrId: number | NetworkDevices
    ) {
      let networkDevicesId = 0;
      if (typeof networkDevicesOrId === "object") {
        networkDevicesId = networkDevicesOrId.id;
      } else {
        networkDevicesId = networkDevicesOrId;
      }
      this.selectedNetworkDevicesDetails =
        this.networkDevices.data.find((c) => c.id === networkDevicesId) || null;
      if (this.selectedNetworkDevicesDetails) {
        this.showNetworkDevicesForm = true;
      }
    },
    closeNetworkDevicesForm() {
      this.selectedNetworkDevicesDetails = null;
      this.showNetworkDevicesForm = false;
    },
  },

  watch: {
    /**
     * Reset the NetworkDevicesForm whenever its dialog is hidden/closed.
     */
    showNetworkDevicesForm(showingForm) {
      if (!showingForm) {
        (this.$refs.networkDevicesForm as any).resetForm();
      }
    },
    "networkDevices.data": {
      handler() {
        this.enhanceRowsAccessibility();
      },
      immediate: true, // Ensure it runs on the initial render
    },
  },
});
