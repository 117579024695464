var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}}),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex aligh-start justify-end"},[_c('v-dialog',{attrs:{"max-width":"600px","fullscreen":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("Add NVR")])]}}]),model:{value:(_vm.showNvrForm),callback:function ($$v) {_vm.showNvrForm=$$v},expression:"showNvrForm"}},[_c('nvr-form',{ref:"nvrForm",attrs:{"parkingLotId":_vm.parkingLotId,"existingNvrDetails":_vm.selectedNvrDetails,"needsInit":_vm.showNvrForm},on:{"close-form":_vm.closeNvrForm,"refresh-data":_vm.getNvrsData}})],1)],1)],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.nvrs.headers,"items":_vm.nvrs.data,"items-per-page":10,"item-class":_vm.getRowClass},on:{"click:row":_vm.showNvrDetailsInForm},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"status-icon ml-4",attrs:{"small":"","color":item && !item.status ? 'red' : 'green',"tabindex":"0","aria-label":item && !item.status
                    ? 'Status Switched Off'
                    : 'Status Switched On'}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item && !item.status ? "Switched Off" : "Switched On"))])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }