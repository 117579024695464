
























































































































































































































































































































































import Vue from "vue";
import api from "@/api/api";
import dayjs from "dayjs";
import Chart, { ChartItem, ChartConfiguration } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { getTodaysDate } from "@/libs/dateUtils";

export interface ChartDataset {
  label: string;
  data: Array<number>;
  borderColor: string;
  backgroundColor: string;
}
export interface enabledLots {
  id: number;
  name: string;
}

export interface filterInfo {
  label: string;
  value: number;
}

export default Vue.extend({
  name: "SpotGeniusAccuracyChart",
  data() {
    return {
      // CHART
      AllParkingAccuracyChart: null as Chart<"line"> | null,
      enabled_lot_ids: [] as Array<enabledLots>,
      selected_lots: [] as Array<number>,
      chart: {
        isLoading: false,
        showStartDate: false,
        showStartTime: false,
        startDate: "",
        startTime: "00:00",
        showEndDate: false,
        showEndTime: false,
        endDate: "",
        endTime: "23:59",
        granularity: [
          { label: "15 Minutes", value: 60 * 15 },
          { label: "Daily", value: 60 * 60 * 24 },
          { label: "Weekly", value: 60 * 60 * 24 * 7 },
          { label: "Monthly", value: 60 * 60 * 24 * 30 },
        ],
        selectedGranularity: 60 * 60 * 24 * 7, //WEEKLY
        duration: [
          { label: "1 Day", value: 0 },
          { label: "1 Week", value: 1 },
          { label: "1 Month", value: 2 },
          { label: "Last Month", value: 3 },
          { label: "Custom", value: 4 },
        ],
        selectedDuration: 2,
        selectedOccupancyType: 1,
        occupanyType: [
          { label: "Maximum Occupancy", value: 1 },
          { label: "Minimum Occupancy", value: 0 },
        ],
        type: "line",
        labels: [], //x axis labels --> in our case dates
        data: {
          labels: [] as Array<Array<string>>,
          datasets: [] as Array<ChartDataset>,
        },
        options: {
          // responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              title: {
                display: true,
                text: "Metric %",
              },
              grace: 1,
            },
            x: {
              title: {
                display: true,
                text: "Date and Time",
              },
              grid: {
                color: ["#D3D3D3"],
                lineWidth: 2,
              },
              ticks: {
                color: ["rgb(0,0,0)"],
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: "Model Accuracy",
            },
            legend: {
              position: "top",
            },
            tooltip: {
              intersect: false,
              mode: "index",
              titleColor: "#DF5286",
              titleFont: { weight: "bold" },
            },
            datalabels: {
              clip: false,
              align: "top",
              formatter: function (value: any, context: any) {
                return Math.round(value) + "%";
              },
              font: {
                size: "14px",
                weight: "bold",
              },
            },
          },
        },
        plugins: [ChartDataLabels],
      },
    };
  },
  mounted() {
    this.fetchEnabledLots();
  },
  methods: {
    initializeChart() {
      const ctx = document.getElementById(
        "all-parkings-accuracy-chart"
      ) as ChartItem;
      this.AllParkingAccuracyChart = new Chart(ctx, this.chart as any);
    },
    async fetchAllParkingAccuracyData() {
      if (this.AllParkingAccuracyChart == null) {
        this.setChartDates();
        this.initializeChart();
      }
      this.chart.isLoading = true;
      //line names
      this.chart.data.labels = [];
      //line data points
      this.chart.data.datasets = [];

      try {
        //modify to be new function getAccuracyDetails
        let AllParkingAccuracyData = await api.getParkingLotsAvgMetricsData(
          this.selected_lots,
          `${this.chart.startDate} ${this.chart.startTime}`,
          `${this.chart.endDate} ${this.chart.endTime}`,
          this.chart.selectedGranularity,
          this.chart.selectedOccupancyType
        );

        let final_x_axis_labels_colors = [];
        let final_axis_colors = [];
        for (var i = 0; i < AllParkingAccuracyData.verified.length; i++) {
          if (AllParkingAccuracyData.verified[i] == 0) {
            final_axis_colors.push("#D3D3D3");
            final_x_axis_labels_colors.push("#656255");
          } else if (AllParkingAccuracyData.verified[i] == 2) {
            final_x_axis_labels_colors.push("rgb(0,0,0)");
            final_axis_colors.push("rgb(0,0,0)");
          } else {
            final_axis_colors.push("#FFD300");
            final_x_axis_labels_colors.push("#FFD300");
          }
        }
        this.chart.options.scales.x.grid.color = final_axis_colors;
        this.chart.options.scales.x.ticks.color = final_x_axis_labels_colors;
        if (AllParkingAccuracyData) {
          const granularity = this.chart.granularity.find(
            (g) => g.value === this.chart.selectedGranularity
          )?.label;
          const diffDays = this.getDateDiffInDays(
            this.chart.startDate,
            this.chart.endDate
          );
          let timestamps = [] as Array<string>;
          let final_labels = [] as Array<Array<string>>;
          for (
            var index = 0;
            index < AllParkingAccuracyData.labels.length;
            index++
          ) {
            if (this.chart.selectedGranularity >= 60 * 60 * 24) {
              let labels = [AllParkingAccuracyData.labels[index].split("T")[0]];
              if (this.chart.selectedGranularity >= 60 * 60 * 24 * 7) {
                labels.push(
                  `(${
                    AllParkingAccuracyData.start_labels[index].split("T")[0]
                  } - ${
                    AllParkingAccuracyData.end_labels[index].split("T")[0]
                  })`
                );
              }
              final_labels.push(labels);
            } else {
              final_labels.push([
                AllParkingAccuracyData.labels[index].split("T")[0],
                AllParkingAccuracyData.labels[index].split("T")[1],
              ]);
            }
          }
          this.chart.data.labels = final_labels;

          // set width of the chart based on number of labels
          const chartContainer: HTMLElement | null =
            document.querySelector(".chartAreaWrapper2");
          if (chartContainer) {
            // 80px is enough to show one data point
            const singleDataPointWidth = 80;
            const numberOfViewableDataPoints =
              (window.innerWidth * singleDataPointWidth) /
              100 /
              singleDataPointWidth;
            if (this.chart.data.labels.length > numberOfViewableDataPoints) {
              chartContainer.style.width = `${
                this.chart.data.labels.length * singleDataPointWidth
              }px`;
            } else {
              chartContainer.style.width = "95vw";
            }
          }

          for (let row of AllParkingAccuracyData.labels) {
            const d = new Date(row.ts * 1000);
            if (this.chart.selectedGranularity > 60 * 60) {
              timestamps.push(
                `${d.toLocaleString("en-us", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                  weekday: "long",
                })}`
              );
              this.chart.options.scales.x.title.text = "Date";
              if (this.chart.selectedGranularity === 60 * 60 * 24)
                this.chart.options.scales.x.title.text = "Each Day";
              else if (this.chart.selectedGranularity === 60 * 60 * 24 * 7)
                this.chart.options.scales.x.title.text = "Each Week";
            } else if (diffDays < 1) {
              timestamps.push(
                `${d.toLocaleString("en-US", {
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: true,
                })}`
              );
              this.chart.options.scales.x.title.text = `Date - ${new Date(
                `${this.chart.endDate} 23:59`
              ).toLocaleString("en-us", {
                day: "2-digit",
                month: "long",
                year: "numeric",
                weekday: "long",
              })} ${this.timeConvert(
                this.chart.startTime
              )} - ${this.timeConvert(
                this.chart.endTime
              )}, (Every ${granularity})`;
            } else {
              timestamps.push(
                `${d.toLocaleString("en-US", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  weekday: "long",
                  hour12: true,
                })}`
              );
              this.chart.options.scales.x.title.text = "Date and Time";
            }
          }
          // Pushing data to graph
          //   this.verification_status = AllParkingAccuracyData.verified;
          let data_set_effectiveness = {
            label: "Effectiveness",
            data: AllParkingAccuracyData.effectiveness,
            fill: false,
            borderColor: "rgb(0, 255, 0)",
            backgroundColor: "rgb(0, 255, 0)",
            datalabels: {
              color: "rgb(3, 192, 60)",
            },
            tension: 0.1,
          };
          let data_set_accuracy = {
            label: "Accuracy AI",
            data: AllParkingAccuracyData.accuracy,
            fill: false,
            borderColor: "rgb(255, 0, 0)",
            backgroundColor: "rgb(255, 0, 0)",
            datalabels: {
              color: "rgb(255, 0, 0)",
            },
            tension: 0.1,
          };
          let data_set_efficiency = {
            label: "Surety AI",
            data: AllParkingAccuracyData.efficiency,
            fill: false,
            borderColor: "rgb(253, 208, 23)",
            backgroundColor: "rgb(253, 208, 23)",
            datalabels: {
              color: "rgb(253, 208, 23)",
            },
            tension: 0.1,
          };
          let data_set_trackability = {
            label: "Trackability",
            data: AllParkingAccuracyData.trackability,
            fill: false,
            borderColor: "rgb(0, 0, 255)",
            backgroundColor: "rgb(0, 0, 255)",
            datalabels: {
              color: "rgb(0, 0, 255)",
            },
            tension: 0.1,
          };
          this.chart.data.datasets.push(data_set_effectiveness);
          this.chart.data.datasets.push(data_set_accuracy);
          this.chart.data.datasets.push(data_set_efficiency);
          this.chart.data.datasets.push(data_set_trackability);
        }
      } catch (e) {
        this.$dialog.message.error(
          "Error, unable to load latest data. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      } finally {
        if (this.AllParkingAccuracyChart) this.AllParkingAccuracyChart.update();
        this.chart.isLoading = false;
      }
    },
    setChartDates() {
      const today = new Date();
      switch (this.chart.selectedDuration) {
        case 0:
          this.chart.startDate = this.todaysDate;
          this.chart.startTime = "00:00";
          this.chart.endTime = "23:59";
          break;
        case 1:
          this.chart.startDate = this.getLocalDate(
            new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6)
          );
          break;
        case 2:
          this.chart.startDate = this.getLocalDate(
            new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() - 30
            )
          );
          break;
        case 3:
          this.chart.startDate = this.convertDate(
            new Date(today.getFullYear(), today.getMonth() - 1, 1)
          );
          this.chart.endDate = this.convertDate(
            new Date(today.getFullYear(), today.getMonth(), 0)
          );
          break;
        default:
          this.chart.startDate = this.getLocalDate(
            new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6)
          );
          break;
      }
      if (this.chart.selectedDuration !== 3) {
        this.chart.endDate = this.todaysDate;
      }

      this.chart.granularity = [
        { label: "5 Minutes", value: 60 * 5 },
        { label: "15 Minutes", value: 60 * 15 },
        { label: "30 Minutes", value: 60 * 30 },
        { label: "60 Minutes", value: 60 * 60 },
        { label: "Daily", value: 60 * 60 * 24 },
        { label: "Weekly", value: 60 * 60 * 24 * 7 },
      ].filter((g, i) => {
        switch (this.chart.selectedDuration) {
          case 0:
            return [1, 2, 3].includes(i);
          case 1:
            return [4].includes(i);
          case 2:
            return [4, 5].includes(i);
          case 3:
            return [4, 5].includes(i);
          default:
            return [3, 4, 5].includes(i);
        }
      });
      this.chart.selectedGranularity = this.chart.granularity[0].value;
      if (this.chart.selectedDuration == 0) {
        this.chart.selectedGranularity = 60 * 30;
      } else if (this.chart.selectedDuration == 1) {
        this.chart.selectedGranularity = 60 * 60 * 24;
      }
      this.setChartGranularity();
    },
    setChartGranularity() {
      // Set granularity options and selection for Custom start and end date
      if (this.chart.selectedDuration === 2) {
        const diffDays = this.getDateDiffInDays(
          this.chart.startDate,
          this.chart.endDate
        );
        if (diffDays > 1) {
          if (diffDays <= 7) {
            this.chart.granularity = [{ label: "Daily", value: 60 * 60 * 24 }];
            this.chart.selectedGranularity = 60 * 60 * 24;
          } else {
            this.chart.granularity = [
              { label: "Daily", value: 60 * 60 * 24 },
              { label: "Weekly", value: 60 * 60 * 24 * 7 },
            ];
            this.chart.selectedGranularity = 60 * 60 * 24 * 7;
          }
        } else {
          this.chart.granularity = [
            { label: "15 Minutes", value: 60 * 15 },
            { label: "30 Minutes", value: 60 * 30 },
            { label: "1 hour", value: 60 * 60 },
          ];
          this.chart.selectedGranularity = 60 * 60;
        }
      } else {
        if (this.chart.selectedDuration == 1) {
          this.chart.granularity = [
            { label: "Daily", value: 60 * 60 * 24 },
            { label: "Weekly", value: 60 * 60 * 24 * 7 },
          ];
          this.chart.selectedGranularity = 60 * 60 * 24 * 7;
        }
      }
    },
    getDateDiffInDays(startDate: string, endDate: string) {
      const diffTime = Math.abs(
        new Date(endDate).getTime() - new Date(startDate).getTime()
      );
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    // convert 24 hour time to 12 hour time format
    timeConvert(time: string) {
      return new Date("1970-01-01T" + time + "Z").toLocaleTimeString("en-US", {
        timeZone: "UTC",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      });
    },
    refreshPage() {
      // this.chart.startDate = this.todaysDate;
      // this.chart.endDate = this.todaysDate;
      // this.chart.startTime = "00:00";
      // this.chart.endTime = "23:59";
      // this.chart.selectedGranularity = 60 * 60 * 24 * 7;
      // this.chart.selectedDuration = 2;
      // this.setChartDates();
      this.fetchAllParkingAccuracyData();
    },
    convertDate(date: Date) {
      const yyyy = date.getFullYear().toString();
      const mm = (date.getMonth() + 1).toString();
      const dd = date.getDate().toString();

      const mmChars = mm.split("");
      const ddChars = dd.split("");

      return (
        yyyy +
        "-" +
        (mmChars[1] ? mm : "0" + mmChars[0]) +
        "-" +
        (ddChars[1] ? dd : "0" + ddChars[0])
      );
    },
    async fetchEnabledLots() {
      this.chart.isLoading = true;
      this.enabled_lot_ids = await api.fetchATEnabledLots();
      if (this.enabled_lot_ids !== null) {
        for (var i = 0; i < this.enabled_lot_ids.length; i++) {
          this.selected_lots.push(this.enabled_lot_ids[i].id);
        }
        // this.fetchAllParkingAccuracyData();
      }
      this.chart.isLoading = false;
    },
    setTimeIfSameDay() {
      if (this.chart.startDate === this.chart.endDate) {
        this.chart.startTime = "00:00";
        this.chart.endTime = "23:59";
        this.chart.granularity = [
          { label: "15 Minutes", value: 60 * 15 },
          { label: "30 Minutes", value: 60 * 30 },
          { label: "1 hour", value: 60 * 60 },
        ];
      }
    },
    getLocalDate(d: Date) {
      return dayjs(d).format("YYYY-MM-DD");
    },
  },
  watch: {
    "chart.startDate"() {
      this.setTimeIfSameDay();
    },
    "chart.endDate"() {
      this.setTimeIfSameDay();
    },
  },
  computed: {
    singleDaySelected() {
      if (
        this.chart.startDate != null &&
        this.chart.startDate != "" &&
        this.chart.endDate != null &&
        this.chart.endDate != "" &&
        this.chart.startDate === this.chart.endDate
      ) {
        return true;
      }
      return false;
    },
    todaysDate() {
      return getTodaysDate();
    },
  },
});
