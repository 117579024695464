var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[(_vm.untrackedZoneData)?_c('v-card-title',{attrs:{"tabindex":"0"}},[_vm._v("Parking Zone "+_vm._s(_vm.untrackedZoneData.name)+" ID "+_vm._s(_vm.untrackedZoneData.id))]):_vm._e(),_c('v-card-text',[_c('v-form',{model:{value:(_vm.allFieldsValid),callback:function ($$v) {_vm.allFieldsValid=$$v},expression:"allFieldsValid"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Total Spots","hint":_vm.untrackedZoneData.num_total_untracked_spots &&
                _vm.numTotalUntrackedSpots ==
                  _vm.untrackedZoneData.num_free_untracked_spots
                  ? 'Total number of free spots'
                  : 'Click on Save to save the new Available spots count',"rules":[_vm.validNumber],"type":"number","persistent-hint":""},on:{"keypress":function($event){return _vm.isInteger($event)}},model:{value:(_vm.numTotalUntrackedSpots),callback:function ($$v) {_vm.numTotalUntrackedSpots=_vm._n($$v)},expression:"numTotalUntrackedSpots"}})],1),_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.numTotalUntrackedSpots <= 0,"aria-label":"Total Spots Minus"},on:{"click":function($event){_vm.numTotalUntrackedSpots--}}},[_c('v-icon',[_vm._v("mdi-minus")])],1)],1),_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('v-btn',{attrs:{"aria-label":"Total Spots Plus","icon":""},on:{"click":function($event){_vm.numTotalUntrackedSpots++}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Available Spots","hint":_vm.untrackedZoneData.num_free_untracked_spots &&
                _vm.numFreeUntrackedSpots ==
                  _vm.untrackedZoneData.num_free_untracked_spots
                  ? 'Total number of free spots'
                  : 'Click on Save to save the new Available spots count',"rules":[
                function (v) { return v <= _vm.numTotalUntrackedSpots ||
                  'Available spots count must not exceed total count.'; },
                function (v) { return v >= 0 ||
                  'Available spots count must not be lesser than zero.'; } ],"type":"number","disabled":_vm.isNumFreeUntrackedSpotsAutomatic,"persistent-hint":""},on:{"keypress":function($event){return _vm.isInteger($event)}},model:{value:(_vm.numFreeUntrackedSpots),callback:function ($$v) {_vm.numFreeUntrackedSpots=_vm._n($$v)},expression:"numFreeUntrackedSpots"}})],1),_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.isNumFreeUntrackedSpotsAutomatic || _vm.numFreeUntrackedSpots <= 0,"aria-label":"Available Spots Minus"},on:{"click":function($event){_vm.numFreeUntrackedSpots--}}},[_c('v-icon',[_vm._v("mdi-minus")])],1)],1),_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.isNumFreeUntrackedSpotsAutomatic ||
                _vm.numFreeUntrackedSpots >= _vm.numTotalUntrackedSpots,"aria-label":"Available Spots Plus"},on:{"click":function($event){_vm.numFreeUntrackedSpots++}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"inset":"","label":"Enable automatic counting of Available Parking spots","disabled":!_vm.untrackedZoneHasCameras,"hint":"Sets the free spots counts automatically by monitoring camera streams of this zone. Note, this option can only be enabled if cameras are assigned to this zone.","persistent-hint":""},model:{value:(_vm.isNumFreeUntrackedSpotsAutomatic),callback:function ($$v) {_vm.isNumFreeUntrackedSpotsAutomatic=$$v},expression:"isNumFreeUntrackedSpotsAutomatic"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close-form')}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"text":"","loading":_vm.saveRequestIsLoading,"disabled":!_vm.allFieldsValid},on:{"click":_vm.updateCount}},[_vm._v("Save")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }