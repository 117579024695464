var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}}),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex aligh-start justify-end"},[_c('v-dialog',{attrs:{"max-width":"600px","fullscreen":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("Add Network Device")])]}}]),model:{value:(_vm.showNetworkDevicesForm),callback:function ($$v) {_vm.showNetworkDevicesForm=$$v},expression:"showNetworkDevicesForm"}},[_c('network-devices-form',{ref:"networkDevicesForm",attrs:{"parkingLotId":_vm.parkingLotId,"existingNetworkDeviceDetails":_vm.selectedNetworkDevicesDetails,"needsInit":_vm.showNetworkDevicesForm},on:{"close-form":_vm.closeNetworkDevicesForm,"refresh-data":_vm.getNetworkDevicesData}})],1)],1)],1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.networkDevices.headers,"items":_vm.networkDevices.data,"items-per-page":10,"item-class":_vm.getRowClass},on:{"click:row":_vm.showNetworkDevicesDetailsInForm},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"status-icon ml-4",attrs:{"small":"","color":item && !item.status ? 'red' : 'green',"tabindex":"0","aria-label":item && !item.status
                    ? 'Status Switched Off'
                    : 'Status Switched On'}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item && !item.status ? "Switched Off" : "Switched On"))])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }