











































































import Vue from "vue";
import { mapGetters } from "vuex";
import { NetworkVideoRecorders } from "@/api/models";
import NvrForm from "@/components/forms/NvrForm.vue";
import api from "@/api/api";

export default Vue.extend({
  name: "NvrTable",

  components: {
    NvrForm,
  },

  props: {
    parkingLotId: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    isLoading: false,
    showNvrForm: false,
    selectedNvrDetails: null as NetworkVideoRecorders | null,
    nvrs: {
      headers: [
        { text: "Name", value: "name" },
        { text: "Public IP Address", value: "public_ip_address" },
        { text: "Local IP Address", value: "local_ip_address" },
        { text: "Number of Channels", value: "number_of_channels" },
        { text: "Application Port", value: "application_port" },
        { text: "RTSP Port", value: "rtsp_port" },
        { text: "HTTP Port", value: "http_port" },
        { text: "Status", value: "status" },
      ],
      data: [] as Array<NetworkVideoRecorders>,
    },
  }),

  computed: {
    ...mapGetters("user", ["isSuperAdmin", "isTechnician"]),
  },

  mounted() {
    this.getNvrsData();
  },

  methods: {
    getRowClass() {
      return {
        "focusable-row": true,
      };
    },
    enhanceRowsAccessibility() {
      this.$nextTick(() => {
        const rows = document.querySelectorAll(".focusable-row");
        rows.forEach((row, index) => {
          row.setAttribute("tabindex", "0");
          row.addEventListener("keydown", (event) => {
            if (event instanceof KeyboardEvent) {
              const item = this.nvrs.data[index];
              if (event.key === "Enter" || event.key === " ") {
                event.preventDefault();
                this.showNvrDetailsInForm(item);
              }
            }
          });
        });
      });
    },
    async getNvrsData() {
      this.isLoading = true;
      let nvrs = await api.getAllNvrs(this.parkingLotId);
      if (nvrs !== null) {
        this.nvrs.data = nvrs;
      } else {
        console.log(
          "Unable to load list of nvrs for lot id",
          this.parkingLotId
        );
      }
      this.isLoading = false;
    },
    showNvrDetailsInForm(nvrOrId: number | NetworkVideoRecorders) {
      let nvrId = 0;
      if (typeof nvrOrId === "object") {
        nvrId = nvrOrId.id;
      } else {
        nvrId = nvrOrId;
      }
      this.selectedNvrDetails =
        this.nvrs.data.find((c) => c.id === nvrId) || null;
      if (this.selectedNvrDetails) {
        this.showNvrForm = true;
      }
    },
    closeNvrForm() {
      this.selectedNvrDetails = null;
      this.showNvrForm = false;
    },
  },

  watch: {
    /**
     * Reset the NvrForm whenever its dialog is hidden/closed.
     */
    showNvrForm(showingForm) {
      if (!showingForm) {
        (this.$refs.nvrForm as any).resetForm();
      }
    },
    "nvrs.data": {
      handler() {
        this.enhanceRowsAccessibility();
      },
      immediate: true, // Ensure it runs on the initial render
    },
  },
});
