

























































































































































































































































































import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import api from "@/api/api";
import {
  ParkingLotUntrackedInfoOnly,
  ParkingZoneUntrackedInfoOnly,
} from "@/api/models";
import UntrackedZoneCountForm from "@/components/forms/UntrackedZoneCountForm.vue";

enum ZoneOperation {
  incrementFree,
  decrementFree,
  incrementTotal,
  decrementTotal,
  toggleAutoCount,
}

export default Vue.extend({
  name: "UntrackedZones",

  components: {
    UntrackedZoneCountForm,
  },

  data() {
    return {
      lotId: 0,
      breadcrumbItems: [
        {
          text: "Home",
          disabled: false,
          to: { name: "Home" },
        },
        {
          text: "Car Counting Zones",
          disabled: true,
        },
      ],
      parkingLots: [] as Array<ParkingLotUntrackedInfoOnly>,
      zoneTableHeaders: [
        { text: "Zone ID", value: "id" },
        { text: "Zone Name", value: "name" },
        { text: "Capacity", value: "num_total_untracked_spots" },
        { text: "Occupied Spots", value: "num_occupied_untracked_spots" },
        { text: "Free Spots", value: "num_free_untracked_spots" },
        {
          text: "Automatically counted",
          value: "is_num_free_untracked_spots_automatic",
        },
      ],
      isShowingCountForm: false,
      selectedZoneData: null as ParkingZoneUntrackedInfoOnly | null,
      selectedZonesLotId: null as number | null,

      isLoading: {
        untrackedZoneData: false,
        totalDecrementZoneId: 0,
        totalIncrementZoneId: 0,
        freeDecrementZoneId: 0,
        freeIncrementZoneId: 0,
        autoCountChangedZoneId: 0,
      },
      refreshInterval: null as number | null,
      showInstructions: false,
    };
  },
  computed: {
    ...mapGetters("user", ["isOperator"]),
  },

  created() {
    this.lotId = Number(this.$route.params.lotId);
  },

  mounted() {
    this.getLotData(this.lotId);
    this.getUntrackedData();
    this.refreshInterval = setInterval(async () => {
      await this.getUntrackedData(false);
    }, 1000 * 60);
  },

  methods: {
    ...mapActions("data", ["initCurrentParkingLotData"]),
    getRowClass() {
      return {
        "focusable-row": true,
      };
    },
    enhanceRowsAccessibility() {
      this.$nextTick(() => {
        const rows = document.querySelectorAll(".focusable-row");
        rows.forEach((row, index) => {
          row.setAttribute("tabindex", "0");
        });
      });
    },
    async getLotData(lotId: number | null) {
      if (lotId) {
        let parkingLotData = await api.getParkingLot(lotId);
        this.initCurrentParkingLotData(parkingLotData);

        if (
          parkingLotData &&
          this.breadcrumbItems[0].text == "Home" &&
          this.lotId
        ) {
          this.breadcrumbItems[0].text = parkingLotData.name;
          this.breadcrumbItems[0].to = { name: "LotDashboard" };
        }
      }
    },

    async getUntrackedData(showLoader = true) {
      if (showLoader) {
        this.isLoading.untrackedZoneData = true;
      }
      let parkingLots = await api.getLotsWithUntrackedZonesInfo();
      if (parkingLots) {
        if (this.lotId) {
          parkingLots = parkingLots.filter((lot) => lot.id == this.lotId);
        }
        let lotsWithUntrackedZones = [];
        for (let lot of parkingLots) {
          let untrackedZones = lot.parking_zones.filter(
            (zone: ParkingZoneUntrackedInfoOnly) =>
              zone.is_untracked && zone.zone_type != "time_limited_zone"
          );
          if (untrackedZones.length > 0) {
            lot.parking_zones = untrackedZones;
            lotsWithUntrackedZones.push(lot);
          }
        }
        this.parkingLots = lotsWithUntrackedZones;
      } else {
        this.$dialog.message.error(
          "Failed to load zone data. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
      this.isLoading.untrackedZoneData = false;
    },

    openCountForm(zoneData: ParkingZoneUntrackedInfoOnly) {
      this.selectedZoneData = zoneData;
      this.selectedZonesLotId = 0;
      this.isShowingCountForm = true;
    },

    closeCountForm() {
      this.isShowingCountForm = false;
      this.selectedZoneData = null;
    },

    incrementTotalCount(
      parkingLotId: number,
      zoneData: ParkingZoneUntrackedInfoOnly
    ) {
      zoneData.num_total_untracked_spots =
        (zoneData.num_total_untracked_spots || 0) + 1;
      zoneData.num_free_untracked_spots =
        (zoneData.num_free_untracked_spots || 0) + 1;
      this.updateZoneFreeCount(
        parkingLotId,
        zoneData,
        ZoneOperation.incrementTotal
      );
    },

    decrementTotalCount(
      parkingLotId: number,
      zoneData: ParkingZoneUntrackedInfoOnly
    ) {
      zoneData.num_total_untracked_spots =
        (zoneData.num_total_untracked_spots || 1) - 1;
      zoneData.num_free_untracked_spots =
        (zoneData.num_free_untracked_spots || 1) - 1;
      this.updateZoneFreeCount(
        parkingLotId,
        zoneData,
        ZoneOperation.decrementTotal
      );
    },

    incrementFreeCount(
      parkingLotId: number,
      zoneData: ParkingZoneUntrackedInfoOnly
    ) {
      zoneData.num_free_untracked_spots =
        (zoneData.num_free_untracked_spots || 0) + 1;
      this.updateZoneFreeCount(
        parkingLotId,
        zoneData,
        ZoneOperation.incrementFree
      );
    },

    decrementFreeCount(
      parkingLotId: number,
      zoneData: ParkingZoneUntrackedInfoOnly
    ) {
      zoneData.num_free_untracked_spots =
        (zoneData.num_free_untracked_spots || 1) - 1;
      this.updateZoneFreeCount(
        parkingLotId,
        zoneData,
        ZoneOperation.decrementFree
      );
    },

    toggleAutoCountSwitch(
      parkingLotId: number,
      zoneData: ParkingZoneUntrackedInfoOnly
    ) {
      this.updateZoneFreeCount(
        parkingLotId,
        zoneData,
        ZoneOperation.toggleAutoCount
      );
    },

    /**
     * Return true if the given zone id has cameras assigned to monitor it.
     */
    async checkUntrackedZoneHasCameras(
      parkingLotId: number,
      untrackedZoneId: number
    ) {
      let assignedCameras = await api.getAllCamerasAssignedToUntrackedZone(
        parkingLotId,
        untrackedZoneId
      );
      if (!assignedCameras || assignedCameras.length < 1) {
        this.$dialog.message.error(
          "Error, cannot enable automatic counting since this zone does not have any cameras monitoring it. " +
            "Please assign some cameras to this zone and try again.",
          {
            position: "top-right",
            timeout: 10000,
          }
        );
        return false;
      }
      return true;
    },

    updateZoneFreeCount: _.debounce(async function (
      this: any,
      parkingLotId: number,
      zoneData: ParkingZoneUntrackedInfoOnly,
      zoneOperation: ZoneOperation
    ) {
      console.log("Updating zone data", parkingLotId, zoneData);
      if (zoneData.is_num_free_untracked_spots_automatic) {
        // Check if untracked zone has cameras assigned to it before proceeding
        if (
          this.checkUntrackedZoneHasCameras(parkingLotId, zoneData.id) != true
        ) {
          zoneData.is_num_free_untracked_spots_automatic = false;
          return;
        }
        // Reset to zero if automatic
        zoneData.num_free_parking_spots = 0;
      } else {
        // Do not exceed total count
        zoneData.num_free_parking_spots = Math.min(
          zoneData.num_free_untracked_spots || 0,
          zoneData.num_total_untracked_spots || 0
        );
      }

      this.setTableButtonsLoadingSpinner(zoneOperation, zoneData.id);
      let updatedCount = {
        id: zoneData.id,
        num_free_untracked_spots: zoneData.num_free_parking_spots,
        num_total_untracked_spots: zoneData.num_total_untracked_spots,
        is_num_free_untracked_spots_automatic:
          zoneData.is_num_free_untracked_spots_automatic,
      };
      let updatedUntrackedZone = await api.updateUntrackedZoneCount(
        parkingLotId,
        zoneData.id,
        updatedCount
      );
      this.resetTableButtonsLoadingSpinners();

      if (updatedUntrackedZone) {
        await this.getUntrackedData(false);
      } else {
        this.$dialog.message.error(
          "Failed to save changes. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },
    1000),

    /**
     * Set appropriate loading flag based on the operation performed.
     */
    setTableButtonsLoadingSpinner(
      zoneOperation: ZoneOperation,
      zoneId: number
    ) {
      switch (zoneOperation) {
        case ZoneOperation.decrementTotal: {
          this.isLoading.totalDecrementZoneId = zoneId;
          break;
        }
        case ZoneOperation.incrementTotal: {
          this.isLoading.totalIncrementZoneId = zoneId;
          break;
        }
        case ZoneOperation.decrementFree: {
          this.isLoading.freeDecrementZoneId = zoneId;
          break;
        }
        case ZoneOperation.incrementFree: {
          this.isLoading.freeIncrementZoneId = zoneId;
          break;
        }
        case ZoneOperation.toggleAutoCount: {
          this.isLoading.autoCountChangedZoneId = zoneId;
          break;
        }
      }
    },

    resetTableButtonsLoadingSpinners() {
      this.isLoading.totalDecrementZoneId = 0;
      this.isLoading.totalIncrementZoneId = 0;
      this.isLoading.freeDecrementZoneId = 0;
      this.isLoading.freeIncrementZoneId = 0;
      this.isLoading.autoCountChangedZoneId = 0;
    },
  },

  destroyed() {
    if (this.refreshInterval !== null) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }
  },

  watch: {
    "lot.parking_zones": {
      handler() {
        this.enhanceRowsAccessibility();
      },
      immediate: true, // Ensure it runs on the initial render
    },
  },
});
