














































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import api from "@/api/api";
import {
  formatDurationSeconds,
  formatDurationShowSeconds,
} from "@/libs/dateUtils";
import _ from "lodash";
import {
  VehicleParkingUsageTrackingAssociation,
  ParkingLotBasic,
  VehicleParkingUsageTrackingRecordIgnored,
} from "@/api/models";
import { getElapsedTimeShort } from "@/libs/dateUtils";
import VehicleParkingUsageRecordDetails from "@/components/VehicleParkingUsageRecordDetails.vue";
import TimePicker from "@/components/timepicker/TimePicker.vue";
import { mapGetters } from "vuex";
import dayjs from "dayjs";

const VehicleParkingUsageTrackingRecordIgnoredItems: Array<
  Record<string, string>
> = [
  {
    text: "Qualified Data",
    value: "all",
  },
  // {
  //   text: "LP Confidence Low",
  //   value: "lp_confidence_low",
  // },
  {
    text: "Ignored - Low Vehicle Orientation Score",
    value: "orientation_confidence_low",
  },
  {
    text: "Ignored - Low Vehicle Type Score",
    value: "type_confidence_low",
  },
  // {
  //   text: "Make Model Confidence Low",
  //   value: "make_model_confidence_low",
  // },
  // {
  //   text: "Color Confidence Low",
  //   value: "color_confidence_low",
  // },
  // {
  //   text: "Region Confidence Low",
  //   value: "region_confidence_low",
  // },
  // {
  //   text: "D Score Low",
  //   value: "d_score_low",
  // },
  {
    text: "Ignored - Direction not in Range",
    value: "direction_not_in_range",
  },
];

import Vue from "vue";
export default Vue.extend({
  name: "LpParkingTracking",

  components: {
    VehicleParkingUsageRecordDetails,
    TimePicker,
  },

  props: {
    lotId: {
      required: false,
      type: Number,
    },
  },

  data() {
    return {
      isLoading: false,
      vehicleParkingUsage: [] as Array<VehicleParkingUsageTrackingAssociation>,

      selectedVehicleParkingUsageRecordId: null as number | null,
      showVehicleParkingUsageDialog: false,

      pagination: {
        page: 1,
        itemsCount: 0,
        itemsPerPage: 10,
        lastPage: 1,
      },

      filters: {
        isLoading: false,

        vehicleParkingUsageTrackingRecordIgnored: Object.freeze(
          VehicleParkingUsageTrackingRecordIgnoredItems
        ) as Array<Record<string, string>>,

        licensePlateDetected: "" as string,
        parkingLotId: null as number | null,
        cameraId: null as number | null,
        verifiedOnly: false,

        vehicleBrand: "" as string,
        vehicleColor: "" as string,
        vehicleType: "" as string,

        dateMenu: {
          showMenu: false,
          value: [] as Array<string>,
        },
        timeMenu: {
          start: {
            showMenu: false,
            value: "00:00" as string,
          },
          end: {
            showMenu: false,
            value: "23:59" as string,
          },
        },
        timeSliced: false,

        record_id: null as number | null,
        record_id_match: "equal_to" as string,
        entry_exit_pair: null as string | null,
        lot_usage_time: null as number | null,
        lot_usage_time_match: "equal_to" as string,
        parking_usage_time: null as number | null,
        parking_usage_time_match: "equal_to" as string,

        autoRefreshParkingUsage: false,
        loadingParkingUsage: false,
        autoRefreshUpdatedAt: null as Date | null,

        sortBy: null as string | null,
        sortOrder: null as boolean | null,

        is_ignored: ["all"] as Array<string>,
      },

      parkingLotInfo: null as ParkingLotBasic | null,
      lotCameras: [] as Array<{ id: number; label: string; value: number }>,
      autoRefreshIntervalId: null as number | null,

      selectedLPRHeaders: [
        "license_plate_detected",
        "is_complete",
        "lot_usage_time",
        "created_at",
      ] as Array<string>,

      IS_FEATURE_4761_UNTRACKED_ZONES_LPR_ALERTS_ENABLED:
        process.env.VUE_APP_IS_FEATURE_4761_UNTRACKED_ZONES_LPR_ALERTS_ENABLED,

      lastUpdatedAt: "",
      lastUpdatedAtAria: "",
    };
  },

  filters: {
    formatDurationSeconds,
    formatDurationShowSeconds,
  },

  computed: {
    ...mapGetters("user", ["isSuperAdmin"]),
    showAnprFields(): boolean {
      if (this.isSuperAdmin && this.parkingLotInfo?.is_anpr_feature_enabled) {
        return true;
      }

      // For other user types
      if (
        this.parkingLotInfo?.is_anpr_feature_enabled &&
        this.parkingLotInfo?.is_anpr_feature_visible_to_customers
      ) {
        return true;
      }

      return false;
    },
    allVehicleParkingUsageHeaders(): Array<Record<string, string | boolean>> {
      const extraHeaders = [];
      if (this.IS_FEATURE_4761_UNTRACKED_ZONES_LPR_ALERTS_ENABLED) {
        extraHeaders.push({
          text: "Zone ID",
          value: "parking_zone_id",
          sortable: false,
        });
      }

      if (this.showAnprFields) {
        let headers = [];
        headers = [
          { text: "ID", value: "id", sortable: false },
          {
            text: "Vehicle LP",
            value: "license_plate_detected",
            sortable: false,
          },
          ...extraHeaders,
        ];
        if (this.isSuperAdmin) {
          headers.push({
            text: "Verification Status",
            value: "is_verified",
            sortable: false,
          });
        }
        const remaining_headers = [
          {
            text: "Complete Entry/Exit Pair",
            value: "is_complete",
            sortable: false,
          },
          { text: "Lot Usage Time", value: "lot_usage_time", sortable: false },
          {
            text: "Parking Usage Time",
            value: "parking_usage_time",
            sortable: false,
          },
          { text: "Entry Timestamp", value: "created_at", sortable: false },
          { text: "Exit Timestamp", value: "exit_timestamp", sortable: false },
        ];
        headers.push(...remaining_headers);
        if (this.isSuperAdmin) {
          headers.push({
            text: "Anpr Details",
            value: "anpr_details",
            sortable: false,
          });
          headers.push({
            text: "View Spot Updates",
            value: "spot_updates",
            sortable: false,
          });
        }
        headers.push({
          text: "Spot Matched",
          value: "spot_matched",
          sortable: false,
        });
        return headers;
      } else {
        return [
          { text: "ID", value: "id", sortable: false },
          {
            text: "Vehicle LP",
            value: "license_plate_detected",
            sortable: false,
          },
          ...extraHeaders,
          { text: "Completed Pair", value: "is_complete", sortable: false },
          { text: "Lot Usage Time", value: "lot_usage_time", sortable: false },
          { text: "Entry Timestamp", value: "created_at", sortable: false },
          { text: "Exit Timestamp", value: "exit_timestamp", sortable: false },
        ];
      }
    },
    configurableVehicleParkingUsageHeaders(): Array<
      Record<string, string | boolean>
    > {
      return this.allVehicleParkingUsageHeaders.filter(
        (header) =>
          !["license_plate_detected", "is_complete", "created_at"].includes(
            header.value as string
          )
      );
    },
    vehicleParkingUsageHeaders(): Array<Record<string, string | boolean>> {
      return this.allVehicleParkingUsageHeaders.filter((header) =>
        this.selectedLPRHeaders.includes(header.value as string)
      );
    },
    singleDaySelected() {
      if (
        this.filters.dateMenu.value.length >= 2 &&
        this.filters.dateMenu.value[0] === this.filters.dateMenu.value[1]
      ) {
        return true;
      }
      return false;
    },
  },

  mounted() {
    this.filters.parkingLotId = this.lotId;
    this.fetchVehicleParkingUsageData();
    this.fetchParkingLotData();

    // Display ANPR record if id is present in URL
    const recordId = this.$route.query.anpr_record_id;
    if (recordId) {
      this.selectedVehicleParkingUsageRecordId = parseInt(recordId as string);
      this.showVehicleParkingUsageDialog = true;
    }

    if (this.isSuperAdmin) {
      this.selectedLPRHeaders.push("spot_matched");
    }

    setInterval(() => {
      this.setLastUpdatedAt();
      this.setLastUpdatedAtAria();
    }, 1000);
  },

  methods: {
    fetchVehicleParkingUsageData: _.debounce(async function (
      this: any,
      show_loader = false
    ) {
      if (!show_loader) {
        this.isLoading = true;
      }
      let filterStartDate = null,
        filterEndDate = null;
      if (
        this.filters.dateMenu.value?.length >= 2 &&
        this.filters.dateMenu.value[0] &&
        this.filters.dateMenu.value[1]
      ) {
        // Convert selected dates to UTC timestamp
        filterStartDate = dayjs(
          `${this.filters.dateMenu.value[0]} ${this.filters.timeMenu.start.value}`
        )
          .utc()
          .toISOString();
        filterEndDate = dayjs(
          `${this.filters.dateMenu.value[1]} ${this.filters.timeMenu.end.value}`
        )
          .utc()
          .add(dayjs.duration({ days: 1 })) // Inclusive end date
          .toISOString();
        console.log("Using UTC timestamps", filterStartDate, filterEndDate);
      }

      if (this.pagination.page == this.pagination.lastPage) {
        this.pagination.page = 1;
      }

      this.filters.isLoading = true;
      let vehicleParkingUsage = await api.getVehicleParkingUsageRecords(
        this.filters.licensePlateDetected,
        this.filters.cameraId,
        this.filters.vehicleBrand,
        this.filters.vehicleColor,
        this.filters.vehicleType,
        this.lotId,
        this.filters.dateMenu.value.length >= 2 &&
          this.filters.dateMenu.value[0]
          ? `${this.filters.dateMenu.value[0]} ${this.filters.timeMenu.start.value}`
          : null,
        this.filters.dateMenu.value.length >= 2 &&
          this.filters.dateMenu.value[1]
          ? `${this.filters.dateMenu.value[1]} ${this.filters.timeMenu.end.value}`
          : null,
        this.filters.timeSliced,
        this.filters.verifiedOnly ? true : null,
        this.filters.record_id,
        this.filters.record_id_match,
        this.filters.entry_exit_pair,
        this.filters.lot_usage_time,
        this.filters.lot_usage_time_match,
        this.filters.parking_usage_time,
        this.filters.parking_usage_time_match,
        this.filters.sortBy,
        this.filters.sortOrder,
        this.filters.is_ignored.length > 0
          ? this.filters.is_ignored.join(",")
          : null,
        this.pagination.page,
        this.pagination.itemsPerPage
      );
      if (vehicleParkingUsage) {
        this.vehicleParkingUsage = vehicleParkingUsage.items;
        this.pagination.itemsCount = vehicleParkingUsage.total;
        this.pagination.lastPage = this.pagination.page;
        this.filters.autoRefreshUpdatedAt = new Date();
      }
      this.isLoading = false;
      this.filters.isLoading = false;
    },
    1000),

    async fetchParkingLotData() {
      let parkingLotInfo = await api.getParkingLotBasicInfo(this.lotId);
      if (parkingLotInfo) {
        this.parkingLotInfo = parkingLotInfo;
        this.lotCameras = this.parkingLotInfo.cameras
          .filter((camera) => camera.is_lpr_camera_type != null)
          .map((camera) => ({
            id: camera.id,
            label: camera.name,
            value: camera.id,
          }));
      }
    },

    showVehicleParkingUsageDetails(
      vehicleParkingUsage: VehicleParkingUsageTrackingAssociation
    ) {
      this.selectedVehicleParkingUsageRecordId = vehicleParkingUsage.id;
      this.showVehicleParkingUsageDialog = true;
    },

    hideVehicleParkingUsageDetails() {
      this.selectedVehicleParkingUsageRecordId = null;
      this.showVehicleParkingUsageDialog = false;
    },

    onFilterLicensePlateDetectedClear() {
      this.filters.licensePlateDetected = "";
      this.fetchVehicleParkingUsageData();
    },

    isStartTimeGreaterThanEndTime(start_time: string, end_time: string) {
      const startTime = new Date(`2000-01-01T${start_time}:00`);
      const endTime = new Date(`2000-01-01T${end_time}:00`);

      return startTime > endTime;
    },

    filterByDate() {
      this.filters.dateMenu.showMenu = false;
      if (this.filters.timeSliced) {
        if (
          this.isStartTimeGreaterThanEndTime(
            this.filters.timeMenu.start.value,
            this.filters.timeMenu.end.value
          )
        ) {
          this.$dialog.message.error(
            "Please set start time less than end time.",
            { position: "top-right", timeout: 3000 }
          );
          return;
        }
      }
      this.fetchVehicleParkingUsageData();
    },

    filterByRecordIdSetBy() {
      if (this.filters.record_id) {
        this.fetchVehicleParkingUsageData();
      }
    },
    filterByLotUsageTimeSetBy() {
      if (this.filters.lot_usage_time) {
        this.fetchVehicleParkingUsageData();
      }
    },
    filterByParkingUsageTimeSetBy() {
      if (this.filters.parking_usage_time) {
        this.fetchVehicleParkingUsageData();
      }
    },
    clearRecordIdFilter() {
      this.filters.record_id = null;
      this.fetchVehicleParkingUsageData();
    },
    clearLotUsageFilter() {
      this.filters.lot_usage_time = null;
      this.fetchVehicleParkingUsageData();
    },
    clearParkingUsageFilter() {
      this.filters.parking_usage_time = null;
      this.fetchVehicleParkingUsageData();
    },

    refreshLPUsageFilters() {
      this.filters.licensePlateDetected = "";
      this.filters.parkingLotId = null;
      this.filters.verifiedOnly = false;

      this.filters.vehicleBrand = "";
      this.filters.vehicleColor = "";
      this.filters.vehicleType = "";

      this.filters.dateMenu.value = [];
      this.filters.timeMenu.start.value = "00:00";
      this.filters.timeMenu.end.value = "23:59";
      this.filters.timeSliced = false;

      this.filters.record_id = null;
      this.filters.record_id_match = "equal_to";
      this.filters.entry_exit_pair = null;
      this.filters.lot_usage_time = null;
      this.filters.lot_usage_time_match = "equal_to";
      this.filters.parking_usage_time = null;
      this.filters.parking_usage_time_match = "equal_to";

      this.fetchVehicleParkingUsageData();
    },

    clearLpDates() {
      this.filters.dateMenu.value = [];
      this.filters.timeMenu.start.value = "00:00";
      this.filters.timeMenu.end.value = "23:59";
      this.fetchVehicleParkingUsageData();
    },

    clearRefreshInterval() {
      if (this.autoRefreshIntervalId !== null) {
        clearInterval(this.autoRefreshIntervalId);
        this.autoRefreshIntervalId = null;
      }
    },

    openANPRPage(license_plate: string, lpr_id: number, timestamp: string) {
      const utcDateTime = dayjs.utc(timestamp);
      const localStartDateTime = utcDateTime.local();
      timestamp = localStartDateTime.format("YYYY-MM-DD HH:mm");

      const routeData = this.$router.resolve({
        name: "Accuracy",
        query: {
          license_plate,
          lpr_id: lpr_id.toString(),
          timestamp,
          // interval: String(
          //   this.parkingLotInfo
          //     ? this.parkingLotInfo.anpr_matching_window_interval_minutes
          //     : 10
          // ),
        },
      });
      window.open(routeData.href, "_blank");
    },

    openSpotUpdates(timestamp: string) {
      if (this.lotId == null) {
        return;
      }
      const utcDateTime = dayjs.utc(timestamp);
      const localStartDateTime = utcDateTime.local();
      timestamp = localStartDateTime.format("YYYY-MM-DD HH:mm");

      const routeData = this.$router.resolve({
        name: "LotDashboard",
        params: { lotId: String(this.lotId) },
        query: {
          timestamp,
          interval: String(
            this.parkingLotInfo
              ? this.parkingLotInfo.anpr_matching_window_interval_minutes
              : 10
          ),
        },
      });
      window.open(routeData.href, "_blank");
    },

    switchSelectedRecord(direction: number) {
      console.log("Direction: ", direction);
      if (!this.selectedVehicleParkingUsageRecordId) {
        console.log("No record selected.");
        return;
      }
      const selectedRecord = this.vehicleParkingUsage.find(
        (v) => v.id == this.selectedVehicleParkingUsageRecordId
      );
      if (!selectedRecord) {
        console.log("Selected record not found in list.");
        return;
      }
      const currentIndex = this.vehicleParkingUsage.indexOf(selectedRecord);
      console.log("Current index: ", currentIndex);
      const nextIndex = currentIndex + direction;
      console.log("Next index: ", nextIndex);
      if (
        nextIndex >= 0 &&
        this.vehicleParkingUsage &&
        nextIndex < this.vehicleParkingUsage.length
      ) {
        this.selectedVehicleParkingUsageRecordId =
          this.vehicleParkingUsage[nextIndex].id;
        console.log("Selected: ", this.selectedVehicleParkingUsageRecordId);
      }
    },

    // convert 24 hour time to 12 hour time format
    timeConvert(time: string) {
      return new Date("1970-01-01T" + time + "Z").toLocaleTimeString("en-US", {
        timeZone: "UTC",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      });
    },

    lpParkingTrackingSort(sort_by: string) {
      this.filters.sortOrder =
        this.filters.sortOrder == null
          ? true
          : this.filters.sortOrder == true
          ? false
          : this.filters.sortOrder == false
          ? null
          : null;
      this.filters.sortBy = this.filters.sortOrder != null ? sort_by : null;
      this.fetchVehicleParkingUsageData();
    },

    showExitTimestamp() {
      if (
        this.filters.timeMenu.start.value != "00:00" &&
        !this.selectedLPRHeaders.includes("exit_timestamp")
      ) {
        this.selectedLPRHeaders.push("exit_timestamp");
      }
    },

    setStartTime(time: string) {
      this.filters.timeMenu.start.showMenu = false;
      this.filters.timeMenu.start.value = time;

      this.showExitTimestamp();

      this.filterByDate();
    },
    setEndTime(time: string) {
      this.filters.timeMenu.end.showMenu = false;
      this.filters.timeMenu.end.value = time;

      this.showExitTimestamp();

      this.filterByDate();
    },
    autoRefreshLastUpdatedTime() {
      if (this.filters.autoRefreshUpdatedAt) {
        let dayObj = dayjs(this.filters.autoRefreshUpdatedAt);
        const selectedTimezone = localStorage.getItem("selected_timezone");
        if (selectedTimezone) {
          dayObj = dayjs(this.filters.autoRefreshUpdatedAt).tz(
            selectedTimezone
          );
        }
        const selectedTimeFormat = localStorage.getItem("time_format_option");
        let formattedDate = dayObj.format("h:mm:ss A");
        if (selectedTimeFormat === "24_hr") {
          formattedDate = dayObj.format("HH:mm:ss");
        }

        let tz_short = "";
        if (selectedTimezone) {
          const locale = navigator.language ? navigator.language : "en-US";
          let timezone_short = new Intl.DateTimeFormat(locale, {
            timeZone: selectedTimezone,
            timeZoneName: "long",
          })
            .formatToParts(new Date())
            .find((part) => part.type === "timeZoneName")?.value;
          if (timezone_short) {
            // abbreviate text
            timezone_short = timezone_short
              .split(" ")
              .map((word) => word[0])
              .join("");
            tz_short = `${timezone_short}`;
          } else {
            tz_short = dayjs().tz(selectedTimezone).format("z");
          }
        }

        return formattedDate + ` ${tz_short}`;
      }
      return "";
    },

    getLPRIgnoredText(is_ignored: string) {
      switch (is_ignored) {
        case VehicleParkingUsageTrackingRecordIgnored.orientation_confidence_low:
          return "(LVOS)";
        case VehicleParkingUsageTrackingRecordIgnored.type_confidence_low:
          return "(LVTS)";
        case VehicleParkingUsageTrackingRecordIgnored.make_model_confidence_low:
          return "(LVMMS)";
        case VehicleParkingUsageTrackingRecordIgnored.color_confidence_low:
          return "(LVCS)";
        case VehicleParkingUsageTrackingRecordIgnored.region_confidence_low:
          return "(LVRS)";
        case VehicleParkingUsageTrackingRecordIgnored.d_score_low:
          return "(LVD)";
        case VehicleParkingUsageTrackingRecordIgnored.direction_not_in_range:
          return "(DNIR)";
        default:
          return "";
      }
    },

    getLPRIgnoredFullText(is_ignored: string) {
      switch (is_ignored) {
        case VehicleParkingUsageTrackingRecordIgnored.orientation_confidence_low:
          return "Low Vehicle Orientation Score";
        case VehicleParkingUsageTrackingRecordIgnored.type_confidence_low:
          return "Low Vehicle Type Score";
        case VehicleParkingUsageTrackingRecordIgnored.make_model_confidence_low:
          return "Low Make Model Confidence Score";
        case VehicleParkingUsageTrackingRecordIgnored.color_confidence_low:
          return "Low Color Confidence Score";
        case VehicleParkingUsageTrackingRecordIgnored.region_confidence_low:
          return "Low Region Confidence Score";
        case VehicleParkingUsageTrackingRecordIgnored.d_score_low:
          return "Low D Score";
        case VehicleParkingUsageTrackingRecordIgnored.direction_not_in_range:
          return "Direction not in Range";
        default:
          return "";
      }
    },

    setLastUpdatedAt() {
      if (this.filters.autoRefreshUpdatedAt) {
        this.lastUpdatedAt = `Last updated ${getElapsedTimeShort(
          this.filters.autoRefreshUpdatedAt,
          false
        )} ago`;
      }
    },
    setLastUpdatedAtAria() {
      if (this.filters.autoRefreshUpdatedAt) {
        this.lastUpdatedAtAria = `Last updated ${getElapsedTimeShort(
          this.filters.autoRefreshUpdatedAt,
          true
        )} ago`;
      }
    },
    onDatePickerFocus() {
      this.filters.dateMenu.showMenu = true;
      setTimeout(() => {
        document.getElementById("lpr-date-range-picker")?.focus();
        document
          .getElementById("lpr-date-range-picker")
          ?.addEventListener("keydown", (event) => {
            if (event.key === "Escape") {
              setTimeout(() => {
                if (this.filters.dateMenu.value.length < 2) {
                  if (this.showAnprFields) {
                    document
                      .getElementById("date-picker-next-focus-verified")
                      ?.focus();
                  } else {
                    document
                      .getElementById("date-picker-next-focus-brand")
                      ?.focus();
                  }
                } else {
                  document.getElementById("date-picker-next-focus")?.focus();
                }
              }, 300);
            }
          });
      }, 300);
    },
    onStartTimePickerFocus() {
      this.filters.timeMenu.start.showMenu = true;
      setTimeout(() => {
        document.getElementById("time-range-picker-hour")?.focus();
      }, 300);
    },
    onEndTimePickerFocus() {
      this.filters.timeMenu.end.showMenu = true;
      setTimeout(() => {
        document.getElementById("time-range-picker-hour")?.focus();
      }, 300);
    },
  },

  watch: {
    "filters.autoRefreshParkingUsage"(value) {
      if (value) {
        this.autoRefreshIntervalId = setInterval(async () => {
          if (this.filters.autoRefreshParkingUsage) {
            this.filters.loadingParkingUsage = true;
            await this.fetchVehicleParkingUsageData(true);
            this.filters.loadingParkingUsage = false;
          }
        }, 4 * 1000);
      } else {
        this.clearRefreshInterval();
      }
    },
    selectedLPRHeaders(value) {
      if (value.length == 0) {
        this.selectedLPRHeaders = [
          "license_plate_detected",
          "is_complete",
          "created_at",
        ];
      }
    },
  },

  destroyed() {
    this.clearRefreshInterval();
  },
});
