








































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import api from "@/api/api";
import {
  DigitalBoard,
  DigitalBoardCreate,
  DigitalBoardUpdate,
  EdgeDevice,
} from "@/api/models";
import { Point } from "geojson";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import { max } from "lodash";
import { mdiFolderZipOutline } from "@mdi/js";

type VForm = Vue & { resetValidation: () => boolean };

interface CellDefinition {
  visual_attributes: any;
  vendor_attributes: {
    sign_address: string;
  };
  cell_value: {
    type: string;
    ids: number[];
    value: string;
  };
}

interface Cell {
  id: string;
  cell_definition: CellDefinition;
}

interface TableDefinition {
  row_definitions: Cell[][];
}

interface ZonesList {
  text: string;
  value: number;
  parent_zone_id: number | null;
}

export default Vue.extend({
  name: "DigitalBoardForm",

  components: {
    VueJsonPretty,
  },

  props: {
    parkingLotId: {
      type: Number,
      required: true,
    },
    parkingZones: {
      type: Array as () => Array<{
        text: string;
        value: number;
        parent_zone_id: number | null;
      }>,
      required: false,
      default: () => [],
    },
    existingDigitalBoardDetails: {
      type: Object as () => DigitalBoard,
      required: false,
    },
    needsInit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    allFieldsValid: false,
    rules: {
      required: (value: string) => !!value || "Required",
      counter: (value: string) => value.length <= 20 || "Max 20 characters",
      noSpecialChars: (value: string) =>
        !/[!@#$%^&*()_+=[\]{}`~;':"\\|,.<>/?]+/.test(value) ||
        "No Special Characters allowed",
      isValidIPAddress: (ip: string) =>
        /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
          ip
        ) ||
        /^(?:(?:[a-fA-F\d]{1,4}:){7}[a-fA-F\d]{1,4}|(?=(?:[a-fA-F\d]{0,4}:){0,7}[a-fA-F\d]{0,4}$)(([0-9a-fA-F]{1,4}:){1,7}|:)((:[0-9a-fA-F]{1,4}){1,7}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3})|:(?:(?::[a-fA-F\d]{1,4}){1,5}|:(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3})?)|(?:[a-fA-F\d]{1,4}:){1,5}(?::[a-fA-F\d]{1,4}){1,5}|[a-fA-F\d]{1,4}:(?:(?::[a-fA-F\d]{1,4}){1,6})|:(?:(?::[a-fA-F\d]{1,4}){1,7}|:)|fe80:(?::[a-fA-F\d]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(?:ffff(?::0{1,4}){0,1}:){0,1}(?:(?:25[0-5]|(?:2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(?:25[0-5]|(?:2[0-4]|1{0,1}[0-9]){0,1}[0-9])|(?:[a-fA-F\d]{1,4}:){1,4}:(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}))$/.test(
          ip
        ) ||
        "Invalid IP Address",
      isValidPort: (portNumber: string) =>
        (!isNaN(parseInt(portNumber)) &&
          parseInt(portNumber) >= 0 &&
          parseInt(portNumber) <= 65535 &&
          /^\d+$/.test(portNumber) &&
          !portNumber.includes(".")) ||
        "Invalid Port Number",
    },

    id: null as number | null,
    name: "",
    lat: 0,
    lng: 0,
    vendorsList: [{ text: "Signal Tech", value: "signal_tech" }],
    vendor: "signal_tech" as string | null,
    comment: null as string | null,
    isActive: false as boolean,
    lotComponents: [
      { text: "Text", value: "text" },
      { text: "Parking Lot", value: "lot" },
      { text: "Zone", value: "zone" },
      { text: "Sum of Zones", value: "sum_of_zones" },
    ],
    cellValues: [
      { text: "Total Count", value: "total_count" },
      { text: "Available Count", value: "available_count" },
      { text: "Unavailable Count", value: "unavailable_count" },
    ],
    vendorConfig: {
      model: "",
      ip_address: "",
      port: "",
      vendor_cell_configuration: {
        cell_id: 0,
        address_id: 0,
      },
      protocol: "",
    },

    dataLayout: {
      table_definition: {
        row_definitions: [] as Cell[][],
      } as TableDefinition,
    },
    currentInputFocus: { row: 0, col: 0 },
    edgeDevices: {
      selectedId: null as string | null,
      selectedLprId: null as string | null,
      items: [] as Array<EdgeDevice>,
      isLoading: false,
    },
    viewLayoutJson: false,

    parentParkingZones: [] as Array<{
      text: string;
      value: number;
      parent_zone_id: number | null;
      nested_zones: Array<ZonesList>;
    }>,

    infoPopup: {
      show: false,
      title: "",
      message: "",
    },
  }),

  mounted() {
    this.loadEdgeDevicesList();
    this.resetForm();
    this.setZonesData();
    this.initFormWithDigitalBoardDetails(this.existingDigitalBoardDetails);
  },

  computed: {
    ...mapGetters("user", ["isSuperAdmin"]),
    validateLayoutConfiguration() {
      // check if the layout configuration is valid, sign address should be unique and not empty, type should be set and valid, ids should be set if type is zone or sum_of_zones, value should be set
      let signAddresses = new Set();
      let valid = true;
      let error = "";
      // check if row_definetions has no entry or dataLayout is empty or null
      if (
        !this.dataLayout ||
        !this.dataLayout["table_definition"] ||
        !this.dataLayout["table_definition"]["row_definitions"] ||
        this.dataLayout["table_definition"]["row_definitions"].length == 0
      ) {
        error = "*Layout Configuration must be set.<br>";
      }

      this.dataLayout["table_definition"]["row_definitions"].forEach(
        (row: Cell[]) => {
          row.forEach((cell: Cell) => {
            if (
              signAddresses.has(
                cell.cell_definition.vendor_attributes.sign_address
              )
            ) {
              valid = false;
              error += `*Sign Address ${cell.cell_definition.vendor_attributes.sign_address} is not unique.<br>`;
            } else {
              signAddresses.add(
                cell.cell_definition.vendor_attributes.sign_address
              );
            }

            if (
              cell.cell_definition.vendor_attributes.sign_address == "" ||
              cell.cell_definition.cell_value.type == "" ||
              ((cell.cell_definition.cell_value.type == "zone" ||
                cell.cell_definition.cell_value.type == "sum_of_zones") &&
                cell.cell_definition.cell_value.ids.length == 0) ||
              cell.cell_definition.cell_value.value == ""
            ) {
              valid = false;
              error += `*Cell ${cell.id} is not properly configured.<br>`;
            }
          });
        }
      );
      return error;
    },
    allDisplayBoardFieldsValidate() {
      let all_errors = "";
      if (!this.name) {
        all_errors += "*Name is required.<br>";
      }
      if (!this.vendor) {
        all_errors += "*Vendor is required.<br>";
      }
      if (!this.vendorConfig.model) {
        all_errors += "*Model is required.<br>";
      }
      if (!this.vendorConfig.ip_address) {
        all_errors += "*IP Address is required.<br>";
      }
      if (!this.vendorConfig.port) {
        all_errors += "*Port is required.<br>";
      }
      if (this.validateLayoutConfiguration != "") {
        all_errors += this.validateLayoutConfiguration;
      }
      if (!this.edgeDevices.selectedId) {
        all_errors += "*Edge Device is required.<br>";
      }
      if (this.comment != null && this.comment.length > 512) {
        all_errors += "*Comment should not exceed 512 characters.<br>";
      }

      return all_errors;
    },
  },

  methods: {
    onlyKeepDigitsInPort() {
      if (typeof this.vendorConfig.port === "number") {
        this.vendorConfig.port = Math.floor(this.vendorConfig.port).toString();
        return;
      }
      this.vendorConfig.port = this.vendorConfig.port
        .toString()
        .replace(/\D/g, "");
    },
    setZonesData() {
      let parentZones = [] as Array<{
        text: string;
        value: number;
        parent_zone_id: number | null;
        nested_zones: Array<ZonesList>;
      }>;
      this.parkingZones.forEach((zone) => {
        if (zone.parent_zone_id === null) {
          parentZones.push({
            text: zone.text,
            value: zone.value,
            parent_zone_id: zone.parent_zone_id,
            nested_zones: [],
          });
        }
      });
      this.parkingZones.forEach((zone) => {
        if (zone.parent_zone_id !== null) {
          const parent = parentZones.find(
            (parent) => parent.value === zone.parent_zone_id
          );
          if (parent) {
            parent.nested_zones.push(zone);
          }
        }
      });
      this.parentParkingZones = parentZones;
    },
    async loadEdgeDevicesList() {
      this.edgeDevices.isLoading = true;
      const edgeDevices = await api.getAllEdgeDevices(this.parkingLotId);
      if (edgeDevices) {
        this.edgeDevices.items = edgeDevices;

        if (this.edgeDevices.items.length > 0) {
          this.edgeDevices.selectedId = this.edgeDevices.items[0].id;
        }
      }
      this.edgeDevices.isLoading = false;
    },
    async initFormWithDigitalBoardDetails(digitalBoardDetails: DigitalBoard) {
      if (digitalBoardDetails) {
        this.id = digitalBoardDetails.id;
        this.name = digitalBoardDetails.name;
        const { coordinates } =
          typeof digitalBoardDetails.gps_coordinates === "string"
            ? JSON.parse(digitalBoardDetails.gps_coordinates)
            : digitalBoardDetails.gps_coordinates;
        this.lat = coordinates[1];
        this.lng = coordinates[0];
        this.vendor = digitalBoardDetails.vendor;
        this.comment = digitalBoardDetails.comment;
        if (digitalBoardDetails.vendor_configuration) {
          this.vendorConfig = digitalBoardDetails.vendor_configuration;
        }
        if (digitalBoardDetails.layout_configuration) {
          this.dataLayout = digitalBoardDetails.layout_configuration;
        }
        this.isActive = digitalBoardDetails.is_active;
      }
    },
    async submitForm() {
      let digitalBoardData: DigitalBoardCreate = {
        name: this.name,
        gps_coordinates: {
          type: "Point",
          coordinates: [this.lng, this.lat],
        } as Point,
        vendor: this.vendor,
        comment: this.comment,
        parking_lot_id: this.parkingLotId,
        is_active: this.isActive,
        connected_edge_device_id: this.edgeDevices.selectedId,
        vendor_configuration: this.vendorConfig,
        layout_configuration: this.dataLayout,
        level_id: this.existingDigitalBoardDetails?.level_id || null,
      };

      if (this.existingDigitalBoardDetails == null || this.id == null) {
        this.createDigitalBoard(digitalBoardData);
      } else {
        this.updateDigitalBoard({
          ...digitalBoardData,
          id: this.id,
        });
      }
    },
    async createDigitalBoard(digitalBoardData: DigitalBoardCreate) {
      try {
        let digitalBoard = await api.createDigitalBoard(
          this.parkingLotId,
          digitalBoardData
        );
        if (digitalBoard) {
          this.$dialog.message.info("New Display Board added successfully", {
            position: "top-right",
            timeout: 3000,
          });
          this.name = digitalBoard.name;
          this.$emit("refresh-data");
          this.$emit("close-form");
        } else {
          this.$dialog.message.error(
            "Error, unable to create new Display Board.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        }
      } catch (error: any) {
        this.$dialog.message.error(error.response.data.detail, {
          position: "top-right",
          timeout: 3000,
        });
      }
    },
    async updateDigitalBoard(digitalBoardData: DigitalBoardUpdate) {
      try {
        let digitalBoard = await api.updateDigitalBoard(
          this.parkingLotId,
          digitalBoardData
        );
        if (digitalBoard) {
          this.$dialog.message.info("Display Board updated successfully", {
            position: "top-right",
            timeout: 3000,
          });
          this.$emit("refresh-data");
          this.$emit("close-form");
        } else {
          this.$dialog.message.error("Error, unable to update Display Board", {
            position: "top-right",
            timeout: 3000,
          });
        }
      } catch (error: any) {
        this.$dialog.message.error(error.response.data.detail, {
          position: "top-right",
          timeout: 3000,
        });
      }
    },
    resetForm(resetName = true) {
      this.id = null;
      this.name = "";
      this.vendor = "signal_tech";
      this.comment = null;
      this.isActive = false;
      this.dataLayout = {
        table_definition: {
          row_definitions: [] as Cell[][],
        } as TableDefinition,
      };
      this.vendorConfig = {
        model: "",
        ip_address: "",
        port: "",
        vendor_cell_configuration: {
          cell_id: 0,
          address_id: 0,
        },
        protocol: "",
      };
      (this.$refs.digitalBoardFormElm as VForm).resetValidation();
    },
    closeForm() {
      this.$emit("close-form");
      this.resetForm(false);
    },
    columnToLetter(col: number) {
      let letter = "";
      while (col >= 0) {
        letter = String.fromCharCode((col % 26) + 65) + letter;
        col = Math.floor(col / 26) - 1;
      }
      return letter;
    },
    getCellName(row: number, col: number) {
      let colLetter = this.columnToLetter(col);
      let rowNumber = row + 1;
      return `${colLetter}${rowNumber}`;
    },
    getMaxSignAddress() {
      let maxSignAddress = 0;
      this.dataLayout["table_definition"]["row_definitions"].forEach(
        (row: Cell[]) => {
          row.forEach((cell: Cell) => {
            const signAddress = parseInt(
              cell.cell_definition.vendor_attributes.sign_address
            );
            if (signAddress > maxSignAddress) {
              maxSignAddress = signAddress;
            }
          });
        }
      );
      return maxSignAddress;
    },
    addCellCol(index: number) {
      const maxSignAddress = this.getMaxSignAddress();
      const cell_id = this.getCellName(
        this.dataLayout["table_definition"]["row_definitions"][index].length,
        index
      );
      this.dataLayout["table_definition"]["row_definitions"][index].push({
        id: cell_id,
        cell_definition: {
          visual_attributes: null,
          vendor_attributes: {
            sign_address: (maxSignAddress + 1).toString(),
          },
          cell_value: {
            type: "text",
            ids: [],
            value: "",
          },
        } as CellDefinition,
      });
    },
    addCellRow() {
      const maxSignAddress = this.getMaxSignAddress();
      const cell_id = this.getCellName(
        0,
        this.dataLayout["table_definition"]["row_definitions"].length
      );
      const newRow = [];
      newRow.push({
        id: cell_id,
        cell_definition: {
          visual_attributes: null,
          vendor_attributes: {
            sign_address: (maxSignAddress + 1).toString(),
          },
          cell_value: {
            type: "text",
            ids: [],
            value: "",
          },
        } as CellDefinition,
      });
      this.dataLayout["table_definition"]["row_definitions"].unshift(newRow);
      this.resetCellIdOnChange();
    },
    removeCell(row: number, col: number) {
      this.dataLayout["table_definition"]["row_definitions"][row].splice(
        col,
        1
      );

      // rename the cells
      this.dataLayout["table_definition"]["row_definitions"][row].forEach(
        (cell, index) => {
          cell.id = this.getCellName(index, row);
        }
      );

      // remove the row if it is empty
      if (
        this.dataLayout["table_definition"]["row_definitions"][row].length == 0
      ) {
        this.dataLayout["table_definition"]["row_definitions"].splice(row, 1);
      }
      this.resetCellIdOnChange();
    },
    resetCellIdOnChange() {
      // set cell id again for each cell on cell remove or add
      this.dataLayout["table_definition"]["row_definitions"].forEach(
        (row, row_index) => {
          row.forEach((cell, col_index) => {
            cell.id = this.getCellName(col_index, row_index);
          });
        }
      );
    },
    showInfoPopup(title: string, message: string) {
      this.infoPopup.title = title;
      this.infoPopup.message = message;
      this.infoPopup.show = true;
    },
  },

  watch: {
    parkingZones() {
      this.setZonesData();
    },
    existingDigitalBoardDetails(digitalBoardDetails) {
      if (digitalBoardDetails) {
        this.initFormWithDigitalBoardDetails(digitalBoardDetails);
      }
    },
    needsInit(show) {
      if (show) {
        this.initFormWithDigitalBoardDetails(this.existingDigitalBoardDetails);
      } else this.closeForm();
    },
  },
});
