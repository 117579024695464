









































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import ParkingLotCard from "../components/cards/ParkingLotCard.vue";
import ParkingLotForm from "@/components/forms/ParkingLotForm.vue";
import CameraMapUploadForm from "@/components/forms/CameraMapUploadForm.vue";
import { ParkingLotBasic, OrganizationUserAuthorization } from "@/api/models";
import api from "@/api/api";

interface SortOptions {
  text: string;
  value: string;
}

type GroupedParkingLots = {
  [organization_name: string]: ParkingLotBasic[];
};

export default Vue.extend({
  name: "Home",

  components: {
    ParkingLotCard,
    ParkingLotForm,
    CameraMapUploadForm,
  },

  data: () => ({
    breadcrumbItems: [
      {
        text: "Home",
        disabled: false,
        to: { name: "Home" },
      },
      {
        text: "Parking Lots",
        disabled: true,
      },
    ],
    parkingLots: [] as Array<ParkingLotBasic>,
    showParkingLotForm: false,
    showCameraMapUploadForm: false,
    isLoading: false,
    selectedLotDetails: null as ParkingLotBasic | null,
    searchText: null as string | null,
    sortBy: {
      selected: "default",
      items: [
        { text: "Enabled Parking Lots", value: "default" },
        { text: "Parking Lot Name", value: "name" },
        { text: "Created at", value: "created_at" },
        { text: "Last Updated", value: "updated_at" },
      ] as Array<SortOptions>,
    },
    filterBy: {
      organizations: [] as Array<OrganizationUserAuthorization>,
      selectedOrgId: null as number | null,
    },
    pagination: {
      page: 0,
      itemsCount: 0,
      itemsPerPage: 12,
    },
  }),

  computed: {
    ...mapGetters("user", [
      "isLoggedIn",
      "isSuperAdmin",
      "isOperator",
      "getCurrentUserData",
    ]),
    parkingLotsGrouped() {
      const groupedData: GroupedParkingLots = {};

      this.parkingLots.forEach((item) => {
        const organization_name = item.organization_name;
        if (organization_name !== null && organization_name !== undefined) {
          if (groupedData[organization_name]) {
            groupedData[organization_name].push(item);
          } else {
            groupedData[organization_name] = [item];
          }
        }
      });

      return groupedData;
    },
  },

  async mounted() {
    window.open(`${process.env.VUE_APP_3_BASE_URL_PATH}/home`, "_self");
    if (this.isOperator) {
      this.$router.push({ name: "UntrackedZones" });
    }
    if (this.isSuperAdmin) {
      this.sortBy.items = [
        { text: "Group by Organizations", value: "group_organization" },
        { text: "Enabled Parking Lots", value: "default" },
        { text: "Parking Lot ID", value: "id" },
        { text: "Parking Lot Name", value: "name" },
        { text: "Created at", value: "created_at" },
        { text: "Last Updated", value: "updated_at" },
      ] as Array<SortOptions>;
    }
    if (!this.isLoggedIn) {
      this.$router.push({ name: "Login" });
    }
    this.getAuthorisedOrgs();
    this.updatePageRoute();
  },

  watch: {
    $route(to) {
      if (to.query.page) {
        this.updatePageRoute(Number(to.query.page), true);
      }
    },

    isOperator(value) {
      if (value === true) {
        this.$router.push({ name: "UntrackedZones" });
      }
    },

    searchText() {
      if (this.searchText === "") {
        this.searchText = null;
        this.pagination.page = 1;
        this.getParkingLotData();
        return;
      }
      if (this.searchText) {
        if (this.searchText.length > 3) {
          this.pagination.page = 1;
          this.getParkingLotData();
        } else if (/^\d+$/.test(this.searchText)) {
          this.pagination.page = 1;
          this.getParkingLotData();
        }
      }
    },

    getCurrentUserData() {
      this.getAuthorisedOrgs();
    },
  },

  methods: {
    async getAuthorisedOrgs() {
      if (this.getCurrentUserData) {
        let authorizations = await api.getAuthorizationsForOrgUser(
          this.getCurrentUserData.id
        );
        if (authorizations) {
          this.filterBy.organizations = authorizations.filter(
            (auth) =>
              auth.organization_id != null &&
              auth.organization_id != process.env.VUE_APP_SUPERADMIN_ORG_ID &&
              auth.organization_id != process.env.VUE_APP_TECHNICIAN_ORG_ID
          );
        }
        if (authorizations && authorizations.length > 1) {
          this.sortBy.items.unshift({
            text: "Group by Organizations",
            value: "group_organization",
          });
        }
      }
    },
    clearSearchText() {
      this.searchText = null;
      this.getParkingLotData();
    },
    sortParkingLots() {
      sessionStorage.setItem("parkingLotSort", this.sortBy.selected);
      this.getParkingLotData();
    },
    filterByOrganization() {
      if (this.filterBy.selectedOrgId === null) {
        sessionStorage.removeItem("filterByOrg");
      } else {
        sessionStorage.setItem(
          "filterByOrg",
          String(this.filterBy.selectedOrgId)
        );
      }
      this.getParkingLotData();
    },
    async getParkingLotData() {
      const parkingLotSort = sessionStorage.getItem("parkingLotSort");
      if (parkingLotSort) {
        this.sortBy.selected = parkingLotSort;
      }
      const filterByOrg = sessionStorage.getItem("filterByOrg");
      if (filterByOrg) {
        this.filterBy.selectedOrgId = parseInt(filterByOrg);
      }
      console.log("Fetching parking lot data.");
      this.isLoading = true;
      let parkingLots = await api.getAllParkingLots(
        this.pagination.page,
        this.pagination.itemsPerPage,
        this.searchText?.trim(),
        this.sortBy.selected,
        this.filterBy.selectedOrgId
      );
      if (parkingLots !== null) {
        this.parkingLots = parkingLots.items;
        this.pagination.page = parkingLots.page;
        this.pagination.itemsCount = parkingLots.total;
        this.pagination.itemsPerPage = parkingLots.size;
      } else {
        console.log("Unable to load list of parking lots.");
      }
      this.isLoading = false;
    },

    updatePageRoute(page = 1, is_route_update = false) {
      if (this.$route.query.page && this.pagination.page === 0) {
        page = Number(this.$route.query.page);
      } else if (!is_route_update) {
        this.$router.push({
          query: {
            ...this.$route.query,
            page: String(page),
          },
        });
      }
      if (!this.$route.query.page && this.pagination.page === 0)
        this.pagination.page = 1;
      else this.pagination.page = page;
      this.getParkingLotData();
    },

    showParkingLotDetails(lotId: number) {
      this.selectedLotDetails =
        this.parkingLots.find((l) => l.id === lotId) ?? null;
      console.log("Showing lot details for id", lotId, this.selectedLotDetails);
      if (this.selectedLotDetails) {
        this.showParkingLotForm = true;
      }
    },

    closeParkingLotDetailsForm() {
      this.showParkingLotForm = false;
      this.selectedLotDetails = null;
    },

    showCamerMapUpload(lotId: number) {
      this.selectedLotDetails =
        this.parkingLots.find((l) => l.id === lotId) ?? null;
      if (this.selectedLotDetails) {
        this.showCameraMapUploadForm = true;
      }
    },

    closeCameraMapUploadForm() {
      this.showCameraMapUploadForm = false;
      this.selectedLotDetails = null;
    },
  },
});
