


















































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { OrganizationUserLoginPayload } from "@/api/models";

export default Vue.extend({
  name: "Signin",

  data: () => ({
    allFieldsValid: false,
    email: "",
    password: "",
    showPassword: false,
  }),

  computed: {
    ...mapGetters("user", ["isLoggedIn", "getCurrentUserData"]),
  },

  mounted() {
    const token = localStorage.getItem("token");
    if (token) {
      this.loginSuccess(token);
      this.redirectHomePage();
    }
  },

  methods: {
    ...mapMutations("user", ["loginSuccess"]),
    ...mapActions("user", ["login", "initCurrentOrgUser"]),

    async submitLogin() {
      console.log("Logging in...");
      let payload: OrganizationUserLoginPayload = {
        username: this.email.toLowerCase(),
        password: this.password,
      };
      try {
        await this.login(payload);
        if (this.isLoggedIn === true) {
          this.redirectHomePage();
        } else {
          console.error("Login Error, please try again.");
          this.$dialog.message.error("Login Error, please try again.", {
            position: "top-right",
            timeout: 3000,
          });
        }
      } catch (error) {
        this.$dialog.message.error(error.response.data.detail, {
          position: "top-right",
          timeout: 3000,
        });
      }
    },

    redirectHomePage() {
      this.initCurrentOrgUser();
      setTimeout(() => {
        if (this.getCurrentUserData) {
          if (this.getCurrentUserData.user_type == "operator") {
            this.$router.push({ name: "UntrackedZones" });
          } else if (this.getCurrentUserData.user_type == "admin") {
            window.open(
              `${process.env.VUE_APP_3_BASE_URL_PATH}/dashboard`,
              "_self"
            );
          } else {
            window.open(`${process.env.VUE_APP_3_BASE_URL_PATH}/home`, "_self");
          }
        } else {
          window.open(`${process.env.VUE_APP_3_BASE_URL_PATH}/home`, "_self");
        }
      }, 300);
    },
  },
});
