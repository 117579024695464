
































































































































import Vue from "vue";
import { ParkingZoneUntrackedInfoOnly } from "@/api/models";
import api from "@/api/api";

export default Vue.extend({
  name: "UntrackedZoneCountForm",

  props: {
    parkingLotId: {
      type: Number,
      required: true,
    },
    untrackedZoneData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      numTotalUntrackedSpots: 0,
      numFreeUntrackedSpots: 0,
      isNumFreeUntrackedSpotsAutomatic: false,
      saveRequestIsLoading: false,

      // Validation flags
      allFieldsValid: false,
      untrackedZoneHasCameras: false,
    };
  },

  async mounted() {
    this.initData(this.untrackedZoneData);
  },

  methods: {
    async initData(untrackedZoneData: ParkingZoneUntrackedInfoOnly) {
      if (!this.untrackedZoneData) return;
      this.numTotalUntrackedSpots =
        untrackedZoneData.num_total_untracked_spots || 0;
      this.isNumFreeUntrackedSpotsAutomatic =
        untrackedZoneData.is_num_free_untracked_spots_automatic;
      if (this.isNumFreeUntrackedSpotsAutomatic) {
        // Set the automatically computed count
        this.numFreeUntrackedSpots = untrackedZoneData.num_free_parking_spots;
      } else {
        this.numFreeUntrackedSpots =
          untrackedZoneData.num_free_untracked_spots || 0;
      }

      this.untrackedZoneHasCameras =
        (await this.checkUntrackedZoneAssignedToCamera()) || false;
    },

    async checkUntrackedZoneAssignedToCamera() {
      let assignedCameras = await api.getAllCamerasAssignedToUntrackedZone(
        this.parkingLotId,
        this.untrackedZoneData.id
      );
      return assignedCameras && assignedCameras.length > 0;
    },

    async updateCount() {
      if (this.isNumFreeUntrackedSpotsAutomatic) {
        // Reset to zero if automatic
        this.numFreeUntrackedSpots = 0;
      } else {
        // Do not exceed total count
        this.numFreeUntrackedSpots = Math.min(
          this.numFreeUntrackedSpots,
          this.numTotalUntrackedSpots
        );
      }

      let updatedCount = {
        id: this.untrackedZoneData.id,
        num_free_untracked_spots: this.numFreeUntrackedSpots,
        num_total_untracked_spots: this.numTotalUntrackedSpots,
        is_num_free_untracked_spots_automatic:
          this.isNumFreeUntrackedSpotsAutomatic,
      };
      this.saveRequestIsLoading = true;
      let updatedUntrackedZone = await api.updateUntrackedZoneCount(
        this.parkingLotId,
        this.untrackedZoneData.id,
        updatedCount
      );
      this.saveRequestIsLoading = false;
      if (updatedUntrackedZone) {
        this.$emit("close-form");
        this.$emit("refresh-data");
      }
    },
  },

  watch: {
    untrackedZoneData(newUntrackedZoneData) {
      this.initData(newUntrackedZoneData);
    },
  },
});
