








































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import axios from "axios";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { Loader } from "@googlemaps/js-api-loader";
import { FeatureCollection, Point, Polygon } from "geojson";
import {
  mdiTimerOutline,
  mdiBlockHelper,
  mdiCctvOff,
  mdiCommentAlertOutline,
  mdiCarBack,
  mdiCarOff,
  mdiHelp,
  mdiTagOutline,
  mdiMapMarkerCheck,
  mdiCar,
  mdiAlertBox,
  mdiParking,
  mdiCancel,
} from "@mdi/js";
import compact from "@/assets/icons/compact.svg";
import addLevelSvg from "@/assets/digimap_icons/add_level.svg";
import ev from "@/assets/icons/ev.svg";
import displayBoard from "@/assets/digimap_icons/display_board.svg";
import api from "@/api/api";
import {
  ParkingLot,
  ParkingSpot,
  ParkingZone,
  ParkingLane,
  CameraData,
  DigitalBoard,
  CameraCreate,
  ParkingPermit,
  ParkingSpotSavedEndUserDetails,
  ParkingLocationSavedEndUserDetails,
  PARKING_PERMIT_PATHS_HASHMAP,
} from "@/api/models";
import { ParkingStatus } from "@/api/models/ParkingSpot";
import { laneToSpots, polyPointsToParallelogram } from "@/libs/autospot";
import { getTodaysDate } from "@/libs/dateUtils";
import { getMarkerPositionsOnRect } from "@/libs/polygonUtils";
import CameraForm from "@/components/forms/CameraForm.vue";
import DigitalBoardForm from "@/components/forms/DigitalBoardForm.vue";
import PropertiesPopup from "@/components/PropertiesPopup.vue";
import FloatingPopup from "@/components/FloatingPopup.vue";
import CameraMapDetails from "@/components/CameraMapDetails.vue";
import SpotSlider from "@/components/spots/SpotSlider.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import { Bbox, SpotBbox } from "@/libs/commonCameraMapEditorTypes";

interface Spot extends google.maps.Polygon {
  id?: number;
  name?: string;
  category: string;
  annoData?: ParkingSpot | null;
}

interface Driveway extends google.maps.Polyline {
  id?: number;
  category: string;
}

interface Lane extends google.maps.Polyline {
  id?: number;
  category: string;
}

interface ParkingStructureShape extends google.maps.Polygon {
  id?: number;
  category: string;
}

interface Zone extends google.maps.Polyline {
  id?: number;
  entrypointsIndexes: Array<number>;
  entrypointsMarkers: Array<google.maps.Marker>;
  category: string;
  multiLevelNumLevels?: number | null;
  shapes: Array<ParkingStructureShape> | null;
}

interface SpecialArea extends google.maps.Polyline {
  id?: number;
  category: string;
}

interface Camera extends google.maps.Marker {
  id?: number;
  fov_direction?: number | null;
}

interface DigitalBoardInterface extends google.maps.Marker {
  id?: number;
}

interface DisplayBoardInfoWindowInterface {
  display_board_id: number;
  infowindow: google.maps.InfoWindow;
}

interface CameraFov extends google.maps.Polygon {
  id?: number;
}

interface Landmark extends google.maps.Marker {
  id?: number;
  name?: string;
}

interface ActionObject {
  name: string;
  action: string;
  args: {
    latLng?: google.maps.LatLng;
    poly?: google.maps.Polygon;
    lanePoints?: Array<Array<number>>;
    spotCount?: number;
    line?: google.maps.Polyline;
    marker?: google.maps.Marker;
  };
}

type Lot = google.maps.Polyline;

enum EditorModes {
  view = "view",
  edit = "edit",
}

const COLORS = {
  zone: "#FF00FF",
  parking_structure: "#F07516",
  parking_structure_fill: "#FADBC7",
  driveway: "#FF0000",
  lane: "#FFFF00",
  lot: "#0000FF",
  special_area: "orange",
};

const SPOT_COLORS = {
  free: "#00FF00",
  unavailable: "#FF0000",
  reserved: "#FF0000",
  unknown: "#F3EA10",
  untrackable: "#9D9D9D",
  user_marked_unknown: "#FFA500",
};

const ZINDEXES = {
  lot: 1,
  zone: 2,
  special_area: 3,
  lane: 4,
  spot: 5,
};

const STROKE_WEIGHTS = {
  zone: 4,
  parking_structure: 4,
  special_area: 4,
  driveway: 12,
  lane: 4,
  lot: 4,
};

const FIXED_MARKER_OPTIONS = {
  visibilityMinZoomLevel: 20,
  iconAnchor: { x: 12, y: 12 },
};

type VComponent = Vue & {
  showConfirmDelete: (category: string) => Promise<boolean>;
  then: (result: boolean) => boolean;
};

export default Vue.extend({
  name: "MapEditor",

  components: {
    CameraForm,
    DigitalBoardForm,
    PropertiesPopup,
    FloatingPopup,
    CameraMapDetails,
    SpotSlider,
    ConfirmDialog,
  },

  props: {
    lotId: {
      type: Number,
      default: 1,
    },
    interactive: {
      type: Boolean,
      default: true,
    },
    showSpotNames: {
      type: Boolean,
      default: false,
    },
    showSpotIds: {
      type: Boolean,
      default: false,
    },
    showSpotCameraIds: {
      type: Boolean,
      default: false,
    },
    showDisplayBoards: {
      type: Boolean,
      default: false,
    },
    showZoneNames: {
      type: Boolean,
      default: false,
    },
    showCameraFOV: {
      type: Boolean,
      default: false,
    },
    showCameraIcons: {
      type: Boolean,
      default: false,
    },
    showLicensePlates: {
      type: Boolean,
      default: false,
    },
    filterSpotIds: {
      type: Object as () => Array<number> | null,
      required: false,
    },
    mode: {
      type: String,
      default: EditorModes.edit,
      validator(value: string) {
        const options: Array<string> = Object.values(EditorModes);
        return options.includes(value);
      },
    },
    authToken: {
      type: String,
      required: false,
    },
    savedSpots: {
      type: Array as () => Array<ParkingSpotSavedEndUserDetails>,
      required: false,
    },
    savedLocations: {
      type: Array as () => Array<ParkingLocationSavedEndUserDetails>,
      required: false,
    },
    viewPastSpotHistory: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data: () => ({
    isLoading: false,
    breadcrumbItems: [
      {
        text: "Home",
        disabled: false,
        to: { name: "Home" },
      },
      {
        text: "Edit Map",
        disabled: true,
      },
    ],
    showCameraForm: false,
    showDigitalBoardForm: false,
    map: null as google.maps.Map | null,
    mapZoomLevel: 0 as number,
    drawingManager: null as google.maps.drawing.DrawingManager | null,
    activeTool: null as string | null,
    isImportingGeoJson: false,
    current: {
      line: null as Driveway | Lane | Zone | SpecialArea | null,
      poly: null as Spot | null,
      marker: null as Camera | null,
    },
    last: {
      activeTool: "select" as string,
      poly: {
        path: null as google.maps.MVCArray<google.maps.LatLng> | null,
      },
    },
    options: {
      spotCount: 10,
      parkingStructureLevelCount: 1,
    },
    annotations: {
      driveways: [] as Array<Driveway>,
      spots: [] as Array<Spot>,
      zones: [] as Array<Zone>,
      specialAreas: [] as Array<SpecialArea>,
      lanes: [] as Array<Lane>,
      cameras: [] as Array<Camera>,
      display_boards: [] as Array<DigitalBoardInterface>,
      displayBoardInfoWindows: [] as Array<DisplayBoardInfoWindowInterface>,
      lot: null as Lot | null,
      landmarks: [] as Array<Landmark>,
      // Fixed position markers (for properties) overlaid on spots displayed only when zoomed in
      fixedSizeMarkers: [] as Array<google.maps.Marker>,
      blockingCars: [] as Array<google.maps.Marker>,
    },
    geoJson: null as FeatureCollection | null,
    showExportDialog: false,
    parkingLot: null as ParkingLot | null,
    parkingPermits: {
      items: null as Array<ParkingPermit> | null,
    },
    autoRefreshIntervalId: null as number | null,
    cameras: [] as Array<CameraData>,
    selectedCameraId: null as number | null,
    selectedCamera: null as CameraData | CameraCreate | null,
    highlightCameraParkingSpotsWithId: 0,
    digitalBoards: [] as Array<DigitalBoard>,
    selectedDigitalBoard: null as DigitalBoard | null,
    selectedDisplayBoardId: null as number | null,
    popup: {
      show: false,
      data: null as null | any,
      annotationObj: null as null | Camera | ParkingSpot,
      category: "",
      savedSpot: null as ParkingSpotSavedEndUserDetails | null,
      savedLocation: null as ParkingLocationSavedEndUserDetails | null,
      spotSliderId: null as number | null,
    },
    undoList: [] as Array<ActionObject>,
    showEmbedLinkDialog: false,
    cameraMapEditor: {
      showForCameraIds: {} as Record<number, any>,
      windowSize: {
        width: 998,
        height: 500,
        minimizedWidth: 0,
        minimizedHeight: 0,
      },
    },
    errorOccurred: false,
    makeParellelogram: false,
    showSavedCarLocationDetails: false,
    savedCarLocation: null as ParkingLocationSavedEndUserDetails | null,
    cameraFovs: [] as Array<CameraFov>,
    selectedCameraFov: {
      camera: null as CameraData | null,
      cameraFov: null as CameraFov | null,
      bearing: 0,
      angle: 60,
      distance: 10,
      edit: false,
      isNew: false,
    },
    spotHistory: {
      showDate: false,
      showTime: false,
      date: "",
      time: "12:00 PM",
      loading: false,
      timeNumber: 720,
      maxTimeNumber: 1439,
      anprMarkers: [] as Array<google.maps.Marker>,
      timelineAnprMarkers: [] as Array<google.maps.Marker>,
    },
    spotSlider: {
      show: false,
      spotId: null as number | null,
      cameraId: null as number | null,
    },
    zoneSlider: {
      show: false,
      zoneId: null as number | null,
      cameraId: null as number | null,
    },
    displayBoardsEvents: [] as Array<{
      display_board_id: number;
      data: string;
    }>,
    displayBoardEventsData: [] as Array<{
      display_board_id: number;
      data: any;
    }>,
    parkingZones: [] as {
      text: string;
      value: number;
      parent_zone_id: number | null;
    }[],
    multiLevelParkingStructure: {
      isParkingStructureView: false,
      isLevelDigimapView: false,
      currentMultiStructureZoneId: null as number | null,
      currentMultiStructureZoneAnno: null as Zone | null,
      numLevels: 0,
      levels: [] as Array<ParkingZone>,
    },
  }),

  created() {
    window.addEventListener("resize", this.setMapHeight);
  },

  async mounted() {
    if (this.authToken) {
      this.parkingLot = await api.getParkingLotMapData(
        this.lotId,
        this.authToken
      );
    } else {
      this.parkingLot = await api.getParkingLot(this.lotId);
      this.initCurrentParkingLotData(this.parkingLot);
      if (this.parkingLot) {
        localStorage.setItem("currentLotName", this.parkingLot.name);
        window.dispatchEvent(
          new CustomEvent("lot-name-changed", {
            detail: {
              lot_name: localStorage.getItem("currentLotName"),
            },
          })
        );

        this.parkingLot.parking_zones
          .filter((zone) => zone.zone_type != "time_limited_zone")
          .forEach((zone) => {
            this.parkingZones.push({
              text:
                zone.name != null && zone.name.trim() != ""
                  ? zone.name
                  : `Zone ${zone.id}`,
              value: zone.id,
              parent_zone_id: zone.nested_parent_zone_id,
            });
          });

        this.breadcrumbItems[0].text = this.parkingLot.name;
        this.breadcrumbItems[0].to = { name: "LotDashboard" };
      }
    }
    if (this.parkingLot?.is_parking_permit_feature_enabled && !this.authToken) {
      console.log(
        "Parking Permits is enabled, loading available permit types."
      );
      this.parkingPermits.items = await api.getAllParkingPermits(
        this.parkingLot.id
      );
      // remove 'Privilege Permit' from the list
      if (this.parkingPermits.items) {
        this.parkingPermits.items = this.parkingPermits.items.filter(
          (permit) => permit.name != "Privilege Permit"
        );
      }
    }
    if (this.parkingLot?.cameras) {
      this.cameras = this.parkingLot.cameras;
    }
    if (!this.parkingLot) {
      console.log("Unable to fetch parking lot info.");
      this.errorOccurred = true;
    } else {
      await this.initMaps(
        this.parkingLot.polygon_center
          ? this.parkingLot.polygon_center.coordinates
          : this.parkingLot.gps_coordinates.coordinates
      );
      this.drawParkingLotAnnotations();
    }

    if (
      this.parkingLot &&
      this.parkingLot.run_inference &&
      this.isViewMode &&
      !this.viewPastSpotHistory
    ) {
      if (this.authToken) {
        this.initParkingAutoRefresh(60000);
      } else {
        this.initParkingAutoRefresh(4000);
      }
    }

    this.getAllDisplayBoards();

    window.addEventListener("keydown", (e) => {
      this.keydownHandler(e);
    });
  },

  destroyed() {
    if (this.autoRefreshIntervalId !== null) {
      clearInterval(this.autoRefreshIntervalId);
      this.autoRefreshIntervalId = null;
    }
    window.removeEventListener("resize", this.setMapHeight);
  },

  methods: {
    ...mapActions("data", ["initCurrentParkingLotData"]),

    getSpotHistory: _.debounce(async function (this: any) {
      if (this.parkingLot && this.viewPastSpotHistory) {
        this.spotHistory.loading = true;
        // also get Updates from that time
        this.$emit(
          "spot-history-time-updated",
          this.spotHistory.date,
          this.convertTo24HourFormat(this.spotHistory.time)
        );
        let response = await api.getParkingSpotHistory(
          this.parkingLot.id,
          `${this.spotHistory.date} ${this.convertTo24HourFormat(
            this.spotHistory.time
          )}`
        );
        console.log("Spot History Response: ", response);
        if (response) {
          const all_spot_ids = response.map(
            (spot: any) => spot.parking_spot_id
          );
          for (let spot of response) {
            let existingSpotData = this.parkingLot.parking_spots.find(
              (s: ParkingSpot) => s.id === spot.parking_spot_id
            );
            if (existingSpotData) {
              existingSpotData.current_status = spot.status;
              existingSpotData.is_status_marked_unknown =
                spot.was_status_marked_unknown;
              existingSpotData.is_status_untrackable =
                spot.was_status_marked_unknown;
              if (spot.flipflop_status == "flipflop_activated") {
                existingSpotData.is_status_unknown = true;
                existingSpotData.is_status_unknown_flip_flop = true;
              } else {
                existingSpotData.is_status_unknown = false;
                existingSpotData.is_status_unknown_flip_flop = false;
              }

              let spotAnno = this.annotations.spots.find(
                (s: ParkingSpot) => s.id === spot.parking_spot_id
              );
              const newColor =
                SPOT_COLORS[spot.status as keyof typeof SPOT_COLORS];
              if (spotAnno && newColor) {
                spotAnno.setOptions({
                  fillColor: newColor,
                  strokeColor: "#0000FF",
                  fillOpacity: 0.5,
                  strokeOpacity: 0.5,
                });
                // Reset default stroke color in 0.3s
                setTimeout(() => {
                  if (spotAnno && spotAnno.getMap()) {
                    spotAnno.setOptions({ strokeColor: "#000000" });
                  }
                }, 300);
              }
              // show anpr data if available
              if (this.showAnprFields && spot.license_plate_detected != null) {
                let marker = new google.maps.Marker({
                  clickable: false,
                  draggable: false,
                  visible: true,
                  icon: {
                    path: mdiCarBack,
                    fillColor: "grey",
                    strokeColor: "grey",
                    fillOpacity: 1.0,
                    anchor: new google.maps.Point(
                      FIXED_MARKER_OPTIONS.iconAnchor.x,
                      FIXED_MARKER_OPTIONS.iconAnchor.y +
                        (this.showLicensePlates ? 9 : 0)
                    ),
                  },
                  // "Parked by" test is used to find this marker if edited please edit spotLPMarkers condition below
                  title:
                    "Parked by LP: " +
                    spot.license_plate_detected.toUpperCase(),
                  map: this.map,
                });
                this.spotHistory.timelineAnprMarkers.push(marker);
                let markerLP = null;

                if (this.showLicensePlates) {
                  markerLP = new google.maps.Marker({
                    clickable: false,
                    draggable: false,
                    visible: true,
                    label: {
                      text: spot.license_plate_detected.toUpperCase(),
                      className: "highlightLP",
                      fontSize: "10px",
                      fontWeight: "bold",
                      color: "black",
                    },
                    icon: {
                      url: `${spot.license_plate_detected.toUpperCase()}`,
                      anchor: new google.maps.Point(
                        FIXED_MARKER_OPTIONS.iconAnchor.x,
                        FIXED_MARKER_OPTIONS.iconAnchor.y - 9
                      ),
                    },
                    map: this.map,
                  });
                }

                let parkingSpotData = this.parkingLot.parking_spots.find(
                  (s: ParkingSpot) => s.id === spot.parking_spot_id
                );
                if (parkingSpotData) {
                  let spotPolygonGeoJSON =
                    typeof parkingSpotData.polygon === "string"
                      ? JSON.parse(parkingSpotData.polygon as any)
                      : parkingSpotData.polygon;
                  let spotLatLngs = spotPolygonGeoJSON.coordinates[0];
                  let markerPositions = getMarkerPositionsOnRect(
                    spotLatLngs,
                    1,
                    false
                  );
                  let markerPosition = markerPositions[0];
                  let latlng = new google.maps.LatLng(
                    markerPosition[1],
                    markerPosition[0]
                  );
                  marker.setPosition(latlng);
                  if (this.showLicensePlates && markerLP != null) {
                    markerLP.setPosition(latlng);
                    this.spotHistory.timelineAnprMarkers.push(markerLP);
                  }
                }
              }
            }
          }
          // Get all spots for which not history is available
          let non_existing_spots = this.parkingLot.parking_spots.filter(
            (s: ParkingSpot) =>
              !all_spot_ids.includes(s.id) && s.camera_id != null
          );
          for (let spot of non_existing_spots) {
            let spotAnno = this.annotations.spots.find(
              (s: ParkingSpot) => s.id === spot.id
            );
            spotAnno.setOptions({
              fillColor: SPOT_COLORS.free,
              strokeColor: "#0000FF",
              fillOpacity: 0.5,
              strokeOpacity: 0.5,
            });
          }
          let non_mapped_spots = this.parkingLot.parking_spots.filter(
            (s: ParkingSpot) => s.camera_id == null
          );
          for (let spot of non_mapped_spots) {
            let spotAnno = this.annotations.spots.find(
              (s: ParkingSpot) => s.id === spot.id
            );
            spotAnno.setOptions({
              fillColor: SPOT_COLORS.untrackable,
              strokeColor: "#0000FF",
              fillOpacity: 0.1,
              strokeOpacity: 0.1,
            });
          }
        }

        this.spotHistory.loading = false;
      }
    }, 300),
    async getUpdatedLotdataOnCameraSave() {
      const parkingLot = await api.getParkingLot(this.lotId);
      // update new saved camera details from camera form in digimap, without full page refresh
      if (parkingLot) {
        this.cameras = parkingLot.cameras;
        if (this.parkingLot) {
          this.parkingLot.cameras = parkingLot.cameras;
        }
        for (let camera of parkingLot.cameras) {
          const ann_camera = this.annotations.cameras.find(
            (cam) => cam.id == camera.id
          );
          if (ann_camera) {
            let camera_coords = JSON.parse(
              camera.gps_coordinates as string
            ).coordinates;
            ann_camera.setPosition(
              new google.maps.LatLng(camera_coords[1], camera_coords[0])
            );
          }
        }
      }
      this.activeTool = "select";
    },
    updateSpotIcons(spots: SpotBbox[], deletedBboxes: number[]) {
      if (!Array.isArray(spots) || spots.length === 0) {
        console.log("No spots provided to update.");
        return;
      }
      for (const marker of this.annotations.fixedSizeMarkers) {
        if (!marker) continue;

        const title = marker.get("title");
        if (title !== "No Camera Assigned") {
          continue; // Skip markers that don't have the desired title
        }
        for (const spot of spots) {
          if (spot && spot.spotId && marker.get("id")?.includes(spot.spotId)) {
            marker.setVisible(false);
          }
        }

        if (Array.isArray(deletedBboxes) && deletedBboxes.length !== 0) {
          for (const deletedSpotId of deletedBboxes) {
            if (
              deletedSpotId &&
              deletedSpotId &&
              marker.get("id")?.includes(deletedSpotId)
            ) {
              marker.setVisible(true);
            }
          }
        }
      }
      // Add new markers for deletedBboxes not in fixedSizeMarkers
      if (Array.isArray(deletedBboxes) && deletedBboxes.length !== 0) {
        for (const deletedSpot of deletedBboxes) {
          const isMarkerPresent = this.annotations.fixedSizeMarkers.some(
            (marker) =>
              marker.get("id")?.includes(deletedSpot) &&
              marker.get("title") === "No Camera Assigned"
          );

          let parkingSpotData = this.parkingLot?.parking_spots.find(
            (s: ParkingSpot) => s.id === deletedSpot
          );

          if (!isMarkerPresent) {
            let spotIconCount: google.maps.Marker[] = [];
            this.annotations.fixedSizeMarkers.forEach((marker) => {
              if (
                marker.get("id") &&
                marker.get("id").includes(parkingSpotData?.id)
              ) {
                spotIconCount.push(marker);
              }
            });
            if (this.interactive) {
              let marker = new google.maps.Marker({
                clickable: this.interactive,
                draggable: false,
                visible: true,
                icon: {
                  path: mdiCctvOff,
                  fillColor: "grey",
                  strokeColor: "grey",
                  fillOpacity: 1.0,
                  anchor: new google.maps.Point(
                    FIXED_MARKER_OPTIONS.iconAnchor.x,
                    FIXED_MARKER_OPTIONS.iconAnchor.y
                  ),
                },
                title: "No Camera Assigned",
                map: this.map,
              });
              marker.set("id", `Spot ${deletedSpot} marker`);
              spotIconCount.push(marker);

              let spotPolygonGeoJSON =
                typeof parkingSpotData?.polygon === "string"
                  ? JSON.parse(parkingSpotData.polygon as any)
                  : parkingSpotData?.polygon;
              let spotLatLngs = spotPolygonGeoJSON.coordinates[0];

              this.annotations.fixedSizeMarkers.push(marker);
              //Setting position for marker
              spotIconCount.forEach((marker, i) => {
                let markerPositions = getMarkerPositionsOnRect(
                  spotLatLngs,
                  spotIconCount.length,
                  false
                );
                const markerPosition = markerPositions[i];
                let latlng = new google.maps.LatLng(
                  markerPosition[1],
                  markerPosition[0]
                );
                marker.setPosition(latlng);
                marker.setVisible(true);
              });
            }
          }
        }
      }
    },
    extractEventAndData(str: string) {
      const lines = str.split("\n");
      let event = "";
      let data = "";

      lines.forEach((line) => {
        if (line.startsWith("event:")) {
          event = line.slice(6).trim();
        } else if (line.startsWith("data:")) {
          data = line.slice(5).trim();
        }
      });

      return { event, data };
    },
    generateTableForDisplayBoard(data: any) {
      let maxRow = "A";
      let maxCol = 0;
      data.forEach((item: any) => {
        const row = item.cell_id.charAt(0);
        const col = parseInt(item.cell_id.slice(1));
        if (row > maxRow) maxRow = row;
        if (col > maxCol) maxCol = col;
      });

      // Create the table
      let table = "<table>";

      // Add data rows
      for (
        let row = "A";
        row <= maxRow;
        row = String.fromCharCode(row.charCodeAt(0) + 1)
      ) {
        table += "<tr>";
        for (let col = 1; col <= maxCol; col++) {
          const cellId = `${row}${col}`;
          const cellData = data.find((item: any) => item.cell_id === cellId);
          table += `<td>${cellData ? cellData.value : ""}</td>`;
        }
        table += "</tr>";
      }

      table += "</table>";
      return table;
    },
    parseDisplayBoardsSseData(display_board_id: number, data: string) {
      const display_board = this.displayBoardsEvents.find(
        (item) => item.display_board_id === display_board_id
      );
      let new_data = null;
      if (display_board) {
        this.displayBoardsEvents = this.displayBoardsEvents.filter(
          (item) => item.display_board_id != display_board_id
        );
        this.displayBoardsEvents.push({
          display_board_id: display_board_id,
          data: data,
        });
        new_data = data.slice(display_board.data.length);
      } else {
        this.displayBoardsEvents.push({
          display_board_id: display_board_id,
          data: data,
        });
        new_data = data;
      }

      if (new_data != null) {
        try {
          const api_event = this.extractEventAndData(new_data);
          if (api_event.event == "count_update") {
            console.log(
              "DISPLAY BOARD: ",
              JSON.parse(api_event.data.replace(/'/g, '"'))
            );
            this.displayBoardEventsData = this.displayBoardEventsData.filter(
              (item) => item.display_board_id != display_board_id
            );
            const data = JSON.parse(api_event.data.replace(/'/g, '"'));
            this.displayBoardEventsData.push({
              display_board_id: display_board_id,
              data: data.cell_values,
            });

            // refresh display board marker on map
            for (let display_board_marker of this.annotations.display_boards) {
              console.log("DISPLAY BOARD: marker: ", display_board_marker);
              if (display_board_marker.id == display_board_id) {
                console.log(
                  "DISPLAY BOARD: updating for display: ",
                  display_board_id
                );
                const infowindow =
                  this.annotations.displayBoardInfoWindows.find(
                    (item) => item.display_board_id == display_board_id
                  );
                if (infowindow) {
                  console.log(
                    "DISPLAY BOARD: found infowindow ",
                    display_board_id
                  );
                  const displayBoardData = this.digitalBoards.find(
                    (item) => item.id == display_board_id
                  );
                  const displayBoardEvent = this.displayBoardEventsData.find(
                    (item) => item.display_board_id == display_board_id
                  );
                  if (infowindow) {
                    infowindow.infowindow.setContent(
                      displayBoardData
                        ? `
                      <div class="display-board-tooltip">
                        <span class="display-board-tooltip-title">${
                          displayBoardData.name
                        } (<span class="display-board-tooltip-status${
                            !displayBoardData.is_active
                              ? '-grey">Disabled'
                              : displayBoardData.is_running
                              ? '-green">Online'
                              : '-red">Offline'
                          }</span>)</span>
                        ${
                          displayBoardEvent
                            ? this.generateTableForDisplayBoard(
                                displayBoardEvent.data
                              )
                            : ""
                        }
                      </div>
                        `
                        : `Display Board ${display_board_id}`
                    );
                    console.log(
                      "DISPLAY BOARD: done updating for display: ",
                      display_board_id
                    );
                  }
                }
              }
            }
          }
        } catch (e) {
          console.log("DISPLAY BOARD: Failed to parse event data", e);
        }
      }
    },
    getDisplayBoardInfoWindow(display_board_id: number | null) {
      if (!display_board_id) return null;
      const displayBoardData = this.digitalBoards.find(
        (item) => item.id == display_board_id
      );
      const displayBoardEvent = this.displayBoardEventsData.find(
        (item) => item.display_board_id == display_board_id
      );
      let infowindow = new google.maps.InfoWindow({
        content: displayBoardData
          ? `
          <div class="display-board-tooltip">
            <span class="display-board-tooltip-title">${
              displayBoardData.name
            } <span class="display-board-tooltip-status${
              !displayBoardData.is_active
                ? '-grey">(Disabled)'
                : displayBoardData.is_running
                ? '-green">(Online)'
                : '-red">(Offline)'
            }</span></span>
            ${
              displayBoardEvent
                ? this.generateTableForDisplayBoard(displayBoardEvent.data)
                : ""
            }
          </div>
            `
          : `Display Board ${display_board_id}`,
      });
      return infowindow;
    },
    async subscribeDisplayBoardEvents(display_board_id: number) {
      axios
        .get(`/display_values/${display_board_id}`, {
          onDownloadProgress: (evt) => {
            let data = evt.target.responseText;
            this.parseDisplayBoardsSseData(display_board_id, data);
          },
        })
        .then((response) => {
          console.log("DISPLAY BOARD: Setup complete:", response.data);
          const eventSource = new EventSource(
            `http://localhost:8000/api/display_values/${display_board_id}`
          );
          eventSource.onmessage = function (event) {
            const data = JSON.parse(event.data);
            console.log("DISPLAY BOARD: New message from server:", data);
          };
          eventSource.onerror = function (error) {
            console.error("DISPLAY BOARD: EventSource failed:", error);
            eventSource.close();
          };
        })
        .catch((error) => {
          console.error("DISPLAY BOARD: Error during setup:", error);
        });
    },
    async getAllDisplayBoards() {
      if (this.parkingLot) {
        const display_boards = await api.getAllDisplayBoards(
          this.parkingLot.id
        );
        if (display_boards) {
          this.digitalBoards = display_boards;
          this.importDigitalBoardsOnMap(this.digitalBoards);

          for (let display_board of display_boards) {
            console.log(
              "DISPLAY BOARD: Calling Subscribe API for ",
              display_board.id
            );
            this.subscribeDisplayBoardEvents(display_board.id);
          }
        }
      }
    },
    async refreshLprLogs(camera_id: number) {
      this.selectedCameraId = camera_id;
      await this.getUpdatedLotdataOnCameraSave();
      this.displayCameraForm(true);
    },
    setMapHeight() {
      const header = document.getElementsByTagName("header")[0];
      let headerHeight = 0;
      if (header) {
        headerHeight = header.clientHeight;
      }
      if (this.isEditMode) {
        const mapEditOptions = document.getElementById("edit-map-options");
        let mapEditOptionsHeight = 0;
        if (mapEditOptions) {
          mapEditOptionsHeight = mapEditOptions.clientHeight;
        }
        const mapElm = document.getElementById("map");
        if (mapElm) {
          mapElm.style.height = `${
            window.innerHeight - headerHeight - mapEditOptionsHeight
          }px`;
        }
      } else {
        const breadcrumb = document.getElementById("lot-dashboard-breadcrumb");
        let breadcrumbHeight = 0;
        if (breadcrumb) {
          breadcrumbHeight = breadcrumb.clientHeight;
        }
        const mapPaddingRow = document.getElementById("show-map-properties");
        let mapPaddingRowHeight = 0;
        if (mapPaddingRow) {
          mapPaddingRowHeight = mapPaddingRow.clientHeight;
        }
        const mapElm = document.getElementById("map");
        if (mapElm) {
          mapElm.style.height = `${
            window.innerHeight -
            headerHeight -
            breadcrumbHeight -
            mapPaddingRowHeight
          }px`;
        }
      }
    },
    isInteger: function (evt: KeyboardEvent) {
      if (evt) {
        var charCode = evt.which ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    },
    showLoader(value: number, id: number) {
      switch (value) {
        case 0:
          this.isLoading = true;
          this.popup.show = false;
          break;
        case 1:
          this.isLoading = false;
          this.popup.show = false;
          break;
        case 2:
          this.popup.show = true;
          break;
        case 3:
          this.isLoading = false;
          this.popup.category = "";
          this.popup.savedSpot = null;
          this.popup.savedLocation = null;
          break;
      }
    },
    async initMaps(centerCoordinates: Array<number>) {
      const GOOGLE_MAPS_API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
      const loader = new Loader({
        apiKey: GOOGLE_MAPS_API_KEY,
        version: "weekly",
        libraries: ["drawing", "places"],
      });

      // // Wait for google maps to initialize/load.
      await loader.load();

      // Load map
      console.log("Loading map center", centerCoordinates);
      // PostGIS and GeoJSON store coordinates in format [lng, lat]
      const parkingLotLatLng = {
        lng: centerCoordinates[0],
        lat: centerCoordinates[1],
      };

      let zoom = 20;
      if (this.authToken) {
        zoom = 18.6;
      }
      let mapOptions = {
        center: parkingLotLatLng,
        zoom: zoom,
        mapTypeId: "roadmap",
        streetViewControl: this.interactive,
        disableDefaultUI: !this.interactive && !this.isViewMode,
        backgroundColor: "#F9F9F9",
        keyboardShortcuts: false,
      };
      // get prev map type before refresh from localStorage if available
      const saved_map_type = localStorage.getItem("mapType");
      if (saved_map_type) {
        mapOptions["mapTypeId"] = saved_map_type;
      }

      this.map = new google.maps.Map(
        document.getElementById("map") as HTMLElement,
        mapOptions
      );

      // Load Drawing Layer
      // this.drawingManager = new google.maps.drawing.DrawingManager({
      //   drawingControl: this.interactive,
      //   drawingControlOptions: {
      //     position: google.maps.ControlPosition.BOTTOM_CENTER,
      //     drawingModes: [
      //       google.maps.drawing.OverlayType.MARKER,
      //       google.maps.drawing.OverlayType.CIRCLE,
      //       google.maps.drawing.OverlayType.POLYGON,
      //       google.maps.drawing.OverlayType.POLYLINE,
      //       google.maps.drawing.OverlayType.RECTANGLE,
      //     ],
      //   },
      // });
      // this.drawingManager.setMap(this.map);

      // Set mouse click handler
      if (this.interactive) {
        this.map.addListener("click", this.onMapClick);
      }

      // Add data listeners
      this.map.data.addListener("click", this.featureAdded);

      // Add zoom listener
      this.map.addListener("zoom_changed", this.onZoomChanged);
      this.mapZoomLevel = this.map.getZoom() || 0;

      // Add listener to set camera fov
      this.map.addListener("mousemove", (event: google.maps.MapMouseEvent) => {
        if (this.selectedCameraFov.edit && event.latLng) {
          this.editCameraFov(event.latLng);
        }
      });

      // set map height once it is loaded
      setTimeout(() => {
        this.setMapHeight();
      }, 300);

      // Poll for dynamic elements rendering
      this.checkAllControlsRendered();
    },

    checkAllControlsRendered() {
      const interval = setInterval(() => {
        const controlsRendered = this.areAllControlsRendered();
        if (controlsRendered) {
          console.log("All Google Maps elements/controls have been rendered.");
          this.addFocusStyles();
          clearInterval(interval);
        }
      }, 100); // Check every 100ms
    },

    areAllControlsRendered() {
      // Check for specific map elements
      const mapElement = document.getElementById("map");
      if (!mapElement) return false;

      const zoomControls = mapElement.querySelector(".gm-control-active");
      const markerLayer = mapElement.querySelector("canvas");
      const otherControls =
        mapElement.querySelectorAll(
          ".gmnoprint button, .gm-style-cc button, .gm-fullscreen-control, .gm-style-mtc button"
        ) || []; // Ensure it's always an iterable

      // Ensure all required elements are present
      return (
        zoomControls !== null &&
        markerLayer !== null &&
        otherControls.length > 0
      );
    },

    addFocusStyles() {
      // Add focus styles to map controls
      const controls = document.querySelectorAll(
        ".gmnoprint button, .gm-style-cc button, .gm-fullscreen-control, .gm-style-mtc button"
      );

      if (controls.length > 0) {
        controls.forEach((control) => {
          // Ensure controls are focusable
          if (!control.hasAttribute("tabindex")) {
            control.setAttribute("tabindex", "0");
          }

          // Apply focus styles
          control.addEventListener("focus", () => {
            const element = control as HTMLElement;
            element.style.outline = "2px solid #1061B6";
            element.style.outlineOffset = "2px";
          });

          control.addEventListener("blur", () => {
            const element = control as HTMLElement;
            element.style.outline = "none";
          });
        });
      }
    },

    drawParkingLotAnnotations() {
      if (this.parkingLot) {
        // check which view is currently open (full lot view or parking structure view or level view)
        if (this.multiLevelParkingStructure.isParkingStructureView) {
          // Draw parking structure view
          this.openMultilevelParkingStructure(
            this.multiLevelParkingStructure.currentMultiStructureZoneAnno
          );
        } else if (this.parkingLot.map) {
          // Draw digimap of normal lot (ground) view or level digimap view
          this.importGeoJson(
            this.parkingLot.map,
            this.parkingLot.parking_spots,
            this.parkingLot.parking_zones
          );
        }
        this.drawParkingStructureMultiLevelShapes();
        this.importCamerasOnMap(this.parkingLot.cameras);
        this.importDigitalBoardsOnMap(this.digitalBoards);
        this.drawNonSpotSavedParking();
      }
    },

    async initParkingAutoRefresh(timeout: number) {
      if (this.viewPastSpotHistory) {
        return;
      }
      console.log("Enabled auto-refreshing parking spot statuses");
      // Auto refresh parking spot updates
      let loadingRefreshData = false;
      let parkingLotDataLastFetchedAt: string | null = null;
      this.autoRefreshIntervalId = setInterval(async () => {
        if (this.$route.name != "LotDashboard") return;
        if (this.viewPastSpotHistory) {
          return;
        }
        // When browser tab is not visible (user has switched to another tab),
        // then do not poll api.
        if (document.hidden) return;
        if (this.authToken) {
          this.parkingLot = await api.getParkingLotMapData(
            this.lotId,
            this.authToken
          );
          return;
        }
        if (loadingRefreshData) return;
        loadingRefreshData = true;
        let apiRequestTime = new Date();
        let parkingLot = await api.getParkingLot(
          this.lotId,
          parkingLotDataLastFetchedAt
        );
        parkingLotDataLastFetchedAt = apiRequestTime
          .toISOString()
          .replace("Z", "");

        loadingRefreshData = false;

        // Check if data has changed and digimap needs to be redrawn.
        let mapNeedsRedraw = false;
        if (parkingLot && this.parkingLot) {
          // Update current_vehicle_count_detected in PropertiesPopup
          if (parkingLot) {
            if (this.popup.data != null) {
              let specialArea = parkingLot.special_areas.find(
                (sa) => sa.id == this.popup.data.id
              );
              if (
                specialArea &&
                (specialArea.current_vehicle_count_detected != null ||
                  specialArea.current_vehicle_count_detected != undefined) &&
                specialArea.current_vehicle_count_detected !=
                  this.popup.data.current_vehicle_count_detected
              ) {
                this.popup.data.current_vehicle_count_detected =
                  specialArea.current_vehicle_count_detected;
              }
            }
          }

          for (let spotDataUpdate of parkingLot.parking_spots) {
            let existingSpotData = this.parkingLot.parking_spots.find(
              (s) => s.id === spotDataUpdate.id
            );
            // First check for any unknown flag update, only then check for spot status update
            // If spot is unknown or untrackable then spot status update should be ignored
            if (
              existingSpotData &&
              !(
                spotDataUpdate.is_status_unknown ||
                spotDataUpdate.is_status_marked_unknown ||
                spotDataUpdate.is_status_unknown_flip_flop ||
                spotDataUpdate.is_status_unknown_parallel_parking ||
                spotDataUpdate.is_status_unknown_camera_offline ||
                spotDataUpdate.is_status_unknown_camera_inactive ||
                spotDataUpdate.is_status_unknown_edge_device_offline
              ) &&
              existingSpotData.current_status !== spotDataUpdate.current_status
            ) {
              console.log(
                "Observed Update for spot",
                spotDataUpdate.id,
                spotDataUpdate.current_status,
                "from",
                existingSpotData.id,
                existingSpotData.current_status
              );
              existingSpotData.current_status = spotDataUpdate.current_status;
              existingSpotData.status_updated_at =
                spotDataUpdate.status_updated_at;
              let spotAnno = this.annotations.spots.find(
                (s) => s.id === spotDataUpdate.id
              );
              const newColor = SPOT_COLORS[spotDataUpdate.current_status];
              if (spotAnno && newColor) {
                console.log("flashing", spotAnno.id);
                // Flash spot stroke color to indicate its status has changed
                spotAnno.setOptions({
                  fillColor: newColor,
                  strokeColor: "#0000FF",
                });
                // Reset default stroke color in 0.6s
                setTimeout(() => {
                  if (spotAnno && spotAnno.getMap()) {
                    console.log("unflashing", spotAnno.id);
                    spotAnno.setOptions({ strokeColor: "#000000" });
                  }
                }, 600);
              }
            }
            if (
              existingSpotData &&
              (existingSpotData.is_status_unknown !==
                spotDataUpdate.is_status_unknown ||
                existingSpotData.is_status_marked_unknown !==
                  spotDataUpdate.is_status_marked_unknown ||
                existingSpotData.is_status_unknown_flip_flop !==
                  spotDataUpdate.is_status_unknown_flip_flop ||
                existingSpotData.is_status_unknown_parallel_parking !==
                  spotDataUpdate.is_status_unknown_parallel_parking ||
                existingSpotData?.is_status_unknown_camera_offline !==
                  spotDataUpdate.is_status_unknown_camera_offline ||
                existingSpotData?.is_status_unknown_edge_device_offline !==
                  spotDataUpdate.is_status_unknown_edge_device_offline ||
                existingSpotData?.is_status_unknown_camera_inactive !==
                  spotDataUpdate.is_status_unknown_camera_inactive ||
                existingSpotData?.vehicle_parking_usage_anpr_record_id !==
                  spotDataUpdate.vehicle_parking_usage_anpr_record_id ||
                existingSpotData?.vehicle_parking_usage_anpr_lp_number !==
                  spotDataUpdate.vehicle_parking_usage_anpr_lp_number)
            ) {
              console.log(
                "Observed",
                spotDataUpdate.id,
                "changed unknown status to",
                spotDataUpdate.is_status_unknown
              );
              existingSpotData.is_status_unknown =
                spotDataUpdate.is_status_unknown;
              existingSpotData.is_status_marked_unknown =
                spotDataUpdate.is_status_marked_unknown;
              existingSpotData.is_status_unknown_flip_flop =
                spotDataUpdate.is_status_unknown_flip_flop;
              existingSpotData.is_status_unknown_parallel_parking =
                spotDataUpdate.is_status_unknown_parallel_parking;
              existingSpotData.is_status_unknown_camera_offline =
                spotDataUpdate.is_status_unknown_camera_offline;
              existingSpotData.is_status_unknown_edge_device_offline =
                spotDataUpdate.is_status_unknown_edge_device_offline;
              existingSpotData.is_status_unknown_camera_inactive =
                spotDataUpdate.is_status_unknown_camera_inactive;
              mapNeedsRedraw = true;
              break;
            }
          }
          // also check if untracked zone counts have changed
          for (let zoneDataUpdate of parkingLot.parking_zones.filter(
            (zone) => zone.is_untracked
          )) {
            let existingZoneData = this.parkingLot.parking_zones.find(
              (z) => z.id === zoneDataUpdate.id
            );
            let idx = this.parkingLot.parking_zones.findIndex(
              (z) => z.id === zoneDataUpdate.id
            );
            if (
              existingZoneData &&
              (existingZoneData.num_total_untracked_spots !=
                zoneDataUpdate.num_total_untracked_spots ||
                existingZoneData.num_free_parking_spots !=
                  zoneDataUpdate.num_free_parking_spots ||
                existingZoneData.num_free_untracked_spots !=
                  zoneDataUpdate.num_free_untracked_spots ||
                existingZoneData.num_vehicles_detected !=
                  zoneDataUpdate.num_vehicles_detected)
            ) {
              this.parkingLot.parking_zones[idx] = zoneDataUpdate;
              mapNeedsRedraw = true;
              break;
            }
          }
          // also check if camera offline status has changed, since line counter zone
          // count should show unknown now
          for (let cameraDataUpdate of parkingLot.cameras) {
            let idx = this.cameras.findIndex(
              (c) => c.id === cameraDataUpdate.id
            );
            let existingCameraData = this.cameras[idx];
            if (
              existingCameraData &&
              (existingCameraData.is_stream_unreadable !=
                cameraDataUpdate.is_stream_unreadable ||
                existingCameraData.is_active != cameraDataUpdate.is_active ||
                existingCameraData.edge_device?.is_device_offline !=
                  cameraDataUpdate.edge_device?.is_device_offline ||
                existingCameraData.is_starting_up !=
                  cameraDataUpdate.is_starting_up)
            ) {
              console.log(
                "Observed, camera is_stream_unreadable change cameraID",
                existingCameraData.id
              );
              this.cameras[idx] = cameraDataUpdate;
              mapNeedsRedraw = true;
            }
          }
        }
        if (mapNeedsRedraw) {
          setTimeout(() => {
            if (this.parkingLot?.map) {
              console.log("Redrawing map...");
              this.parkingLot = parkingLot;
              this.clearMapAnnotations();
              this.drawParkingLotAnnotations();
            }
          }, 700); // Wait for all unflashing of spots to finish, then redraw map.
        }
      }, timeout);
    },
    onMapClick(event: google.maps.MapMouseEvent) {
      let { domEvent, latLng } = event;
      console.log("Clicked on map", domEvent, latLng);
      switch (this.activeTool) {
        case "driveway":
        case "lane":
        case "zone":
        case "parking_structure":
        case "special_area":
        case "lot": {
          if (latLng) {
            this.addLinePoint(latLng, true);
          }
          break;
        }
        case "spot": {
          if (latLng) {
            this.addPolyPoint(latLng, "spot", 4, true);
          }
          break;
        }
        case "select": {
          this.unSelectCurrent();
          break;
        }
        case "camera": {
          if (latLng && this.current.marker) {
            this.current.marker.setPosition(latLng);
            this.undoList.push({
              name: "camera",
              action: "undo_camera",
              args: { marker: this.current.marker, latLng },
            });
          }
          break;
        }
        case "display_board": {
          if (latLng && this.current.marker) {
            this.current.marker.setPosition(latLng);
            this.undoList.push({
              name: "display_board",
              action: "undo_display_board",
              args: { marker: this.current.marker, latLng },
            });
          }
          break;
        }
        case "landmark": {
          // Hiding temporarily due to landmark duplication bug
          // if (latLng && this.current.marker) {
          //   this.saveCurrentAnnotation(this.activeTool);
          //   this.toolChanged(this.activeTool);
          //   this.current.marker.setPosition(latLng);
          //   this.undoList.push({
          //     name: "landmark",
          //     action: "undo_landmark",
          //     args: { marker: this.current.marker, latLng },
          //   });
          // }
          break;
        }
      }

      // Also stop camera fov setting and reset selectedCameraFov
      this.stopCameraFovSetting();
    },
    addLinePoint(latLng: google.maps.LatLng, doUndo = false) {
      if (this.current.line) {
        let latLngJson = latLng.toJSON();
        this.current.line
          .getPath()
          .push(new google.maps.LatLng(latLngJson.lat, latLngJson.lng));
        if (doUndo)
          this.undoList.push({
            name: "lot",
            action: "undo_lot",
            args: { latLng },
          });
      }
    },
    addPolyPoint(
      latLng: google.maps.LatLng,
      category: string,
      polyMaxPoints: number,
      doUndo = false
    ) {
      if (this.current.poly) {
        // Save the current polygon if polyMaxPoints points have been drawn,
        // then create a new polygon.
        if (this.current.poly.getPath().getLength() >= polyMaxPoints) {
          this.saveCurrentAnnotation(category);
          this.toolChanged(this.activeTool); // Trigger creating a new polygon
          if (doUndo)
            this.undoList.push({
              name: "spot",
              action: "undo_spot_poly",
              args: { poly: this.current.poly },
            });
        }
        this.current.poly.getPath().push(latLng);
        if (doUndo)
          this.undoList.push({
            name: "spot",
            action: "undo_spot",
            args: { latLng },
          });
      }
    },
    onPointClick(event: google.maps.PolyMouseEvent) {
      let vertexIdx = event.vertex;
      const currentLine = this.current.line;
      // Check if the click was on a vertex of a polyline
      if (vertexIdx != null && currentLine != null) {
        console.log("Clicked on existing vertex", vertexIdx);
        // Start a new line if the user clicked on the last vertex of the current line,
        // else add a new point at the end of the current line
        switch (this.activeTool) {
          case "lane":
          case "driveway": {
            if (vertexIdx === currentLine.getPath().getLength() - 1) {
              this.saveCurrentAnnotation(this.activeTool);
              this.toolChanged(this.activeTool);
            } else {
              this.onMapClick(event);
            }
            break;
          }
          case "zone":
          case "parking_structure":
          case "special_area":
          case "lot": {
            // click on first point to complete line
            if (vertexIdx === 0) {
              this.onMapClick(event); // draw line back to first point
              this.saveCurrentAnnotation(this.activeTool);
              this.toolChanged(this.activeTool);
            } else {
              this.onMapClick(event);
            }
            break;
          }
        }
      }
    },
    unSelectCurrent() {
      if (this.current.poly) {
        this.current.poly.setEditable(false);
        this.current.poly = null;
      }
      if (this.current.line) {
        this.current.line.setEditable(false);
        this.current.line = null;
      }
      if (this.current.marker) {
        this.current.marker = null;
      }
    },
    onSelect(
      event: google.maps.PolyMouseEvent,
      poly?: Spot | null,
      line?: Driveway | Lane | Zone | SpecialArea | null
    ) {
      this.toolChanged("select");
      this.unSelectCurrent();
      if (poly) {
        console.log("Clicked on poly", poly);
        if (this.isEditMode) {
          poly.setEditable(true);
        }
        this.current.poly = poly as Spot;
      } else if (line) {
        console.log("Clicked on line", line);
        this.current.line = line;
      }
    },
    deleteSpot(poly: google.maps.Polygon) {
      // Do not allow deletes in view mode
      if (this.isViewMode) {
        return;
      }
      this.toolChanged("select");
      this.unSelectCurrent();
      console.log("deleting spot", poly);
      poly.setMap(null);
      // Remove from annotations list
      const annIdx = this.annotations.spots.indexOf(poly as Spot);
      this.annotations.spots.splice(annIdx, 1);
      this.undoList.push({
        name: "spot",
        action: "undo_spot_delete",
        args: { poly },
      });
      // Hide and reset Properties Popup after delete
      this.popup.data = null;
      this.popup.annotationObj = null;
      this.popup.show = false;
      this.popup.category = "";
    },
    deleteLandmark(landmark: Landmark) {
      // Remove the landmark from annotations
      landmark.setMap(null);
      let idx = this.annotations.landmarks.indexOf(landmark);
      this.annotations.landmarks.splice(idx, 1);
      // Hide and reset Properties Popup after delete
      this.popup.data = null;
      this.popup.annotationObj = null;
      this.popup.show = false;
      this.popup.category = "";
    },
    deleteZone(zone: Zone) {
      if (this.isViewMode) {
        return;
      }
      this.toolChanged("select");
      this.unSelectCurrent();
      console.log("deleting zone", zone);
      zone.setMap(null);
      // Remove from annotations list
      const annIdx = this.annotations.zones.indexOf(zone);
      let deletedZone = this.annotations.zones.splice(annIdx, 1);

      // Remove shapes of multi-level parking structure zone
      if (zone.category == "parking_structure") {
        let shapes = zone.shapes || [];
        for (let shape of shapes) {
          console.log("shape delete", shape);
          shape.setMap(null);
        }
      }
      this.undoList.push({
        name: "zone",
        action: "undo_zone_delete",
        args: { line: deletedZone[0] },
      });
      // Hide and reset Properties Popup after delete
      this.popup.data = null;
      this.popup.annotationObj = null;
      this.popup.show = false;
      this.popup.category = "";
    },
    deleteSpecialArea(specialArea: SpecialArea) {
      if (this.isViewMode) {
        return;
      }
      this.toolChanged("select");
      this.unSelectCurrent();
      console.log("deleting specialArea", specialArea);
      specialArea.setMap(null);
      // Remove from annotations list
      const annIdx = this.annotations.specialAreas.indexOf(specialArea);
      const deletedSpecialArea = this.annotations.specialAreas.splice(
        annIdx,
        1
      );
      this.undoList.push({
        name: "specialArea",
        action: "undo_special_area_delete",
        args: { line: deletedSpecialArea[0] },
      });
      // Hide and reset Properties Popup after delete
      this.popup.data = null;
      this.popup.annotationObj = null;
      this.popup.show = false;
      this.popup.category = "";
    },
    deleteLane(lane: Lane) {
      if (this.isViewMode) {
        return;
      }
      this.toolChanged("select");
      this.unSelectCurrent();
      console.log("deleting lane", lane);
      // Remove from annotations list
      lane.setMap(null);
      const annIdx = this.annotations.lanes.indexOf(lane);
      if (annIdx >= 0) {
        console.log("Removing lane", annIdx);
        const line = this.annotations.lanes.splice(annIdx, 1);
        this.undoList.push({
          name: "lane",
          action: "undo_lane_delete",
          args: { line: line[0] },
        });
      } else {
        this.undoList.push({
          name: "lane",
          action: "undo_lane_delete",
          args: { line: lane },
        });
      }
      // Hide and reset Properties Popup after delete
      this.popup.data = null;
      this.popup.annotationObj = null;
      this.popup.show = false;
      this.popup.category = "";
    },
    onSelectCamera(event: google.maps.MapMouseEvent, camera: Camera) {
      console.log("Selected camera", camera);
      if (this.current.marker?.id == null) {
        this.current.marker?.setMap(null);
        this.unSelectCurrent();
      }
      this.current.marker = camera;
    },
    drawZoneEntrypoint(zone: Zone, latLng: google.maps.LatLng) {
      zone.entrypointsMarkers.push(
        new google.maps.Marker({
          clickable: false,
          draggable: false,
          map: this.map,
          title: "Entrypoint",
          position: latLng,
          label: {
            text: "E",
            color: "white",
            fontSize: "14px",
          },
        })
      );
    },
    drawParkingStructureMultiLevelShapes() {
      console.log("drawParkingStructureMultiLevelShapes");
      for (let zoneAnno of this.annotations.zones) {
        if (zoneAnno.multiLevelNumLevels) {
          // Remove old shape and redraw shape
          if (zoneAnno.shapes) {
            for (let shape of zoneAnno.shapes) {
              shape.setMap(null);
            }
          }
          zoneAnno.shapes = [];
          // let offsetPath = zoneAnno.getPath();
          let offsetPath = new google.maps.MVCArray();
          for (let i = 0; i < zoneAnno.getPath().getLength(); i++) {
            let point = zoneAnno.getPath().getAt(i);
            let pointOffset = new google.maps.LatLng(
              point.lat() - 0.00002,
              point.lng() + 0.00001
            );
            offsetPath.push(pointOffset);
          }
          // bottom dark shape
          zoneAnno.shapes.push(
            new google.maps.Polygon({
              paths: offsetPath,
              strokeColor: "#D0AE94",
              strokeOpacity: 1.0,
              strokeWeight: STROKE_WEIGHTS["parking_structure"],
              fillColor: "#D0AE94",
              fillOpacity: 1.0,
              map: this.map,
            }) as ParkingStructureShape
          );
          // top light shape
          zoneAnno.shapes.push(
            new google.maps.Polygon({
              paths: zoneAnno.getPath(),
              strokeColor: COLORS["parking_structure"],
              strokeOpacity: 1.0,
              strokeWeight: STROKE_WEIGHTS["parking_structure"],
              fillColor: COLORS["parking_structure_fill"],
              fillOpacity: 1.0,
              map: this.map,
            }) as ParkingStructureShape
          );

          for (let shape of zoneAnno.shapes) {
            shape.id = zoneAnno.id;
            shape.category = "parking_structure";

            shape.addListener("click", () => {
              console.log("Clicked shape");
              this.openMultilevelParkingStructure(zoneAnno);
            });

            if (this.interactive) {
              let structureZoneData = this.parkingLot?.parking_zones.find(
                (z) => z.id == zoneAnno.id
              );
              shape.addListener(
                this.is_iOS ? "dblclick" : "contextmenu",
                (event: google.maps.PolyMouseEvent) => {
                  // do nothing if Timeline enabled
                  if (this.viewPastSpotHistory) {
                    return;
                  }
                  // this.showConfirmDelete(event, line, tool)
                  this.displayPropertiesPopup({
                    category: "parking_structure",
                    id: zoneAnno.id,
                    data: structureZoneData,
                    annotationObj: zoneAnno,
                  });
                }
              );
            }
          }
        }
      }
    },
    async cancelLeavingWithUnsavedChangesOnDigimap() {
      if (!this.unsavedChangesExist) {
        return false;
      }
      let leaveWithoutSaving = await (
        this.$refs.confirmLeaveDigimap as any
      ).open(
        "Confirm Unsaved Changes",
        "There are unsaved changes on the Map, Are you sure you want to leave ?",
        { color: "red" }
      );
      if (leaveWithoutSaving) {
        this.clearUndoHistory();
        return false; // Return false to proceed with leaving without saving changes
      } else {
        return true; // Return true to cancel leaving without saving changes
      }
    },
    async openMultilevelParkingStructure(zoneAnno: Zone | null) {
      if (await this.cancelLeavingWithUnsavedChangesOnDigimap()) {
        return;
      }
      const zoneId = zoneAnno?.id || null;
      if (zoneAnno == null || zoneId == null || this.map == null) {
        let error = "Could not open selected Multi-Level parking structure.";
        if (this.isEditMode) {
          error +=
            " \nPlease ensure that you have saved this parking structure first.";
        }
        this.$dialog.message.warning(error, {
          position: "top-right",
          timeout: 5000,
        });
        return;
      }

      console.log(
        "Opening Multilevel Parking structure Zone ID",
        zoneAnno.id,
        zoneAnno
      );
      this.multiLevelParkingStructure.isParkingStructureView = true;
      this.multiLevelParkingStructure.isLevelDigimapView = false;
      this.multiLevelParkingStructure.currentMultiStructureZoneId = zoneId;
      this.multiLevelParkingStructure.currentMultiStructureZoneAnno = zoneAnno;
      // Change zone filter in occupancy sidebar to to the currently visible level
      this.$emit(
        "change-zone-filters",
        this.multiLevelParkingStructure.currentMultiStructureZoneId
      );

      // clear all existing annotations and google map background,
      // only keep single color as background.
      this.clearMapAnnotations();
      var mapStyles = [
        {
          featureType: "all",
          stylers: [{ visibility: "off" }],
        },
      ];
      this.map.setOptions({ styles: mapStyles });

      // Draw floors grid
      this.drawParkingStructureFloorsGrid(zoneId);
    },

    closeMultiLevelParkingStructure() {
      this.$router.go(0);
    },

    async drawParkingStructureFloorsGrid(zoneId: number) {
      const GRID_COLS = 3; // Number of zones in a row

      if (!this.multiLevelParkingStructure.currentMultiStructureZoneId) {
        console.error("MultiLevel zone structure not selected.");
        return;
      }

      let levels = await api.getMultiStructureZoneLevels(
        this.lotId,
        this.multiLevelParkingStructure.currentMultiStructureZoneId
      );
      console.log("Got levels", levels);

      if (!levels) {
        console.error(
          "Zone does not have levels",
          this.multiLevelParkingStructure.currentMultiStructureZoneId
        );
        return;
      }

      this.multiLevelParkingStructure.numLevels = levels.length;
      this.multiLevelParkingStructure.levels = levels;

      // Add extra level to draw last add level button
      if (this.isEditMode) {
        levels = [...levels, levels[levels.length - 1]];
      }

      // Draw each zone level at row and column offsets
      for (let [zoneIndex, levelZone] of levels.entries()) {
        const colIndex = zoneIndex % GRID_COLS;
        const rowIndex = Math.floor(zoneIndex / GRID_COLS);
        const isAddLevelButton =
          this.isEditMode && zoneIndex === levels.length - 1;

        let poly = JSON.parse(levelZone.polygon as any);
        console.log("---->", poly, zoneIndex);

        let coordinates = poly.coordinates[0];
        let numPoints = coordinates.length;

        // Determine offset between each zone in grid
        let gpsCenter = JSON.parse(levelZone.gps_coordinates as any);
        let center = new google.maps.LatLng(
          gpsCenter.coordinates[1],
          gpsCenter.coordinates[0]
        );
        let maxOffsetHalfLength = 0;
        for (let i = 0; i < numPoints; i++) {
          let pointVals = coordinates[i];
          let point = new google.maps.LatLng(pointVals[1], pointVals[0]);
          // let distance = google.maps.geometry.spherical.computeDistanceBetween(center, point);
          let distance = Math.sqrt(
            Math.pow(gpsCenter.coordinates[1] - pointVals[1], 2) +
              Math.pow(gpsCenter.coordinates[0] - pointVals[0], 2)
          );
          if (distance > maxOffsetHalfLength) {
            maxOffsetHalfLength = distance;
          }
        }
        console.log("maxOffsetHalfLength", maxOffsetHalfLength);

        let offsetPath = new google.maps.MVCArray();
        const directionLat = gpsCenter.coordinates[1] > 0 ? -1 : 1;
        const directionLng = gpsCenter.coordinates[0] < 0 ? -1 : 1;

        for (let i = 0; i < numPoints; i++) {
          let pointVals = coordinates[i];
          let pointOffset = new google.maps.LatLng(
            pointVals[1] + rowIndex * maxOffsetHalfLength * 2.5 * directionLat,
            pointVals[0] + colIndex * maxOffsetHalfLength * 2.5 * directionLng
          );
          offsetPath.push(pointOffset);
        }

        let levelText;
        if (!isAddLevelButton) {
          let polygon = new google.maps.Polygon({
            paths: offsetPath,
            strokeColor: COLORS["parking_structure"],
            strokeOpacity: 1.0,
            strokeWeight: STROKE_WEIGHTS["parking_structure"],
            fillColor: COLORS["parking_structure_fill"],
            fillOpacity: 1.0,
            map: this.map,
          });
          this.annotations.zones.push(polygon as any);
          console.log("Added polygon", offsetPath);

          polygon.addListener("click", () => {
            this.openMultiStructureLevel(levelZone);
          });
          levelText = levelZone.name || "Level " + (zoneIndex + 1);

          // Also display counts information next to each level name.
          // Check if the vehicle counter camera is offline or edge device is
          // offline and show the vehicle count as unknown.
          let unknownCameraIds = [];
          if (
            levelZone.untracked_zone_camera_ids &&
            // levelZone.zone_type == "line_counter_zone"
            levelZone.is_untracked
          ) {
            for (let cam of this.cameras) {
              if (
                (levelZone.untracked_zone_camera_ids?.includes(cam.id) ||
                  levelZone.adjacent_zone_camera_ids?.includes(cam.id)) &&
                (cam.is_stream_unreadable ||
                  cam.is_lpr_unreadable ||
                  !cam.is_active ||
                  cam.edge_device?.is_device_offline ||
                  cam.is_starting_up)
              ) {
                unknownCameraIds.push(cam.id);
              }
            }
          }
          if (unknownCameraIds.length == 0) {
            if (levelZone.zone_type == "time_limited_zone") {
              levelText += `, Count: ${levelZone.num_vehicles_detected}`;
            } else {
              levelText += `, Available: ${levelZone.num_free_parking_spots}/${levelZone.num_total_parking_spots}`;
            }
          } else {
            let unknownCameraIdsStr = unknownCameraIds.join(",");
            levelText += `, Count Unknown, Camera ID ${unknownCameraIdsStr} is Offline`;
          }
        } else {
          let addLevelMarker = new google.maps.Marker({
            draggable: false,
            visible: true,
            icon: {
              url: addLevelSvg,
              // anchor: new google.maps.Point(
              //   FIXED_MARKER_OPTIONS.iconAnchor.x,
              //   FIXED_MARKER_OPTIONS.iconAnchor.y
              // ),
              anchor: new google.maps.Point(67, 67),
              //size: new google.maps.Size(137, 137),
            },
            map: this.map,
            position: {
              lat:
                gpsCenter.coordinates[1] +
                rowIndex * maxOffsetHalfLength * 2.5 * directionLat,
              lng:
                gpsCenter.coordinates[0] +
                colIndex * maxOffsetHalfLength * 2.5 * directionLng,
            },
          });
          let addLevelHandler = addLevelMarker.addListener("click", () => {
            addLevelHandler.remove(); // debounce to avoid creating duplicate levels on doubleclick.
            this.addNewMultiLevelParkingStructureLevel();
          });
          levelText = "";
        }

        if (levelText) {
          let marker = new google.maps.Marker({
            clickable: false,
            draggable: false,
            visible: true,
            label: {
              text: levelText,
              className: "highlight",
              fontWeight: "bold",
              color: "white",
            },
            icon: {
              url: `${levelZone.id}`,
              anchor: new google.maps.Point(
                FIXED_MARKER_OPTIONS.iconAnchor.x,
                FIXED_MARKER_OPTIONS.iconAnchor.y
              ),
            },
            map: this.map,
            zIndex: 100,
          });
          marker.setPosition(
            new google.maps.LatLng(
              gpsCenter.coordinates[1] +
                rowIndex * maxOffsetHalfLength * 2.5 * directionLat,
              gpsCenter.coordinates[0] +
                colIndex * maxOffsetHalfLength * 2.5 * directionLng
            )
          );
          this.annotations.fixedSizeMarkers.push(marker);
        }
      }
    },

    async openMultiStructureLevel(levelZone: ParkingZone) {
      if (await this.cancelLeavingWithUnsavedChangesOnDigimap()) {
        return;
      }

      const levelZoneId = levelZone.id || null;
      if (!this.map || !levelZoneId) {
        console.error("Invalid data when viewing multistructure level");
        return;
      }
      console.log("Clicked level", levelZoneId);
      this.clearMapAnnotations();

      console.log("Opening Level Zone", levelZoneId, levelZone);

      this.multiLevelParkingStructure.isParkingStructureView = false;
      this.multiLevelParkingStructure.isLevelDigimapView = true;
      this.multiLevelParkingStructure.currentMultiStructureZoneId = levelZoneId;
      // Change zone filter in occupancy sidebar to to the currently visible level
      this.$emit(
        "change-zone-filters",
        this.multiLevelParkingStructure.currentMultiStructureZoneId
      );

      // unhide map styles (previously hidden by level grid view)
      var mapStyles = [
        {
          featureType: "all",
          stylers: [{ visibility: "on" }],
        },
      ];
      const gpsCoordindates = JSON.parse(levelZone.gps_coordinates as any);
      const levelCenter = {
        lng: gpsCoordindates.coordinates[0],
        lat: gpsCoordindates.coordinates[1],
      };
      this.map.setOptions({
        center: levelCenter,
        //styles: mapStyles
      });

      // Add background poly
      if (levelZone.polygon) {
        let polyCoords = JSON.parse(String(levelZone.polygon)).coordinates;
        let polyLatLngs: any = [];
        for (let [lng, lat] of polyCoords[0]) {
          polyLatLngs.push({ lng, lat });
        }
        let polygon = new google.maps.Polygon({
          paths: polyLatLngs,
          strokeColor: COLORS["parking_structure"],
          strokeOpacity: 1.0,
          strokeWeight: STROKE_WEIGHTS["parking_structure"],
          fillColor: COLORS["parking_structure_fill"],
          fillOpacity: 1.0,
          map: this.map,
          zIndex: -100,
          clickable: false,
          draggable: false,
          editable: false,
        });
        //this.annotations.zones.push(polygon as any);
      }

      let digimapResp = await api.getMultiLevelParkingStructureZoneDigimap(
        this.lotId,
        this.multiLevelParkingStructure.currentMultiStructureZoneId
      );
      console.log("Got level digimap", digimapResp);

      if (!digimapResp || !digimapResp.map) {
        this.$dialog.message.error(
          "This parking structure level does not have a digimap drawn on it.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        return;
      }
      if (this.parkingLot) {
        // Set level digimap/spots/zones to lot, and redraw annotations of level on digimap
        this.parkingLot.map = digimapResp.map;
        this.parkingLot.parking_spots = digimapResp.parking_spots;
        this.parkingLot.parking_zones = digimapResp.parking_zones;
        this.drawParkingLotAnnotations();
      }
    },

    /**
     * Increase the number of levels in current multi-level parking Structure
     * on clicking the "Add Level" button.
     */
    async addNewMultiLevelParkingStructureLevel() {
      this.isLoading = true;
      let parkingLot = await api.getParkingLot(this.lotId);
      if (!parkingLot || !parkingLot.map) {
        this.$dialog.message.error(
          "Unable to fetch existing parking lot digimap. Failed to add level, please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        return;
      }
      const updatedNumLevels = this.multiLevelParkingStructure.numLevels + 1;
      console.log("Adding a new Level");

      let mapGeoJson = parkingLot.map;
      let parkingStructureFeature = mapGeoJson.features.find(
        (f) =>
          f.properties != null &&
          f.properties.is_multi_level_structure &&
          f.properties.num_levels != null &&
          f.properties.id ==
            this.multiLevelParkingStructure.currentMultiStructureZoneId
      );

      if (!parkingStructureFeature || !parkingStructureFeature.properties) {
        this.$dialog.message.error(
          "Unable to add new level in the this parking structure was not found in mapGeojson.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        return;
      }

      parkingStructureFeature.properties.num_levels = updatedNumLevels;
      this.geoJson = mapGeoJson;
      this.saveParkingLot();
      this.isLoading = false;
    },

    showConfirmDelete(
      event: google.maps.PolyMouseEvent,
      line: google.maps.Polyline,
      category: string
    ) {
      (this.$refs.propertiesPopup as VComponent)
        .showConfirmDelete(category)
        .then((result: boolean) => {
          if (result) {
            this.onRightClickLine(event, line, category);
          }
          this.popup.show = false;
        });
    },
    onRightClickLine(
      event: google.maps.PolyMouseEvent,
      line: google.maps.Polyline,
      category: string
    ) {
      console.log("Right clicked on category", category);
      this.toolChanged("select");
      this.unSelectCurrent();
      console.log("Right clicked on line", line);
      switch (category) {
        case "zone": {
          let zone = line as Zone;
          // Add/remove zone entrypoint if right click on vertex, else remove the zone
          if (event.vertex != undefined && event.vertex >= 0) {
            if (zone.entrypointsIndexes.includes(event.vertex)) {
              const idx = zone.entrypointsIndexes.indexOf(event.vertex);
              zone.entrypointsIndexes.splice(idx, 1);
              zone.entrypointsMarkers[idx].setMap(null);
              zone.entrypointsMarkers.splice(idx, 1);
            } else if (event.latLng) {
              zone.entrypointsIndexes.push(event.vertex);
              this.drawZoneEntrypoint(zone, event.latLng);
            }
          } else {
            zone.setMap(null);
            const annIdx = this.annotations.zones.indexOf(line as Zone);
            if (annIdx >= 0) {
              console.log("Removing zone", annIdx);
              this.annotations.zones.splice(annIdx, 1);
            }
          }
          break;
        }
        case "special_area": {
          let specialArea = line as SpecialArea;
          specialArea.setMap(null);
          const annIdx = this.annotations.lanes.indexOf(specialArea);
          if (annIdx >= 0) {
            console.log("Removing specialArea", annIdx);
            const line = this.annotations.specialAreas.splice(annIdx, 1);
            this.undoList.push({
              name: "special_area",
              action: "undo_special_area_delete",
              args: { line: line[0] },
            });
          } else {
            this.undoList.push({
              name: "special_area",
              action: "undo_special_area_delete",
              args: { line: specialArea },
            });
          }
          break;
        }
        case "lot": {
          if (this.annotations.lot)
            this.undoList.push({
              name: "lot",
              action: "undo_lot_delete",
              args: { line: this.annotations.lot },
            });
          this.annotations.lot = null;
          line.setMap(null);
          break;
        }
        case "lane": {
          let lane = line as Lane;
          lane.setMap(null);
          const annIdx = this.annotations.lanes.indexOf(lane);
          if (annIdx >= 0) {
            console.log("Removing lane", annIdx);
            const line = this.annotations.lanes.splice(annIdx, 1);
            this.undoList.push({
              name: "lane",
              action: "undo_lane_delete",
              args: { line: line[0] },
            });
          } else {
            this.undoList.push({
              name: "lane",
              action: "undo_lane_delete",
              args: { line: lane },
            });
          }
          break;
        }
        case "driveway": {
          line.setMap(null);
          let lineArr = line.getPath().getArray();
          this.undoList.push({
            name: "driveway",
            action: "undo_driveway_delete",
            args: { line },
          });
          this.annotations.driveways = this.annotations.driveways.filter(
            (driveway) => {
              let locArr = driveway.getPath().getArray();
              if (lineArr.length === locArr.length) {
                for (let i = 0; i < lineArr.length; i++) {
                  if (
                    !(
                      lineArr[i].lat() === locArr[i].lat() &&
                      lineArr[i].lng() === locArr[i].lng()
                    )
                  ) {
                    return true;
                  }
                }
                return false;
              }
              return true;
            }
          );
          break;
        }
        default: {
          line.setMap(null);
        }
      }
    },
    onPointDragStart(event: google.maps.MapMouseEvent) {
      let { domEvent, latLng } = event;
      if (latLng) {
        let latLngJson = latLng.toJSON();
        console.log("Drag start event", domEvent, latLngJson);
      }
    },
    onPointDragEnd(event: google.maps.MapMouseEvent) {
      let { domEvent, latLng } = event;
      if (latLng) {
        let latLngJson = latLng.toJSON();
        console.log("Drag end event", domEvent, latLngJson);
      }
    },
    toolChanged(
      tool: string | null,
      id = null as number | null,
      parkingSpotData = null as ParkingSpot | null,
      cameraData = null as CameraData | null,
      parkingZoneData = null as ParkingZone | null,
      displayBoardData = null as DigitalBoard | null
    ) {
      // Color for spot/zone highlighting on clicking camera
      let fillOpacity = 0.5;
      let strokeOpacity = 0.5;
      if (
        this.highlightCameraParkingSpotsWithId &&
        ((parkingSpotData &&
          this.highlightCameraParkingSpotsWithId !==
            parkingSpotData?.camera_id) ||
          (parkingZoneData &&
            !parkingZoneData?.untracked_zone_camera_ids?.includes(
              this.highlightCameraParkingSpotsWithId
            ) &&
            !parkingZoneData?.adjacent_zone_camera_ids?.includes(
              this.highlightCameraParkingSpotsWithId
            )))
      ) {
        // fillColor = "gray";
        fillOpacity = 0.14;
        strokeOpacity = 0.14;
      }

      console.log("Tool changed to", tool);
      switch (tool) {
        case "driveway":
        case "zone":
        case "parking_structure":
        case "lot": {
          // Start a new line
          let lineColor = COLORS[tool];
          if (
            this.multiLevelParkingStructure.isLevelDigimapView &&
            parkingZoneData?.id != null &&
            parkingZoneData?.id ===
              this.multiLevelParkingStructure.currentMultiStructureZoneId
          ) {
            lineColor = COLORS["parking_structure"];
          }
          let line = new google.maps.Polyline({
            path: [],
            editable: this.isEditMode,
            draggable: this.isEditMode,
            strokeColor: lineColor,
            strokeOpacity: strokeOpacity,
            strokeWeight: STROKE_WEIGHTS[tool],
            map: this.map,
          }) as Zone;
          console.log(
            "ZoneDATA",
            parkingZoneData?.id,
            strokeOpacity,
            parkingZoneData,
            parkingZoneData?.untracked_zone_camera_ids,
            this.highlightCameraParkingSpotsWithId
          );
          this.current.line = line;
          if (id) {
            this.current.line.id = id;
          }
          line.category = tool;

          if (tool === "zone" || tool == "parking_structure") {
            (this.current.line as Zone).entrypointsIndexes = [];
            (this.current.line as Zone).entrypointsMarkers = [];
            let parkingZoneData = this.parkingLot?.parking_zones?.find(
              (zone: ParkingZone) => zone.id === id
            );
            if (parkingZoneData?.gps_coordinates) {
              // generate label that will be shown on Zone centre
              let zoneLabel = "";
              if (this.showZoneNames) {
                zoneLabel += `${
                  parkingZoneData.name
                    ? parkingZoneData.name
                    : `Zone-ID ${parkingZoneData.id}`
                }`;
              }
              if (
                parkingZoneData.is_untracked &&
                (parkingZoneData.num_free_untracked_spots != null ||
                  parkingZoneData.num_free_parking_spots != null) &&
                parkingZoneData.num_total_untracked_spots != null
              ) {
                if (zoneLabel != "") {
                  zoneLabel += ", ";
                }

                // Check if the vehicle counter camera is offline or edge device is
                // offline and show the vehicle count as unknown.
                let unknownCameraIds = [];
                if (
                  parkingZoneData.untracked_zone_camera_ids &&
                  // parkingZoneData.zone_type == "line_counter_zone"
                  parkingZoneData.is_untracked
                ) {
                  for (let cam of this.cameras) {
                    if (
                      (parkingZoneData.untracked_zone_camera_ids?.includes(
                        cam.id
                      ) ||
                        parkingZoneData.adjacent_zone_camera_ids?.includes(
                          cam.id
                        )) &&
                      (cam.is_stream_unreadable ||
                        cam.is_lpr_unreadable ||
                        !cam.is_active ||
                        cam.edge_device?.is_device_offline ||
                        cam.is_starting_up)
                    ) {
                      unknownCameraIds.push(cam.id);
                    }
                  }
                }
                if (unknownCameraIds.length == 0) {
                  if (parkingZoneData.zone_type == "time_limited_zone") {
                    zoneLabel += `Count: ${parkingZoneData.num_vehicles_detected}`;
                  } else {
                    zoneLabel += `Available: ${parkingZoneData.num_free_parking_spots}/${parkingZoneData.num_total_untracked_spots}`;
                  }
                } else {
                  let unknownCameraIdsStr = unknownCameraIds.join(",");
                  zoneLabel += `Count Unknown, Camera ID ${unknownCameraIdsStr} is Offline`;
                }
              }
              if (
                (this.showZoneNames || this.showSpotCameraIds) &&
                parkingZoneData.untracked_zone_camera_ids
              ) {
                zoneLabel += ", ";
              }
              if (this.showSpotCameraIds) {
                if (parkingZoneData.untracked_zone_camera_ids) {
                  zoneLabel += "Camera ID";
                  if (parkingZoneData.untracked_zone_camera_ids.length > 1) {
                    zoneLabel += "'s: ";
                  } else {
                    zoneLabel += ": ";
                  }
                  zoneLabel +=
                    parkingZoneData.untracked_zone_camera_ids.join(",");
                }
              }
              if (
                (this.showZoneNames || this.showSpotCameraIds) &&
                parkingZoneData.adjacent_zone_camera_ids
              ) {
                zoneLabel += ", ";
              }
              if (this.showSpotCameraIds) {
                if (parkingZoneData.adjacent_zone_camera_ids) {
                  zoneLabel += "Adjacent Camera ID";
                  if (parkingZoneData.adjacent_zone_camera_ids.length > 1) {
                    zoneLabel += "'s: ";
                  } else {
                    zoneLabel += ": ";
                  }
                  zoneLabel +=
                    parkingZoneData.adjacent_zone_camera_ids.join(",");
                }
              }
              if (zoneLabel != "") {
                let marker = new google.maps.Marker({
                  clickable: this.interactive,
                  draggable: false,
                  visible: this.showFixedSizeMarkers,
                  label: {
                    text: zoneLabel,
                    className: "highlight",
                    fontWeight: "bold",
                    color: "white",
                  },
                  icon: {
                    url: `${parkingZoneData.id}`,
                    anchor: new google.maps.Point(
                      FIXED_MARKER_OPTIONS.iconAnchor.x,
                      FIXED_MARKER_OPTIONS.iconAnchor.y
                    ),
                  },
                  map: this.map,
                  zIndex: 100,
                });
                this.annotations.fixedSizeMarkers.push(marker);
                const zone_gps_coords = String(
                  parkingZoneData?.gps_coordinates
                );
                const zone_coords = JSON.parse(zone_gps_coords).coordinates;
                //console.log("ZONECCCC", parkingZoneData.gps_coordinates, zone_gps_coords, zone_coords)
                marker.setPosition(
                  new google.maps.LatLng(zone_coords[1], zone_coords[0])
                );
                marker.addListener(
                  "click",
                  (event: google.maps.MapMouseEvent) => {
                    // show Zone Slider
                    if (parkingZoneData?.zone_type != "time_limited_zone")
                      return;
                    if (
                      !this.isEditMode &&
                      parkingZoneData?.zone_type == "time_limited_zone"
                    ) {
                      this.zoneSlider.show = true;
                      this.zoneSlider.zoneId = parkingZoneData
                        ? parkingZoneData.id
                        : id;
                      this.zoneSlider.cameraId =
                        parkingZoneData.untracked_zone_camera_ids
                          ? parkingZoneData.untracked_zone_camera_ids[0]
                          : null;
                    }
                  }
                );
                marker.addListener(
                  this.is_iOS ? "dblclick" : "contextmenu",
                  (event: google.maps.MapMouseEvent) => {
                    console.log("Right clicked on zone marker");

                    // do nothing if Timeline enabled
                    if (this.viewPastSpotHistory) {
                      return;
                    }
                    this.displayPropertiesPopup({
                      category: tool,
                      id: line.id,
                      data: parkingZoneData,
                      annotationObj: line,
                    });
                  }
                );
              }
            }
            if (this.interactive && id) {
              line.addListener("click", (event: google.maps.PolyMouseEvent) => {
                // show Zone Slider
                if (
                  !this.isEditMode &&
                  parkingZoneData?.zone_type == "time_limited_zone"
                ) {
                  this.zoneSlider.show = true;
                  this.zoneSlider.zoneId = parkingZoneData
                    ? parkingZoneData.id
                    : id;
                  this.zoneSlider.cameraId =
                    parkingZoneData.untracked_zone_camera_ids
                      ? parkingZoneData.untracked_zone_camera_ids[0]
                      : null;
                }
              });
              line.addListener(
                this.is_iOS ? "dblclick" : "contextmenu",
                (event: google.maps.PolyMouseEvent) => {
                  // do nothing if Timeline enabled
                  if (this.viewPastSpotHistory) {
                    return;
                  }
                  // this.showConfirmDelete(event, line, tool)
                  this.displayPropertiesPopup({
                    category: tool,
                    id: line.id,
                    data: parkingZoneData,
                    annotationObj: line,
                  });
                }
              );
              line.addListener("click", (event: google.maps.PolyMouseEvent) =>
                this.onSelect(event, null, line)
              );
            }
          } else {
            if (this.isEditMode) {
              line.addListener(
                this.is_iOS ? "dblclick" : "contextmenu",
                (event: google.maps.PolyMouseEvent) => {
                  this.showConfirmDelete(event, line, tool);
                }
              );
            }
          }
          if (this.interactive) {
            line.addListener("click", this.onPointClick);
            line.addListener("dragstart", this.onPointDragStart);
            line.addListener("dragstart", this.onPointDragEnd);
          }
          break;
        }
        case "special_area": {
          let line = new google.maps.Polyline({
            path: [],
            editable: this.isEditMode,
            draggable: this.isEditMode,
            strokeColor: COLORS[tool],
            strokeOpacity: strokeOpacity,
            strokeWeight: STROKE_WEIGHTS[tool],
            map: this.map,
          }) as SpecialArea;
          this.current.line = line;
          if (id) {
            this.current.line.id = id;
          }
          line.category = "special_area";

          let specialAreaData = this.parkingLot?.special_areas?.find(
            (specialArea) => specialArea.id === id
          );
          if (specialAreaData) {
            if (specialAreaData.is_illegal_parking_area) {
              let no_parking_marker = new google.maps.Marker({
                clickable: false,
                draggable: false,
                visible: this.showFixedSizeMarkers,
                icon: {
                  path: mdiParking,
                  fillColor: "black",
                  strokeColor: "black",
                  fillOpacity: 1.0,
                  scale: 0.8,
                  anchor: new google.maps.Point(
                    FIXED_MARKER_OPTIONS.iconAnchor.x,
                    FIXED_MARKER_OPTIONS.iconAnchor.y
                  ),
                },
                title: specialAreaData.name
                  ? specialAreaData.name
                  : `Special Area-${specialAreaData.id}`,
                map: this.map,
                zIndex: 101,
              });
              this.annotations.fixedSizeMarkers.push(no_parking_marker);
              const special_area_gps_coords = String(
                specialAreaData?.gps_coordinates
              );
              const special_area_coords = JSON.parse(
                special_area_gps_coords
              ).coordinates;
              no_parking_marker.setPosition(
                new google.maps.LatLng(
                  special_area_coords[1],
                  special_area_coords[0]
                )
              );
              let no_parking_marker_block = new google.maps.Marker({
                clickable: this.interactive,
                draggable: false,
                visible: this.showFixedSizeMarkers,
                icon: {
                  path: mdiCancel,
                  fillColor: "red",
                  strokeColor: "red",
                  fillOpacity: 1.0,
                  scale: 1.2,
                  anchor: new google.maps.Point(
                    FIXED_MARKER_OPTIONS.iconAnchor.x,
                    FIXED_MARKER_OPTIONS.iconAnchor.y
                  ),
                },
                title: specialAreaData.name
                  ? specialAreaData.name
                  : `Special Area-${specialAreaData.id}`,
                map: this.map,
                zIndex: 102,
              });
              this.annotations.fixedSizeMarkers.push(no_parking_marker_block);
              no_parking_marker_block.setPosition(
                new google.maps.LatLng(
                  special_area_coords[1],
                  special_area_coords[0]
                )
              );
              if (this.interactive) {
                no_parking_marker_block.addListener(
                  this.is_iOS ? "dblclick" : "contextmenu",
                  (event: google.maps.MapMouseEvent) => {
                    // do nothing if Timeline enabled
                    if (this.viewPastSpotHistory) {
                      return;
                    }
                    this.displayPropertiesPopup({
                      category: "special_area",
                      id: line.id,
                      data: specialAreaData,
                      annotationObj: line,
                    });
                  }
                );
              }
            } else if (this.showZoneNames) {
              let marker = new google.maps.Marker({
                clickable: this.interactive,
                draggable: false,
                visible: this.showFixedSizeMarkers,
                label: {
                  text: `${
                    specialAreaData.name
                      ? specialAreaData.name
                      : `Special Area-${specialAreaData.id}`
                  }`,
                  className: "highlight",
                  fontWeight: "bold",
                  color: "white",
                },
                icon: {
                  url: `special-area-${specialAreaData.id}`,
                  anchor: new google.maps.Point(
                    FIXED_MARKER_OPTIONS.iconAnchor.x,
                    FIXED_MARKER_OPTIONS.iconAnchor.y
                  ),
                },
                map: this.map,
                zIndex: 100,
              });
              this.annotations.fixedSizeMarkers.push(marker);
              const special_area_gps_coords = String(
                specialAreaData?.gps_coordinates
              );
              const special_area_coords = JSON.parse(
                special_area_gps_coords
              ).coordinates;
              marker.setPosition(
                new google.maps.LatLng(
                  special_area_coords[1],
                  special_area_coords[0]
                )
              );
              marker.addListener(
                this.is_iOS ? "dblclick" : "contextmenu",
                (event: google.maps.MapMouseEvent) => {
                  // do nothing if Timeline enabled
                  if (this.viewPastSpotHistory) {
                    return;
                  }
                  this.displayPropertiesPopup({
                    category: "special_area",
                    id: line.id,
                    data: specialAreaData,
                    annotationObj: line,
                  });
                }
              );
            }
          }
          if (this.interactive) {
            line.addListener(
              this.is_iOS ? "dblclick" : "contextmenu",
              (event: google.maps.PolyMouseEvent) => {
                // do nothing if Timeline enabled
                if (this.viewPastSpotHistory) {
                  return;
                }
                // this.showConfirmDelete(event, line, tool)
                this.displayPropertiesPopup({
                  category: "special_area",
                  id: line.id,
                  data: specialAreaData,
                  annotationObj: line,
                });
              }
            );
            if (id) {
              line.addListener("click", (event: google.maps.PolyMouseEvent) =>
                this.onSelect(event, null, line)
              );
            }
            line.addListener("click", this.onPointClick);
            line.addListener("dragstart", this.onPointDragStart);
            line.addListener("dragstart", this.onPointDragEnd);
          }
          break;
        }
        case "lane": {
          let line = new google.maps.Polyline({
            path: [],
            editable: this.isEditMode,
            draggable: this.isEditMode,
            strokeColor: COLORS[tool],
            strokeOpacity: 0.5,
            strokeWeight: STROKE_WEIGHTS[tool],
            map: this.map,
          }) as Lane;
          this.current.line = line;
          if (id) {
            this.current.line.id = id;
          }
          line.category = "lane";

          let parkingLaneData = this.parkingLot?.parking_lanes?.find(
            (lane: ParkingLane) => lane.id === id
          );
          if (this.interactive) {
            line.addListener(
              this.is_iOS ? "dblclick" : "contextmenu",
              (event: google.maps.PolyMouseEvent) => {
                // do nothing if Timeline enabled
                if (this.viewPastSpotHistory) {
                  return;
                }
                // this.showConfirmDelete(event, line, tool)
                this.displayPropertiesPopup({
                  category: "lane",
                  id: line.id,
                  data: parkingLaneData,
                  annotationObj: line,
                });
              }
            );
            line.addListener("click", this.onPointClick);
            line.addListener("dragstart", this.onPointDragStart);
            line.addListener("dragstart", this.onPointDragEnd);
          }
          break;
        }
        case "spot": {
          let fillColor = SPOT_COLORS[ParkingStatus.free];
          if (parkingSpotData != null) {
            if (
              parkingSpotData.current_status !== ParkingStatus.reserved &&
              parkingSpotData.is_status_untrackable
            ) {
              if (
                this.parkingLot &&
                this.parkingLot.is_unknown_perception_feature_enabled
              ) {
                fillColor = SPOT_COLORS["untrackable"];
              } else {
                fillColor = SPOT_COLORS["unknown"];
              }
            } else if (
              parkingSpotData.current_status !== ParkingStatus.reserved &&
              parkingSpotData.is_status_unknown
            ) {
              if (parkingSpotData?.is_status_marked_unknown) {
                fillColor = SPOT_COLORS["untrackable"];
              } else {
                if (
                  this.parkingLot &&
                  this.parkingLot.is_unknown_perception_feature_enabled
                ) {
                  if (this.isSuperAdmin) {
                    if (
                      parkingSpotData.is_status_unknown_flip_flop ||
                      parkingSpotData.is_status_unknown_parallel_parking
                    ) {
                      fillColor = SPOT_COLORS["user_marked_unknown"];
                    }
                  } else {
                    if (parkingSpotData.is_status_unknown_flip_flop) {
                      fillColor = SPOT_COLORS[parkingSpotData.current_status];
                    } else if (
                      parkingSpotData.is_status_unknown_parallel_parking
                    ) {
                      fillColor = SPOT_COLORS[ParkingStatus.free];
                    } else {
                      fillColor = SPOT_COLORS["unknown"];
                    }
                  }
                } else {
                  fillColor = SPOT_COLORS["unknown"];
                }
              }
            } else {
              if (
                parkingSpotData.current_status === ParkingStatus.unavailable
              ) {
                fillColor = SPOT_COLORS[ParkingStatus.unavailable];
              } else if (
                parkingSpotData.current_status === ParkingStatus.reserved
              ) {
                fillColor = SPOT_COLORS[ParkingStatus.reserved];
              }
            }
            console.log("Using color", fillColor);
          }

          let fillOpacity = 0.5;
          let strokeOpacity = 0.5;
          if (
            this.highlightCameraParkingSpotsWithId &&
            this.highlightCameraParkingSpotsWithId !==
              parkingSpotData?.camera_id
          ) {
            // fillColor = "gray";
            fillOpacity = 0.14;
            strokeOpacity = 0.14;
          }

          let poly = new google.maps.Polygon({
            editable: this.isEditMode,
            draggable: this.isEditMode,
            fillColor: fillColor,
            fillOpacity: fillOpacity,
            strokeOpacity: strokeOpacity,
            map: this.map,
          }) as Spot;
          if (id) {
            poly.id = id;
          }
          if (parkingSpotData?.name) {
            poly.name = parkingSpotData.name;
          }
          if (parkingSpotData) {
            poly.annoData = parkingSpotData;
          }
          poly.category = "spot";
          if (this.interactive) {
            poly.addListener("click", (event: google.maps.PolyMouseEvent) => {
              this.onSelect(event, poly, null);
              // Also stop camera fov setting and reset selectedCameraFov
              this.stopCameraFovSetting();

              // show Spot Slider
              if (!this.isEditMode) {
                this.spotSlider.show = true;
                this.spotSlider.spotId = parkingSpotData
                  ? parkingSpotData.id
                  : id;
                this.spotSlider.cameraId = parkingSpotData?.camera_id || null;
              }
            });
            poly.addListener(
              this.is_iOS ? "dblclick" : "contextmenu",
              (event: google.maps.PolyMouseEvent) => {
                // do nothing if Timeline enabled
                if (this.viewPastSpotHistory) {
                  return;
                }
                this.displayPropertiesPopup({
                  category: "spot",
                  id: poly.id,
                  data: parkingSpotData,
                  annotationObj: poly,
                });
              }
            );
            poly.addListener(
              "mousemove",
              (event: google.maps.MapMouseEvent) => {
                if (this.selectedCameraFov.edit && event.latLng) {
                  this.editCameraFov(event.latLng);
                }
              }
            );
            let spotIconCount: google.maps.Marker[] = [];
            if (parkingSpotData != null) {
              poly.addListener("dragstart", () => {
                spotIconCount = [];
                this.annotations.fixedSizeMarkers.forEach((marker) => {
                  if (
                    marker.get("id") &&
                    marker.get("id").includes(parkingSpotData?.id)
                  ) {
                    spotIconCount.push(marker);
                    marker.setVisible(false);
                  }
                });
              });

              poly.addListener("dragend", () => {
                const polygonPath = poly.getPath();
                const centerCoordinates: number[][] = [];

                polygonPath.forEach((latLng: google.maps.LatLng) => {
                  centerCoordinates.push([latLng.lng(), latLng.lat()]);
                });
                spotIconCount.forEach((marker, i) => {
                  // Calculate marker positions
                  let markerPositions = getMarkerPositionsOnRect(
                    centerCoordinates,
                    spotIconCount.length,
                    false
                  );
                  console.log(`markerPositions ${markerPositions}`);
                  const markerPosition = markerPositions[i];
                  let latlng = new google.maps.LatLng(
                    markerPosition[1],
                    markerPosition[0]
                  );
                  marker.setPosition(latlng);
                  marker.setVisible(true);
                });
              });
            }
          }

          // Draw Spot Poperties Markers
          if (parkingSpotData) {
            let spotPolygonGeoJSON =
              typeof parkingSpotData.polygon === "string"
                ? JSON.parse(parkingSpotData.polygon as any)
                : parkingSpotData.polygon;
            let spotLatLngs = spotPolygonGeoJSON.coordinates[0];
            let spotLPMarkers = [];
            let currentSpotMarkers = [];
            let blockedSpotMarkers = [];

            if (!parkingSpotData?.camera_id && this.interactive) {
              let marker = new google.maps.Marker({
                clickable: this.interactive,
                draggable: false,
                visible: this.showFixedSizeMarkers,
                icon: {
                  path: mdiCctvOff,
                  fillColor: "grey",
                  strokeColor: "grey",
                  fillOpacity: 1.0,
                  anchor: new google.maps.Point(
                    FIXED_MARKER_OPTIONS.iconAnchor.x,
                    FIXED_MARKER_OPTIONS.iconAnchor.y
                  ),
                },
                title: "No Camera Assigned",
                map: this.map,
              });
              marker.set("id", `Spot ${parkingSpotData?.id} marker`);
              this.annotations.fixedSizeMarkers.push(marker);
              currentSpotMarkers.push(marker);
              console.log("Adding Marker for Unassigned Camera");
            }

            if (parkingSpotData?.comment && this.interactive) {
              let marker = new google.maps.Marker({
                clickable: false,
                draggable: false,
                visible: this.showFixedSizeMarkers,
                icon: {
                  path: mdiCommentAlertOutline,
                  fillColor: "grey",
                  strokeColor: "grey",
                  fillOpacity: 1.0,
                  anchor: new google.maps.Point(
                    FIXED_MARKER_OPTIONS.iconAnchor.x,
                    FIXED_MARKER_OPTIONS.iconAnchor.y
                  ),
                },
                title: parkingSpotData?.comment,
                map: this.map,
              });
              marker.set("id", `Spot ${parkingSpotData?.id} marker`);
              this.annotations.fixedSizeMarkers.push(marker);
              currentSpotMarkers.push(marker);
              console.log("Adding Marker for Parking Spot comment");
            }
            // Draw Spot Poperties Markers only on Lot Dashboard
            if (!this.isEditMode) {
              if (
                parkingSpotData?.max_park_violation_alert_time_seconds &&
                this.interactive
              ) {
                let maxParkingTimeTitle = "Max Parking Time ";
                maxParkingTimeTitle += this.formatTime(
                  parkingSpotData.max_park_violation_alert_time_seconds
                );

                let marker = new google.maps.Marker({
                  clickable: this.interactive,
                  draggable: false,
                  visible: this.showFixedSizeMarkers,
                  icon: {
                    path: mdiTimerOutline,
                    fillColor: "grey",
                    strokeColor: "grey",
                    fillOpacity: 1.0,
                    anchor: new google.maps.Point(
                      FIXED_MARKER_OPTIONS.iconAnchor.x,
                      FIXED_MARKER_OPTIONS.iconAnchor.y
                    ),
                  },
                  title: maxParkingTimeTitle,
                  map: this.map,
                });
                this.annotations.fixedSizeMarkers.push(marker);
                currentSpotMarkers.push(marker);
                console.log("Adding Marker for Max park");
              }

              // Unknown spot status reasons
              let statusUnknownCauses = [];
              if (
                this.parkingLot &&
                !this.parkingLot.is_unknown_perception_feature_enabled
              ) {
                if (parkingSpotData?.camera_id == null) {
                  statusUnknownCauses.push(
                    "Camera not assigned, spot status unknown"
                  );
                } else if (parkingSpotData?.is_status_unknown_camera_inactive) {
                  statusUnknownCauses.push(
                    "Camera is switched off, spot status unknown"
                  );
                } else if (parkingSpotData?.is_status_unknown_camera_offline) {
                  statusUnknownCauses.push(
                    "SpotGenius unable to determine status: Camera is offline. Please check power/network status or contact your network administrator"
                  );
                } else if (
                  parkingSpotData?.is_status_unknown_edge_device_offline
                ) {
                  statusUnknownCauses.push(
                    "SpotGenius unable to determine status: Edge device is offline. Please check power/network status or contact your network administrator"
                  );
                }
                if (parkingSpotData?.is_status_marked_unknown) {
                  statusUnknownCauses.push("Status marked unknown by admin");
                }
              }
              if (
                this.parkingLot &&
                this.parkingLot.is_unknown_perception_feature_enabled &&
                !parkingSpotData?.is_status_marked_unknown
              ) {
                if (parkingSpotData?.is_status_unknown_flip_flop) {
                  statusUnknownCauses.push(
                    "SpotGenius unable to determine status: Spot status is rapidly changing and may be inaccurate. Please check for any obstructions to the camera's view of that spot. Alternatively, contact SpotGenius support for more information"
                  );
                }
                if (parkingSpotData?.is_status_unknown_parallel_parking) {
                  statusUnknownCauses.push(
                    "Spot status is unknown due to parallel parking"
                  );
                }
              }
              if (statusUnknownCauses.length > 0 && this.interactive) {
                let marker = new google.maps.Marker({
                  clickable: this.interactive,
                  draggable: false,
                  visible: this.showFixedSizeMarkers,
                  icon: {
                    path: mdiHelp,
                    fillColor: "grey",
                    strokeColor: "grey",
                    fillOpacity: 1.0,
                    anchor: new google.maps.Point(
                      FIXED_MARKER_OPTIONS.iconAnchor.x,
                      FIXED_MARKER_OPTIONS.iconAnchor.y
                    ),
                  },
                  title: statusUnknownCauses.join("\n"),
                  map: this.map,
                });
                this.annotations.fixedSizeMarkers.push(marker);
                currentSpotMarkers.push(marker);
                console.log("Adding Marker for Unknown");
              }

              // Draw saved spot and saved location end user details marker
              if (this.interactive) {
                let saved_spot = this.savedSpots.find(
                  (s) => s.spot_id == parkingSpotData.id
                );
                if (saved_spot) {
                  let marker = new google.maps.Marker({
                    clickable: false,
                    draggable: false,
                    visible: this.showFixedSizeMarkers,
                    icon: {
                      path: mdiMapMarkerCheck,
                      fillColor: "grey",
                      strokeColor: "grey",
                      fillOpacity: 1.0,
                      anchor: new google.maps.Point(
                        FIXED_MARKER_OPTIONS.iconAnchor.x,
                        FIXED_MARKER_OPTIONS.iconAnchor.y
                      ),
                    },
                    title: `Saved by: ${
                      saved_spot.name ? `\nName: ${saved_spot.name}` : ""
                    } ${
                      saved_spot.email ? `\nEmail: ${saved_spot.email}` : ""
                    }${
                      saved_spot.contact_number
                        ? `\nContact: ${saved_spot.contact_number}`
                        : ""
                    }${
                      saved_spot.tenant
                        ? `\nOrganization: ${saved_spot.tenant}`
                        : ""
                    }${
                      saved_spot.parked_vehicle
                        ? `\nVehicle: ${saved_spot.parked_vehicle.vehicle_model_name} ${saved_spot.parked_vehicle.license_plate_number}`
                        : ""
                    }`,
                    map: this.map,
                  });
                  this.annotations.fixedSizeMarkers.push(marker);
                  currentSpotMarkers.push(marker);
                  console.log("Adding Marker for Saved spot end user details");
                }

                let saved_location = this.savedLocations.find((s) =>
                  s.blocked_spots.includes(parkingSpotData.id)
                );
                if (saved_location) {
                  let marker = new google.maps.Marker({
                    clickable: this.interactive,
                    draggable: false,
                    visible: this.showFixedSizeMarkers,
                    icon: {
                      path: mdiAlertBox,
                      fillColor: "#FF0000",
                      strokeColor: "#000000",
                      fillOpacity: 1.0,
                      scale: 0.8,
                      anchor: new google.maps.Point(
                        FIXED_MARKER_OPTIONS.iconAnchor.x,
                        FIXED_MARKER_OPTIONS.iconAnchor.y
                      ),
                    },
                    title: "Spot blocked by another Car",
                    map: this.map,
                  });
                  this.annotations.fixedSizeMarkers.push(marker);
                  blockedSpotMarkers.push(marker);
                }
              }
            }

            // Draw Spot Poperties Markers on both Lot dashboard and Edit page
            if (
              (this.showSpotIds || this.showSpotNames) &&
              parkingSpotData?.id
            ) {
              let labelText =
                this.showSpotNames && parkingSpotData.name
                  ? parkingSpotData.name
                  : String(parkingSpotData.id);
              let marker = new google.maps.Marker({
                clickable: false,
                draggable: false,
                visible: this.showFixedSizeMarkers,
                label: {
                  text: labelText,
                  fontFamily: "monospace",
                  fontWeight: "bold",
                },
                icon: {
                  url: `${parkingSpotData.id}`,
                  anchor: new google.maps.Point(
                    FIXED_MARKER_OPTIONS.iconAnchor.x,
                    FIXED_MARKER_OPTIONS.iconAnchor.y
                  ),
                },
                map: this.map,
              });
              this.annotations.fixedSizeMarkers.push(marker);
              currentSpotMarkers.push(marker);
            }

            if (this.showSpotCameraIds && parkingSpotData?.camera_id) {
              let marker = new google.maps.Marker({
                clickable: false,
                draggable: false,
                visible: this.showFixedSizeMarkers,
                label: `${parkingSpotData.camera_id}`,
                icon: {
                  url: `${parkingSpotData.camera_id}`,
                  anchor: new google.maps.Point(
                    FIXED_MARKER_OPTIONS.iconAnchor.x,
                    FIXED_MARKER_OPTIONS.iconAnchor.y
                  ),
                },
                map: this.map,
              });
              this.annotations.fixedSizeMarkers.push(marker);
              currentSpotMarkers.push(marker);
            }

            if (
              this.parkingLot?.is_parking_permit_feature_enabled &&
              parkingSpotData?.requires_parking_permit_ids &&
              !this.authToken
            ) {
              let parkingPermitsHashmap =
                this.parkingPermits.items?.reduce(
                  (
                    hashMap: Record<number, ParkingPermit>,
                    permitObj: ParkingPermit
                  ) => {
                    hashMap[permitObj.id] = permitObj;
                    return hashMap;
                  },
                  {}
                ) || {};
              for (let permitId of parkingSpotData.requires_parking_permit_ids) {
                let permitObj = parkingPermitsHashmap[permitId];
                let permitIcon = null;
                if (permitObj.icon_symbol.startsWith("mdi")) {
                  permitIcon = {
                    path: PARKING_PERMIT_PATHS_HASHMAP[permitObj.icon_symbol],
                    fillColor: "#512DA8", // Use different color for parking permit icons
                    strokeColor: "#512DA8",
                    fillOpacity: 1.0,
                    anchor: new google.maps.Point(
                      FIXED_MARKER_OPTIONS.iconAnchor.x,
                      FIXED_MARKER_OPTIONS.iconAnchor.y
                    ),
                  };
                } else {
                  permitIcon = {
                    url: this.getCustomSvgPath(permitObj.icon_symbol) as any,
                    fillColor: "#512DA8", // Use different color for parking permit icons
                    strokeColor: "#512DA8",
                    fillOpacity: 1.0,
                    anchor: new google.maps.Point(
                      FIXED_MARKER_OPTIONS.iconAnchor.x,
                      FIXED_MARKER_OPTIONS.iconAnchor.y
                    ),
                  };
                }

                let marker = new google.maps.Marker({
                  clickable: this.interactive,
                  draggable: false,
                  visible: this.showFixedSizeMarkers,
                  icon: permitIcon,
                  title: permitObj.name + " Permit",
                  map: this.map,
                });
                this.annotations.fixedSizeMarkers.push(marker);
                currentSpotMarkers.unshift(marker); // Put parking permit markers first at the top
                console.log(
                  "Adding Marker for Permit type",
                  permitId,
                  permitObj.icon_symbol
                );
              }
            }
            if (
              parkingSpotData?.current_status === ParkingStatus.reserved &&
              this.interactive
            ) {
              let marker = new google.maps.Marker({
                clickable: this.interactive,
                draggable: false,
                visible: this.showFixedSizeMarkers,
                icon: {
                  path: mdiBlockHelper,
                  fillColor: "grey",
                  strokeColor: "grey",
                  fillOpacity: 1.0,
                  anchor: new google.maps.Point(
                    FIXED_MARKER_OPTIONS.iconAnchor.x,
                    FIXED_MARKER_OPTIONS.iconAnchor.y
                  ),
                },
                title: "Blocked Parking Spot",
                map: this.map,
              });
              this.annotations.fixedSizeMarkers.push(marker);
              currentSpotMarkers.push(marker);
              console.log("Adding Marker for Reserved");
            }

            if (parkingSpotData?.is_illegal_parking_spot && this.interactive) {
              let marker = new google.maps.Marker({
                clickable: false,
                draggable: false,
                visible: this.showFixedSizeMarkers,
                icon: {
                  path: mdiCarOff,
                  fillColor: "grey",
                  strokeColor: "grey",
                  fillOpacity: 1.0,
                  anchor: new google.maps.Point(
                    FIXED_MARKER_OPTIONS.iconAnchor.x,
                    FIXED_MARKER_OPTIONS.iconAnchor.y
                  ),
                },
                title:
                  "Illegal Parking Spot (Parking is not allowed on this spot)",
                map: this.map,
              });
              this.annotations.fixedSizeMarkers.push(marker);
              currentSpotMarkers.push(marker);
              console.log("Adding Marker for Parking Spot comment");
            }

            if (
              this.showAnprFields &&
              parkingSpotData?.vehicle_parking_usage_anpr_lp_number
            ) {
              let marker = new google.maps.Marker({
                clickable: false,
                draggable: false,
                visible: this.showFixedSizeMarkers,
                icon: {
                  path: mdiCarBack,
                  fillColor: "grey",
                  strokeColor: "grey",
                  fillOpacity: 1.0,
                  anchor: new google.maps.Point(
                    FIXED_MARKER_OPTIONS.iconAnchor.x,
                    FIXED_MARKER_OPTIONS.iconAnchor.y +
                      (this.showLicensePlates ? 9 : 0)
                  ),
                },
                // "Parked by" test is used to find this marker if edited please edit spotLPMarkers condition below
                title:
                  "Parked by LP: " +
                  parkingSpotData?.vehicle_parking_usage_anpr_lp_number.toUpperCase(),
                map: this.map,
              });
              this.annotations.fixedSizeMarkers.push(marker);
              this.spotHistory.anprMarkers.push(marker);
              currentSpotMarkers.push(marker);
              console.log("Adding Marker for Parking Spot ANPR");

              if (this.showLicensePlates) {
                let markerLP = new google.maps.Marker({
                  clickable: false,
                  draggable: false,
                  visible: this.showFixedSizeMarkers,
                  label: {
                    text: parkingSpotData?.vehicle_parking_usage_anpr_lp_number.toUpperCase(),
                    className: "highlightLP",
                    fontSize: "10px",
                    fontWeight: "bold",
                    color: "black",
                  },
                  icon: {
                    url: `${parkingSpotData?.vehicle_parking_usage_anpr_lp_number.toUpperCase()}`,
                    anchor: new google.maps.Point(
                      FIXED_MARKER_OPTIONS.iconAnchor.x,
                      FIXED_MARKER_OPTIONS.iconAnchor.y - 9
                    ),
                  },
                  map: this.map,
                });
                this.annotations.fixedSizeMarkers.push(markerLP);
                this.spotHistory.anprMarkers.push(markerLP);
                spotLPMarkers.push(markerLP);
                console.log("Adding Marker for Parking Spot ANPR LP");
              }
            }

            // Position the markers, and add contextmenu handlers to spot properties menu
            console.log("Spot Point Lat lngs", spotLatLngs);
            let markerPositions = getMarkerPositionsOnRect(
              spotLatLngs,
              currentSpotMarkers.length,
              false
            );
            let blockedMarkerPositions = getMarkerPositionsOnRect(
              spotLatLngs,
              6,
              true
            );

            for (let [i, marker] of blockedSpotMarkers.entries()) {
              let markerPosition = blockedMarkerPositions[i];
              let latlng = new google.maps.LatLng(
                markerPosition[1],
                markerPosition[0]
              );
              marker.setPosition(latlng);
              if (this.interactive) {
                marker.addListener(
                  "click",
                  (event: google.maps.PolyMouseEvent) => {
                    // show Spot Slider
                    if (!this.isEditMode) {
                      this.spotSlider.show = true;
                      this.spotSlider.spotId = parkingSpotData
                        ? parkingSpotData.id
                        : id;
                      this.spotSlider.cameraId =
                        parkingSpotData?.camera_id || null;
                    }
                  }
                );
                marker.addListener(
                  this.is_iOS ? "dblclick" : "contextmenu",
                  (event: google.maps.PolyMouseEvent) => {
                    // do nothing if Timeline enabled
                    if (this.viewPastSpotHistory) {
                      return;
                    }
                    this.displayPropertiesPopup({
                      category: "spot",
                      id: poly.id,
                      data: parkingSpotData,
                      annotationObj: poly,
                    });
                  }
                );
              }
            }
            for (let [i, marker] of currentSpotMarkers.entries()) {
              let markerPosition = markerPositions[i];
              let latlng = new google.maps.LatLng(
                markerPosition[1],
                markerPosition[0]
              );
              marker.setPosition(latlng);
              if (
                this.showLicensePlates &&
                marker.getTitle() &&
                marker.getTitle().includes("Parked by LP") &&
                spotLPMarkers.length > 0
              ) {
                spotLPMarkers[0].setPosition(latlng);
              }
              if (this.interactive) {
                marker.addListener(
                  "click",
                  (event: google.maps.PolyMouseEvent) => {
                    // show Spot Slider
                    if (!this.isEditMode) {
                      this.spotSlider.show = true;
                      this.spotSlider.spotId = parkingSpotData
                        ? parkingSpotData.id
                        : id;
                      this.spotSlider.cameraId =
                        parkingSpotData?.camera_id || null;
                    }
                  }
                );
                marker.addListener(
                  this.is_iOS ? "dblclick" : "contextmenu",
                  (event: google.maps.PolyMouseEvent) => {
                    // do nothing if Timeline enabled
                    if (this.viewPastSpotHistory) {
                      return;
                    }
                    this.displayPropertiesPopup({
                      category: "spot",
                      id: poly.id,
                      data: parkingSpotData,
                      annotationObj: poly,
                    });
                  }
                );
              }
            }
            console.log("Marker positions", markerPositions);
          }

          this.current.poly = poly;
          break;
        }
        case "camera": {
          let labelText = " ";
          if (this.isSuperAdmin) {
            if (cameraData?.count_vehicles_crossing_line_points) {
              // Use '-' as pin label for cameras that have counter zone lines
              labelText = "--";
            } else if (cameraData?.count_vehicles_only_in_roi) {
              // Use '<' as pin label for cameras that have an roi
              labelText = "<";
            }
          }
          let label = {
            className: cameraData?.adjacent_zone_id
              ? "mdi mdi-camera-switch"
              : "mdi mdi-camera",
            text: labelText,
            color: "white",
          };
          if (this.showSpotCameraIds) {
            label.className = " ";
            label.text = `${id}`;
          }
          let camera = new google.maps.Marker({
            clickable: this.interactive,
            draggable: this.isEditMode, // reposition by clicking at new point
            label: label,
            title: "Camera",
            map: this.map,
            visible: this.isEditMode || this.showCameraIcons,
          }) as Camera;
          this.annotations.cameras.push(camera);
          if (this.interactive || this.hasAccessLevelCameraEditing) {
            camera.addListener("click", (event: google.maps.MapMouseEvent) => {
              this.onSelectCamera(event, camera);
              if (this.isSuperAdmin && !this.isEditMode && camera?.id) {
                this.highlightCameraParkingSpotsWithId =
                  this.highlightCameraParkingSpotsWithId &&
                  this.highlightCameraParkingSpotsWithId == camera?.id
                    ? 0
                    : camera?.id;
                this.clearMapAnnotations();
                this.drawParkingLotAnnotations();
              }
            });
            camera.addListener(
              this.is_iOS ? "dblclick" : "contextmenu",
              (event: google.maps.MapMouseEvent) => {
                // do nothing if Timeline enabled
                if (this.viewPastSpotHistory) {
                  return;
                }
                let cameraData = this.cameras.find((c) => c.id === camera.id);
                if (cameraData) {
                  this.selectedCameraId = cameraData.id;
                  this.displayCameraForm(true);
                  // this.displayPropertiesPopup({
                  //   category: "camera",
                  //   id: cameraData.id,
                  //   data: cameraData,
                  //   annotationObj: camera,
                  // });
                }
              }
            );
            camera.addListener(
              this.is_iOS ? "rightclick" : "dblclick",
              (event: google.maps.MapMouseEvent) => {
                // do nothing if Timeline enabled
                if (this.viewPastSpotHistory) {
                  return;
                }
                // draw camera fov if enabled
                if (
                  this.showCameraFOV &&
                  !this.selectedCameraFov.edit &&
                  this.parkingLot &&
                  camera.id
                ) {
                  let cameraFov = this.cameraFovs.find(
                    (c) => c.id === camera.id
                  );
                  let cameraData = this.parkingLot.cameras.find(
                    (c) => c.id === camera.id
                  );

                  if (cameraData) {
                    let cameraPosition = JSON.parse(
                      cameraData.gps_coordinates as string
                    ).coordinates;

                    if (cameraFov && cameraData.fov_direction) {
                      this.selectedCameraFov.isNew = false;
                      this.selectedCameraFov.bearing = cameraData.fov_direction;
                      this.selectedCameraFov.cameraFov = cameraFov;
                    } else {
                      this.selectedCameraFov.isNew = true;
                      this.selectedCameraFov.cameraFov = this.drawCameraFov(
                        cameraPosition[1],
                        cameraPosition[0],
                        camera.id
                      );
                    }
                    this.selectedCameraFov.camera = cameraData;
                    this.selectedCameraFov.edit = true;
                  }
                }
              }
            );
          }
          if (id) {
            camera.id = id;
          }
          this.current.marker = camera;
          break;
        }
        case "display_board": {
          let label = {
            className: "highlight",
            fontWeight: "bold",
            color: "white",
            text: "",
          };
          if (this.showDisplayBoards) {
            label.text = displayBoardData
              ? displayBoardData.name
              : `Display Board ${id}`;
          }

          let infowindow = this.getDisplayBoardInfoWindow(id);
          if (id && infowindow) {
            this.annotations.displayBoardInfoWindows =
              this.annotations.displayBoardInfoWindows.filter(
                (item) => item.display_board_id != id
              );
            this.annotations.displayBoardInfoWindows.push({
              display_board_id: id,
              infowindow: infowindow,
            });
          }
          let display_board = new google.maps.Marker({
            clickable: this.interactive,
            draggable: this.isEditMode, // reposition by clicking at new point
            // label: label,
            icon: {
              url: this.getCustomSvgPath("sg-display-board") as any,
              fillOpacity: 1.0,
              size: new google.maps.Size(32, 32),
              scaledSize: new google.maps.Size(32, 32),
              anchor: new google.maps.Point(
                FIXED_MARKER_OPTIONS.iconAnchor.x,
                FIXED_MARKER_OPTIONS.iconAnchor.y
              ),
            },
            title: "Display Board",
            map: this.map,
            visible: this.isEditMode || this.showDisplayBoards,
          }) as DigitalBoardInterface;
          if (this.interactive) {
            // display_board.addListener("click", (event: google.maps.MapMouseEvent) => {});
            if (infowindow != null) {
              display_board.addListener(
                "click",
                (event: google.maps.MapMouseEvent) => {
                  if (infowindow) {
                    infowindow.open(this.map, display_board);
                  }
                }
              );
            }

            if (!id) {
              // remove display board marker on click of not created
              display_board.addListener(
                "click",
                (event: google.maps.MapMouseEvent) => {
                  display_board.setMap(null);
                }
              );
            }

            display_board.addListener(
              this.is_iOS ? "dblclick" : "contextmenu",
              (event: google.maps.MapMouseEvent) => {
                // do nothing if Timeline enabled
                if (this.viewPastSpotHistory) {
                  return;
                }
                let displayBoardData = this.digitalBoards.find(
                  (c) => c.id === display_board.id
                );
                if (displayBoardData) {
                  this.selectedDisplayBoardId = displayBoardData.id;
                  this.showDisplayBoardForm(true);
                }
              }
            );
          }
          if (id) {
            display_board.id = id;
          }
          this.current.marker = display_board;
          if (id && !this.showDisplayBoards) {
            this.current.marker.setMap(null);
          }
          break;
        }
        case "landmark": {
          let landmark = new google.maps.Marker({
            clickable: this.interactive,
            draggable: this.isEditMode,
            label: {
              className: "mdi mdi-star",
              text: " ",
              color: "white",
            },
            title: "Landmark",
            map: this.map,
          }) as Landmark;
          if (this.interactive) {
            landmark.addListener(
              this.is_iOS ? "dblclick" : "contextmenu",
              (event: google.maps.MapMouseEvent) => {
                // do nothing if Timeline enabled
                if (this.viewPastSpotHistory) {
                  return;
                }
                this.displayPropertiesPopup({
                  category: "landmark",
                  id: landmark.id,
                  data: null,
                  annotationObj: landmark,
                });
              }
            );
          }
          landmark.name = "";
          this.current.marker = landmark;
          break;
        }
      }
    },
    saveCurrentAnnotation(activeTool: string, doUndo = false) {
      console.log("Saving current annotation of", activeTool);
      switch (activeTool) {
        case "driveway": {
          // Save the non-empty (at least 2 points) current line into annotations list
          if (
            this.current.line &&
            this.current.line.getPath().getLength() >= 2
          ) {
            this.annotations.driveways.push(this.current.line);
            if (doUndo)
              this.undoList.push({
                name: "driveways",
                action: "undo_driveway",
                args: { line: this.current.line },
              });
            this.current.line = null;
          }
          break;
        }
        case "spot": {
          console.log("Spot", this.current.poly?.getPath().getArray());
          // Save the non-empty current poly as a spot in annotations list
          if (this.current.poly) {
            if (this.current.poly.getPath().getLength() == 4) {
              // if Make Parellelogram is set then make a parellelogram from given points
              if (
                this.current.poly.getPath().getLength() >= 4 &&
                this.makeParellelogram
              ) {
                let laneLatLngs: google.maps.LatLng[] = this.current.poly
                  .getPath()
                  .getArray();
                let polyPoints = laneLatLngs.map((p) => [p.lng(), p.lat()]);
                let polyParellelogramPoints =
                  polyPointsToParallelogram(polyPoints);
                // remove all poly points
                for (let i = 0; i < 4; i++) {
                  this.current.poly.getPath().pop();
                }
                // add adjusted poly points, make parellelogram
                for (let i = 0; i < 4; i++) {
                  this.current.poly
                    .getPath()
                    .push(
                      new google.maps.LatLng(
                        polyParellelogramPoints[i][1],
                        polyParellelogramPoints[i][0]
                      )
                    );
                }
              }

              this.annotations.spots.push(this.current.poly);
              if (doUndo)
                this.undoList.push({
                  name: "spot",
                  action: "undo_spot_poly",
                  args: { poly: this.current.poly },
                });
              this.current.poly.setEditable(false);
            } else {
              for (
                let i = 0;
                i < this.current.poly.getPath().getLength();
                i++
              ) {
                if (
                  this.undoList[this.undoList.length - 1].action == "undo_spot"
                ) {
                  this.undoList.pop();
                }
              }
              this.current.poly.setMap(null); // Remove drawing
            }
            this.current.poly = null;
          }
          break;
        }
        case "lane": {
          console.log("Lane", this.current.line?.getPath().getArray());
          // Every 3 points define a new lane (the fourth point of the poly is computed
          // to ensure opposite sides of the quad are of equal length).
          if (
            this.current.line &&
            this.current.line.getPath().getLength() >= 3
          ) {
            // Note, spot autogeneration is done only when creating a new lane.
            // Spot autogeneration does not get performed when importing the geojson for an
            // existing lane, to avoid creating new spots on top of existing spots which
            // were previously created.
            if (!this.current.line.id && !this.isImportingGeoJson) {
              let laneLatLngs: google.maps.LatLng[] = this.current.line
                .getPath()
                .getArray();
              let lanePoints = laneLatLngs.map((p) => [p.lng(), p.lat()]);
              if (doUndo)
                this.undoList.push({
                  name: "lane",
                  action: "undo_lane_poly",
                  args: { lanePoints, spotCount: this.options.spotCount },
                });
              this.current.line.setMap(null); // Remove incomplete lane
              console.log("Lane has points", lanePoints);

              let spotsPoints = laneToSpots(lanePoints, this.options.spotCount);
              console.log("Generated spots for new lane", spotsPoints);
              for (const [i, spotPoints] of spotsPoints.entries()) {
                this.toolChanged("spot"); // Trigger creating a new spot
                console.log("Generating spot", i, spotPoints);
                for (const point of spotPoints) {
                  let [lng, lat] = point;
                  let latLng = new google.maps.LatLng(lat, lng);
                  this.addPolyPoint(latLng, "spot", 4);
                }
                this.saveCurrentAnnotation("spot");
              }

              this.toolChanged("lane");
              // Add completed lane with the fourth computed point.
              const [fourthPointLng, fourthPointLat] = lanePoints[3];
              for (let latLng of laneLatLngs) {
                this.addLinePoint(latLng);
              }
              this.addLinePoint(
                new google.maps.LatLng(fourthPointLat, fourthPointLng)
              );
              this.addLinePoint(laneLatLngs[0]);
            }

            this.current.line.setOptions({
              editable: false,
              strokeWeight: 4,
              strokeColor: COLORS.lane,
            });
            this.annotations.lanes.push(this.current.line);
            this.current.line = null;
          }
          break;
        }
        case "zone":
        case "parking_structure": {
          console.log("Zone", this.current.line?.getPath().getArray());
          // Save the non-empty (at least 3 points) current line into annotations list
          if (
            this.current.line &&
            this.current.line.getPath().getLength() >= 3
          ) {
            let line = this.current.line as Zone;
            if (this.activeTool === "parking_structure") {
              line.multiLevelNumLevels =
                this.options.parkingStructureLevelCount;
            }
            this.annotations.zones.push(line);
            if (doUndo)
              this.undoList.push({
                name: "zone",
                action: "undo_zone_poly",
                args: { line: line },
              });
            this.current.line = null;
          }
          break;
        }
        case "special_area": {
          console.log("Special Area", this.current.line?.getPath().getArray());
          if (
            this.current.line &&
            this.current.line.getPath().getLength() >= 3
          ) {
            this.annotations.specialAreas.push(
              this.current.line as SpecialArea
            );
            if (doUndo)
              this.undoList.push({
                name: "special_area",
                action: "undo_special_area_poly",
                args: { line: this.current.line },
              });
            this.current.line = null;
          }
          break;
        }
        case "lot": {
          if (
            this.current.line &&
            this.current.line.getPath().getLength() >= 3
          ) {
            this.annotations.lot = this.current.line;
            let laneLatLngs: google.maps.LatLng[] = this.current.line
              .getPath()
              .getArray();
            let lanePoints = laneLatLngs.map((p) => [p.lng(), p.lat()]);
            if (doUndo)
              this.undoList.push({
                name: "lot",
                action: "undo_lot_poly",
                args: { line: this.annotations.lot, lanePoints },
              });
            this.current.line = null;
          }
          break;
        }
        case "landmark": {
          if (this.current.marker) {
            this.annotations.landmarks.push(this.current.marker as Landmark);
            this.current.marker = null;
          }
        }
      }
    },
    featureAdded() {
      console.log("Added a new feature");
      this.exportDrawings();
    },
    exportDrawings() {
      if (this.map) {
        let dataLayer = new google.maps.Data();

        // Save ths annotation being drawn currently
        if (this.activeTool) {
          this.saveCurrentAnnotation(this.activeTool);
        }

        for (let camera of this.annotations.cameras) {
          console.log("Exporting camera:", camera.getPosition()?.toJSON());
          let position = camera.getPosition()?.toJSON();
          if (position) {
            dataLayer.add(
              new google.maps.Data.Feature({
                geometry: new google.maps.Data.Point(position),
                properties: {
                  category: "camera",
                  id: camera.id,
                  fov_direction: camera.fov_direction,
                },
              })
            );
          }
        }

        for (let display_board of this.annotations.display_boards) {
          console.log(
            "Exporting display boards:",
            display_board.getPosition()?.toJSON()
          );
          let position = display_board.getPosition()?.toJSON();
          if (position) {
            dataLayer.add(
              new google.maps.Data.Feature({
                geometry: new google.maps.Data.Point(position),
                properties: {
                  category: "display_board",
                  id: display_board.id,
                },
              })
            );
          }
        }

        // Add all driveways to dataLayer
        for (let line of this.annotations.driveways) {
          console.log("Exporting lines:", line.getPath().getArray());
          dataLayer.add(
            new google.maps.Data.Feature({
              geometry: new google.maps.Data.LineString(
                line.getPath().getArray()
              ),
              properties: {
                category: "driveway",
                id: line.id,
              },
            })
          );
        }

        // Add all spots to dataLayer
        for (const spotPoly of this.annotations.spots) {
          console.log("Exporting spotPoly", spotPoly.getPath().getArray());
          dataLayer.add(
            new google.maps.Data.Feature({
              geometry: new google.maps.Data.Polygon([
                new google.maps.Data.LinearRing(spotPoly.getPath().getArray()),
              ]),
              properties: {
                category: "spot",
                id: spotPoly.id,
              },
            })
          );
        }

        // Add all lanes to dataLayer
        for (const laneLine of this.annotations.lanes) {
          console.log("Exporting lane:", laneLine.getPath().getArray());
          dataLayer.add(
            new google.maps.Data.Feature({
              geometry: new google.maps.Data.Polygon([
                new google.maps.Data.LinearRing(laneLine.getPath().getArray()),
              ]),
              properties: {
                category: "lane",
                id: laneLine.id,
              },
            })
          );
        }

        // Add all zones to dataLayer
        for (const zoneLine of this.annotations.zones) {
          console.log("Exporting zone:", zoneLine.getPath().getArray());
          dataLayer.add(
            new google.maps.Data.Feature({
              geometry: new google.maps.Data.Polygon([
                new google.maps.Data.LinearRing(zoneLine.getPath().getArray()),
              ]),
              properties: {
                category: zoneLine.multiLevelNumLevels
                  ? "parking_structure"
                  : "zone",
                id: zoneLine.id,
                entrypoints: zoneLine.entrypointsIndexes,
                is_multi_level_structure: zoneLine.multiLevelNumLevels
                  ? true
                  : false,
                num_levels: zoneLine.multiLevelNumLevels,
              },
            })
          );
        }

        // Add all special_areas to dataLayer
        for (const specialAreaLine of this.annotations.specialAreas) {
          console.log(
            "Exporting special area:",
            specialAreaLine.getPath().getArray()
          );
          dataLayer.add(
            new google.maps.Data.Feature({
              geometry: new google.maps.Data.Polygon([
                new google.maps.Data.LinearRing(
                  specialAreaLine.getPath().getArray()
                ),
              ]),
              properties: {
                category: "special_area",
                id: specialAreaLine.id,
              },
            })
          );
        }

        // Add all landmarks to dataLayer
        for (const landmark of this.annotations.landmarks) {
          let position = landmark.getPosition()?.toJSON();
          console.log("Exporting landmark:", position);
          if (position) {
            dataLayer.add(
              new google.maps.Data.Feature({
                geometry: new google.maps.Data.Point(position),
                properties: {
                  category: "landmark",
                  name: landmark.name || "",
                },
              })
            );
          }
        }

        if (this.annotations.lot != null) {
          const lot = this.annotations.lot;
          console.log("Exporting lot boundary:", lot.getPath().getArray());
          dataLayer.add(
            new google.maps.Data.Feature({
              geometry: new google.maps.Data.Polygon([
                new google.maps.Data.LinearRing(lot.getPath().getArray()),
              ]),
              properties: {
                category: "lot",
              },
            })
          );
        }

        // Export dataLayer to GeoJson
        dataLayer.toGeoJson((json) => {
          console.log("Exported JSON: ", json);
          this.geoJson = json as FeatureCollection;
          this.saveParkingLot();
        });
      }
    },
    /**
     * Load a GeoJSON object and draw all its features.
     */
    importGeoJson(
      geoJson: FeatureCollection,
      parkingSpotsData: Array<ParkingSpot>,
      parkingZonesData: Array<ParkingZone>
    ) {
      let dataLayer = new google.maps.Data();
      let features = dataLayer.addGeoJson(geoJson);
      this.isImportingGeoJson = true;
      for (const feature of features) {
        const category: string = feature.getProperty("category");
        let featureId = feature.getProperty("id");
        const geometry = feature.getGeometry();
        let parkingSpotData = null;
        let parkingZoneData = null;
        if (featureId) {
          switch (category) {
            case "spot": {
              parkingSpotData = parkingSpotsData.find(
                (spot: ParkingSpot) => spot.id === featureId
              );
              break;
            }
            case "zone":
            case "parking_structure": {
              parkingZoneData = parkingZonesData.find(
                (zone: ParkingZone) => zone.id === featureId
              );
              break;
            }
          }
        }
        console.log("Metadata", parkingSpotData, parkingZoneData);

        // If zone data is not found, then zone may be hidden to customers so
        // dont draw this zone on the digimap since it is not visible to them.
        // if (
        //   !this.isSuperAdmin &&
        //   category == "zone" &&
        //   parkingZoneData == null
        // ) {
        //   continue;
        // }

        this.activeTool = category;
        // Trigger creating a new drawing (poly, line, etc)
        this.toolChanged(
          category,
          featureId,
          parkingSpotData,
          null,
          parkingZoneData,
          null
        );
        switch (category) {
          case "driveway":
          case "lane":
          case "zone":
          case "parking_structure":
          case "special_area":
          case "lot": {
            console.log(">>> IMPORTING CATEGORY", category);
            geometry?.forEachLatLng((latLng) => {
              console.log("Added line point", latLng);
              this.addLinePoint(latLng);
            });
            // Add and draw all zone entrypoints
            if (category === "zone" && feature.getProperty("entrypoints")) {
              let zone = this.current.line as Zone;
              zone.entrypointsIndexes = feature.getProperty("entrypoints");
              for (let idx of zone.entrypointsIndexes) {
                this.drawZoneEntrypoint(zone, zone.getPath().getAt(idx));
              }
              if (feature.getProperty("is_multi_level_structure")) {
                zone.multiLevelNumLevels = feature.getProperty("num_levels");
              }
            }
            break;
          }
          case "spot": {
            geometry?.forEachLatLng((latLng) => {
              console.log("Added point", latLng);
              this.addPolyPoint(latLng, "spot", 4);
            });

            break;
          }
          case "landmark": {
            geometry?.forEachLatLng((latLng) => {
              this.current.marker?.setPosition(latLng);
              let landmarkName = feature.getProperty("name") || "Landmark";
              (this.current.marker as Landmark).name = landmarkName;
              this.current.marker?.setLabel(landmarkName);
              this.current.marker?.setTitle(landmarkName);
            });
            break;
          }
        }
        this.saveCurrentAnnotation(category);
      }
      this.activeTool = null;
      this.isImportingGeoJson = false;
    },
    /**
     * Show or hide spot markers whenever map is zoomed in.
     */
    onZoomChanged() {
      this.mapZoomLevel = this.map?.getZoom() || 0;
      for (let marker of this.annotations.fixedSizeMarkers) {
        marker.setVisible(this.showFixedSizeMarkers);
      }
      if (this.viewPastSpotHistory) {
        for (let marker of this.spotHistory.timelineAnprMarkers) {
          marker.setVisible(this.showFixedSizeMarkers);
        }
      }
    },
    importCamerasOnMap(cameras: Array<CameraData>) {
      for (let camera of cameras) {
        if (
          camera.level_id !=
          this.multiLevelParkingStructure.currentMultiStructureZoneId
        ) {
          continue;
        }
        this.activeTool = "camera";
        this.toolChanged("camera", camera.id, null, camera); // Trigger creating a new drawing (poly, line, etc)
        if (this.showSpotCameraIds) {
          let marker = new google.maps.Marker({
            clickable: false,
            draggable: false,
            visible: this.showFixedSizeMarkers,
            label: {
              text: camera.name,
              className: "highlight",
              fontWeight: "bold",
              color: "white",
            },
            icon: {
              url: `${camera.id}`,
              anchor: new google.maps.Point(
                FIXED_MARKER_OPTIONS.iconAnchor.x,
                FIXED_MARKER_OPTIONS.iconAnchor.y
              ),
            },
            map: this.map,
            zIndex: 100,
          });
          let camera_coords = JSON.parse(
            camera.gps_coordinates as string
          ).coordinates;
          marker.setPosition(
            new google.maps.LatLng(camera_coords[1], camera_coords[0])
          );
          this.annotations.cameras.push(marker);
        }
        let cameraPosition = JSON.parse(
          camera.gps_coordinates as string
        ).coordinates;
        let latlng = new google.maps.LatLng(
          cameraPosition[1],
          cameraPosition[0]
        );
        this.current.marker?.setPosition(latlng);
        this.current.marker?.setTitle(camera.name);
        if (camera.comment && !this.showSpotCameraIds) {
          this.current.marker?.setLabel({
            className: "mdi mdi-camera-wireless",
            text: this.current.marker?.getLabel()?.text || " ",
            color: "white",
          });
          this.current.marker?.setTitle(`${camera.name}:${camera.comment}`);
        }
        if (camera.untracked_zone_id) {
          const zoneType =
            this.parkingLot?.parking_zones.find(
              (z) => z.id === camera.untracked_zone_id
            )?.zone_type === "time_limited_zone"
              ? "Drop Off/Pick Up"
              : "Car Counting";
          this.current.marker?.setTitle(
            this.current.marker?.getTitle() +
              `\nAssigned to ${zoneType} Zone ID: ` +
              (this.parkingLot?.parking_zones?.find(
                (z) => z.id === camera.untracked_zone_id
              )?.name || `ID ${camera.untracked_zone_id}`)
          );
        }
        if (camera.counting_zone_id) {
          this.current.marker?.setTitle(
            this.current.marker?.getTitle() +
              "\nAssigned to Counter Zone: " +
              (this.parkingLot?.parking_zones?.find(
                (z) => z.id === camera.counting_zone_id
              )?.name || `ID ${camera.counting_zone_id}`)
          );
        }
        if (camera.adjacent_zone_id) {
          this.current.marker?.setTitle(
            this.current.marker?.getTitle() +
              `\nAssigned to Adjacent Zone: ` +
              (this.parkingLot?.parking_zones?.find(
                (z) => z.id === camera.adjacent_zone_id
              )?.name || `ID ${camera.adjacent_zone_id}`)
          );
        }
        if (this.current.marker) {
          this.current.marker.fov_direction = camera.fov_direction;
          this.annotations.cameras.push(this.current.marker);
        }

        // draw camera fov if enabled
        if (this.showCameraFOV && camera.fov_direction) {
          // Define the LatLng coordinates for the polygon's path.
          let cameraPosition = JSON.parse(
            camera.gps_coordinates as string
          ).coordinates;

          // draw camera fov if set
          this.selectedCameraFov.bearing = camera.fov_direction;
          const drawnFov = this.drawCameraFov(
            cameraPosition[1],
            cameraPosition[0],
            camera.id
          );
          this.cameraFovs.push(drawnFov);
          this.selectedCameraFov.bearing = 0;
        }
      }
      this.activeTool = null;
    },
    importDigitalBoardsOnMap(display_boards: Array<DigitalBoard>) {
      for (let display_board of display_boards) {
        if (
          display_board.level_id !=
          this.multiLevelParkingStructure.currentMultiStructureZoneId
        ) {
          continue;
        }
        this.activeTool = "display_board";
        this.toolChanged(
          "display_board",
          display_board.id,
          null,
          null,
          null,
          display_board
        ); // Trigger creating a new drawing (poly, line, etc)

        // if (this.showDisplayBoards) {
        //   let marker = new google.maps.Marker({
        //     clickable: false,
        //     draggable: false,
        //     visible: this.showFixedSizeMarkers && this.showDisplayBoards,
        //     // label: {
        //     //   text: display_board.name,
        //     //   className: "highlight",
        //     //   fontWeight: "bold",
        //     //   color: "white",
        //     // },
        //     icon: {
        //       url: this.getCustomSvgPath("sg-display-board") as any,
        //       fillOpacity: 1.0,
        //       anchor: new google.maps.Point(
        //         FIXED_MARKER_OPTIONS.iconAnchor.x,
        //         FIXED_MARKER_OPTIONS.iconAnchor.y
        //       ),
        //     },
        //     map: this.map,
        //     zIndex: 100,
        //   });
        //   let display_board_coords = JSON.parse(
        //     display_board.gps_coordinates as string
        //   ).coordinates;
        //   marker.setPosition(
        //     new google.maps.LatLng(
        //       display_board_coords[1],
        //       display_board_coords[0]
        //     )
        //   );
        //   this.annotations.display_boards.push(marker);
        // }
        let digitalBoardPosition = JSON.parse(
          display_board.gps_coordinates as string
        ).coordinates;
        let latlng = new google.maps.LatLng(
          digitalBoardPosition[1],
          digitalBoardPosition[0]
        );
        this.current.marker?.setPosition(latlng);
        this.current.marker?.setTitle(display_board.name);
        if (display_board.comment && !this.showDisplayBoards) {
          // this.current.marker?.setLabel({
          //   className: "mdi mdi-sign-caution",
          //   text: this.current.marker?.getLabel()?.text || " ",
          //   color: "white",
          // });
          this.current.marker?.setTitle(
            `${display_board.name}:${display_board.comment}`
          );
        }
        if (this.current.marker) {
          this.annotations.display_boards.push(this.current.marker);
        }
      }
      this.activeTool = null;
    },

    drawCameraFov(
      lat: number,
      lng: number,
      camera_id: number
    ): google.maps.Polygon {
      let [[lat_1, lon_1], [lat_2, lon_2]] =
        this.getCameraFOVTriangleCoordinates(
          lat,
          lng,
          this.selectedCameraFov.bearing,
          this.selectedCameraFov.angle,
          this.selectedCameraFov.distance
        );

      const triangleCoords = [
        { lat: lat, lng: lng },
        { lat: lat_1, lng: lon_1 },
        { lat: lat_2, lng: lon_2 },
        { lat: lat, lng: lng },
      ];

      // Construct the polygon.
      let cameraFov = new google.maps.Polygon({
        paths: triangleCoords,
        strokeColor: "#3307ef",
        strokeOpacity: 0.3,
        strokeWeight: 1,
        fillColor: "#3307ef",
        fillOpacity: 0.35,
      }) as CameraFov;

      if (this.isEditMode) {
        cameraFov.addListener(
          "mousemove",
          (event: google.maps.MapMouseEvent) => {
            if (this.selectedCameraFov.edit && event.latLng) {
              this.editCameraFov(event.latLng);
            }
          }
        );
        cameraFov.addListener("click", (event: google.maps.MapMouseEvent) => {
          // Also stop camera fov setting and reset selectedCameraFov
          this.stopCameraFovSetting();
        });
      }

      cameraFov.id = camera_id;
      cameraFov.setMap(this.map);
      return cameraFov;
    },

    getCameraFOVTriangleCoordinates(
      latitude: number,
      longitude: number,
      bearing: number,
      angle: number,
      distance: number
    ) {
      distance = distance / 1000;

      // Radius of the Earth in km
      const R = 6378.1;
      // Convert angle to radian
      const brng_1 = (bearing * Math.PI) / 180;
      const brng_2 = (((bearing + angle) % 360) * Math.PI) / 180;

      // Current coords to radians
      let lat_1 = (latitude * Math.PI) / 180,
        lat_2 = (latitude * Math.PI) / 180;
      let lon_1 = (longitude * Math.PI) / 180,
        lon_2 = (longitude * Math.PI) / 180;

      lat_1 = Math.asin(
        Math.sin(lat_1) * Math.cos(distance / R) +
          Math.cos(lat_1) * Math.sin(distance / R) * Math.cos(brng_1)
      );
      lon_1 += Math.atan2(
        Math.sin(brng_1) * Math.sin(distance / R) * Math.cos(lat_1),
        Math.cos(distance / R) - Math.sin(lat_1) * Math.sin(lat_1)
      );

      lat_2 = Math.asin(
        Math.sin(lat_2) * Math.cos(distance / R) +
          Math.cos(lat_2) * Math.sin(distance / R) * Math.cos(brng_2)
      );
      lon_2 += Math.atan2(
        Math.sin(brng_2) * Math.sin(distance / R) * Math.cos(lat_2),
        Math.cos(distance / R) - Math.sin(lat_2) * Math.sin(lat_2)
      );

      // Coords back to degrees and return
      lat_1 = (lat_1 * 180) / Math.PI;
      lon_1 = (lon_1 * 180) / Math.PI;

      lat_2 = (lat_2 * 180) / Math.PI;
      lon_2 = (lon_2 * 180) / Math.PI;

      return [
        [lat_1, lon_1],
        [lat_2, lon_2],
      ];
    },

    getBearingBetweenTwoCoordinates(
      startLat: number,
      startLng: number,
      destLat: number,
      destLng: number
    ) {
      startLat = (startLat * Math.PI) / 180;
      startLng = (startLng * Math.PI) / 180;
      destLat = (destLat * Math.PI) / 180;
      destLng = (destLng * Math.PI) / 180;

      const y = Math.sin(destLng - startLng) * Math.cos(destLat);
      const x =
        Math.cos(startLat) * Math.sin(destLat) -
        Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
      let brng = Math.atan2(y, x);
      brng = (brng * 180) / Math.PI;
      return (brng + 360) % 360;
    },

    editCameraFov(latLng: google.maps.LatLng) {
      const destlatitude = latLng.lat();
      const destlongitude = latLng.lng();

      if (this.selectedCameraFov.camera) {
        let cameraPosition = JSON.parse(
          this.selectedCameraFov.camera.gps_coordinates as string
        ).coordinates;

        this.selectedCameraFov.bearing = this.getBearingBetweenTwoCoordinates(
          cameraPosition[1],
          cameraPosition[0],
          destlatitude,
          destlongitude
        );

        let [[lat_1, lon_1], [lat_2, lon_2]] =
          this.getCameraFOVTriangleCoordinates(
            cameraPosition[1],
            cameraPosition[0],
            this.selectedCameraFov.bearing,
            this.selectedCameraFov.angle,
            this.selectedCameraFov.distance
          );
        const triangleCoords = [
          {
            lat: cameraPosition[1],
            lng: cameraPosition[0],
          },
          { lat: lat_1, lng: lon_1 },
          { lat: lat_2, lng: lon_2 },
          {
            lat: cameraPosition[1],
            lng: cameraPosition[0],
          },
        ];

        if (this.selectedCameraFov.cameraFov) {
          this.selectedCameraFov.cameraFov?.setPath(triangleCoords);
        }
      }
    },

    stopCameraFovSetting() {
      if (this.selectedCameraFov.camera) {
        this.selectedCameraFov.camera.fov_direction =
          this.selectedCameraFov.bearing;
        if (this.selectedCameraFov.isNew && this.selectedCameraFov.cameraFov) {
          this.cameraFovs.push(this.selectedCameraFov.cameraFov);
        }
        let camera = this.annotations.cameras.find(
          (c) =>
            this.selectedCameraFov.camera &&
            c.id === this.selectedCameraFov.camera.id
        );
        if (camera) {
          camera.fov_direction = this.selectedCameraFov.camera.fov_direction;
        }
        this.selectedCameraFov.camera = null;
        this.selectedCameraFov.cameraFov = null;
        this.selectedCameraFov.bearing = 0;
      }
      this.selectedCameraFov.edit = false;
      this.selectedCameraFov.isNew = false;
    },

    async saveParkingLot() {
      if (this.parkingLot) {
        if (this.geoJson) {
          this.parkingLot.map = this.geoJson;
        }
        try {
          let data;
          if (this.multiLevelParkingStructure.isLevelDigimapView) {
            if (
              this.multiLevelParkingStructure.currentMultiStructureZoneId ==
                null ||
              this.geoJson == null
            ) {
              this.$dialog.message.error(
                "Error saving digimap, unable to extract Digimap from current view.",
                {
                  position: "top-right",
                  timeout: 3000,
                }
              );
              return;
            }

            data = await api.updateMultiLevelParkingStructureDigimap(
              this.parkingLot.id,
              this.multiLevelParkingStructure.currentMultiStructureZoneId,
              { map: this.geoJson }
            );
          } else {
            data = await api.updateParkingLot(
              this.parkingLot.id,
              this.parkingLot
            );
          }
          console.log("Response data", data);

          // get maptype (satellite, roadmap, terrain, hybrid) and store it in localStorage so that it comes back after page refresh
          let map_type = this.map?.getMapTypeId();
          if (map_type) {
            localStorage.setItem("mapType", map_type);
          }

          // Reload the current editor page to fetch newly drawn spots along with their ids
          this.$router.go(0);
        } catch (e) {
          console.log("ERRR", e);
          this.$dialog.message.error(
            "<p>Error saving:</p><p>" +
              e.response.data.detail +
              "</p> Please make the necessary adjustments and save again.",

            {
              position: "top-right",
              timeout: 12000,
            }
          );
        }
        this.showExportDialog = false;
      }
    },
    /**
     * Remove every type of annotation from the map.
     */
    clearMapAnnotations() {
      if (this.activeTool != null && this.activeTool != "select") {
        this.saveCurrentAnnotation(this.activeTool);
        this.activeTool = "select";
      }
      for (let driveway of this.annotations.driveways) {
        driveway.setMap(null); // Setting map to null removes the drawing from the map
      }
      this.annotations.driveways.splice(0, this.annotations.driveways.length);

      for (let spot of this.annotations.spots) {
        spot.setMap(null);
      }
      this.annotations.spots.splice(0, this.annotations.spots.length);

      for (let zone of this.annotations.zones) {
        zone.setMap(null);
        if (zone.shapes) {
          for (let shape of zone.shapes) {
            shape.setMap(null);
          }
          zone.shapes = [];
        }
      }
      this.annotations.zones.splice(0, this.annotations.zones.length);

      for (let specialArea of this.annotations.specialAreas) {
        specialArea.setMap(null);
      }
      this.annotations.specialAreas.splice(
        0,
        this.annotations.specialAreas.length
      );

      for (let lane of this.annotations.lanes) {
        lane.setMap(null);
      }
      this.annotations.lanes.splice(0, this.annotations.lanes.length);

      for (let camera of this.annotations.cameras) {
        camera.setMap(null);
      }
      this.annotations.cameras.splice(0, this.annotations.cameras.length);

      for (let display_board of this.annotations.display_boards) {
        display_board.setMap(null);
      }
      this.annotations.display_boards.splice(
        0,
        this.annotations.display_boards.length
      );

      for (let display_board_infowindow of this.annotations
        .displayBoardInfoWindows) {
        display_board_infowindow.infowindow.close();
      }
      this.annotations.displayBoardInfoWindows.splice(
        0,
        this.annotations.displayBoardInfoWindows.length
      );

      for (let cameraFov of this.cameraFovs) {
        cameraFov.setMap(null);
      }
      this.cameraFovs.splice(0, this.cameraFovs.length);

      for (let blockingCar of this.annotations.blockingCars) {
        blockingCar.setMap(null);
      }
      this.annotations.blockingCars.splice(
        0,
        this.annotations.blockingCars.length
      );

      for (let marker of this.annotations.fixedSizeMarkers) {
        marker.setMap(null);
      }
      this.annotations.fixedSizeMarkers.splice(
        0,
        this.annotations.fixedSizeMarkers.length
      );

      this.annotations.lot?.setMap(null);
      this.annotations.lot = null;
    },
    clearUndoHistory() {
      this.undoList = [];
    },
    displayCameraForm(showProperties: boolean) {
      this.selectedCamera =
        this.cameras.find((c) => c.id === this.selectedCameraId) || null;

      // Get the lat/lng of the current marker at the position where the user last clicked
      const newLatlng = this.current.marker?.getPosition();
      const newGpsCoordinates = {
        type: "Point",
        coordinates: [newLatlng?.lng() || 0, newLatlng?.lat() || 0],
      } as Point;

      // Set the newGpsCoordinates to the current camera if it exists, else create a new camera
      if (this.selectedCamera) {
        // do not set coordinates to zero if opening form by right click on camera marker
        if (newLatlng && !showProperties) {
          this.selectedCamera.gps_coordinates = newGpsCoordinates;
          this.selectedCamera.level_id =
            this.multiLevelParkingStructure.currentMultiStructureZoneId;
        }
      } else {
        this.selectedCamera = {
          name: "",
          stream_url: "",
          public_stream_url: "",
          parking_lot_id: (this.parkingLot && this.parkingLot.id) || -1,
          level_id: this.multiLevelParkingStructure.currentMultiStructureZoneId,
          gps_coordinates: newGpsCoordinates,
          is_inference_processing_method: "cloud",
          server_id: null,
          edge_device_id: null,
          lpr_edge_device_id: null,
          camera_offline_alert_delay_threshold_minutes: null,
          comment: null,
          is_lpr_camera_type: null,
          is_lpr_status_check_enabled: false,
          is_direction_detected_from_lpr: false,
          flock_lpr_external_device_id: null,
          lpr_url: null,
          lpr_direction: null,
          save_lpr_events_only_for_vehicle_facing_direction: "both",
          save_exit_lpr_events_only_for_vehicle_facing_direction: "both",
          is_lot_boundary_lpr_camera: false,
          crop_anpr_matching_image_using_inference: false,
          is_camera_garbled_image_detection_feature_enabled: false,
          untracked_zone_id: null,
          adjacent_zone_id: null,
          lpr_zone_id: null,
          lpr_log: null,
          lpr_vehicle_type_threshold: 0.15,
          lpr_vehicle_orientation_threshold: 0.6,
          lpr_vehicle_direction_threshold_start: null,
          lpr_vehicle_direction_threshold_end: null,
        };
      }
      // Finally display the camera details in the camera form
      console.log("Showing camera details", this.selectedCamera);
      this.showCameraForm = true;
    },
    showDisplayBoardForm(showProperties: boolean) {
      this.selectedDigitalBoard =
        this.digitalBoards.find((c) => c.id === this.selectedDisplayBoardId) ||
        null;

      // Get the lat/lng of the current marker at the position where the user last clicked
      const newLatlng = this.current.marker?.getPosition();
      const newGpsCoordinates = {
        type: "Point",
        coordinates: [newLatlng?.lng() || 0, newLatlng?.lat() || 0],
      } as Point;

      // Set the newGpsCoordinates to the current display board if it exists, else create a new display board
      if (this.selectedDigitalBoard) {
        // do not set coordinates to zero if opening form by right click on display board marker
        if (newLatlng && !showProperties) {
          this.selectedDigitalBoard.gps_coordinates = newGpsCoordinates;
        }
      } else {
        this.selectedDigitalBoard = {
          name: "",
          parking_lot_id: (this.parkingLot && this.parkingLot.id) || -1,
          gps_coordinates: newGpsCoordinates,
          vendor: "signal_tech",
          connected_edge_device_id: null,
          comment: null,
          is_active: false,
          vendor_configuration: null,
          layout_configuration: null,
          level_id: this.multiLevelParkingStructure.currentMultiStructureZoneId,
        } as DigitalBoard;
      }
      // Finally display the display board details in the display board form
      console.log("Showing Display board details", this.selectedDigitalBoard);
      this.showDigitalBoardForm = true;
    },
    closeDigitalBoardForm() {
      this.showDigitalBoardForm = false;
      this.selectedDisplayBoardId = null;
      this.activeTool = "select";
    },
    closeCameraForm() {
      this.showCameraForm = false;
      this.selectedCameraId = null;
      this.activeTool = "select";
    },
    closeCameraMapEditor(cameraId: number) {
      console.log(
        "Closing camera map editor for ID",
        cameraId,
        this.cameraMapEditor.showForCameraIds[cameraId]
      );
      this.cameraMapEditor.showForCameraIds[cameraId] = false;
      this.cameraMapEditor.showForCameraIds = {
        ...this.cameraMapEditor.showForCameraIds,
      };
    },
    displayPropertiesPopup({ category, id, data, annotationObj }: any) {
      // let position = JSON.parse(data.gps_coordinates as string).coordinates;
      // let latlng = new google.maps.LatLng(position[1], position[0]);
      // this.map?.setCenter(latlng);

      // Prevent users who do not have access level dashboard monitoring (technician)
      // from seeing the popup details of any item except for cameras
      if (!this.hasAccessLevelDashboardMonitoring && category != "camera") {
        return;
      }

      console.log("Showing props for", category, id);
      this.unSelectCurrent();
      this.activeTool = category;
      this.popup.data = data;
      this.selectedCamera = data;
      this.popup.show = true;
      this.popup.annotationObj = annotationObj;
      this.popup.category = category;
      if (category == "spot") {
        let saved_spot = this.savedSpots.find((s) => s.spot_id == data.id);
        if (saved_spot) {
          this.popup.savedSpot = saved_spot;
        }
        let saved_location = this.savedLocations.find((s) =>
          s.blocked_spots.includes(data.id)
        );
        if (saved_location) {
          this.popup.savedLocation = saved_location;
        }
      }
    },

    // check if any saved parking location and draw a marker for it
    drawNonSpotSavedParking() {
      if (this.savedLocations && this.savedLocations.length > 0) {
        for (let saved_location of this.savedLocations) {
          let non_spot_marker = new google.maps.Marker({
            clickable: this.interactive,
            draggable: false,
            visible: true,
            icon: {
              path: mdiCar,
              fillColor: "#454545",
              strokeColor: "#808080",
              fillOpacity: 1.0,
              scale: 1.3,
              anchor: new google.maps.Point(
                FIXED_MARKER_OPTIONS.iconAnchor.x,
                FIXED_MARKER_OPTIONS.iconAnchor.y
              ),
            },
            title: "Spot Blocking Car",
            map: this.map,
          });
          if (this.interactive) {
            non_spot_marker.addListener(
              "click",
              (event: google.maps.MapMouseEvent) => {
                this.savedCarLocation = saved_location;
                this.showSavedCarLocationDetails = true;
              }
            );
          }
          const non_spot_coords = JSON.parse(
            saved_location.gps_coordinates as string
          ).coordinates;
          non_spot_marker?.setPosition(
            new google.maps.LatLng(non_spot_coords[1], non_spot_coords[0])
          );
          this.annotations.blockingCars.push(non_spot_marker);
        }
      }
    },

    async clearSavedParkingLocation(saveId: number) {
      if (saveId) {
        let clearedParking = await api.clearBlockedParking(this.lotId, saveId);
        if (clearedParking) {
          this.$dialog.message.info("Successfully cleared the Parking.", {
            position: "top-right",
            timeout: 3000,
          });
        } else {
          this.$dialog.message.error(
            "Error, unable to clear the Parking. Please try again later.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        }
      }
      this.closeSavedCarLocationDetailsDialog();
    },

    closeSavedCarLocationDetailsDialog() {
      this.savedCarLocation = null;
      this.showSavedCarLocationDetails = false;
    },

    /**
     * Listens ctrl+z event for undo
     */
    keydownHandler(event: KeyboardEvent) {
      if (event.ctrlKey && event.code === "KeyZ") {
        this.undoHandler();
      }
      // Add event handler for left and right arrow keys
      if (this.viewPastSpotHistory) {
        if (event.code === "ArrowLeft") {
          this.spotHistory.timeNumber -= 1;
        }
        if (event.code === "ArrowRight") {
          this.spotHistory.timeNumber += 1;
        }
      }
    },
    undoHandler() {
      console.log("Undo Clicked");
      const actionObject = this.undoList.pop();
      if (actionObject) {
        switch (actionObject.action) {
          case "undo_spot":
            if (this.current.poly) {
              this.current.poly.getPath().pop();
              if (this.current.poly.getPath().getLength() == 0) {
                if (
                  this.undoList[this.undoList.length - 1].action ==
                  "undo_spot_poly"
                ) {
                  this.undoHandler();
                }
              }
            }
            break;
          case "undo_spot_poly":
            if (this.annotations.spots) {
              let poly = this.annotations.spots.pop();
              if (poly) {
                google.maps.event.trigger(poly, "click");
              }
              this.activeTool = "spot";
            }
            break;
          case "undo_lane_poly":
            if (
              this.annotations.spots &&
              actionObject.args.spotCount &&
              actionObject.args.lanePoints
            ) {
              for (let i = 0; i < actionObject.args.spotCount; i++) {
                let poly = this.annotations.spots.pop();
                this.undoList.pop();
                if (poly) {
                  poly.setMap(null);
                }
              }
              let lane = this.annotations.lanes.pop();
              if (lane) lane.setMap(null);
              this.toolChanged("lane");
              for (
                let i = 0;
                i < actionObject.args.lanePoints.length - 1;
                i++
              ) {
                let [lng, lat] = actionObject.args.lanePoints[i];
                let latLng = new google.maps.LatLng(lat, lng);
                this.addLinePoint(latLng, true);
              }
              this.activeTool = "lane";
            }
            break;
          case "undo_zone_poly":
            if (actionObject.args.line) {
              this.activeTool = "zone";
              const line = this.annotations.zones.pop();
              if (line) this.current.line = line;
            }
            break;
          case "undo_special_area_poly":
            if (actionObject.args.line) {
              this.activeTool = "special_area";
              const line = this.annotations.specialAreas.pop();
              if (line) this.current.line = line;
            }
            break;
          case "undo_lot_poly":
            if (actionObject.args.lanePoints) {
              this.activeTool = "lot";
              let poly = actionObject.args.line;
              if (poly) {
                poly.setMap(null);
                this.annotations.lot = null;
              }
              while (
                this.undoList[this.undoList.length - 1].action == "undo_lot"
              ) {
                this.undoList.pop();
              }
              this.toolChanged("lot");
              for (
                let i = 0;
                i < actionObject.args.lanePoints.length - 1;
                i++
              ) {
                let [lng, lat] = actionObject.args.lanePoints[i];
                let latLng = new google.maps.LatLng(lat, lng);
                this.addLinePoint(latLng, true);
              }
            }
            break;
          case "undo_lot":
            if (this.current.line) {
              this.current.line.getPath().pop();
              if (
                this.undoList[this.undoList.length - 1].action ==
                  "undo_zone_poly" ||
                this.undoList[this.undoList.length - 1].action ==
                  "undo_lot_poly"
              ) {
                this.undoHandler();
              }
            }
            break;
          case "undo_driveway":
            if (actionObject.args.line) {
              this.current.line = actionObject.args.line as Driveway;
            }
            break;
          case "undo_camera":
            if (actionObject.args.marker) {
              let current_latLng = actionObject.args.marker.getPosition();
              if (
                this.undoList.length > 0 &&
                this.undoList[this.undoList.length - 1].action ==
                  "undo_camera" &&
                current_latLng &&
                current_latLng !=
                  this.undoList[this.undoList.length - 1].args.latLng
              ) {
                actionObject.args.marker.setPosition(
                  this.undoList[this.undoList.length - 1].args.latLng
                );
              } else {
                actionObject.args.marker?.setMap(null);
                this.activeTool = "select";
              }
            }
            break;
          case "undo_display_board":
            if (actionObject.args.marker) {
              let current_latLng = actionObject.args.marker.getPosition();
              if (
                this.undoList.length > 0 &&
                this.undoList[this.undoList.length - 1].action ==
                  "undo_display_board" &&
                current_latLng &&
                current_latLng !=
                  this.undoList[this.undoList.length - 1].args.latLng
              ) {
                actionObject.args.marker.setPosition(
                  this.undoList[this.undoList.length - 1].args.latLng
                );
              } else {
                actionObject.args.marker?.setMap(null);
                this.activeTool = "select";
              }
            }
            break;
          case "undo_landmark":
            if (this.annotations.landmarks.length > 0) {
              if (actionObject.args.marker) {
                actionObject.args.marker.setMap(null);
                this.annotations.landmarks.pop();
              }
            }
            break;
          case "undo_spot_delete":
            if (actionObject.args.poly) {
              if (actionObject.args.poly.getPath().getLength() == 4) {
                this.toolChanged("spot");
                let laneLatLngs: google.maps.LatLng[] = actionObject.args.poly
                  .getPath()
                  .getArray();
                for (let latLng of laneLatLngs) {
                  if (latLng) this.addPolyPoint(latLng, "spot", 4, true);
                }
              }
            }
            break;
          case "undo_lane_delete":
            if (actionObject.args.line) {
              this.toolChanged("lane");
              let laneLatLngs: google.maps.LatLng[] = actionObject.args.line
                .getPath()
                .getArray();
              for (let latLng of laneLatLngs) {
                if (latLng) this.addLinePoint(latLng);
              }
            }
            break;
          case "undo_zone_delete":
            if (actionObject.args.line) {
              this.toolChanged("zone");
              let laneLatLngs: google.maps.LatLng[] = actionObject.args.line
                .getPath()
                .getArray();
              for (let latLng of laneLatLngs) {
                if (latLng) this.addLinePoint(latLng);
              }
              if (
                (actionObject.args.line as Zone).category == "parking_structure"
              ) {
                let shapes = (actionObject.args.line as Zone).shapes || [];
                for (let shape of shapes) {
                  shape.setMap(this.map);
                }
              }
            }
            break;
          case "undo_special_area_delete":
            if (actionObject.args.line) {
              this.toolChanged("special_area");
              let laneLatLngs: google.maps.LatLng[] = actionObject.args.line
                .getPath()
                .getArray();
              for (let latLng of laneLatLngs) {
                if (latLng) this.addLinePoint(latLng);
              }
            }
            break;
          case "undo_lot_delete":
            if (actionObject.args.line) {
              this.toolChanged("lot");
              let laneLatLngs: google.maps.LatLng[] = actionObject.args.line
                .getPath()
                .getArray();
              for (let latLng of laneLatLngs) {
                if (latLng) this.addLinePoint(latLng);
              }
            }
            break;
          case "undo_driveway_delete":
            if (actionObject.args.line) {
              this.toolChanged("driveway");
              let laneLatLngs: google.maps.LatLng[] = actionObject.args.line
                .getPath()
                .getArray();
              for (let latLng of laneLatLngs) {
                if (latLng) this.addLinePoint(latLng);
              }
            }
            break;
          default:
            break;
        }
      }
    },
    openEmbedDialog() {
      this.showEmbedLinkDialog = true;
      navigator.clipboard.writeText(this.mapEmbedCode);
    },
    showCameraMapEditor(cameraId: number) {
      this.cameraMapEditor.showForCameraIds[cameraId] = true;
    },
    cameraMapDetailsWindowResized(newWindowSize: Record<string, any>) {
      if (newWindowSize.isMaximized) {
        this.cameraMapEditor.windowSize.minimizedWidth =
          this.cameraMapEditor.windowSize.width;
        this.cameraMapEditor.windowSize.minimizedHeight =
          this.cameraMapEditor.windowSize.height;
        this.cameraMapEditor.windowSize.width = newWindowSize.width;
        this.cameraMapEditor.windowSize.height = newWindowSize.height;
      } else {
        this.cameraMapEditor.windowSize.width =
          this.cameraMapEditor.windowSize.minimizedWidth;
        this.cameraMapEditor.windowSize.height =
          this.cameraMapEditor.windowSize.minimizedHeight;
      }
    },
    // convert 24 hour time to 12 hour time format
    timeConvert(time: string) {
      return new Date("1970-01-01T" + time + "Z").toLocaleTimeString("en-US", {
        timeZone: "UTC",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      });
    },
    spotHistoryDateSelected() {
      if (this.spotHistory.date == this.todaysDate) {
        const now = new Date();
        const hours = now.getHours();
        const mins = now.getMinutes();
        const totalMinutes = hours * 60 + mins;
        this.spotHistory.maxTimeNumber = totalMinutes;
        this.spotHistory.timeNumber = totalMinutes;
      } else {
        this.spotHistory.maxTimeNumber = 1439;
        this.spotHistory.timeNumber = 720;
      }
    },
    formatLabel(minutes: number) {
      let hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      let ampm = "AM";
      if (hours >= 12) {
        ampm = "PM";
        if (hours > 12) {
          hours -= 12;
        }
      }
      if (hours === 0) {
        hours = 12;
      }
      return `${String(hours).padStart(2, "0")} ${ampm}`;
    },
    convertTo24HourFormat(timeString: string) {
      let [time, period] = timeString.split(" ");
      let [hours, minutes] = time.split(":").map(Number);
      if (period === "PM" && hours !== 12) {
        // Convert PM hours to 24-hour format (except 12 PM)
        hours += 12;
      } else if (period === "AM" && hours === 12) {
        hours = 0;
      }
      const formattedTime = `${String(hours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}`;
      return formattedTime;
    },

    formatTime(seconds: number) {
      let hours = Math.floor(seconds / 3600);
      let minutes = Math.floor((seconds % 3600) / 60);
      let remainingSeconds = seconds % 60;
      let result = "";
      if (hours > 0) {
        result += hours + " Hours ";
      }
      if (minutes > 0) {
        result += minutes + " Minutes ";
      }
      if (remainingSeconds > 0 || result === "") {
        result += remainingSeconds + " Seconds";
      }
      return result.trim();
    },

    getCustomSvgPath(icon_name: string) {
      if (icon_name === "sg-compact") {
        return compact;
      } else if (icon_name === "sg-ev") {
        return ev;
      } else if (icon_name === "sg-display-board") {
        return displayBoard;
      }
      return mdiTagOutline;
    },

    closeSpotSliderDetailsDialog() {
      this.spotSlider.show = false;
      this.spotSlider.spotId = null;
      this.spotSlider.cameraId = null;
    },

    closeZoneSliderDetailsDialog() {
      this.zoneSlider.show = false;
      this.zoneSlider.zoneId = null;
    },

    openSpotSliderDetailsDialog(spotId: number, cameraId: number) {
      this.spotSlider.show = true;
      this.spotSlider.spotId = spotId;
      this.spotSlider.cameraId = cameraId;
      this.popup.spotSliderId = null;
    },

    openSpotPropertiesForm(spotId: number) {
      this.spotSlider.show = false;

      let spot = this.parkingLot?.parking_spots.find((s) => s.id === spotId);
      if (spot) {
        this.popup.spotSliderId = spotId;
        this.displayPropertiesPopup({
          category: "spot",
          id: spot.id,
          data: spot,
        });
      }
    },

    openZonePropertiesForm(zoneId: number) {
      console.log("Opening zone properties form", zoneId);
      this.zoneSlider.show = false;

      let zone = this.parkingLot?.parking_zones.find((s) => s.id === zoneId);
      if (zone) {
        this.displayPropertiesPopup({
          category: "zone",
          id: zone.id,
          data: zone,
        });
      }
    },
    formatTimestamp(timestamp: string) {
      if (timestamp) {
        let dayObj = dayjs(timestamp);
        const selectedTimezone = localStorage.getItem("selected_timezone");
        if (selectedTimezone) {
          dayObj = dayjs(timestamp).tz(selectedTimezone);
        }
        const selectedTimeFormat = localStorage.getItem("time_format_option");
        let formattedDate = dayObj.format("ddd, MMM D, YYYY h:mm:ss A");
        if (selectedTimeFormat === "24_hr") {
          formattedDate = dayObj.format("ddd, MMM D, YYYY HH:mm:ss");
        }

        let tz_short = "";
        if (selectedTimezone) {
          const locale = navigator.language ? navigator.language : "en-US";
          let timezone_short = new Intl.DateTimeFormat(locale, {
            timeZone: selectedTimezone,
            timeZoneName: "long",
          })
            .formatToParts(new Date())
            .find((part) => part.type === "timeZoneName")?.value;
          if (timezone_short) {
            // abbreviate text
            timezone_short = timezone_short
              .split(" ")
              .map((word) => word[0])
              .join("");
            tz_short = `${timezone_short}`;
          } else {
            tz_short = dayjs().tz(selectedTimezone).format("z");
          }
        }

        return formattedDate + ` ${tz_short}`;
      }
      return "";
    },
  },

  computed: {
    ...mapGetters("user", [
      "isSuperAdmin",
      "isDeveloper",
      "hasAccessLevelDashboardMonitoring",
      "hasAccessLevelCameraEditing",
    ]),
    cameraNames() {
      return this.cameras.map((c) => ({ label: c.name, value: c.id }));
    },
    digitalBoardsNames() {
      return this.digitalBoards.map((c) => ({ label: c.name, value: c.id }));
    },
    isEditMode(): boolean {
      return this.mode === EditorModes.edit;
    },
    isViewMode(): boolean {
      return this.mode === EditorModes.view;
    },
    selectionLastUpdated(): string {
      let selectedSpotData: ParkingSpot | null = null;
      if (this.current.poly && this.current.poly.id && this.parkingLot) {
        for (let spot of this.parkingLot.parking_spots) {
          if (spot.id === this.current.poly.id) {
            selectedSpotData = spot;
          }
        }
      }
      if (selectedSpotData) {
        return new Date(selectedSpotData.updated_at).toLocaleString();
      } else {
        return "";
      }
    },
    showFixedSizeMarkers() {
      const zoom = this.mapZoomLevel || 0;
      return zoom >= FIXED_MARKER_OPTIONS.visibilityMinZoomLevel;
    },
    freeSpotsCount() {
      let freeCount = 0;
      if (
        this.parkingLot &&
        this.parkingLot.parking_zones &&
        this.parkingLot.parking_spots
      ) {
        for (let zone of this.parkingLot.parking_zones) {
          if (zone.is_untracked) {
            if (
              !zone.is_num_free_untracked_spots_automatic &&
              zone.num_free_untracked_spots
            ) {
              freeCount += zone.num_free_untracked_spots;
            } else if (
              zone.is_num_free_untracked_spots_automatic &&
              zone.num_free_parking_spots
            ) {
              freeCount += zone.num_free_parking_spots;
            }
          }
        }
        freeCount += this.parkingLot.parking_spots.filter(
          (spot) => spot.current_status == "free" && !spot.is_status_unknown
        ).length;
      }
      return freeCount;
    },
    totalSpotsCount() {
      let totalCount = 0;
      if (this.parkingLot && this.parkingLot.parking_zones) {
        for (let zone of this.parkingLot.parking_zones) {
          if (zone.is_untracked && zone.num_total_untracked_spots) {
            totalCount += zone.num_total_untracked_spots;
          }
        }
        totalCount += this.parkingLot.parking_spots.length;
      }
      return totalCount;
    },
    mapEmbedCode() {
      if (this.parkingLot) {
        const routeData = this.$router.resolve({
          name: "ShowMap",
          params: { lotId: String(this.parkingLot.id) },
        });
        const routeUrl = routeData.href;
        const pageUrl = new URL(routeUrl, window.location.origin).href;

        return `
          <iframe id="spotgenius-map"
              title="${this.parkingLot.name}"
              width="320"
              height="420"
              scrolling="no"
              src="${pageUrl}">
          </iframe>
        `;
      }
      return "";
    },
    unsavedChangesExist() {
      return this.undoList.length > 0;
    },
    showAnprFields(): boolean {
      if (this.isSuperAdmin && this.parkingLot?.is_anpr_feature_enabled) {
        return true;
      }

      // For other user types
      if (
        this.parkingLot?.is_anpr_feature_enabled &&
        this.parkingLot?.is_anpr_feature_visible_to_customers
      ) {
        return true;
      }

      return false;
    },
    is_iOS() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    },
    allSpotNames() {
      if (this.parkingLot && this.parkingLot.parking_spots) {
        return this.parkingLot.parking_spots.map(
          (spot: ParkingSpot) => spot.name
        );
      }
      return [];
    },
    todaysDate() {
      return getTodaysDate();
    },
    allLastUpdated() {
      let spotsTimestamps = [];
      if (this.parkingLot && this.parkingLot.parking_spots) {
        for (let spot of this.parkingLot.parking_spots) {
          if (spot.updated_at) {
            spotsTimestamps.push(new Date(spot.updated_at));
          }
        }
      }
      if (spotsTimestamps.length > 0) {
        let mostRecentTimestamp: Date = spotsTimestamps.reduce((a, b) =>
          a > b ? a : b
        );
        return mostRecentTimestamp;
      } else {
        return "";
      }
    },
    spotHistoryTitle() {
      if (this.spotHistory.date) {
        const dateTimeString = `${this.spotHistory.date} ${this.spotHistory.time}`;
        const dateTime = new Date(dateTimeString);

        const daysOfWeek = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];

        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        const dayOfWeek = daysOfWeek[dateTime.getDay()];
        const month = months[dateTime.getMonth()];
        const day = dateTime.getDate();
        const year = dateTime.getFullYear();
        const hours = dateTime.getHours();
        const minutes = dateTime.getMinutes().toString().padStart(2, "0");
        const ampm = hours >= 12 ? "PM" : "AM";

        const formattedDate = `${dayOfWeek}, ${month} ${day}, ${year} at ${
          hours % 12 || 12
        }:${minutes} ${ampm}`;

        const selectedTimezone = localStorage.getItem("selected_timezone");
        let tz_short = "";
        if (selectedTimezone) {
          const locale = navigator.language ? navigator.language : "en-US";
          let timezone_short = new Intl.DateTimeFormat(locale, {
            timeZone: selectedTimezone,
            timeZoneName: "long",
          })
            .formatToParts(new Date())
            .find((part) => part.type === "timeZoneName")?.value;
          if (timezone_short) {
            // abbreviate text
            timezone_short = timezone_short
              .split(" ")
              .map((word) => word[0])
              .join("");
            tz_short = `(${timezone_short})`;
          } else {
            tz_short = dayjs().tz(selectedTimezone).format("(z)");
          }
        }

        return formattedDate + " " + tz_short;
      }
      return "";
    },
    labelHours() {
      let len = Math.ceil(this.spotHistory.maxTimeNumber / 288);
      if (len <= 0) len = 1;
      return Array.from({ length: len }, (_, index) => index * (len + 1));
    },
    currentRoute() {
      return this.$route.name;
    },
  },

  watch: {
    /**
     * Set the previous value of activeTool to last.activeTool whenever it changes
     * and save the annotation that the user was creating.
     */
    activeTool(activeTool, lastActiveTool) {
      this.last.activeTool = lastActiveTool;

      // Use crosshairs cursor when using one of the annotation tools
      if (this.map) {
        if (
          ["spot", "driveway", "lane", "zone", "parking_structure"].includes(
            activeTool
          )
        ) {
          this.map.setOptions({ draggableCursor: "crosshair" });
        } else {
          this.map.setOptions({ draggableCursor: "auto" });
        }
      }

      // Try saving the currently drawn annotation when the user changes the tool
      if (lastActiveTool) {
        this.saveCurrentAnnotation(lastActiveTool, true);
      }
    },
    showSpotNames() {
      this.clearMapAnnotations();
      this.drawParkingLotAnnotations();
    },
    showSpotIds() {
      this.clearMapAnnotations();
      this.drawParkingLotAnnotations();
    },
    showSpotCameraIds() {
      this.clearMapAnnotations();
      this.drawParkingLotAnnotations();
    },
    showDisplayBoards() {
      this.clearMapAnnotations();
      this.drawParkingLotAnnotations();
    },
    showZoneNames() {
      this.clearMapAnnotations();
      this.drawParkingLotAnnotations();
    },
    showCameraFOV() {
      this.clearMapAnnotations();
      this.drawParkingLotAnnotations();
    },
    showCameraIcons() {
      this.clearMapAnnotations();
      this.drawParkingLotAnnotations();
    },
    showLicensePlates() {
      this.clearMapAnnotations();
      this.drawParkingLotAnnotations();
    },
    async viewPastSpotHistory(oldVal, newVal) {
      if (newVal) {
        this.$emit("spot-history-clear-updates-filters");
        this.parkingLot = await api.getParkingLot(this.lotId);
        this.clearMapAnnotations();
        this.drawParkingLotAnnotations();
      } else {
        this.spotHistory.date = this.todaysDate;
        const now = new Date();
        const hours = now.getHours();
        const mins = now.getMinutes();
        const totalMinutes = hours * 60 + mins;
        this.spotHistory.maxTimeNumber = totalMinutes;
        this.spotHistory.timeNumber = totalMinutes;
        for (let marker of this.spotHistory.anprMarkers) {
          marker.setVisible(false);
          marker.setMap(null);
        }
        for (let marker of this.spotHistory.timelineAnprMarkers) {
          marker.setVisible(false);
          marker.setMap(null);
        }
        this.spotHistory.timelineAnprMarkers = [];
        this.getSpotHistory();
      }
    },

    /**
     * Watch the filter list and fade out spots whose IDs is not in the list.
     */
    filterSpotIds(spotIdsToFilter: Array<number>) {
      if (spotIdsToFilter == null) {
        for (let spotAnno of this.annotations.spots) {
          spotAnno.setOptions({
            fillOpacity: 0.5,
            strokeOpacity: 0.5,
          });
        }
        return;
      }
      if (spotIdsToFilter.length == 0) {
        for (let spotAnno of this.annotations.spots) {
          spotAnno.setOptions({
            fillOpacity: 0.1,
            strokeOpacity: 0.1,
          });
        }
      } else {
        for (let spotAnno of this.annotations.spots) {
          if (spotAnno.id && !spotIdsToFilter.includes(spotAnno.id)) {
            spotAnno.setOptions({
              fillOpacity: 0.1,
              strokeOpacity: 0.1,
            });
          } else {
            spotAnno.setOptions({
              fillOpacity: 0.5,
              strokeOpacity: 0.5,
            });
          }
        }
      }
    },
    unsavedChangesExist() {
      this.$emit("unsaved-changes", this.unsavedChangesExist);
    },
    // check for savedSpots changes
    savedSpots(oldVal, newVal) {
      if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.clearMapAnnotations();
        this.drawParkingLotAnnotations();
      }
    },
    // check for savedLocations changes
    savedLocations(oldVal, newVal) {
      if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.clearMapAnnotations();
        this.drawParkingLotAnnotations();
        this.drawNonSpotSavedParking();
      }
    },
    "spotHistory.timeNumber"(oldVal, newVal) {
      const hours = Math.floor(oldVal / 60);
      const mins = oldVal % 60;

      const amOrPm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;

      this.spotHistory.time = `${String(formattedHours).padStart(
        2,
        ""
      )}:${String(mins).padStart(2, "0")} ${amOrPm}`;
      for (let marker of this.spotHistory.timelineAnprMarkers) {
        marker.setVisible(false);
        marker.setMap(null);
      }
      this.spotHistory.timelineAnprMarkers = [];
      this.getSpotHistory();
    },
  },

  beforeDestroy() {
    localStorage.removeItem("mapType");
  },
});
