// import dayjs lib along with its plugins and load them
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(duration);

/**
 * Convert crontab (m h * * *) (utc) to HH:mm (local) format.
 */
export function convertCrontabToTime(crontabStr: string | null): string {
  if (crontabStr == null) {
    return "";
  }
  const parts = crontabStr.split(" ");
  const dayObj = dayjs()
    .minute(Number(parts[0]))
    .hour(Number(parts[1]))
    .utc(true)
    .local(); // convert utc to local
  if (dayObj.isValid()) {
    return dayObj.format("HH:mm");
  } else {
    return "";
  }
}

/**
 * Convert HH:mm (local) time to crontab (m h * * *) (utc) format.
 */
export function convertTimeToCrontab(time: string, dayOfWeek = "*"): string {
  const dayObj = dayjs(time, "HH:mm", true).utc(); // convert local to utc
  if (dayObj.isValid()) {
    const minute = dayObj.minute();
    const hour = dayObj.hour();
    return `${minute} ${hour} * * ${dayOfWeek}`;
  } else {
    return "";
  }
}

/**
 * Check if all crontab times are equal.
 */
export function checkIfAllWeekCrontabTimesAreEqual(
  startTimeCrontabs: Array<string | null> | null,
  endTimeCrontabs: Array<string | null> | null
): boolean {
  if (startTimeCrontabs != null) {
    for (const crontab of startTimeCrontabs) {
      if (crontab !== startTimeCrontabs[0]) {
        return false;
      }
    }
  }
  if (endTimeCrontabs != null) {
    for (const crontab of endTimeCrontabs) {
      if (crontab !== endTimeCrontabs[0]) {
        return false;
      }
    }
  }
  return true;
}

export function checkIfAllWeekDaysCrontabTimesAreEqual(
  startTimeCrontabs: Array<string | null> | null,
  endTimeCrontabs: Array<string | null> | null
): boolean {
  if (startTimeCrontabs != null) {
    for (const crontab of startTimeCrontabs) {
      if (
        crontab != null &&
        startTimeCrontabs[0] != null &&
        crontab.slice(0, -1) !== startTimeCrontabs[0].slice(0, -1)
      ) {
        return false;
      }
    }
  }
  if (endTimeCrontabs != null) {
    for (const crontab of endTimeCrontabs) {
      if (
        crontab != null &&
        endTimeCrontabs[0] != null &&
        crontab.slice(0, -1) !== endTimeCrontabs[0].slice(0, -1)
      ) {
        return false;
      }
    }
  }
  return true;
}

export function isValidTimeHHmm(s: string): boolean {
  return dayjs(s, "HH:mm", true).isValid();
}

export function extractHHmmFromHHmmss(s: string | null): string | null {
  if (s == null) {
    return null;
  }
  return s.split(":").slice(0, 2).join(":");
}

export function convertHHmmUtcToLocal(time: string | null): string | null {
  if (time == null) {
    return null;
  }
  let dayObj = dayjs.utc(time, "HH:mm", true).local(); // convert utc to local

  const selectedTimezone = localStorage.getItem("selected_timezone");
  if (selectedTimezone) {
    dayObj = dayjs.utc(time, "HH:mm", true).tz(selectedTimezone);
  }

  if (dayObj.isValid()) {
    return dayObj.format("HH:mm");
  } else {
    return null;
  }
}

export function convertHHmmLocalToUtc(time: string | null): string | null {
  if (time == null) {
    return null;
  }
  const localTime = dayjs(time, "HH:mm", true);
  let dayObj = localTime.utc(); // convert local to utc

  const selectedTimezone = localStorage.getItem("selected_timezone");
  if (selectedTimezone) {
    dayObj = localTime.tz(selectedTimezone, true).utc();
  }

  if (dayObj.isValid()) {
    return dayObj.format("HH:mm");
  } else {
    return null;
  }
}

export function convert24HourToAmPm(time24hour: string | null): string | null {
  const dayObj = dayjs(time24hour, "HH:mm");
  if (!dayObj.isValid()) {
    return null;
  }
  return dayObj.format("hh:mm A");
}

export function formatDurationMinutes(durationMinutes: number): string {
  durationMinutes = Math.abs(durationMinutes);
  return dayjs.duration(durationMinutes, "minutes").humanize();
}

export function formatDurationSeconds(durationSeconds: number): string {
  if (durationSeconds == null) {
    return "";
  }
  durationSeconds = Math.abs(durationSeconds);
  const minutes = Math.ceil((durationSeconds % 3600) / 60);
  const hours = Math.floor(durationSeconds / 3600);

  // If not hours needed, return only minutes
  if (hours === 0) {
    if (minutes === 1) {
      return `${minutes} minute`;
    } else {
      return `${minutes} minutes`;
    }
  }

  // If hours needed, then construct "x hours and y minutes" format text
  let text = "";
  if (hours === 1) {
    text = text.concat(`${hours} hour`);
  } else {
    text = text.concat(`${hours} hours`);
  }
  if (minutes === 1) {
    text = text.concat(` and ${minutes} minute`);
  } else if (minutes > 1) {
    text = text.concat(` and ${minutes} minutes`);
  }
  return text;
}

export function formatDurationShowSeconds(durationSeconds: number): string {
  if (durationSeconds == null) {
    return "";
  }
  durationSeconds = Math.abs(durationSeconds);
  const seconds = Math.round(durationSeconds % 60); // Round seconds to the nearest whole number
  const remainingSeconds = Math.abs(durationSeconds - seconds);
  const minutes = Math.floor((remainingSeconds % 3600) / 60);
  const hours = Math.floor(remainingSeconds / 3600);

  // If not hours needed, return only minutes and seconds
  if (hours === 0) {
    let text = "";
    if (minutes > 0) {
      text = `${minutes} minute${minutes === 1 ? "" : "s"}`;
    }
    if (seconds > 0) {
      if (text.length > 0) {
        text += " and ";
      }
      text += `${seconds} second${seconds === 1 ? "" : "s"}`;
    }
    return text;
  }

  // If hours needed, then construct "x hours, y minutes, and z seconds" format text
  let text = `${hours} hour${hours === 1 ? "" : "s"}`;
  if (minutes > 0 || seconds > 0) {
    text += ", ";
    if (minutes > 0) {
      text += `${minutes} minute${minutes === 1 ? "" : "s"}`;
    }
    if (seconds > 0) {
      if (minutes > 0) {
        text += " and ";
      }
      text += `${seconds} second${seconds === 1 ? "" : "s"}`;
    }
  }
  return text;
}

export function getTodaysDate() {
  const selected_timezone = localStorage.getItem("selected_timezone");
  if (selected_timezone) {
    return dayjs().tz(selected_timezone).format("YYYY-MM-DD");
  }
  return dayjs().format("YYYY-MM-DD");
}

export function formatDurationSecondsShort(
  startTime: Date,
  endTime = new Date()
) {
  let timeDiff = endTime.getTime() - startTime.getTime();
  timeDiff = timeDiff / 1000;
  const seconds = Math.floor(timeDiff % 60); //ignoring uncompconste seconds (floor)
  const secondsAsString = seconds < 10 ? "0" + seconds : seconds + "";
  timeDiff = Math.floor(timeDiff / 60);
  const minutes = timeDiff % 60; //no need to floor possible incompconste minutes, becase they've been handled as seconds
  const minutesAsString = minutes < 10 ? "0" + minutes : minutes + "";
  timeDiff = Math.floor(timeDiff / 60);
  const hours = timeDiff % 24; //no need to floor possible incompconste hours, becase they've been handled as seconds
  timeDiff = Math.floor(timeDiff / 24);
  const days = timeDiff;
  const totalHours = hours + days * 24; // add days to hours
  const totalHoursAsString =
    totalHours < 10 ? "0" + totalHours : totalHours + "";

  if (days > 0) {
    if (days > 30 && days / 30 > 1) {
      if (days / 30 > 12) {
        return `${Math.round(days / 30 / 12)}Y`;
      }
      return `${Math.round(days / 30)} ${days / 30 > 1 ? "M" : "M"}${
        days % 30
      } ${days % 30 > 1 ? "d" : "d"}`;
    }
    return `${days}${days > 1 ? "d" : "d"}`;
  } else if (totalHoursAsString !== "00") {
    return `${totalHours}${totalHours > 1 ? "h" : "h"}`;
  } else if (minutesAsString !== "00") {
    return `${minutes}${minutes > 1 ? "m" : "m"}`;
  } else if (secondsAsString !== "00") {
    return `${seconds}${seconds > 1 ? "s" : "s"}`;
  }
  return "1s";
}

export function getElapsedTimeShort(startTime: Date, aria = false) {
  const endTime = new Date();
  let timeDiff = endTime.getTime() - startTime.getTime();
  timeDiff = timeDiff / 1000;
  const seconds = Math.floor(timeDiff % 60); //ignoring uncompconste seconds (floor)
  const secondsAsString = seconds < 10 ? "0" + seconds : seconds + "";
  timeDiff = Math.floor(timeDiff / 60);
  const minutes = timeDiff % 60; //no need to floor possible incompconste minutes, becase they've been handled as seconds
  const minutesAsString = minutes < 10 ? "0" + minutes : minutes + "";
  timeDiff = Math.floor(timeDiff / 60);
  const hours = timeDiff % 24; //no need to floor possible incompconste hours, becase they've been handled as seconds
  timeDiff = Math.floor(timeDiff / 24);
  const days = timeDiff;
  const totalHours = hours + days * 24; // add days to hours
  const totalHoursAsString =
    totalHours < 10 ? "0" + totalHours : totalHours + "";

  if (days > 0) {
    if (days > 30 && days / 30 > 1) {
      if (days / 30 > 12) {
        return `${Math.round(days / 30 / 12)}${aria ? "Year" : "Y"}`;
      }
      return `${Math.round(days / 30)} ${
        days / 30 > 1 ? (aria ? "Months" : "M") : aria ? "Month" : "M"
      }${days % 30} ${
        days % 30 > 1 ? (aria ? " days" : "d") : aria ? " day" : "d"
      }`;
    }
    return `${days}${days > 1 ? (aria ? " days" : "d") : aria ? " day" : "d"}`;
  } else if (totalHoursAsString !== "00") {
    return `${totalHours}${
      totalHours > 1 ? (aria ? " hours" : "h") : aria ? " hour" : "h"
    }`;
  } else if (minutesAsString !== "00") {
    return `${minutes}${
      minutes > 1 ? (aria ? " minutes" : "m") : aria ? " minute" : "m"
    }`;
  } else if (secondsAsString !== "00") {
    return `${seconds}${
      seconds > 1 ? (aria ? " seconds" : "s") : aria ? " second" : "s"
    }`;
  }
  return aria ? "1 second" : "1s";
}
