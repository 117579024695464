
















































































































































































import Vue from "vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import api from "@/api/api";
import { AllTimezones } from "@/api/models";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

export default Vue.extend({
  name: "DateTimePreferences",

  props: {
    needsInit: Boolean,
  },

  data: () => ({
    isLoading: false,

    groupedTimeZones: [] as Array<AllTimezones>,
    filteredTimezones: [] as Array<AllTimezones>,

    selectedTimezone: null as AllTimezones | null,

    timezoneOption: "my_timezone" as string,
    timeFormatOption: "12_hr" as string,
    currentSelectedTimezone: "America/Chicago" as string | null,

    currentTime: dayjs(),
    autoCurrentTimeUpdater: null as number | null,
  }),

  async mounted() {
    this.isLoading = true;
    if (Intl.DateTimeFormat().resolvedOptions().timeZone) {
      this.currentSelectedTimezone =
        Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    await this.getAlltimezones();

    const timezone_option = localStorage.getItem("timezone_option");
    const selected_timezone = localStorage.getItem("selected_timezone");
    const time_format_option = localStorage.getItem("time_format_option");

    if (timezone_option) {
      this.timezoneOption = timezone_option;
    }
    if (this.timezoneOption != "my_timezone" && selected_timezone) {
      this.currentSelectedTimezone = selected_timezone;
    }
    if (
      this.timezoneOption === "custom_timezone" &&
      this.currentSelectedTimezone
    ) {
      const selected_timezone = this.groupedTimeZones.find(
        (tz) => tz.value === this.currentSelectedTimezone
      );
      if (selected_timezone) {
        this.selectedTimezone = selected_timezone;
        if (this.selectedTimezone.value) {
          this.currentSelectedTimezone = this.selectedTimezone.value;
        }
      }
    }
    if (time_format_option) {
      this.timeFormatOption = time_format_option;
    }

    this.autoCurrentTimeUpdater = setInterval(() => {
      this.currentTime = dayjs();
    }, 30000);
    this.isLoading = false;
  },

  beforeDestroy() {
    if (this.autoCurrentTimeUpdater) {
      clearInterval(this.autoCurrentTimeUpdater);
    }
  },

  methods: {
    async getAlltimezones() {
      const timezones = await api.getAllTimeZones();
      if (timezones) {
        this.groupedTimeZones = timezones;
        this.filteredTimezones = this.groupedTimeZones;
      }
    },
    searchingTimezone(timezone: string) {
      if (!timezone) {
        this.filteredTimezones = this.groupedTimeZones;
        return;
      }
      this.filteredTimezones = this.groupedTimeZones.filter((tz) => {
        if (tz && tz.props && tz.props.header) {
          return false;
        }
        if (!tz.title) {
          return false;
        }
        return tz.title.toLowerCase().includes(timezone.toLowerCase());
      });
    },
    setDashboardTimezone() {
      localStorage.setItem("timezone_option", this.timezoneOption);
      localStorage.setItem(
        "selected_timezone",
        this.currentSelectedTimezone?.toString() || ""
      );
      localStorage.setItem("time_format_option", this.timeFormatOption);

      this.$emit(
        "set-dashboard-timezone",
        this.timezoneOption,
        this.currentSelectedTimezone,
        this.timeFormatOption
      );

      setTimeout(() => {
        this.$router.go(0);
      }, 800);
    },
    setCurrentSelectedTimezone() {
      if (this.selectedTimezone && this.selectedTimezone.value) {
        this.currentSelectedTimezone = this.selectedTimezone.value;
      } else if (this.timezoneOption === "custom_timezone") {
        this.currentSelectedTimezone = null;
      }
    },
    timezoneOptionChanged() {
      if (this.timezoneOption === "my_timezone") {
        this.currentSelectedTimezone =
          Intl.DateTimeFormat().resolvedOptions().timeZone;
      } else if (this.timezoneOption === "custom_timezone") {
        this.currentSelectedTimezone = null;
        this.selectedTimezone = null;
      } else if (
        this.timezoneOption === "local_lot_timezone" &&
        this.currentSelectedTimezone == null
      ) {
        this.currentSelectedTimezone =
          Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
    },
  },

  computed: {
    currentSystemUTCOffset(): string {
      const offset_minutes = dayjs().utcOffset();
      const offset_hours = offset_minutes / 60;
      const sign = offset_hours >= 0 ? "+" : "-";
      return `UTC ${sign}${Math.abs(offset_hours)}`;
    },
    formattedDay(): string {
      return this.currentTime
        .format("ddd, h:mma")
        .replace(/am|pm/, (match) => match.toUpperCase());
    },
    currentTime12Hr() {
      if (!this.currentSelectedTimezone) {
        return "";
      }
      const current_time = this.currentTime.tz(this.currentSelectedTimezone);
      return current_time.format("(h:mm A)");
    },
    currentTime24Hr() {
      if (!this.currentSelectedTimezone) {
        return "";
      }
      const current_time = this.currentTime.tz(this.currentSelectedTimezone);
      return current_time.format("(HH:mm)");
    },
  },
});
