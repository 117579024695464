<template>
  <a
    href="#main-content"
    class="skip-to-content"
    @click="handleSkip"
    @keydown.enter="handleSkip"
  >
    Skip to main content
  </a>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "SkipToContent",
  methods: {
    handleSkip(event) {
      event.preventDefault();
      const mainContent = document.getElementById("main-content");
      if (mainContent) {
        mainContent.setAttribute("tabindex", "-1");
        mainContent.focus();
        mainContent.removeAttribute("tabindex");
      }
    },
  },
});
</script>

<style scoped>
.skip-to-content {
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  color: #fff;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: top 0.3s;
  opacity: 0;
}
.skip-to-content:focus {
  top: 0;
  opacity: 1;
  outline: none;
  z-index: 1000;
}
</style>
