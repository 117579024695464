
































































































































































































































































































































































































































































































































































import Vue from "vue";
import Konva from "konva";
import VueKonva from "vue-konva";

import { getConvexHullRoiForBboxes } from "@/libs/roiUtils";

import {
  TOOLS,
  BBOX_CATEGORY,
  BboxBasic,
  Bbox,
  POLYGON_CATEGORY,
  PolygonBasic,
  Polygon,
  LineBasic,
  Line,
} from "@/libs/commonCameraMapEditorTypes";

Vue.use(VueKonva);

const DEFAULT_SPOT_BBOX_FILL = "white";
const DEFAULT_SPOT_BBOX_OPACITY = 0.6;

interface UndoAction {
  execute: () => void;
  undo: () => void;
}
export default Vue.extend({
  name: "ImageAnnotator",

  props: {
    cameraId: {
      type: Number,
      required: false,
    },
    initBboxes: {
      required: false,
      type: Array as () => Array<BboxBasic>,
    },
    initPolygons: {
      required: false,
      type: Array as () => Array<PolygonBasic>,
    },
    initLines: {
      required: false,
      type: Array as () => Array<LineBasic>,
    },
    imageURL: {
      required: true,
      type: String,
    },
    needsInit: {
      required: true,
      type: Boolean,
    },
    showAnnotations: {
      type: Boolean,
      required: false,
      default: true,
    },
    isViewerMode: {
      required: false,
      default: false,
    },
    isEditMode: {
      required: false,
      default: true,
    },
    stageWidth: {
      required: false,
      default: 998,
    },
    stageHeight: {
      required: false,
      default: 500,
    },
    uniqueAnnotationLabels: {
      required: false,
      default: true,
    },
    pendingCameraMapUpdateAlertId: {
      required: false,
      default: null,
    },
    isFeatureFlexibleCameraMappingEnabled: {
      type: Boolean,
      default: false,
    },
    waitingForCameraToBeDisabled: {
      required: false,
      default: false,
    },
    isViewFrameMode: {
      required: false,
      default: false,
    },
    isViewFrameThumbnail: {
      required: false,
      default: false,
    },
    cameraMapWidth: {
      type: Number,
      required: false,
    },
    cameraMapHeight: {
      type: Number,
      required: false,
    },
  },

  data: () => ({
    isLoading: false,
    scale: 1,
    activeTool: TOOLS.SELECT as TOOLS,
    annoListVisible: null,
    stageConfig: {
      draggable: true,
    },
    transformerConfig: {
      rotateEnabled: false,
    },
    bboxes: [] as Array<Bbox>,
    deletedBboxes: [] as Array<number | null>,
    polygons: [] as Array<Polygon>,
    lines: [] as Array<Line>,

    doubleParkingRoiStretchDirection: {
      items: [
        { text: "UP", value: 90 },
        { text: "DOWN", value: 270 },
        { text: "LEFT", value: 0 },
        { text: "RIGHT", value: 180 },
      ],
      selectedValue: null as number | null,
    },

    imageObj: null as HTMLImageElement | null,
    selectedBboxId: null as string | null,
    selectedPolygonId: null as string | null,
    selectedLineId: null as string | null,
    isCreatingNewAnno: false,
    focusOpacityMode: false,
    tooltipLayer: {
      text: "",
      show: false,
      x: 0,
      y: 0,
    },
    originalImageWidth: 514,
    originalImageHeight: 300,
    stageHeightUpdated: 500,

    undoActions: [] as Array<UndoAction>,
    redoActions: [] as Array<UndoAction>,
  }),

  computed: {
    // Hashmaps to quickly get bbox object for every id
    bboxesIdHashMap(): Record<string, Bbox> {
      let hashMap = {} as Record<string, Bbox>;
      this.bboxes.forEach((bbox) => (hashMap[bbox.id] = bbox));
      return hashMap;
    },
    polygonsIdHashMap(): Record<string, Polygon> {
      let hashMap = {} as Record<string, Polygon>;
      this.polygons.forEach((poly) => (hashMap[poly.id] = poly));
      return hashMap;
    },
    linesIdHashMap(): Record<string, Line> {
      let hashMap = {} as Record<string, Line>;
      this.lines.forEach((line) => (hashMap[line.id] = line));
      return hashMap;
    },

    lineArrowsPerpendicular(): Array<Array<number>> {
      let arrows = [] as Array<Array<number>>;
      for (const line of this.lines) {
        if (line.points.length < 4) {
          arrows.push([]);
          continue;
        }
        const x1 = line.points[0];
        const y1 = line.points[1];
        const x2 = line.points[2];
        const y2 = line.points[3];

        const cx = Math.abs(x1 + x2) / 2;
        const cy = Math.abs(y1 + y2) / 2;

        const slope = (y2 - y1) / (x2 - x1);
        const perpSlope = -1 / slope;

        const x3 = cx;
        const y3 = cy;
        const k =
          ((y2 - y1) * (x3 - x1) - (x2 - x1) * (y3 - y1)) /
          (Math.pow(y2 - y1, 2) + Math.pow(x2 - x1, 2));
        const x4 = x3 - k * (y2 - y1);
        const y4 = y3 + k * (x2 - x1);

        arrows.push([
          // cx + perpSlope * 10,
          // cy + perpSlope * 10,
          // cx - perpSlope * 10,
          // cy - perpSlope * 10,
          //
          x4,
          y4,
          x3,
          y3,
          // cx, cy, cx + perpSlope * 10, cy + perpSlope * 10
        ]);
      }

      return arrows;
    },
  },

  mounted() {
    if (this.isViewerMode || this.isViewFrameThumbnail) {
      // disable events
      this.stageConfig.draggable = false;
    }
    if (this.isViewFrameMode || this.isViewFrameThumbnail) {
      this.stageHeightUpdated = this.stageHeight;
    }
    this.init();
  },

  methods: {
    init() {
      console.log("RELOADING IMAGE ANNOTATOR", this.imageURL);
      this.loadImageFromURL(this.imageURL);
      // const outputs = this.generateNBboxes(10); // for test
      // this.bboxes = this.createBboxes(outputs);
      this.bboxes = this.createBboxes(this.initBboxes);
      this.polygons = this.createPolygons(this.initPolygons);
      this.lines = this.createLines(this.initLines);
    },

    generateNBboxes(n: number): Array<BboxBasic> {
      // Generate n number of bboxes for testing
      let bboxes = [];
      for (let i = 0; i < n; i++) {
        bboxes.push({
          spotId: null,
          spotName: null,
          laneId: null,
          x: Math.random() * this.stageWidth,
          y: Math.random() * this.stageHeightUpdated,
          width: 300,
          height: 200,
          category: BBOX_CATEGORY.spot,
        });
      }
      return bboxes;
    },

    createBboxes(bboxes: Array<BboxBasic>): Array<Bbox> {
      return bboxes.map((bbox) => ({
        fill: Konva.Util.getRandomColor(),
        ...bbox,
        id: (window.crypto as any).randomUUID(),
        // fill: DEFAULT_SPOT_BBOX_FILL,
        opacity: DEFAULT_SPOT_BBOX_OPACITY,
        visible: true,
      }));
    },

    createPolygons(polygons: Array<PolygonBasic>): Array<Polygon> {
      return polygons.map((poly) => ({
        annoId: poly.annoId,
        annoName: poly.annoName,
        points: poly.points,
        category: poly.category,
        closed: true,
        id: (window.crypto as any).randomUUID(),
        fill: Konva.Util.getRandomColor(),
        opacity: DEFAULT_SPOT_BBOX_OPACITY,
        visible: true,
      }));
    },

    createLines(lines: Array<LineBasic>): Array<Line> {
      return lines.map((line) => ({
        annoId: line.annoId,
        annoName: line.annoName,
        points: line.points,
        category: line.category,
        id: (window.crypto as any).randomUUID(),
        stroke: Konva.Util.getRandomColor(),
        opacity: DEFAULT_SPOT_BBOX_OPACITY,
        visible: true,
      }));
    },

    loadImageFromURL(newImageURL: string | null, refreshed = false) {
      if (!newImageURL) {
        return;
      }
      if (!refreshed) {
        this.isLoading = true;
      }
      const imageObj = new Image();
      imageObj.src = newImageURL;
      console.log("Loading Annotator image from URL", imageObj.src);
      imageObj.onload = () => {
        this.imageObj = imageObj;
        this.originalImageWidth = imageObj.width;
        this.originalImageHeight = imageObj.height;

        if (this.cameraMapWidth && this.cameraMapHeight) {
          // scale or dwonsacale image if new imported image resolution is different from earlier image
          const previous_image_width = this.cameraMapWidth;
          const previous_image_height = this.cameraMapHeight;

          const new_image_width = imageObj.width;
          const new_image_height = imageObj.height;

          if (
            previous_image_width &&
            previous_image_height &&
            (previous_image_width != new_image_width ||
              previous_image_height != new_image_height)
          ) {
            let spotBboxes = [...this.initBboxes].map((bbox) => {
              bbox.x = bbox.x * (new_image_width / previous_image_width);
              bbox.y = bbox.y * (new_image_height / previous_image_height);
              bbox.width =
                bbox.width * (new_image_width / previous_image_width);
              bbox.height =
                bbox.height * (new_image_height / previous_image_height);
              return bbox;
            });
            this.bboxes = this.createBboxes(spotBboxes);

            let roiPolygons = [...this.initPolygons].map((poly) => {
              poly.points = [...poly.points].map((point, index) => {
                if (index % 2 == 0) {
                  return point * (new_image_width / previous_image_width);
                } else {
                  return point * (new_image_height / previous_image_height);
                }
              });
              return poly;
            });
            this.polygons = this.createPolygons(roiPolygons);
          }
        }

        this.adjustCanvasSize();
        console.log("Loaded Annotator image", this.imageObj);
        setTimeout(() => {
          this.centerToSpotBbox();
        }, 300);
        if (!refreshed) {
          this.isLoading = false;
        }
      };
    },

    handleStageMouseWheel(e: any) {
      if (this.isViewerMode || this.isViewFrameThumbnail) return;
      // filter native events
      if (!e.evt) {
        return;
      }
      let stage = e.target?.getStage();
      if (!stage) {
        return;
      }

      const scaleFactor = 1.3;
      const oldScale = stage.scaleX();
      const pointer = stage.getPointerPosition();
      let direction = e.evt.deltaY > 0 ? 1 : -1; // Zoom in or zoom out
      var mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      };

      var newScale =
        direction > 0 ? oldScale / scaleFactor : oldScale * scaleFactor;
      stage.scale({ x: newScale, y: newScale });

      var newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };
      stage.position(newPos);
    },

    handleRectDragEnd(e: any) {
      if (this.isViewerMode || this.isViewFrameMode) return;
      if (!this.selectedBboxId) {
        return;
      }
      const bbox = this.bboxesIdHashMap[this.selectedBboxId];
      let oldX = bbox.x;
      let oldY = bbox.y;
      let newX = e.target.x();
      let newY = e.target.y();
      if (bbox) {
        this.executeAction({
          execute: () => {
            bbox.x = newX;
            bbox.y = newY;
            console.log("Moved bbox position", newX, newY, bbox.id);
          },
          undo: () => {
            console.log(
              "Restoring bbox position",
              bbox.x,
              bbox.y,
              bbox.id,
              oldX,
              oldY
            );
            bbox.x = oldX;
            bbox.y = oldY;
          },
        });
      }
    },

    handleRectMouseEnter(e: any) {
      if (this.isViewerMode || this.isViewFrameMode) return;
      const bbox = this.bboxesIdHashMap[e.target.id()];
      // Make all other spots lightly visible when focus mode is enabled
      if (this.focusOpacityMode) {
        for (let otherBbox of this.bboxes) {
          otherBbox.opacity = 0.1;
        }
        bbox.opacity = DEFAULT_SPOT_BBOX_OPACITY;
      } else {
        bbox.opacity = 0.25;
      }
      if (bbox.spotId) {
        this.tooltipLayer.show = true;
        this.tooltipLayer.text = String(bbox.spotName);
        if (bbox.extraText) {
          this.tooltipLayer.text += " " + bbox.extraText;
        }
      }
    },

    handleRectMouseLeave(e: any) {
      if (this.isViewerMode || this.isViewFrameMode) return;
      const bbox = this.bboxesIdHashMap[e.target.id()];
      // Undo making all other spots lightly visible when focus mode is enabled
      if (this.focusOpacityMode) {
        for (let otherBbox of this.bboxes) {
          otherBbox.opacity = DEFAULT_SPOT_BBOX_OPACITY;
        }
      } else {
        bbox.opacity = DEFAULT_SPOT_BBOX_OPACITY;
      }
      if (this.tooltipLayer.show) {
        this.tooltipLayer.show = false;
        this.tooltipLayer.text = "";
      }
    },

    handleRectTransformEnd(e: any) {
      if (this.isViewerMode || this.isViewFrameMode) return;
      if (!this.selectedBboxId) {
        return;
      }
      // shape is transformed, let us save new attrs back to the node
      // find element in our state
      const bbox = this.bboxesIdHashMap[this.selectedBboxId];
      let oldX = bbox.x;
      let oldY = bbox.y;
      let oldWidth = bbox.width;
      let oldHeight = bbox.height;
      let newX = bbox.x + e.target.x();
      let newY = bbox.y + e.target.y();
      let newWidth = e.target.scaleX() * e.target.width();
      let newHeight = e.target.scaleY() * e.target.height();
      console.log("Transformed position", e.target, oldX, oldY, newX, newY);
      // Use scales to change height/width then reset scales
      e.target.scaleX(1.0);
      e.target.scaleY(1.0);
      // Reset rect position to (0,0) since position is applied to group instead
      e.target.x(0);
      e.target.y(0);
      this.executeAction({
        execute: () => {
          (bbox.x = newX), (bbox.y = newY), (bbox.width = newWidth);
          bbox.height = newHeight;
          console.log("Updated bbox size", bbox);
        },
        undo: () => {
          (bbox.x = oldX), (bbox.y = oldY), (bbox.width = oldWidth);
          bbox.height = oldHeight;
          console.log("Updated bbox size", bbox);
        },
      });
    },

    handleStageMouseDown(e: any) {
      console.log("Stage mousedown event", e);
      if (this.isViewerMode || this.isViewFrameMode) {
        this.$emit("show-image", this.imageURL);
        return;
      }
      // filter native events
      if (!e.evt) {
        return;
      }
      // clicked on stage - clear selection
      if (e.target === e.target.getStage()) {
        this.selectedBboxId = null;
        this.selectedPolygonId = null;
        this.selectedLineId = null;
        this.updateTransformer();
        return;
      }

      // clicked on transformer - do nothing
      const clickedOnTransformer =
        e.target.getParent().className === "Transformer";
      if (clickedOnTransformer) {
        return;
      }

      // find clicked bbox by its id
      if (!this.isCreatingNewAnno) {
        console.log("Clicked on konva object id", e.target.id());
        const bbox = this.bboxesIdHashMap[e.target.id()];
        const poly = this.polygonsIdHashMap[e.target.id()];
        const line = this.linesIdHashMap[e.target.id()];
        if (bbox) {
          // Select the clicked bbox
          this.selectedBboxId = e.target.id();
          this.selectedPolygonId = null;
          this.selectedLineId = null;
          console.log("Selected bbox", bbox);
        } else if (poly) {
          // Select the clicked polygon
          this.selectedPolygonId = e.target.id();
          this.selectedBboxId = null;
          this.selectedLineId = null;
          console.log("Selected poly", poly);
        } else if (line) {
          // Select the clicked line
          this.selectedLineId = e.target.id();
          this.selectedBboxId = null;
          this.selectedPolygonId = null;
          console.log("Selected line", line);
        } else {
          // Unselect the last clicked bbox
          this.selectedBboxId = null;
          this.selectedPolygonId = null;
          this.selectedLineId = null;
        }
      }

      const pos = e.target.getStage().getRelativePointerPosition();
      switch (this.activeTool) {
        case TOOLS.BBOX:
        case TOOLS.EV_BBOX: {
          // Start creating a new spot bbox for ev charger bbox
          const category =
            this.activeTool == TOOLS.EV_BBOX
              ? BBOX_CATEGORY.ev_charger
              : BBOX_CATEGORY.spot;
          const newBbox = this.createNewBbox(pos.x, pos.y, category);
          this.selectedBboxId = newBbox.id;
          e.target.getStage().draggable(false);
          this.isCreatingNewAnno = true;
          break;
        }
        case TOOLS.POLYGON: {
          if (this.selectedPolygonId == null) {
            console.log("Creating new polygon");
            const newPoly = this.createNewPoly(pos.x, pos.y);
            this.selectedPolygonId = newPoly.id;
            this.isCreatingNewAnno = true;
          } else {
            const selectedPoly = this.polygonsIdHashMap[this.selectedPolygonId];
            const len = selectedPoly.points.length;
            const newX = pos.x;
            const newY = pos.y;
            // Check if clicked on first point to close polygon
            if (
              selectedPoly.points.length > 6 &&
              Math.pow(selectedPoly.points[0] - newX, 2) +
                Math.pow(selectedPoly.points[1] - newY, 2) <
                25
            ) {
              console.log("Closing polygon", selectedPoly);
              selectedPoly.points.splice(len - 2, 2); // Remove last hover points
              this.selectedPolygonId = null;
              this.isCreatingNewAnno = false;
              this.activeTool = TOOLS.SELECT;
            } else {
              this.executeAction({
                execute: () => {
                  // Remove last 2 hover points and push 2 new points (click point, new hover point)
                  selectedPoly.points.splice(
                    len - 2,
                    2,
                    newX,
                    newY,
                    newX,
                    newY
                  );
                  const annotationsLayer = (
                    this.$refs.annotationsLayer as any
                  ).getNode();
                  annotationsLayer.draw(); // Force draw call to display last line along mouse moves.
                },
                undo: () => {
                  selectedPoly.points.splice(len - 2, 2);
                  const annotationsLayer = (
                    this.$refs.annotationsLayer as any
                  ).getNode();
                  annotationsLayer.draw(); // Force draw call to display last line along mouse moves.
                },
              });
            }
          }
          break;
        }
        case TOOLS.LINE: {
          // Start a new line
          if (this.selectedLineId == null) {
            if (this.lines.length >= 8) {
              this.$dialog.message.error(
                "Error, only a maximum of 8 lines are allowed to be drawn.",
                {
                  position: "top-right",
                  timeout: 3000,
                }
              );
              break;
            }

            const newLine = this.createNewLine(pos.x, pos.y);
            console.log("Created new line", newLine);
            this.selectedLineId = newLine.id;
            this.isCreatingNewAnno = true;
          } else {
            // Complete existing line
            const selectedLine = this.linesIdHashMap[this.selectedLineId];
            const len = selectedLine.points.length;
            const newX = pos.x;
            const newY = pos.y;
            const oldX = selectedLine.points[len - 2];
            const oldY = selectedLine.points[len - 1];
            this.isCreatingNewAnno = false;
            // Check if both points of the line are already drawn
            this.executeAction({
              execute: () => {
                // Remove last 2 hover points and push 2 new points (click point, new hover point)
                selectedLine.points.splice(2, 2, newX, newY);
                this.selectedLineId = null;
                const annotationsLayer = (
                  this.$refs.annotationsLayer as any
                ).getNode();
                annotationsLayer.draw(); // Force draw call to display last line along mouse moves.
              },
              undo: () => {
                this.selectedLineId = selectedLine.id;
                selectedLine.points.splice(2, 2, oldX, oldY);
                const annotationsLayer = (
                  this.$refs.annotationsLayer as any
                ).getNode();
                annotationsLayer.draw(); // Force draw call to display last line along mouse moves.
              },
            });
          }

          break;
        }
      }

      this.updateTransformer();
    },

    createNewBbox(x: number, y: number, category: BBOX_CATEGORY): Bbox {
      let bbox: Bbox = {
        spotId: null,
        spotName: null,
        laneId: null,
        x,
        y,
        width: 0,
        height: 0,
        id: (window.crypto as any).randomUUID(),
        // fill: DEFAULT_SPOT_BBOX_FILL,
        fill: Konva.Util.getRandomColor(),
        opacity: DEFAULT_SPOT_BBOX_OPACITY,
        visible: true,
        category: category,
      };
      this.executeAction({
        execute: () => {
          this.bboxes.push(bbox);
        },
        undo: () => {
          let bboxIndex = this.bboxes.indexOf(bbox);
          this.bboxes.splice(bboxIndex, 1);
        },
      });
      return bbox;
    },

    createNewPoly(x: number, y: number): Polygon {
      let polygon: Polygon = {
        annoId: this.cameraId,
        annoName: null,
        points: [x, y, x, y],
        id: (window.crypto as any).randomUUID(),
        fill: Konva.Util.getRandomColor(),
        opacity: DEFAULT_SPOT_BBOX_OPACITY,
        closed: true,
        category: POLYGON_CATEGORY.camera,
        visible: true,
      };
      this.executeAction({
        execute: () => {
          this.polygons.push(polygon);
          console.log("After adding poly", this.polygons);
        },
        undo: () => {
          let polyIndex = this.polygons.indexOf(polygon);
          this.polygons.splice(polyIndex, 1);
        },
      });
      return polygon;
    },

    createNewLine(x: number, y: number): Line {
      let line = {
        annoId: null,
        annoName: null,
        points: [x, y, x, y],
        id: (window.crypto as any).randomUUID(),
        stroke: Konva.Util.getRandomColor(),
        opacity: DEFAULT_SPOT_BBOX_OPACITY,
        closed: true,
        category: "zone",
        visible: true,
      };
      this.executeAction({
        execute: () => {
          this.lines.push(line);
          console.log("After adding line", this.lines);
        },
        undo: () => {
          let lineIndex = this.lines.indexOf(line);
          this.lines.splice(lineIndex, 1);
        },
      });
      return line;
    },

    handlePolyDragEnd(e: any) {
      if (this.isViewerMode || this.isViewFrameMode) return;
      console.log("Poly drag end", this.selectedPolygonId, e);
      if (!this.selectedPolygonId) {
        return;
      }
      const selectedPoly = this.polygonsIdHashMap[this.selectedPolygonId];
      if (!selectedPoly) {
        return;
      }
      console.log("Moving Poly", selectedPoly);
      if (selectedPoly) {
        let offsetX = e.target.x();
        let offsetY = e.target.y();
        this.executeAction({
          execute: () => {
            let updatedPoints = [];
            for (let i = 0; i < selectedPoly.points.length; i += 2) {
              updatedPoints.push(selectedPoly.points[i] + offsetX);
              updatedPoints.push(selectedPoly.points[i + 1] + offsetY);
            }
            selectedPoly.points = updatedPoints;
            console.log("Moved poly by offset", offsetX, offsetY);
            this.refreshDrawings();
          },
          undo: () => {
            console.log("Restoring poly by offset", offsetX, offsetY);
            let updatedPoints = [];
            for (let i = 0; i < selectedPoly.points.length; i += 2) {
              updatedPoints.push(selectedPoly.points[i] - offsetX);
              updatedPoints.push(selectedPoly.points[i + 1] - offsetY);
            }
            selectedPoly.points = updatedPoints;

            this.refreshDrawings();
          },
        });
      }
    },

    handlePolyPointDragMove(e: any) {
      if (this.isViewerMode || this.isViewFrameMode) return;
      console.log("End point index", e.target.attrs.pointIndex, "drag move", e);
      const selectedPoly = e.target.attrs.poly || e.target.attrs.line;
      if (!selectedPoly) {
        return;
      }
      if (selectedPoly) {
        let pointIndex = e.target.attrs.pointIndex * 2;
        let newX = e.target.x();
        let newY = e.target.y();

        selectedPoly.points[pointIndex] = newX;
        selectedPoly.points[pointIndex + 1] = newY;
        console.log("Moved poly point position", newX, newY, pointIndex);

        // set execute for last dragged psoition into undos execute, this way redo works correctly
        let action = this.undoActions.pop();
        if (action) {
          action.execute = () => {
            selectedPoly.points[pointIndex] = newX;
            selectedPoly.points[pointIndex + 1] = newY;
            console.log("Moved poly point position", newX, newY, pointIndex);

            this.refreshDrawings();
          };
          this.undoActions.push(action);
        }
      }
    },

    handlePolyPointDragStart(e: any) {
      if (this.isViewerMode || this.isViewFrameMode) return;
      console.log(
        "End point index",
        e.target.attrs.pointIndex,
        "drag start",
        e
      );
      const selectedPoly = e.target.attrs.poly || e.target.attrs.line;
      if (!selectedPoly) {
        return;
      }
      if (selectedPoly) {
        let pointIndex = e.target.attrs.pointIndex * 2;
        let oldX = selectedPoly.points[pointIndex];
        let oldY = selectedPoly.points[pointIndex + 1];
        let newX = e.target.x();
        let newY = e.target.y();
        this.executeAction({
          execute: () => {
            selectedPoly.points[pointIndex] = newX;
            selectedPoly.points[pointIndex + 1] = newY;
            console.log("Moved poly point position", newX, newY, pointIndex);
          },
          undo: () => {
            console.log(
              "Restoring poly point position",
              newX,
              newY,
              pointIndex,
              oldX,
              oldY
            );
            selectedPoly.points[pointIndex] = oldX;
            selectedPoly.points[pointIndex + 1] = oldY;

            this.refreshDrawings();
          },
        });
      }
    },

    handlePolyPointDragEnd(e: any) {
      if (this.isViewerMode || this.isViewFrameMode) return;
      // Prevent event from bubbling up to avoid handlePolyDragEnd from
      // being called.
      e.cancelBubble = true;
      console.log("Stop point drag.");
      this.refreshDrawings();
    },

    togglePolyVisibility(poly: Polygon) {
      poly.visible = !poly.visible;
      this.refreshDrawings();
    },

    refreshDrawings() {
      const oldBboxes = [...this.bboxes];
      const oldPolygons = [...this.polygons];

      this.bboxes.splice(0, this.bboxes.length);
      this.polygons.splice(0, this.polygons.length);

      setTimeout(() => {
        this.bboxes.push(...oldBboxes);
        this.polygons.push(...oldPolygons);
      }, 1);
    },

    deleteSelectedAnno() {
      if (this.selectedBboxId) {
        const bbox = this.bboxesIdHashMap[this.selectedBboxId];
        this.executeAction({
          execute: () => {
            let bboxIndex = this.bboxes.indexOf(bbox);
            this.deletedBboxes.push(bbox.spotId);
            this.bboxes.splice(bboxIndex, 1);
          },
          undo: () => {
            this.bboxes.push(bbox);
            let deletedBboxesIndex = this.deletedBboxes.indexOf(bbox.spotId);
            this.deletedBboxes.splice(deletedBboxesIndex, 1);
          },
        });
      }
      if (this.selectedPolygonId) {
        const poly = this.polygonsIdHashMap[this.selectedPolygonId];
        this.executeAction({
          execute: () => {
            let polyIndex = this.polygons.indexOf(poly);
            this.polygons.splice(polyIndex, 1);
          },
          undo: () => {
            this.polygons.push(poly);
          },
        });
      }
      if (this.selectedLineId) {
        const line = this.linesIdHashMap[this.selectedLineId];
        this.executeAction({
          execute: () => {
            let lineIndex = this.lines.indexOf(line);
            this.lines.splice(lineIndex, 1);
          },
          undo: () => {
            this.lines.push(line);
          },
        });
      }
    },

    handleStageMouseMove(e: any) {
      if (this.isViewerMode || this.isViewFrameThumbnail) return;
      const pos = e.target.getStage().getRelativePointerPosition();
      if (this.tooltipLayer.show) {
        this.tooltipLayer.x = pos.x;
        this.tooltipLayer.y = pos.y;
      }
      switch (this.activeTool) {
        case TOOLS.BBOX:
        case TOOLS.EV_BBOX: {
          if (this.isCreatingNewAnno && this.selectedBboxId) {
            // update height and width of the bbox being created
            const bbox = this.bboxesIdHashMap[this.selectedBboxId];
            bbox.width = Math.abs(pos.x - bbox.x);
            bbox.height = Math.abs(pos.y - bbox.y);
          }
          break;
        }
        case TOOLS.POLYGON: {
          if (this.selectedPolygonId) {
            // update last 2 points of the current polygon
            const selectedPoly = this.polygonsIdHashMap[this.selectedPolygonId];
            const len = selectedPoly.points.length;
            selectedPoly.points.splice(len - 2, 2, pos.x, pos.y);
            const annotationsLayer = (
              this.$refs.annotationsLayer as any
            ).getNode();
            annotationsLayer.draw(); // Force draw call to display last line along mouse moves.
          }
          break;
        }
        case TOOLS.LINE: {
          if (this.selectedLineId) {
            // update last 2 points of the current line
            const selectedLine = this.linesIdHashMap[this.selectedLineId];
            const len = selectedLine.points.length;
            selectedLine.points.splice(len - 2, 2, pos.x, pos.y);
            const annotationsLayer = (
              this.$refs.annotationsLayer as any
            ).getNode();
            annotationsLayer.draw(); // Force draw call to display last line along mouse moves.
          }
          break;
        }
      }
    },

    handleStageMouseUp(e: any) {
      console.log("Mouse Up event", e);
      if (this.isViewerMode || this.isViewFrameMode) return;
      const pos = e.target.getStage().getRelativePointerPosition();
      if (
        (this.activeTool === TOOLS.BBOX || this.activeTool === TOOLS.EV_BBOX) &&
        this.selectedBboxId &&
        this.isCreatingNewAnno
      ) {
        // finish creating a new bbox when mouseup
        const bbox = this.bboxesIdHashMap[this.selectedBboxId];
        bbox.width = Math.abs(pos.x - bbox.x);
        bbox.height = Math.abs(pos.y - bbox.y);
        e.target.getStage().draggable(true);
        this.selectedBboxId = null;
        this.isCreatingNewAnno = false;
      }
    },

    updateTransformer() {
      // here we need to manually attach or detach Transformer node
      const transformerNode = (this.$refs.transformer as any).getNode();
      const stage = transformerNode.getStage();

      const selectedNode = stage.findOne("#" + this.selectedBboxId);
      // do nothing if selected node is already attached
      if (selectedNode === transformerNode.node()) {
        return;
      }

      if (selectedNode) {
        // attach to another node
        transformerNode.nodes([selectedNode]);
      } else {
        // remove transformer
        transformerNode.nodes([]);
      }
    },

    extractAnnotations() {
      const bboxes = this.bboxes.map((bbox) => ({
        x: bbox.x,
        y: bbox.y,
        width: bbox.width,
        height: bbox.height,
        spotId: bbox.spotId,
        category: bbox.category,
      }));

      const polygons = this.polygons.map((poly) => ({
        points: poly.points,
        annoId: poly.annoId,
        category: poly.category,
      }));

      const lines = this.lines.map((line) => ({
        points: line.points,
        annoId: line.annoId,
        category: line.category,
      }));

      return {
        bboxes,
        polygons,
        lines,
      };
    },

    handleDelete() {
      if (
        this.selectedBboxId ||
        this.selectedPolygonId ||
        this.selectedLineId
      ) {
        this.deleteSelectedAnno();
      }
    },

    executeAction(action: UndoAction) {
      this.redoActions.splice(0, this.redoActions.length);
      this.undoActions.push(action);
      action.execute();
    },

    /**
     * Delete all annotations on the image. (Operation can be undone).
     */
    handleClearAllAnnotations() {
      if (this.bboxes.length == 0 && this.polygons.length == 0) {
        return;
      }
      const oldBboxes = [...this.bboxes];
      const oldPolygons = [...this.polygons];
      this.executeAction({
        execute: () => {
          this.bboxes.splice(0, this.bboxes.length);
          this.polygons.splice(0, this.polygons.length);
        },
        undo: () => {
          this.bboxes.push(...oldBboxes);
          this.polygons.push(...oldPolygons);
        },
      });
    },

    handleUndo() {
      let action = this.undoActions.pop();
      if (action) {
        action.undo();
        this.redoActions.push(action);
      }
    },

    handleRedo() {
      let action = this.redoActions.pop();
      if (action) {
        action.execute();
        this.undoActions.push(action);
      }
    },

    handleSave() {
      this.$emit(
        "save-annotations",
        this.extractAnnotations(),
        this.deletedBboxes
      );
      this.deletedBboxes = [];
    },

    setSelectedAnnoMapping(
      category: string,
      annoId: number,
      annoName: string,
      annoObj: any
    ) {
      console.log(
        "Click on digimap's anno",
        category,
        "id",
        annoId,
        "name",
        annoName,
        "obj",
        annoObj
      );
      switch (category) {
        case "spot": {
          if (this.selectedBboxId) {
            const selectedBbox = this.bboxesIdHashMap[this.selectedBboxId];
            // Unset label if another spot annotation has same
            this.checkUnsetAnnotationLabelUniqueness(
              annoId,
              selectedBbox.category
            ); // bbox category is pre-set by either BBOX or EV_BBOX tool
            selectedBbox.spotId = annoId;
            selectedBbox.spotName = annoName;
            selectedBbox.laneId = annoObj?.annoData?.parking_lane_id;
          } else if (
            this.isFeatureFlexibleCameraMappingEnabled &&
            this.selectedPolygonId
          ) {
            const selectedPolygon =
              this.polygonsIdHashMap[this.selectedPolygonId];
            if (selectedPolygon.points.length == 8) {
              // Link this poly with annotation only if it has exactly 4 points
              this.checkUnsetAnnotationLabelUniqueness(
                annoId,
                POLYGON_CATEGORY.spot
              );
              selectedPolygon.annoId = annoId;
              selectedPolygon.category = POLYGON_CATEGORY.spot;
              selectedPolygon.annoName = annoName;
            } else {
              this.$dialog.message.error(
                "Error, only 4 point polygons may be assigned to parking spots.",
                {
                  position: "top-right",
                  timeout: 3000,
                }
              );
            }
          } else {
            this.$dialog.message.error(
              "Error, please select a bbox in camera map to assign a parking spot ID.",
              {
                position: "top-right",
                timeout: 3000,
              }
            );
          }
          break;
        }
        case "zone": {
          if (this.selectedPolygonId) {
            // Assign untracked_zone to polygon
            const selectedPolygon =
              this.polygonsIdHashMap[this.selectedPolygonId];
            this.checkUnsetAnnotationLabelUniqueness(
              annoId,
              POLYGON_CATEGORY.zone
            );
            selectedPolygon.annoId = annoId;
            selectedPolygon.category = POLYGON_CATEGORY.zone;
          } else if (this.selectedLineId) {
            // Assign line_counter_zone to all lines (since all lines belong to the same counter zone)
            for (const line of this.lines) {
              line.annoId = annoId;
              line.category = "zone";
              line.annoName = annoName;
            }
          } else {
            this.$dialog.message.error(
              "Error, please select a polygon or line in camera map to assign a parking zone ID.",
              {
                position: "top-right",
                timeout: 3000,
              }
            );
          }
          break;
        }
        case "special_area": {
          if (this.selectedPolygonId) {
            const selectedPolygon =
              this.polygonsIdHashMap[this.selectedPolygonId];
            this.checkUnsetAnnotationLabelUniqueness(
              annoId,
              POLYGON_CATEGORY.special_area
            );
            selectedPolygon.annoId = annoId;
            selectedPolygon.category = POLYGON_CATEGORY.special_area;
          } else {
            this.$dialog.message.error(
              "Error, please select a polygon in camera map to assign a special area ID.",
              {
                position: "top-right",
                timeout: 3000,
              }
            );
          }
          break;
        }
      }
    },

    /**
     * When uniqueAnnotationLabels is enabled, unset label from any other annotations if
     * it has the same annoId asssociated with it.
     */
    checkUnsetAnnotationLabelUniqueness(
      annoId: number,
      category: BBOX_CATEGORY | POLYGON_CATEGORY
    ) {
      if (this.uniqueAnnotationLabels) {
        switch (category) {
          case BBOX_CATEGORY.spot: {
            for (const bbox of this.bboxes) {
              if (bbox.spotId === annoId && bbox.category == category) {
                console.log("Got bbox in another spot", bbox);
                bbox.spotId = null;
                bbox.spotName = null;
              }
            }
            break;
          }
          case POLYGON_CATEGORY.zone:
          case POLYGON_CATEGORY.special_area: {
            for (const poly of this.polygons) {
              if (poly.annoId === annoId && poly.category === category) {
                poly.annoId = null;
              }
            }
            break;
          }
        }
      }
    },

    adjustCanvasSize() {
      if (this.isViewerMode && this.imageObj) {
        const scaleX = this.stageWidth / this.originalImageWidth;
        const scaleY = this.stageHeight / this.originalImageHeight;
        const scale = scaleX; //Math.max(scaleX, scaleY);
        this.scale = scale;
        this.imageObj.width = this.originalImageWidth * scale;
        this.imageObj.height =
          (this.imageObj.width / this.originalImageWidth) *
          this.originalImageHeight;
        this.stageHeightUpdated = this.imageObj.height;
      }
    },

    onDoubleParkingRoiStretchDirectionChanged(item: any) {
      this.doubleParkingRoiStretchDirection.selectedValue = item.value;
      this.computeDoubleParkingRoi();
    },

    computeDoubleParkingRoi() {
      const TRANSLATE_AMOUNT_RATIO = 0.5;

      console.log("Computing Double Parking ROI", this.selectedBboxId);
      let bbox = null;

      if (this.selectedBboxId) {
        bbox = this.bboxesIdHashMap[this.selectedBboxId];
        console.log("For bbox", bbox);
      }

      if (bbox && bbox.spotId === null) {
        this.$dialog.message.error("Please assign this bbox to a spot first.", {
          position: "top-right",
          timeout: 3000,
        });
        return;
      }

      if (bbox && bbox.laneId === null) {
        this.$dialog.message.error(
          "Cannot generate lane double parking ROI since this spot does not belong to a lane.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        return;
      }

      if (
        bbox &&
        this.doubleParkingRoiStretchDirection.selectedValue !== null
      ) {
        let selectedLaneId = bbox.laneId;
        let laneBboxes = this.bboxes
          .filter((b) => b.laneId === selectedLaneId)
          .map((b) => [b.x, b.y, b.width, b.height]);
        let roiPoly = getConvexHullRoiForBboxes(
          laneBboxes,
          this.doubleParkingRoiStretchDirection.selectedValue,
          TRANSLATE_AMOUNT_RATIO
        );

        console.log("Computed ROI", roiPoly);

        if (roiPoly && roiPoly.length > 3) {
          // Check if poly for this lane ID already exists (since a lane/camera pair can have only one roi).
          let lanePolys = this.polygons.filter(
            (p) => p.category === "lane" && p.annoId === selectedLaneId
          );
          let poly = null;
          if (lanePolys && lanePolys.length > 0) {
            poly = lanePolys[0]; // Replace if exists
          } else {
            poly = this.createNewPoly(roiPoly[0][0], roiPoly[0][1]); // Else, create new poly
          }
          const pointsFlat = [];
          for (const point of roiPoly) {
            pointsFlat.push(point[0], point[1]);
          }
          console.log("Flat Points", pointsFlat);
          poly.points = pointsFlat;
          poly.annoId = selectedLaneId;
          poly.category = POLYGON_CATEGORY.lane;
        }
      } else {
        this.$dialog.message.error("Error, you must select a bbox first.", {
          position: "top-right",
          timeout: 3000,
        });
      }
    },

    reset() {
      this.imageObj = null;
      this.bboxes = [];
      this.polygons = [];
    },

    centerToSpotBbox() {
      if (this.isViewFrameMode && this.imageObj) {
        // center to coordinate of initBbox first element
        if (this.initBboxes.length > 0) {
          const bbox = this.initBboxes[0];
          const x = bbox.x + bbox.width / 2;
          const y = bbox.y + bbox.height / 2;

          // center image in konva canvas stage to x, y and zoom into it
          let stage = this.$refs.stage as any;
          stage = stage.getStage();
          this.scale = 1;
          stage.scale({ x: 1, y: 1 });
          stage.position({
            x: -x * this.scale + this.stageWidth / 2,
            y: -y * this.scale + this.stageHeight / 2,
          });
        } else if (this.initPolygons.length > 0) {
          const poly = this.initPolygons[0].points;
          // calculate center of polygon
          let x = 0;
          let y = 0;
          for (let i = 0; i < poly.length; i += 2) {
            x += poly[i];
            y += poly[i + 1];
          }
          x /= poly.length / 2;
          y /= poly.length / 2;

          // center image in konva canvas stage to x, y and zoom into it
          let stage = this.$refs.stage as any;
          stage = stage.getStage();
          this.scale = 1;
          stage.scale({ x: 1, y: 1 });
          stage.position({
            x: -x * this.scale + this.stageWidth / 2,
            y: -y * this.scale + this.stageHeight / 2,
          });
        }
      }
    },
    zoomInOutImageViewer(direction: number) {
      let stage = this.$refs.stage as any;
      stage = stage.getStage();

      // center to coordinate of initBbox first element
      const bbox = this.initBboxes[0];
      const x = (bbox.x + bbox.width) / 2;
      const y = (bbox.y + bbox.height) / 2;

      const scaleFactor = 1.3;
      const oldScale = stage.scaleX();

      let spotCenter = {
        x: (x - stage.x()) / oldScale,
        y: (y - stage.y()) / oldScale,
      };

      var newScale =
        direction > 0 ? oldScale / scaleFactor : oldScale * scaleFactor;
      stage.scale({ x: newScale, y: newScale });

      var newPos = {
        x: x - spotCenter.x * newScale,
        y: y - spotCenter.y * newScale,
      };
      stage.position(newPos);
    },
    resetImageViewer() {
      this.centerToSpotBbox();
    },
  },

  watch: {
    /**
     * Reload the image whenever the URL change
     */
    imageURL(newImageURL) {
      this.loadImageFromURL(newImageURL, true);
    },
    initBboxes(updatedBboxes) {
      this.bboxes = this.createBboxes(updatedBboxes);
    },
    initPolygons(updatedPolygons) {
      this.polygons = this.createPolygons(updatedPolygons);
    },
    needsInit(show) {
      if (show) {
        // this.$nextTick(() => {
        this.init();
        // });
      } else {
        this.reset();
      }
    },
    stageWidth(newWidth) {
      this.adjustCanvasSize();
    },
  },
});
