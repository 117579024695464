



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import _ from "lodash";
import dayjs, { Dayjs } from "dayjs";
import api from "@/api/api";
import { ParkingSpotSavedEndUserDetails } from "@/api/models";
import Chart, { ChartItem, ChartConfiguration } from "chart.js/auto";
import {
  ActivityAlertssDiyAppResponseV2,
  ParkingStatus,
} from "@/api/models/ParkingSpot";
import { ParkingSpotUnknownStatus } from "@/api/models/ParkingHistory";
import ImageAnnotator from "@/components/ImageAnnotator.vue";
import { POLYGON_CATEGORY } from "@/libs/commonCameraMapEditorTypes";
import VehicleParkingUsageRecordDetails from "@/components/VehicleParkingUsageRecordDetails.vue";
import { getTodaysDate } from "@/libs/dateUtils";

interface BarChartDataset {
  label: string;
  data: Array<number>;
  sessions_count: Array<number>;
  borderColor: string;
  backgroundColor: Array<string>;
  barPercentage: number;
  borderRadius: number;
  hoverBackgroundColor: Array<string>;
  skipNull: boolean;
}

const plugin = {
  id: "customCanvasBackgroundColor",
  beforeDraw: (chart: any, args: any, options: any) => {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = "destination-over";
    ctx.fillStyle = "#FFFFFF"; // options.color || "#EBFAFC";
    ctx.fillRect(6, 0, chart.width, chart.height - 26);
    ctx.restore();
  },
};

interface TimelineImageUrl {
  id: number;
  index: number | null;
  img_index: number;
  url: string | null | undefined;
}

export default Vue.extend({
  name: "SpotSlider",

  components: {
    ImageAnnotator,
    VehicleParkingUsageRecordDetails,
  },

  props: {
    lotId: {
      type: Number,
      required: true,
    },
    spotId: {
      type: Number,
      required: false,
    },
    zoneId: {
      type: Number,
      required: false,
    },
    cameraId: {
      type: Number,
      required: false,
    },
    savedSpot: {
      type: Object as () => ParkingSpotSavedEndUserDetails,
      required: false,
    },
  },

  data() {
    return {
      spotDetails: {
        loading: false,
        lotName: null as string | null,
        meta: null as any | null,
        spotAnn: [] as Array<any>,
        zoneAnn: [] as Array<any>,
        spotName: null as string | null,
        camera_id: null as number | null,
        status: null as string | null,
        unknownReason: null as string | null,
        permitNames: [] as Array<string>,
        parkingTime: null as string | null,
        violation: null as string | null,
        liveFrame: null as string | null,
        loadingLiveFrame: false,
        showFullFrame: false,
        frameWidth: null as number | null,
        frameHeight: null as number | null,

        anpr: {
          recordId: null as number | null,
          vehicleType: null as string | null | undefined,
          vehicleColor: null as string | null | undefined,
          vehicleMake: null as string | null | undefined,
          licensePlate: null as string | null | undefined,
          region: null as string | null | undefined,
        },

        dwellTime: null as string | null,
        parkedAt: null as string | null,

        num_of_sessions: 0,
        occupancy: 0,
        avg_dwell_time: null as string | null,
        last_week_avg_dwell_time: null as string | null,
        last_week_avg_comparison_percent: 0 as number | null,
        longest_dwell_time: null as string | null,
        last_week_longest_dwell_time: null as string | null,
        last_week_longest_dwell_comparison_percent: 0 as number | null,
        shortest_dwell_time: null as string | null,
        last_week_shortest_dwell_time: null as string | null,
        last_week_shortest_dwell_comparison_percent: 0 as number | null,
      },

      loadingTimeline: false,
      spotStatusTimeline: [] as Array<ActivityAlertssDiyAppResponseV2>,
      groupedSpotStatusTimeline: null as any | null,
      groupedSpotStatusTimelineDates: null as Array<string> | null,
      timelineImageUrls: [] as Array<TimelineImageUrl>,
      timelineFilters: {
        showMenu: false,
        showViolations: true,
        showVacant: true,
        showOccupied: true,
        showUnknown: true,
        dateRangeOptions: [
          { text: "Today", value: "today" },
          { text: "Yesterday", value: "yesterday" },
          { text: "Last Week", value: "last_week" },
          { text: "Custom", value: "custom" },
        ],
        selectedDateRange: "today" as string | null,
        value: [] as Array<string>,
        startDate: dayjs().format("YYYY-MM-DD") as string | null,
        endDate: dayjs().format("YYYY-MM-DD") as string | null,
      },
      appliedTimelineFilters: {
        showViolations: true,
        showVacant: true,
        showOccupied: true,
        showUnknown: true,
        selectedDateRange: "today" as string | null,
        value: [] as Array<string>,
        startDate: null as string | null,
        endDate: null as string | null,
      },
      page: 1,
      itemsPerPage: 20,
      spotSliderChart: null as Chart<"bar"> | null,

      cameraMap: {
        width: null as number | null,
        height: null as number | null,
      },

      chart: {
        loading: false,
        startDate: "",
        startTime: "00:00",
        endDate: "",
        endTime: "23:59",

        type: "bar",
        data: {
          labels: [] as Array<string>,
          datasets: [] as Array<BarChartDataset>,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          scales: {
            y: {
              title: {
                display: false,
                text: "",
              },
              border: {
                color: "black",
              },
              grid: {
                // color: '#D3DBDF',
                borderColor: "#475467",
                tickColor: "transparent",
                color: (ctx: any) =>
                  ctx.tick.value === 0 ? "#D3DBDF" : "transparent",
              },
              ticks: {
                display: false,
              },
              grace: "1%",
            },
            x: {
              title: {
                display: false,
                text: "",
              },
              border: {
                color: "black",
              },
              grid: {
                color: "#D3DBDF",
                borderColor: "#475467",
                tickColor: "transparent",
              },
              ticks: {
                color: "#475467",
                font: {
                  size: 12,
                  weight: "500",
                },
                autoSkip: false,
                maxRotation: 0,
                minRotation: 0,
              },
            },
          },
          plugins: {
            title: {
              display: false,
              text: "",
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              intersect: false,
              mode: "index",
              titleColor: "#FFFFFF",
              titleFont: { weight: "bold" },
              callbacks: {
                label: function (context: any) {
                  let index = context.dataIndex;
                  let value = context.dataset.data[index];
                  let sessions = context.dataset.sessions_count[index];
                  let label = context.dataset.showLabels
                    ? ` ${context.dataset.label}:`
                    : "";
                  return `Parking Sessions: ${sessions}`;
                },
                title: function (context: any) {
                  let time_str = context[0].label;
                  let dataIndex = context[0].dataIndex;
                  if (time_str.includes("am") || time_str.includes("pm")) {
                    return `${time_str}`;
                  } else {
                    return `${time_str}${dataIndex < 12 ? "am" : "pm"}`;
                  }
                },
              },
            },
            customCanvasBackgroundColor: {
              color: "#EBFAFC",
            },
          },
        },
        plugins: [plugin],
      },

      showVehicleParkingUsageDialog: false,
      shrinkHeader: false,
    };
  },

  computed: {
    ...mapGetters("user", ["isSuperAdmin"]),
    todaysDate(): string {
      return getTodaysDate();
    },
    validateCurrentFilters(): boolean {
      if (
        this.timelineFilters.selectedDateRange == "custom" &&
        this.timelineFilters.value.length < 2
      ) {
        return true;
      }
      if (
        !this.timelineFilters.showViolations &&
        !this.timelineFilters.showVacant &&
        !this.timelineFilters.showOccupied &&
        !this.timelineFilters.showUnknown
      ) {
        return true;
      }
      return false;
    },
    validateFilters(): boolean {
      if (
        this.appliedTimelineFilters.selectedDateRange == "custom" &&
        this.appliedTimelineFilters.value.length < 2
      ) {
        return true;
      }
      if (
        !this.appliedTimelineFilters.showViolations &&
        !this.appliedTimelineFilters.showVacant &&
        !this.appliedTimelineFilters.showOccupied &&
        !this.appliedTimelineFilters.showUnknown
      ) {
        return true;
      }
      return false;
    },
    selectedTimelineRangeFilterText(): string {
      const selected = this.timelineFilters.dateRangeOptions.find(
        (option) =>
          option.value == this.appliedTimelineFilters.selectedDateRange
      );
      if (selected) {
        return selected.text;
      }
      return "";
    },
  },

  mounted() {
    if (this.spotId) {
      this.fetchSpotDetails();
      this.fetchSpotStatusTimeline();
    } else if (this.zoneId) {
      this.fetchZoneDetails();
      this.fetchZoneStatusTimeline();
    }
    this.initializeChart();
    this.fetchSpotOccupancyChartData([
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      -1,
    ]);
    this.lazyLoadTimeline();
    this.shrinkSpotDetailsHeader();
  },

  methods: {
    initializeChart() {
      if (this.spotSliderChart) this.spotSliderChart.destroy();
      const occ_ctx = document.getElementById("spot-slider-chart") as ChartItem;
      this.spotSliderChart = new Chart(
        occ_ctx,
        this.chart as ChartConfiguration<"bar">
      );
    },
    fetchSpotOccupancyChartData(data_arr: Array<number>) {
      data_arr = data_arr.slice(0, 24);

      let data_bar_arr: Array<number> = data_arr.map((value: number) =>
        value >= 1 ? 1 : -1
      );

      this.chart.data.labels = [
        "12am",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12pm",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11pm",
      ];
      const background_color = data_bar_arr.map((value) =>
        value === 1 ? "#0A7AAD" : value === -1 ? "#D3DBDF" : "transparent"
      );
      const hover_background_color = data_bar_arr.map((value) =>
        value === 1 ? "#D3DBDF" : value === -1 ? "#0A7AAD" : "transparent"
      );
      data_bar_arr.push(1);
      data_bar_arr.push(-1);

      this.chart.data.datasets = [
        {
          label: "Parking Sessions",
          data: data_bar_arr,
          sessions_count: data_arr,
          borderColor: "#0A7AAD",
          backgroundColor: background_color,
          barPercentage: 0.8,
          borderRadius: 4,
          hoverBackgroundColor: hover_background_color,
          skipNull: true,
        },
      ];

      if (this.spotSliderChart) this.spotSliderChart.update();
    },
    formatParkingTime(seconds: number) {
      if (seconds < 60) {
        return `${seconds} Sec Parking`;
      } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        return `${minutes} Min Parking`;
      } else {
        const hours = Math.floor(seconds / 3600);
        return `${hours} Hr Parking`;
      }
    },
    formatDwellTime(seconds: number) {
      const days = Math.floor(seconds / 86400);
      const hours = Math.floor((seconds % 86400) / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = Math.floor(seconds % 60);

      const daysStr = days > 0 ? `${days}d ` : "";
      const hoursStr = hours > 0 ? `${hours}h ` : "";
      const minutesStr = minutes > 0 ? `${minutes}m ` : "";
      const secondsStr = daysStr == "" ? `${secs}s` : "";

      return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`.trim();
    },
    formatDwellPercent(value: number) {
      if (value >= 1000 || value <= -1000) {
        return Math.round(value / 1000) + "k";
      }
      return value;
    },
    formatViolationTime(
      inactive_timestamp: string | null,
      created_timestamp: string | null
    ) {
      let inactive_timestamp_now = null;
      if (inactive_timestamp == null) {
        inactive_timestamp_now = dayjs();
      }
      if ((inactive_timestamp || inactive_timestamp_now) && created_timestamp) {
        const inactiveDate =
          inactive_timestamp_now == null
            ? dayjs.utc(inactive_timestamp).local()
            : inactive_timestamp_now;
        let createdDate = dayjs.utc(created_timestamp).local();

        const selectedTimezone = localStorage.getItem("selected_timezone");
        if (selectedTimezone) {
          createdDate = dayjs.utc(created_timestamp).tz(selectedTimezone);
        }

        const diff = inactiveDate.diff(createdDate, "seconds");
        return this.formatDwellTime(diff);
      }
      return "0s";
    },
    formatTime12Hour(timestamp: string) {
      const date = dayjs.utc(timestamp).local();
      return date.format("h:mm A");
    },
    formatTimelineHeader(timestamp: string) {
      let date = dayjs(timestamp);
      const today = dayjs();

      if (date.isSame(today, "day")) {
        return "Today";
      } else {
        return date.format("ddd, MMM D");
      }
    },
    formatTimestamp(timestamp: string, format: string, hide_timezone = false) {
      if (timestamp) {
        let dayObj = dayjs.utc(timestamp).local();
        const selectedTimezone = localStorage.getItem("selected_timezone");
        if (selectedTimezone) {
          dayObj = dayjs.utc(timestamp).tz(selectedTimezone);
        }
        const selectedTimeFormat = localStorage.getItem("time_format_option");
        let formattedDate = dayObj.format(format);
        if (selectedTimeFormat === "24_hr") {
          formattedDate = dayObj.format(format == "h:mm A" ? "HH:mm" : format);
        }

        let tz_short = "";
        if (selectedTimezone && !hide_timezone) {
          const locale = navigator.language ? navigator.language : "en-US";
          let timezone_short = new Intl.DateTimeFormat(locale, {
            timeZone: selectedTimezone,
            timeZoneName: "long",
          })
            .formatToParts(new Date())
            .find((part) => part.type === "timeZoneName")?.value;
          if (timezone_short) {
            // abbreviate text
            timezone_short = timezone_short
              .split(" ")
              .map((word) => word[0])
              .join("");
            tz_short = `${timezone_short}`;
          } else {
            tz_short = dayjs().tz(selectedTimezone).format("z");
          }
        }

        return formattedDate + (tz_short ? ` ${tz_short}` : "");
      }
      return "";
    },
    async fetchSpotDetails() {
      this.spotDetails.loading = true;
      this.chart.loading = true;
      try {
        const response = await api.getParkingSpotDetails(
          this.lotId,
          this.spotId,
          `${this.todaysDate} 00:00`,
          `${this.todaysDate} 23:59`
        );

        const cameraId = this.cameraId
          ? this.cameraId
          : this.spotDetails.camera_id
          ? this.spotDetails.camera_id
          : null;
        if (cameraId) {
          let cameraMapData = await api.getCameraMapDetails(
            this.lotId,
            cameraId
          );

          if (cameraMapData) {
            this.cameraMap.width = cameraMapData.frame_width;
            this.cameraMap.height = cameraMapData.frame_height;
          }
        }

        if (response) {
          this.spotDetails.lotName = response.lot_name;
          this.spotDetails.spotName =
            `Spot ${response.spot_name}` || `Spot ${this.spotId}`;
          this.spotDetails.meta = response.meta || null;
          if (this.spotDetails.meta) {
            const [x, y, width, height] =
              this.spotDetails.meta.camera_frame.bbox;

            let spotAnn = {
              spotId: this.spotId,
              spotName: this.spotDetails.spotName,
              laneId: "",
              x: x,
              y: y,
              width: width,
              height: height,
              category: "spot",
            };
            this.spotDetails.spotAnn.push(spotAnn);
          }

          this.spotDetails.camera_id = response.camera_id || null;
          this.spotDetails.status = response.status || "";
          this.spotDetails.unknownReason = response.unknown_reason || null;
          this.spotDetails.permitNames =
            response.permits != null ? response.permits : [];
          this.spotDetails.parkingTime =
            response.max_parking_time != null
              ? this.formatParkingTime(response.max_parking_time)
              : null;
          this.spotDetails.violation = response.violation || "";
          this.spotDetails.anpr.recordId = response.anpr_record_id || null;
          this.spotDetails.anpr.licensePlate =
            response.license_plate_number || "";
          this.spotDetails.anpr.vehicleType = response.vehicle_type;
          this.spotDetails.anpr.vehicleColor = response.vehicle_color;
          this.spotDetails.anpr.vehicleMake = response.vehicle_brand;
          this.spotDetails.anpr.region = response.vehicle_region;
          this.spotDetails.dwellTime = response.dwell_time
            ? this.formatDwellTime(response.dwell_time)
            : null;
          this.spotDetails.parkedAt =
            (dayjs.utc(response.parked_at).local().isSame(dayjs(), "day")
              ? this.formatTimestamp(response.parked_at, "h:mm A")
              : this.formatTimestamp(response.parked_at, "MMM D, h:mm A")) ||
            "";
          this.spotDetails.num_of_sessions = response.num_of_sessions || 0;
          this.spotDetails.occupancy = response.occupancy || 0;
          this.spotDetails.avg_dwell_time =
            response.avg_dwell_time != null
              ? this.formatDwellTime(response.avg_dwell_time)
              : null;
          this.spotDetails.last_week_avg_dwell_time =
            response.last_week_avg_dwell_time != null
              ? this.formatDwellTime(response.last_week_avg_dwell_time)
              : null;
          this.spotDetails.last_week_avg_comparison_percent = 0;
          if (response.avg_dwell_time && response.last_week_avg_dwell_time) {
            this.spotDetails.last_week_avg_comparison_percent = Math.round(
              ((response.avg_dwell_time - response.last_week_avg_dwell_time) /
                response.last_week_avg_dwell_time) *
                100
            );
          }
          this.spotDetails.longest_dwell_time =
            response.longest_dwell_time != null
              ? this.formatDwellTime(response.longest_dwell_time)
              : null;
          this.spotDetails.last_week_longest_dwell_time =
            response.last_week_longest_dwell_time != null
              ? this.formatDwellTime(response.last_week_longest_dwell_time)
              : null;
          this.spotDetails.last_week_longest_dwell_comparison_percent = 0;
          if (
            response.longest_dwell_time &&
            response.last_week_longest_dwell_time
          ) {
            this.spotDetails.last_week_longest_dwell_comparison_percent =
              Math.round(
                ((response.longest_dwell_time -
                  response.last_week_longest_dwell_time) /
                  response.last_week_longest_dwell_time) *
                  100
              );
          }
          this.spotDetails.shortest_dwell_time =
            response.shortest_dwell_time != null
              ? this.formatDwellTime(response.shortest_dwell_time)
              : null;
          this.spotDetails.last_week_shortest_dwell_time =
            response.last_week_shortest_dwell_time != null
              ? this.formatDwellTime(response.last_week_shortest_dwell_time)
              : null;
          this.spotDetails.last_week_shortest_dwell_comparison_percent = 0;
          if (
            response.shortest_dwell_time &&
            response.last_week_shortest_dwell_time
          ) {
            this.spotDetails.last_week_shortest_dwell_comparison_percent =
              Math.round(
                ((response.shortest_dwell_time -
                  response.last_week_shortest_dwell_time) /
                  response.last_week_shortest_dwell_time) *
                  100
              );
          }

          this.fetchSpotOccupancyChartData([...response.occupancy_data, -1]);
        } else {
          this.$dialog.message.error(
            "Error fetching spot details, please try again later.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
          this.$emit("close-slider");
        }
      } catch (error) {
        console.error(error);
        this.$dialog.message.error(
          "Error fetching spot details, please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        this.$emit("close-slider");
      } finally {
        this.spotDetails.loading = false;
        this.chart.loading = false;
        this.fetchLiveFrame();
      }
    },
    async fetchSpotStatusTimeline(append_new = false) {
      if (this.loadingTimeline) return;
      this.loadingTimeline = true;
      try {
        if (!append_new) {
          this.page = 1;
        }
        const response = await api.getParkingSpotTimeline(
          this.lotId,
          this.spotId,
          this.appliedTimelineFilters.showViolations,
          this.appliedTimelineFilters.showVacant,
          this.appliedTimelineFilters.showOccupied,
          this.appliedTimelineFilters.showUnknown,
          this.appliedTimelineFilters.startDate != null
            ? `${this.appliedTimelineFilters.startDate} 00:00`
            : null,
          this.appliedTimelineFilters.endDate != null
            ? `${this.appliedTimelineFilters.endDate} 23:59`
            : null,
          this.page,
          this.itemsPerPage
        );

        if (response) {
          if (!append_new) {
            this.spotStatusTimeline = response.items;
            this.setTimelineImageUrls(this.spotStatusTimeline);
          } else if (response.items.length > 0) {
            this.spotStatusTimeline = [
              ...this.spotStatusTimeline,
              ...response.items,
            ];
          }

          // sort entire timeline by created_at
          this.spotStatusTimeline.sort(
            (
              a: ActivityAlertssDiyAppResponseV2,
              b: ActivityAlertssDiyAppResponseV2
            ) => {
              return dayjs.utc(b.created_at).diff(dayjs.utc(a.created_at));
            }
          );

          // group spotStatusTimeline by created_at, convert to local first
          this.spotStatusTimeline = this.spotStatusTimeline
            .filter((obj) => {
              return (
                obj.unknown_status == null ||
                !obj.unknown_status.includes("deactivated")
              );
            })
            .map((obj) => {
              obj["local_date"] = obj.created_at
                ? this.formatTimestamp(obj.created_at, "YYYY-MM-DD", true)
                : dayjs.utc(obj.created_at).local().format("YYYY-MM-DD");
              return obj;
            });
          this.groupedSpotStatusTimelineDates = this.spotStatusTimeline.map(
            (obj) => {
              return obj.created_at
                ? this.formatTimestamp(obj.created_at, "YYYY-MM-DD", true)
                : dayjs.utc(obj.created_at).local().format("YYYY-MM-DD");
            }
          );
          // remove duplicates
          this.groupedSpotStatusTimelineDates = [
            ...new Set(this.groupedSpotStatusTimelineDates),
          ];
          const grouped_data = this.groupByLocalDate(this.spotStatusTimeline);
          // if savedSpot is available, add it to the latest violation and occupied status in timeline
          // commented below as its not required now. We fetch saved spot by linked end_users_parking_spots in alert and parking history
          // if (
          //   this.savedSpot &&
          //   (this.timelineFilters.selectedDateRange == "today" ||
          //     this.timelineFilters.selectedDateRange == "last_week")
          // ) {
          //   for (const key in grouped_data) {
          //     const latest_occupied = grouped_data[key].find(
          //       (timeline: ActivityAlertssDiyAppResponseV2) =>
          //         timeline.alert_type_id == null &&
          //         timeline.status == ParkingStatus.unavailable &&
          //         timeline.unknown_status == null
          //     );
          //     // check if there is no vacant or unknown status after the latest occupied status
          //     const latest_vacant_or_unknown = grouped_data[key].find(
          //       (timeline: ActivityAlertssDiyAppResponseV2) =>
          //         timeline.alert_type_id == null &&
          //         (timeline.status == ParkingStatus.free ||
          //           timeline.unknown_status != null)
          //     );
          //     if (latest_vacant_or_unknown && latest_occupied) {
          //       if (
          //         dayjs(latest_vacant_or_unknown.created_at).isAfter(
          //           dayjs(latest_occupied.created_at)
          //         )
          //       ) {
          //         continue;
          //       }
          //     }

          //     let index = grouped_data[key].findIndex(
          //       (timeline: ActivityAlertssDiyAppResponseV2) =>
          //         timeline.alert_type_id == null &&
          //         timeline.status == ParkingStatus.unavailable &&
          //         timeline.unknown_status == null
          //     );
          //     if (latest_occupied) {
          //       grouped_data[key][index]["saved_spot"] = this.savedSpot;

          //       const all_latest_violations = grouped_data[key].filter(
          //         (timeline: ActivityAlertssDiyAppResponseV2) =>
          //           timeline.alert_type_id != null &&
          //           dayjs(timeline.created_at).isAfter(
          //             dayjs(latest_occupied.created_at)
          //           )
          //       );
          //       if (all_latest_violations && all_latest_violations.length > 0) {
          //         for (const violation of all_latest_violations) {
          //           let index = grouped_data[key].findIndex(
          //             (timeline: ActivityAlertssDiyAppResponseV2) =>
          //               timeline.id == violation.id
          //           );
          //           if (index != undefined && index != null && index >= 0) {
          //             grouped_data[key][index]["saved_spot"] = this.savedSpot;
          //           }
          //         }
          //       }
          //     }
          //     break;
          //   }
          // }

          // sort each group by created_at
          for (const key in grouped_data) {
            grouped_data[key].sort(
              (
                a: ActivityAlertssDiyAppResponseV2,
                b: ActivityAlertssDiyAppResponseV2
              ) => {
                return dayjs.utc(b.created_at).diff(dayjs.utc(a.created_at));
              }
            );
          }
          // sort grouped_data by key
          this.groupedSpotStatusTimeline = grouped_data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingTimeline = false;
      }
    },
    async fetchZoneDetails() {
      this.spotDetails.loading = true;
      this.chart.loading = true;
      try {
        const response = await api.getParkingZoneDetails(
          this.lotId,
          this.zoneId,
          `${this.todaysDate} 00:00`,
          `${this.todaysDate} 23:59`
        );

        const cameraId = this.cameraId
          ? this.cameraId
          : this.spotDetails.camera_id
          ? this.spotDetails.camera_id
          : null;
        if (cameraId) {
          let cameraMapData = await api.getCameraMapDetails(
            this.lotId,
            cameraId
          );

          if (cameraMapData) {
            this.cameraMap.width = cameraMapData.frame_width;
            this.cameraMap.height = cameraMapData.frame_height;

            if (cameraMapData && cameraMapData.count_vehicles_only_in_roi) {
              this.spotDetails.zoneAnn.push({
                points: cameraMapData.count_vehicles_only_in_roi.poly[0],
                annoId: cameraMapData.id,
                annoName: null,
                category: POLYGON_CATEGORY.zone,
              });
            }
          }
        }

        if (response) {
          this.spotDetails.lotName = response.lot_name;
          this.spotDetails.spotName = response.zone_name
            ? `Zone ${response.zone_name}`
            : `Zone ${this.zoneId}`;
          this.spotDetails.camera_id = this.cameraId || null;
          this.spotDetails.status = response.status || "";
          this.spotDetails.parkingTime =
            response.max_parking_time != null
              ? this.formatParkingTime(response.max_parking_time)
              : null;
          this.spotDetails.violation = response.violation || "";
          this.spotDetails.dwellTime = response.dwell_time
            ? this.formatDwellTime(response.dwell_time)
            : null;
          this.spotDetails.parkedAt =
            (dayjs.utc(response.parked_at).local().isSame(dayjs(), "day")
              ? this.formatTimestamp(response.parked_at, "h:mm A")
              : this.formatTimestamp(response.parked_at, "MMM D, h:mm A")) ||
            "";
          this.spotDetails.num_of_sessions = response.num_of_sessions || 0;
          this.spotDetails.occupancy = response.occupancy || 0;
          this.spotDetails.avg_dwell_time =
            response.avg_dwell_time != null
              ? this.formatDwellTime(response.avg_dwell_time)
              : null;
          this.spotDetails.last_week_avg_dwell_time =
            response.last_week_avg_dwell_time != null
              ? this.formatDwellTime(response.last_week_avg_dwell_time)
              : null;
          this.spotDetails.last_week_avg_comparison_percent = 0;
          if (response.avg_dwell_time && response.last_week_avg_dwell_time) {
            this.spotDetails.last_week_avg_comparison_percent = Math.round(
              ((response.avg_dwell_time - response.last_week_avg_dwell_time) /
                response.last_week_avg_dwell_time) *
                100
            );
          }
          this.spotDetails.longest_dwell_time =
            response.longest_dwell_time != null
              ? this.formatDwellTime(response.longest_dwell_time)
              : null;
          this.spotDetails.last_week_longest_dwell_time =
            response.last_week_longest_dwell_time != null
              ? this.formatDwellTime(response.last_week_longest_dwell_time)
              : null;
          this.spotDetails.last_week_longest_dwell_comparison_percent = 0;
          if (
            response.longest_dwell_time &&
            response.last_week_longest_dwell_time
          ) {
            this.spotDetails.last_week_longest_dwell_comparison_percent =
              Math.round(
                ((response.longest_dwell_time -
                  response.last_week_longest_dwell_time) /
                  response.last_week_longest_dwell_time) *
                  100
              );
          }
          this.spotDetails.shortest_dwell_time =
            response.shortest_dwell_time != null
              ? this.formatDwellTime(response.shortest_dwell_time)
              : null;
          this.spotDetails.last_week_shortest_dwell_time =
            response.last_week_shortest_dwell_time != null
              ? this.formatDwellTime(response.last_week_shortest_dwell_time)
              : null;
          this.spotDetails.last_week_shortest_dwell_comparison_percent = 0;
          if (
            response.shortest_dwell_time &&
            response.last_week_shortest_dwell_time
          ) {
            this.spotDetails.last_week_shortest_dwell_comparison_percent =
              Math.round(
                ((response.shortest_dwell_time -
                  response.last_week_shortest_dwell_time) /
                  response.last_week_shortest_dwell_time) *
                  100
              );
          }

          this.fetchSpotOccupancyChartData([...response.occupancy_data, -1]);
        } else {
          this.$dialog.message.error(
            "Error fetching zone details, please try again later.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
          this.$emit("close-slider");
        }
      } catch (error) {
        console.error(error);
        this.$dialog.message.error(
          "Error fetching zone details, please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        this.$emit("close-slider");
      } finally {
        this.spotDetails.loading = false;
        this.chart.loading = false;
        this.fetchLiveFrame();
      }
    },
    async fetchZoneStatusTimeline(append_new = false) {
      if (this.loadingTimeline) return;
      this.loadingTimeline = true;
      try {
        if (!append_new) {
          this.page = 1;
        }
        const response = await api.getParkingZoneTimeline(
          this.lotId,
          this.zoneId,
          this.appliedTimelineFilters.showViolations,
          this.appliedTimelineFilters.showVacant,
          this.appliedTimelineFilters.showOccupied,
          this.appliedTimelineFilters.showUnknown,
          this.appliedTimelineFilters.startDate != null
            ? `${this.appliedTimelineFilters.startDate} 00:00`
            : null,
          this.appliedTimelineFilters.endDate != null
            ? `${this.appliedTimelineFilters.endDate} 23:59`
            : null,
          this.page,
          this.itemsPerPage
        );

        if (response) {
          if (!append_new) {
            this.spotStatusTimeline = response.items;
            this.setTimelineImageUrls(this.spotStatusTimeline);
          } else if (response.items.length > 0) {
            this.spotStatusTimeline = [
              ...this.spotStatusTimeline,
              ...response.items,
            ];
          }

          // group spotStatusTimeline by created_at, convert to local first
          this.spotStatusTimeline = this.spotStatusTimeline.map((obj) => {
            obj["local_date"] = obj.created_at
              ? this.formatTimestamp(obj.created_at, "YYYY-MM-DD", true)
              : dayjs.utc(obj.created_at).local().format("YYYY-MM-DD");
            return obj;
          });
          this.groupedSpotStatusTimelineDates = this.spotStatusTimeline.map(
            (obj) => {
              return obj.created_at
                ? this.formatTimestamp(obj.created_at, "YYYY-MM-DD", true)
                : dayjs.utc(obj.created_at).local().format("YYYY-MM-DD");
            }
          );
          // remove duplicates
          this.groupedSpotStatusTimelineDates = [
            ...new Set(this.groupedSpotStatusTimelineDates),
          ];
          const grouped_data = this.groupByLocalDate(this.spotStatusTimeline);
          // sort grouped_data by key
          this.groupedSpotStatusTimeline = grouped_data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingTimeline = false;
      }
    },
    groupByLocalDate(data: Array<ActivityAlertssDiyAppResponseV2>) {
      return data.reduce(
        (groups: any, item: ActivityAlertssDiyAppResponseV2) => {
          const date = item.local_date;
          if (date) {
            groups[date] = groups[date] || []; // Initialize if key doesn't exist
            groups[date].push(item);
          }
          return groups;
        },
        {}
      );
    },
    lazyLoadTimeline() {
      const spotSliderBodyTimeline = document.querySelector(
        "#spot-slider-body-timeline"
      );
      if (spotSliderBodyTimeline) {
        const debouncedFetchSpotStatusTimeline = _.debounce(() => {
          if (
            spotSliderBodyTimeline.scrollTop +
              spotSliderBodyTimeline.clientHeight >=
            spotSliderBodyTimeline.scrollHeight
          ) {
            this.page += 1;
            this.fetchStatusTimeline(true);
          }
        }, 1000);
        spotSliderBodyTimeline.addEventListener(
          "scroll",
          debouncedFetchSpotStatusTimeline
        );
      }
    },
    shrinkSpotDetailsHeader() {
      const spotSliderBodyTimeline = document.querySelector(
        "#spot-slider-body-timeline"
      );
      if (spotSliderBodyTimeline) {
        const shrinkSpotSliderHeader = _.debounce(() => {
          console.log(
            spotSliderBodyTimeline.scrollTop,
            spotSliderBodyTimeline.clientHeight,
            spotSliderBodyTimeline.scrollHeight
          );
          if (spotSliderBodyTimeline.scrollTop > 12) {
            this.shrinkHeader = true;
          } else {
            this.shrinkHeader = false;
          }
        }, 10);
        spotSliderBodyTimeline.addEventListener(
          "scroll",
          shrinkSpotSliderHeader
        );
      }
    },
    editSpotDetails() {
      if (this.spotId) {
        this.$emit("edit-spot-details", this.spotId);
      } else if (this.zoneId) {
        this.$emit("edit-spot-details", this.zoneId);
      }
    },
    viewSpotFrame() {
      this.spotDetails.showFullFrame = true;
    },
    openAlert(alertId: number) {
      window.open(
        `${window.location.origin}/admin/alerts?alert_id=${alertId}`,
        "_blank"
      );
    },
    blobToBase64(blob: Blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },
    async fetchAuthenticatedImage(imageUrl: string) {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          return "";
        }
        const response = await fetch(imageUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const blob = await response.blob();
        return await this.blobToBase64(blob);
      } catch (error) {
        console.error("Error fetching image:", error);
        return "";
      }
    },
    async getBase64FromImageUrl(url: string) {
      try {
        const base64 = await new Promise((resolve, reject) => {
          const img = new Image();
          img.crossOrigin = "Anonymous";
          img.onload = function () {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            if (ctx) ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL("image/jpg");
            resolve(dataURL);
          };
          img.onerror = function (event) {
            const errorDetails = {
              message: "Failed to load image",
              url: url,
              event: event,
            };
            console.error("Image load error:", errorDetails);
            reject(new Error(JSON.stringify(errorDetails)));
          };
          img.src = url;
        });
        console.log("base64:", url, base64);
        return base64;
      } catch (error) {
        console.error("Error converting image to base64:", error);
        return "";
      }
    },
    cleanFrameUrl(history_id: number, image_url: string | null) {
      if (image_url != null) {
        return image_url;
      }
      return `${process.env.VUE_APP_API_URL}/parking_lots/${history_id}/stats/parking_update_image/${history_id}`;
    },
    async cleanFrameUrlAuth(history_id: number, image_url: string | null) {
      if (image_url != null) {
        return image_url;
      }
      const clean_image_url = await this.fetchAuthenticatedImage(
        `${process.env.VUE_APP_API_URL}/parking_lots/${history_id}/stats/parking_update_image/${history_id}`
      );
      return clean_image_url as string;
    },
    viewFrame(timeline_item_id: number, img_index = 0) {
      const item = this.timelineImageUrls.find(
        (item) => item.id == timeline_item_id
      );
      if (item && item.index != null) {
        let final_index = item.index;
        if (img_index > 0) {
          final_index = item.index + img_index;
        }
        this.$viewerApi({
          options: {
            initialViewIndex: final_index,
          },
          images: this.timelineImageUrls
            .filter((item) => item.index != null)
            .map((item) => {
              return {
                src: item.url,
                index: item.index,
              };
            }),
        });
      }
    },
    async setTimelineImageUrls(
      timeline: Array<ActivityAlertssDiyAppResponseV2>
    ) {
      let index = 0;
      for (const item of timeline) {
        if (item.id) {
          if (item.image_path_url) {
            this.timelineImageUrls.push({
              id: item.id,
              index: item.image_path_url ? index : null,
              img_index: 0,
              url:
                item.alert_type_id != null
                  ? item.image_path_url //await this.getBase64FromImageUrl(item.image_path_url) as string
                  : await this.cleanFrameUrlAuth(item.id, null),
            });
            index = index + (item.image_path_url ? 1 : 0);

            if (item.exit_image_path_url) {
              this.timelineImageUrls.push({
                id: item.id,
                index: item.exit_image_path_url ? index : null,
                img_index: 1,
                url: item.exit_image_path_url, //await this.getBase64FromImageUrl(item.image_path_url) as string
              });
              index = index + (item.exit_image_path_url ? 1 : 0);
            }
          }
          if (item.image_paths_urls && item.image_paths_urls.length > 0) {
            for (const [
              img_index,
              image_path_url,
            ] of item.image_paths_urls.entries()) {
              this.timelineImageUrls.push({
                id: item.id,
                index: image_path_url ? index : null,
                img_index: img_index,
                url:
                  item.alert_type_id != null
                    ? image_path_url //await this.getBase64FromImageUrl(image_path_url) as string
                    : await this.cleanFrameUrlAuth(item.id, null),
              });
              index = index + (image_path_url ? 1 : 0);
            }
          }
        }
      }
    },
    getTimelineUrl(timeline_item_id: number) {
      const item = this.timelineImageUrls.find(
        (item) => item.id == timeline_item_id
      );
      return item ? item.url : null;
    },
    async fetchLiveFrame() {
      if (!this.spotDetails.camera_id) return;
      this.spotDetails.loadingLiveFrame = true;
      try {
        const url = await api.downloadFrame(
          this.lotId,
          this.spotDetails.camera_id
        );
        if (url) {
          this.spotDetails.liveFrame = url;
          // get frame width and height
          const getMeta = (url: string, cb: any) => {
            const img = new Image();
            img.onload = () => cb(null, img);
            img.onerror = (err) => cb(err);
            img.src = url;
          };
          getMeta(url, (err: any, img: any) => {
            this.spotDetails.frameWidth = img.naturalWidth;
            this.spotDetails.frameHeight = img.naturalHeight;

            if (this.cameraMap.width != null && this.cameraMap.height != null) {
              const previous_image_width = this.cameraMap.width;
              const previous_image_height = this.cameraMap.height;

              const new_image_width = img.width;
              const new_image_height = img.height;

              if (
                previous_image_width &&
                previous_image_height &&
                (previous_image_width != new_image_width ||
                  previous_image_height != new_image_height)
              ) {
                this.spotDetails.spotAnn = [...this.spotDetails.spotAnn].map(
                  (bbox) => {
                    bbox.x = bbox.x * (new_image_width / previous_image_width);
                    bbox.y =
                      bbox.y * (new_image_height / previous_image_height);
                    bbox.width =
                      bbox.width * (new_image_width / previous_image_width);
                    bbox.height =
                      bbox.height * (new_image_height / previous_image_height);
                    return bbox;
                  }
                );

                this.spotDetails.zoneAnn = [...this.spotDetails.zoneAnn].map(
                  (poly) => {
                    poly.points = [...poly.points].map((point, index) => {
                      if (index % 2 == 0) {
                        return point * (new_image_width / previous_image_width);
                      } else {
                        return (
                          point * (new_image_height / previous_image_height)
                        );
                      }
                    });
                    return poly;
                  }
                );
              }
            }
          });
        } else {
          this.$dialog.message.error(
            "Read Error, unable to read frame camera.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        }
      } catch (error: any) {
        if (error.response.status === 403) {
          this.$dialog.message.warning(
            "Unstable camera stream connection, unable to read frame.<br> Please try again later.",
            {
              position: "top-right",
              timeout: 5000,
            }
          );
        }
      }
      this.spotDetails.loadingLiveFrame = false;
    },
    getUnknownText(unknown_reason: string | null) {
      if (unknown_reason) {
        if (
          unknown_reason === ParkingSpotUnknownStatus.marked_unknown_activated
        ) {
          return "Unknown: Admin Marked";
        } else if (
          unknown_reason ===
          ParkingSpotUnknownStatus.camera_inactive_unknown_activated
        ) {
          return "Unknown: Camera Inactive";
        } else if (
          unknown_reason ===
          ParkingSpotUnknownStatus.camera_offline_unknown_activated
        ) {
          return "Unknown: Camera Offline";
        } else if (
          unknown_reason ===
          ParkingSpotUnknownStatus.edge_device_offline_unknown_activated
        ) {
          return "Unknown: Edge Device Offline";
        } else if (
          unknown_reason === ParkingSpotUnknownStatus.flipflop_unknown_activated
        ) {
          return "Unknown: FlipFlop (Spot status is rapidly changing)";
        } else if (
          unknown_reason === ParkingSpotUnknownStatus.parallel_unknown_activated
        ) {
          return "Unknown: Parallel Parking";
        } else {
          return "Unknown";
        }
      }
      return "Unknown";
    },
    setTimelineDateRange(value: string, check_date_option = true) {
      if (
        check_date_option &&
        this.timelineFilters.selectedDateRange == value
      ) {
        this.timelineFilters.selectedDateRange = null;
        this.timelineFilters.startDate = null;
        this.timelineFilters.endDate = null;

        return;
      } else {
        this.timelineFilters.selectedDateRange = value;
      }

      if (value === "today") {
        this.timelineFilters.startDate = this.todaysDate;
        this.timelineFilters.endDate = this.todaysDate;
      } else if (value === "yesterday") {
        this.timelineFilters.startDate = dayjs()
          .subtract(1, "day")
          .format("YYYY-MM-DD");
        this.timelineFilters.endDate = dayjs()
          .subtract(1, "day")
          .format("YYYY-MM-DD");
      } else if (value === "last_week") {
        this.timelineFilters.startDate = dayjs()
          .subtract(7, "day")
          .format("YYYY-MM-DD");
        this.timelineFilters.endDate = this.todaysDate;
      } else if (value === "custom") {
        this.timelineFilters.startDate = this.timelineFilters.value[0];
        this.timelineFilters.endDate = this.timelineFilters.value[1];
      } else {
        this.timelineFilters.startDate = null;
        this.timelineFilters.endDate = null;
      }
    },
    applyTimelineFilters() {
      this.timelineFilters.showMenu = false;

      this.appliedTimelineFilters.showViolations =
        this.timelineFilters.showViolations;
      this.appliedTimelineFilters.showVacant = this.timelineFilters.showVacant;
      this.appliedTimelineFilters.showOccupied =
        this.timelineFilters.showOccupied;
      this.appliedTimelineFilters.showUnknown =
        this.timelineFilters.showUnknown;
      this.appliedTimelineFilters.selectedDateRange =
        this.timelineFilters.selectedDateRange;
      this.appliedTimelineFilters.value = this.timelineFilters.value;
      this.appliedTimelineFilters.startDate = this.timelineFilters.startDate;
      this.appliedTimelineFilters.endDate = this.timelineFilters.endDate;

      this.fetchStatusTimeline();
    },
    closeTimelineFilters() {
      this.timelineFilters.showViolations =
        this.appliedTimelineFilters.showViolations;
      this.timelineFilters.showVacant = this.appliedTimelineFilters.showVacant;
      this.timelineFilters.showOccupied =
        this.appliedTimelineFilters.showOccupied;
      this.timelineFilters.showUnknown =
        this.appliedTimelineFilters.showUnknown;
      this.timelineFilters.selectedDateRange =
        this.appliedTimelineFilters.selectedDateRange;
      this.timelineFilters.startDate = this.appliedTimelineFilters.startDate;
      this.timelineFilters.endDate = this.appliedTimelineFilters.endDate;

      this.timelineFilters.showMenu = false;
    },
    clearTimelineViolations() {
      this.appliedTimelineFilters.showViolations = false;
      this.fetchStatusTimeline();
    },
    clearTimelineVacant() {
      this.appliedTimelineFilters.showVacant = false;
      this.fetchStatusTimeline();
    },
    clearTimelineOccupied() {
      this.appliedTimelineFilters.showOccupied = false;
      this.fetchStatusTimeline();
    },
    clearTimelineUnknown() {
      this.appliedTimelineFilters.showUnknown = false;
      this.fetchStatusTimeline();
    },
    clearTimelineDateRange() {
      this.appliedTimelineFilters.selectedDateRange = "today";
      this.appliedTimelineFilters.value = [];
      this.appliedTimelineFilters.startDate = null;
      this.appliedTimelineFilters.endDate = null;
      this.fetchStatusTimeline();
    },
    clearTimelineFilters() {
      this.timelineFilters.showViolations = false;
      this.timelineFilters.showVacant = false;
      this.timelineFilters.showOccupied = false;
      this.timelineFilters.showUnknown = false;
      this.timelineFilters.selectedDateRange = null;
      this.timelineFilters.value = [];
      this.timelineFilters.startDate = null;
      this.timelineFilters.endDate = null;
    },

    fetchStatusTimeline(append_new = false) {
      if (this.spotId) {
        this.fetchSpotStatusTimeline(append_new);
      } else if (this.zoneId) {
        this.fetchZoneStatusTimeline(append_new);
      }
    },
    showAnprParkingRecordDetails(recordId: number) {
      console.log("Showing details of ANPR record ID", recordId);
      this.showVehicleParkingUsageDialog = true;
    },
  },

  watch: {
    lotId() {
      if (this.spotId) {
        this.fetchSpotDetails();
        this.fetchSpotStatusTimeline();
      } else if (this.zoneId) {
        this.fetchZoneDetails();
        this.fetchZoneStatusTimeline();
      }
    },
    spotId() {
      this.fetchSpotDetails();
      this.fetchSpotStatusTimeline();
    },
    zoneId() {
      this.fetchZoneDetails();
      this.fetchZoneStatusTimeline();
    },
  },
});
