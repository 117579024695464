
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import api from "@/api/api";
import dayjs, { duration } from "dayjs";
import { mapGetters } from "vuex";
import Chart, { ChartItem, ChartConfiguration } from "chart.js/auto";
import { getRelativePosition } from "chart.js/helpers";
import { getTodaysDate } from "@/libs/dateUtils";
import {
  ParkingLot,
  AzureUsers,
  AccuracyTrackingChartData,
} from "@/api/models";
import ChartDataLabels from "chartjs-plugin-datalabels";

export interface ChartDataset {
  label: string;
  data: Array<any>;
  fill: boolean;
  borderColor: string;
  tension: number;
  datalabels: {
    color: string;
  };
}

export interface TrackabilityDataset {
  x: number;
  y: number;
  extra: {
    actual_free: number;
    actual_unavailable: number;
    camera_inactive: number;
    camera_offline: number;
    edge_device_offline: number;
    detected_free: number;
    detected_unavailable: number;
    flip_flop: number;
    parallel_parking: number;
    marked_unknown: number;
    num_unknown: number;
    blocked_spots: number;
    num_untrackable: number;
    trackable_spots: number;
    untrackable_spots: number;
    note: string;
    verification_status: number;
    total_mapped_spots: number;
    total_spots: number;
    correctly_matched: number;
    flagged_anpr_total: number | null;
    flagged_anpr_free: number | null;
    flagged_anpr_unavailable: number | null;
    flagged_anpr_unknown: number | null;
    occupancy: number | null;
    ignore_unmapped_spots: boolean;
  };
}

export default Vue.extend({
  name: "AccuracyTrackingChart",
  props: {
    lotId: {
      type: Number,
      required: true,
    },
    parkingLotData: {
      type: Object as () => ParkingLot,
      required: true,
    },
    parkingLotName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      // Data Verification
      image_clicked: false,
      data_saved_snackbar: false,
      snackbar_text: "",
      verification_status: [] as Array<number>,
      clicked_label: "" as string,
      dialog: false as boolean,
      current_snapshots: {} as any,
      current_viewed_camera_id: 0 as number,
      // Current incorrect spots --> considering just using the second in the future.
      incorrect_spots: [] as Array<number>,
      incorrect_anpr: [] as Array<number>,
      // JSON containing corrected spot data for a camera
      corrected_spot_status: {} as any,
      corrected_anpr: {} as any,
      spot_states: [
        { state: "Unavailable", value: "unavailable" },
        { state: "Available", value: "free" },
      ],
      // Issue Creation
      issue_creation_dialog: false as boolean,
      issue_type: true as boolean,
      issue_title: null as string | null,
      // Declared any so that it can be set to null
      prev_issue_id: null as any,
      issue_parking_lot: null as any,
      issue_spot_id: null as any,
      issue_spot_name: null as any,
      issue_camera_id: null as any,
      issue_image_path: "" as string,
      issue_last_image_path: "" as string,
      issue_anpr_lp_number: null as string | null,
      issue_anpr_record_id: null as number | null,

      // Extra Tooltip Data
      trackable_spots: [] as Array<number>,
      untrackable_spots: [] as Array<number>,
      detected_unavailable: [] as Array<number>,
      detected_free: [] as Array<number>,
      actual_unavailable: [] as Array<number>,
      actual_free: [] as Array<number>,
      camera_offline: [] as Array<number>,
      edge_device_offline: [] as Array<number>,
      flip_flop: [] as Array<number>,
      parallel_parking: [] as Array<number>,
      camera_inactive: [] as Array<number>,
      marked_unknown: [] as Array<number>,
      num_unknown: [] as Array<number>,
      blocked_spots: [] as Array<number>,
      num_untrackable: [] as Array<number>,
      notes: [] as Array<string>,

      //Note Creation --> Lot id (from props) and Timestamp (clicked label)
      note_dialog: false as boolean,
      note_text: "" as string,

      //Metric Definitions :
      definitions: {
        Trackability: "",
        Accuracy: "",
        Effectiveness: "",
        Efficiency: "",
      },

      // Chart
      AccuracyChart: null as Chart<"line"> | null,
      accuracyChartData: null as AccuracyTrackingChartData | null,
      chart: {
        isLoading: false,
        ignoreUnmappedSpots: false,
        showStartDate: false,
        showStartTime: false,
        startDate: "",
        startTime: "00:00",
        showEndDate: false,
        showEndTime: false,
        endDate: "",
        endTime: "23:59",
        granularity: [
          { label: "15 Minutes", value: 60 * 15 },
          { label: "Daily", value: 60 * 60 * 24 },
          { label: "Weekly", value: 60 * 60 * 24 * 7 },
          { label: "Monthly", value: 60 * 60 * 24 * 30 },
        ],
        selectedGranularity: 60 * 60 * 24, //DAILY
        duration: [
          { label: "1 Day", value: 0 },
          { label: "1 Week", value: 1 },
          { label: "1 Month", value: 2 },
          { label: "Last Month", value: 3 },
          { label: "Custom", value: 4 },
        ],
        selectedDuration: 1,
        selectedOccupancyType: 1,
        occupanyType: [
          { label: "Maximum Occupancy", value: 1 },
          { label: "Minimum Occupancy", value: 0 },
        ],

        type: "line",
        // not_displayed
        complete_labels: [] as Array<string>,
        //x axis labels --> in our case dates (displayed)
        labels: [],
        data: {
          labels: [] as Array<Array<string>>,
          datasets: [] as Array<ChartDataset>,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              title: {
                display: true,
                text: "Metric %",
              },
              grace: 1,
              min: 0,
            },
            x: {
              title: {
                display: true,
                text: "Date and Time",
              },
              grid: {
                color: ["#D3D3D3"],
                lineWidth: 2,
              },
              ticks: {
                color: ["rgb(0,0,0)", "rgb(0,0,0)", "rgb(0,0,0)"],
                padding: 2,
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: "Model Accuracy",
            },
            legend: {
              position: "top",
            },
            tooltip: {
              intersect: false,
              mode: "index",
              titleColor: "#DF5286",
              titleFont: { weight: "bold" },
              callbacks: {
                footer: (context: string | any[]) => {
                  let trackability_index = 3; // context.length - 1;
                  let index = context[0].dataIndex;
                  let extra_data =
                    context[trackability_index].dataset.data[index].extra;
                  let final_note = "";
                  for (var key in extra_data) {
                    if (!extra_data[key]) {
                      extra_data[key] = 0;
                    }
                  }
                  //Wrapping text using Regex --> split after 32 characters(all included) [start point is after new line]
                  if (extra_data.note) {
                    final_note = extra_data.note.replace(
                      /(?![^\n]{1,32}$)([^\n]{1,32})\s/g,
                      "$1\n"
                    );
                  }
                  let final_str = "--------------";
                  const incorrectly_matched =
                    extra_data.actual_free +
                    extra_data.actual_unavailable -
                    extra_data.correctly_matched;
                  final_str += `\n* TOTAL SPOTS (All): ${
                    extra_data.ignore_unmapped_spots
                      ? extra_data.total_mapped_spots
                      : extra_data.total_spots
                  } ${
                    extra_data.ignore_unmapped_spots
                      ? "(Ignoring Camera not mapped)"
                      : ""
                  }\n`;

                  final_str += `* DETECTED (D=DA+DU)\n`;
                  final_str += `    Detected Available (DA): ${extra_data.detected_free}\n`;
                  final_str += `    Detected Unavailable (DU): ${extra_data.detected_unavailable}\n`;
                  final_str += `* VERIFIED (V=Correctly matched)\n`;
                  final_str += `    Actual Available: ${extra_data.actual_free}\n`;
                  final_str += `    Actual Unavailable: ${extra_data.actual_unavailable}\n`;
                  final_str += `    Incorrectly Matched: ${
                    incorrectly_matched >= 0 ? incorrectly_matched : 0
                  }\n`;
                  final_str += `* BLOCKED SPOTS: ${extra_data.blocked_spots}\n`;
                  final_str += `* UNKNOWN SPOTS (Y)\n`;
                  final_str += `    Total : ${extra_data.num_unknown}\n`;
                  final_str += `    Flip Flop : ${extra_data.flip_flop}\n`;
                  final_str += `    Parallel Parking : ${extra_data.parallel_parking}\n`;
                  final_str += `* UNTRACKABLE SPOTS (U)\n`;
                  final_str += `    Total : ${extra_data.untrackable_spots}\n`;
                  final_str += `    Camera not mapped : ${
                    extra_data.total_spots - extra_data.total_mapped_spots
                  }\n`;
                  final_str += `    Camera Offline : ${extra_data.camera_offline}\n`;
                  final_str += `    Edge Device Offline : ${extra_data.edge_device_offline}\n`;
                  final_str += `    Camera Inactive : ${extra_data.camera_inactive}\n`;
                  final_str += `    Marked Unknown : ${extra_data.marked_unknown}\n`;
                  final_str += `* TRACKABILITY \n`;
                  final_str += `    Trackable (T): ${extra_data.trackable_spots}\n`;
                  final_str += `    Untrackable (U): ${extra_data.untrackable_spots}\n`;
                  final_str += `* OCCUPANCY \n`;
                  final_str += `    Occupied Spots: ${
                    extra_data.occupancy != null ? extra_data.occupancy : "NA"
                  }\n`;
                  final_str += `    Occupancy %: ${
                    extra_data.occupancy != null
                      ? `${Math.round(
                          (extra_data.occupancy / extra_data.total_spots) * 100
                        )} %`
                      : "NA"
                  }\n`;

                  if (
                    this.parkingLotData.is_anpr_feature_enabled &&
                    extra_data.flagged_anpr_total !== null
                  ) {
                    final_str += "* ANPR \n";
                    final_str += `    Total Spots with ANPR Flagged : ${extra_data.flagged_anpr_total}\n`;
                    final_str += `    Free Spots with ANPR Flagged : ${extra_data.flagged_anpr_free}\n`;
                    final_str += `    Unavailable Spots with ANPR Flagged : ${extra_data.flagged_anpr_unavailable}\n`;
                    final_str += `    Unknown Spots with ANPR Flagged : ${extra_data.flagged_anpr_unknown}\n`;
                  }

                  if (final_note && final_note.length > 0) {
                    final_str += `-----NOTE------\n`;
                    final_str += `${final_note}`;
                  }

                  return final_str;
                },
                beforeTitle: function (context: string | any[]) {
                  let trackability_index = 3; // context.length - 1;
                  let index = context[0].dataIndex;
                  let extra_data =
                    context[trackability_index].dataset.data[index].extra;
                  if (extra_data.verification_status == 2) {
                    return "VERIFIED (S=V)";
                  } else if (extra_data.verification_status == 1) {
                    return "PARTIALLY VERIFIED (S=D)";
                  } else {
                    return "NOT VERIFIED (S=D)";
                  }
                },
              },
            },
            datalabels: {
              clip: false,
              align: "top",
              formatter: function (value: any, context: any) {
                if (value.y) {
                  return Math.round(value.y) + "%";
                } else {
                  return Math.round(value) + "%";
                }
              },
              font: {
                size: "14px",
                weight: "bold",
              },
            },
          },
          parsing: {
            xAxisKey: "x",
            yAxisKey: "y",
          },
        },
        plugins: [ChartDataLabels],
      },
      azureUsers: {
        selected: "",
        items: [] as Array<AzureUsers>,
      },
      isLoadingSnapshot: false,
      creatingUpdatingIssue: false,
    };
  },
  mounted() {
    this.chart.startDate = this.todaysDate;
    this.chart.endDate = this.todaysDate;

    this.setFiltersFromURLParams();

    this.fetchAccuracyChartData();
    this.fetchAzureUsers();
  },
  methods: {
    setFiltersFromURLParams() {
      const duration = Number(this.$route.query.duration);
      if (duration && typeof duration == "number") {
        this.chart.selectedDuration = duration;
        this.setChartDates();
        this.setChartGranularity();
      }
      const granularity = Number(this.$route.query.granularity);
      if (granularity && typeof granularity == "number") {
        this.chart.selectedGranularity = granularity;
      }
      const occupancyType = Number(this.$route.query.occupancyType);
      if (occupancyType && typeof occupancyType == "number") {
        this.chart.selectedOccupancyType = occupancyType;
      }
      this.setChartDates();
    },
    initializeChart() {
      const ctx = document.getElementById(
        "accuracy-tracking-chart"
      ) as HTMLCanvasElement;
      this.AccuracyChart = new Chart(
        ctx,
        this.chart as any
        // as ChartConfiguration<"line">
      );
      ctx.addEventListener("click", (e) => {
        this.clickableScales(ctx, e);
      });
    },
    async fetchAccuracyChartData(callapi = true) {
      if (this.AccuracyChart == null) {
        this.setChartDates();
        this.initializeChart();
      }
      this.chart.isLoading = true;
      //line names
      this.chart.data.labels = [];
      //line data points
      this.chart.data.datasets = [];

      try {
        //modify to be new function getAccuracyDetails
        if (callapi) {
          this.accuracyChartData = await api.getAccuracyTrackingMetricsData(
            this.lotId,
            `${this.chart.startDate} ${this.chart.startTime}`,
            `${this.chart.endDate} ${this.chart.endTime}`,
            this.chart.selectedGranularity,
            this.chart.selectedOccupancyType
          );
        }
        let final_x_axis_labels_colors = [] as Array<string>;
        let final_axis_colors = [] as Array<string>;
        if (
          this.accuracyChartData !== null &&
          this.accuracyChartData !== undefined &&
          this.accuracyChartData.accuracy.length > 0
        ) {
          this.verification_status = this.accuracyChartData.verified;
          this.chart.complete_labels = this.accuracyChartData.labels;
          for (var i = 0; i < this.accuracyChartData.verified.length; i++) {
            if (this.accuracyChartData.verified[i] == 0) {
              final_axis_colors.push("#D3D3D3");
              final_x_axis_labels_colors.push("#656255");
            } else if (this.accuracyChartData.verified[i] == 1) {
              final_axis_colors.push("#FFD300");
              final_x_axis_labels_colors.push("#FFD300");
            } else {
              final_axis_colors.push("rgb(0,0,0)");
              final_x_axis_labels_colors.push("rgb(0,0,0)");
            }
          }
        } else {
          this.$dialog.message.error(
            "No data available for the selected date range",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        }
        this.chart.options.scales.x.grid.color = final_axis_colors;
        this.chart.options.scales.x.ticks.color = final_x_axis_labels_colors;
        if (
          this.accuracyChartData &&
          this.accuracyChartData.labels.length > 0
        ) {
          const granularity = this.chart.granularity.find(
            (g) => g.value === this.chart.selectedGranularity
          )?.label;
          const diffDays = this.getDateDiffInDays(
            this.chart.startDate,
            this.chart.endDate
          );
          let timestamps = [] as Array<string>;
          // this.chart.data.labels = this.accuracyChartData.labels;
          let final_labels = [] as Array<Array<string>>;
          for (
            var index = 0;
            index < this.accuracyChartData.labels.length;
            index++
          ) {
            if (this.singleDaySelected) {
              final_labels.push([
                this.accuracyChartData.labels[index]
                  .split("T")[1]
                  .split(".")[0],
              ]);
            } else {
              let labels = [
                this.accuracyChartData.labels[index].split("T")[0],
                this.accuracyChartData.labels[index]
                  .split("T")[1]
                  .split(".")[0],
              ];
              if (this.chart.selectedGranularity >= 60 * 60 * 24 * 7) {
                labels.push(
                  `(${
                    this.accuracyChartData.start_labels[index].split("T")[0]
                  } - ${
                    this.accuracyChartData.end_labels[index].split("T")[0]
                  })`
                );
              }
              final_labels.push(labels);
            }
          }
          this.chart.data.labels = final_labels;

          // set width of the chart based on number of labels
          const chartContainer: HTMLElement | null =
            document.querySelector(".chartAreaWrapper2");
          if (chartContainer) {
            // 80px is enough to show one data point
            const singleDataPointWidth = 80;
            const numberOfViewableDataPoints =
              (window.innerWidth * singleDataPointWidth) /
              100 /
              singleDataPointWidth;
            if (this.chart.data.labels.length > numberOfViewableDataPoints) {
              chartContainer.style.width = `${
                this.chart.data.labels.length * singleDataPointWidth
              }px`;
            } else {
              chartContainer.style.width = "95vw";
            }
          }

          for (let row of this.accuracyChartData.labels) {
            if (this.chart.selectedGranularity > 60 * 60) {
              this.chart.options.scales.x.title.text = "Date";
              if (this.chart.selectedGranularity === 60 * 60 * 24)
                this.chart.options.scales.x.title.text = "Each Day";
              else if (this.chart.selectedGranularity === 60 * 60 * 24 * 7)
                this.chart.options.scales.x.title.text = "Each Week";
            } else if (diffDays < 1) {
              this.chart.options.scales.x.title.text = `Date - ${new Date(
                `${this.chart.endDate} 23:59`
              ).toLocaleString("en-us", {
                day: "2-digit",
                month: "long",
                year: "numeric",
                weekday: "long",
              })}, ${this.timeConvert(
                this.chart.startTime
              )} - ${this.timeConvert(
                this.chart.endTime
              )}, (Every ${granularity})`;
            } else {
              this.chart.options.scales.x.title.text = "Date and Time";
            }
          }
          // Pushing data to graph
          this.verification_status = this.accuracyChartData.verified;
          let tool_tip_data = [] as Array<TrackabilityDataset>;
          for (var m = 0; m < this.accuracyChartData.trackability.length; m++) {
            tool_tip_data.push({
              x: m,
              y: this.accuracyChartData.trackability[m],
              extra: {
                actual_free: this.accuracyChartData.actual_free[m],
                actual_unavailable:
                  this.accuracyChartData.actual_unavailable[m],
                camera_inactive: this.accuracyChartData.camera_inactive[m],
                camera_offline: this.accuracyChartData.camera_offline[m],
                edge_device_offline:
                  this.accuracyChartData.edge_device_offline[m],
                detected_free: this.accuracyChartData.detected_free[m],
                detected_unavailable:
                  this.accuracyChartData.detected_unavailable[m],
                flip_flop: this.accuracyChartData.flip_flop[m],
                parallel_parking: this.accuracyChartData.parallel_parking[m],
                marked_unknown: this.accuracyChartData.marked_unknown[m],
                num_unknown: this.accuracyChartData.num_unknown[m],
                blocked_spots: this.accuracyChartData.blocked_spots[m],
                num_untrackable: this.accuracyChartData.num_untrackable[m],
                trackable_spots: this.accuracyChartData.trackable_spots[m],
                untrackable_spots: this.accuracyChartData.untrackable_spots[m],
                note: this.accuracyChartData.notes[m],
                verification_status: this.accuracyChartData.verified[m],
                total_mapped_spots:
                  this.accuracyChartData.total_mapped_spots[m],
                total_spots: this.accuracyChartData.total_spots[m],
                correctly_matched: this.accuracyChartData.correctly_matched[m],
                flagged_anpr_total:
                  this.accuracyChartData.flagged_anpr_total[m],
                flagged_anpr_free: this.accuracyChartData.flagged_anpr_free[m],
                flagged_anpr_unavailable:
                  this.accuracyChartData.flagged_anpr_unavailable[m],
                flagged_anpr_unknown:
                  this.accuracyChartData.flagged_anpr_unknown[m],
                occupancy: this.accuracyChartData.occupancy[m],
                ignore_unmapped_spots: this.chart.ignoreUnmappedSpots,
              },
            });
          }

          let data_set_effectiveness = {
            label: "Effectiveness (S/All)",
            data: this.accuracyChartData.effectiveness,
            fill: false,
            borderColor: "rgb(3, 192, 60)",
            tension: 0.1,
            datalabels: {
              color: "rgb(3, 192, 60)",
            },
          };
          let data_set_accuracy = {
            label: "Accuracy AI ((S+Y)/T)",
            data: this.accuracyChartData.accuracy,
            fill: false,
            borderColor: "rgb(255, 0, 0)",
            tension: 0.1,
            datalabels: {
              color: "rgb(255, 0, 0)",
            },
          };
          let data_set_efficiency = {
            label: "Surety AI (S/T)",
            data: this.accuracyChartData.efficiency,
            fill: false,
            borderColor: "rgb(253, 208, 23)",
            tension: 0.1,
            datalabels: {
              color: "rgb(253, 208, 23)",
            },
          };
          let data_set_trackability = {
            label: "Trackability (T/All)",
            data: tool_tip_data,
            fill: false,
            borderColor: "rgb(0, 0, 255)",
            tension: 0.1,
            datalabels: {
              color: "rgb(0, 0, 255)",
            },
          };

          // Graph Data
          this.chart.data.datasets.push(data_set_effectiveness);
          this.chart.data.datasets.push(data_set_accuracy);
          this.chart.data.datasets.push(data_set_efficiency);
          this.chart.data.datasets.push(data_set_trackability);

          // Graph ANPR
          if (this.parkingLotData.is_anpr_feature_enabled) {
            let anpr_summary = {
              label: "ANPR Accuracy (1-F/All)",
              data: this.accuracyChartData.anpr_accuracy_summary,
              fill: false,
              borderColor: "rgb(0, 255, 255)",
              tension: 0.1,
              datalabels: {
                color: "rgb(0, 255, 255)",
              },
            };
            let anpr_green = {
              label: "ANPR Green",
              data: this.accuracyChartData.anpr_free,
              fill: false,
              borderColor: "rgb(0, 255, 0)",
              tension: 0.1,
              datalabels: {
                color: "rgb(0, 255, 0)",
              },
            };
            let anpr_unknown = {
              label: "ANPR Unknown",
              data: this.accuracyChartData.anpr_unknown,
              fill: false,
              borderColor: "rgb(255, 255, 0)",
              tension: 0.1,
              datalabels: {
                color: "rgb(255, 255, 0)",
              },
            };
            let anpr_red = {
              label: "ANPR Red",
              data: this.accuracyChartData.anpr_unavailable,
              fill: false,
              borderColor: "rgb(256, 148, 148)",
              tension: 0.1,
              datalabels: {
                color: "rgb(255, 148, 148)",
              },
            };

            this.chart.data.datasets.push(anpr_summary);
            this.chart.data.datasets.push(anpr_green);
            this.chart.data.datasets.push(anpr_unknown);
            this.chart.data.datasets.push(anpr_red);
          }

          // Tool-Tip Information
          this.trackable_spots = this.accuracyChartData.trackable_spots;
          this.untrackable_spots = this.accuracyChartData.untrackable_spots;
          this.detected_unavailable =
            this.accuracyChartData.detected_unavailable;
          this.detected_free = this.accuracyChartData.detected_free;
          this.actual_unavailable = this.accuracyChartData.actual_unavailable;
          this.actual_free = this.accuracyChartData.actual_free;
          this.camera_offline = this.accuracyChartData.camera_offline;
          this.edge_device_offline = this.accuracyChartData.edge_device_offline;
          this.flip_flop = this.accuracyChartData.flip_flop;
          this.parallel_parking = this.accuracyChartData.parallel_parking;
          this.camera_inactive = this.accuracyChartData.camera_inactive;
          this.marked_unknown = this.accuracyChartData.marked_unknown;
          this.num_unknown = this.accuracyChartData.num_unknown;
          this.blocked_spots = this.accuracyChartData.blocked_spots;
          this.num_untrackable = this.accuracyChartData.num_untrackable;
          this.notes = this.accuracyChartData.notes;
        }
      } catch (e) {
        this.$dialog.message.error(
          "Error, unable to load latest data. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        console.log(e);
      } finally {
        if (this.AccuracyChart) this.AccuracyChart.update();
        this.chart.isLoading = false;
      }
    },
    setChartDates() {
      const today = new Date();
      switch (this.chart.selectedDuration) {
        case 0:
          this.chart.startDate = this.todaysDate;
          this.chart.startTime = "00:00";
          this.chart.endTime = "23:59";
          break;
        case 1:
          this.chart.startDate = this.getLocalDate(
            new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6)
          );
          break;
        case 2:
          this.chart.startDate = this.getLocalDate(
            new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() - 30
            )
          );
          break;
        case 3:
          this.chart.startDate = this.convertDate(
            new Date(today.getFullYear(), today.getMonth() - 1, 1)
          );
          this.chart.endDate = this.convertDate(
            new Date(today.getFullYear(), today.getMonth(), 0)
          );
          break;
        default:
          this.chart.startDate = this.getLocalDate(
            new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6)
          );
          break;
      }
      if (this.chart.selectedDuration !== 3) {
        this.chart.endDate = this.todaysDate;
      }

      this.chart.granularity = [
        { label: "5 Minutes", value: 60 * 5 },
        { label: "15 Minutes", value: 60 * 15 },
        { label: "30 Minutes", value: 60 * 30 },
        { label: "Hourly", value: 60 * 60 },
        { label: "Daily", value: 60 * 60 * 24 },
        { label: "Weekly", value: 60 * 60 * 24 * 7 },
      ].filter((g, i) => {
        switch (this.chart.selectedDuration) {
          case 0:
            return [1, 2, 3].includes(i);
          case 1:
            return [4].includes(i);
          case 2:
            return [4, 5].includes(i);
          case 3:
            return [4, 5].includes(i);
          default:
            return [3, 4, 5].includes(i);
        }
      });
      this.chart.selectedGranularity = this.chart.granularity[0].value;
      if (this.chart.selectedDuration == 0) {
        this.chart.selectedGranularity = 60 * 30;
      } else if (this.chart.selectedDuration == 1) {
        this.chart.selectedGranularity = 60 * 60 * 24;
      }
      this.setChartGranularity();
    },
    setChartGranularity() {
      // Set granularity options and selection for Custom start and end date
      if (this.chart.selectedDuration === 2) {
        const diffDays = this.getDateDiffInDays(
          this.chart.startDate,
          this.chart.endDate
        );
        if (diffDays > 1) {
          if (diffDays <= 7) {
            this.chart.granularity = [{ label: "Daily", value: 60 * 60 * 24 }];
            this.chart.selectedGranularity = 60 * 60 * 24;
          } else {
            this.chart.granularity = [
              { label: "Daily", value: 60 * 60 * 24 },
              { label: "Weekly", value: 60 * 60 * 24 * 7 },
            ];
            this.chart.selectedGranularity = 60 * 60 * 24 * 7;
          }
        } else {
          this.chart.granularity = [
            { label: "15 Minutes", value: 60 * 15 },
            { label: "30 Minutes", value: 60 * 30 },
            { label: "Hourly", value: 60 * 60 },
          ];
          this.chart.selectedGranularity = 60 * 60;
        }
      } else {
        if (this.chart.selectedDuration == 1) {
          this.chart.granularity = [
            { label: "Daily", value: 60 * 60 * 24 },
            { label: "Weekly", value: 60 * 60 * 24 * 7 },
          ];
          this.chart.selectedGranularity = 60 * 60 * 24;
        }
      }
    },
    getDateDiffInDays(startDate: string, endDate: string) {
      const diffTime = Math.abs(
        new Date(endDate).getTime() - new Date(startDate).getTime()
      );
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    convertDate(date: Date) {
      const yyyy = date.getFullYear().toString();
      const mm = (date.getMonth() + 1).toString();
      const dd = date.getDate().toString();

      const mmChars = mm.split("");
      const ddChars = dd.split("");

      return (
        yyyy +
        "-" +
        (mmChars[1] ? mm : "0" + mmChars[0]) +
        "-" +
        (ddChars[1] ? dd : "0" + ddChars[0])
      );
    },
    // convert 24 hour time to 12 hour time format
    timeConvert(time: string) {
      return new Date("1970-01-01T" + time + "Z").toLocaleTimeString("en-US", {
        timeZone: "UTC",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      });
    },
    refreshPage() {
      // this.chart.startDate = this.todaysDate;
      // this.chart.endDate = this.todaysDate;
      // this.chart.startTime = "00:00";
      // this.chart.endTime = "23:59";
      // this.chart.selectedGranularity = 60 * 60 * 24;
      // this.chart.selectedDuration = 1;
      // this.setChartDates();
      this.fetchAccuracyChartData();
    },
    get_verification_status(index: number) {
      return this.verification_status[index];
    },
    clickableScales(canvas: HTMLCanvasElement, click: any) {
      if (this.AccuracyChart !== null) {
        this.isLoadingSnapshot = true;
        const top = this.AccuracyChart.scales.x.top;
        const bottom = this.AccuracyChart.scales.x.bottom;
        let reset_coords = canvas.getBoundingClientRect();
        const x = click.clientX - reset_coords.left;
        const y = click.clientY - reset_coords.top;
        this.current_viewed_camera_id = 0;
        for (var i = 0; i < this.AccuracyChart.scales.x.ticks.length; i++) {
          const x_lower = this.AccuracyChart.scales.x.getPixelForValue(i) - 30;
          const x_upper = this.AccuracyChart.scales.x.getPixelForValue(i) + 30;
          if (x >= x_lower && x <= x_upper && y >= top && y <= bottom) {
            this.clicked_label = this.chart.complete_labels[i];
            this.dialog = true;
            this.fetchLotSnapshot(this.clicked_label);
          }
        }
      }
    },
    async fetchLotSnapshot(timestamp: string) {
      let lotData = await api.getLotSnapshot(this.lotId, timestamp);
      this.current_snapshots = lotData;
      this.isLoadingSnapshot = false;
    },
    async fetchAzureUsers() {
      let response = await api.fetchAzureUsers(this.lotId);
      this.azureUsers.items = response;
    },
    setIncorrectSpotNewStatus(event: any, spot: number) {
      this.corrected_spot_status[spot] = event.value;
    },
    update_incorrect_spots(spot: number, spot_original_status: string) {
      if (this.incorrect_spots.indexOf(spot) >= 0) {
        if (spot_original_status == "free") {
          this.corrected_spot_status[spot] = "unavailable";
        } else {
          this.corrected_spot_status[spot] = "free";
        }
      } else {
        delete this.corrected_spot_status[spot];
      }
    },
    update_incorrect_anpr(spot: number, spot_data: any, camera_id: number) {
      let reset_verified_anpr = false;
      if (this.incorrect_anpr.indexOf(spot) >= 0) {
        if (
          this.current_snapshots[camera_id]["verified_snapshot"][spot][
            "vehicle_parking_usage_anpr_record_id"
          ] != null
        ) {
          this.corrected_anpr[spot] = {
            vehicle_parking_usage_anpr_record_id: null,
            vehicle_parking_usage_anpr_lp_number: null,
          };
        } else {
          reset_verified_anpr = true;
        }
      } else {
        delete this.corrected_anpr[spot];
        reset_verified_anpr = true;
      }
      if (reset_verified_anpr) {
        this.current_snapshots[camera_id]["verified_snapshot"][spot][
          "vehicle_parking_usage_anpr_record_id"
        ] = spot_data.vehicle_parking_usage_anpr_record_id;
        this.current_snapshots[camera_id]["verified_snapshot"][spot][
          "vehicle_parking_usage_anpr_lp_number"
        ] = spot_data.vehicle_parking_usage_anpr_lp_number;
      }
    },
    async verify_camera_data(camera_id: number, timestamp: string) {
      console.log("Changed Spots", this.corrected_spot_status);
      let new_spot_snapshot = {} as any;
      if (this.current_snapshots[camera_id]["verified_snapshot"]) {
        new_spot_snapshot =
          this.current_snapshots[camera_id]["verified_snapshot"];
      } else {
        new_spot_snapshot = this.current_snapshots[camera_id]["snapshot"];
      }
      //Remove any empty changes.
      Object.keys(this.corrected_spot_status).forEach(
        (k) =>
          this.corrected_spot_status[k] == "" &&
          delete this.corrected_spot_status[k]
      );
      for (let key in this.corrected_spot_status) {
        new_spot_snapshot[key]["current_status"] =
          this.corrected_spot_status[key];
      }
      for (let key in this.corrected_anpr) {
        new_spot_snapshot[key]["vehicle_parking_usage_anpr_record_id"] =
          this.corrected_anpr[key]["vehicle_parking_usage_anpr_record_id"];
        new_spot_snapshot[key]["vehicle_parking_usage_anpr_lp_number"] =
          this.corrected_anpr[key]["vehicle_parking_usage_anpr_lp_number"];
      }
      let response = await api.verifyCameraData(
        this.lotId,
        timestamp,
        camera_id,
        new_spot_snapshot
      );
      if (response) {
        this.snackbar_text = "Updated Successfully";
        this.data_saved_snackbar = true;
        this.fetchLotSnapshot(timestamp);
        this.corrected_spot_status = {};
        this.corrected_anpr = {};
        this.incorrect_spots = [];
        this.incorrect_anpr = [];
      } else {
        this.snackbar_text = "Update Failed";
        this.data_saved_snackbar = true;
      }
    },
    async create_or_update_issue() {
      this.creatingUpdatingIssue = true;
      // create post , clear data from state (same for cancel), have check for not-null before post or make not null on the text-field
      let issue_data = {} as any;

      issue_data["description"] = {
        parking_name: this.parkingLotData.name || this.parkingLotName,
        parking_lot_id: this.lotId,
        parking_spot_id: this.issue_spot_id,
        parking_spot_name: this.issue_spot_name,
        camera_id: this.issue_camera_id,
        image_path_url: this.issue_image_path,
        last_image_path: this.issue_last_image_path,
        timestamp: this.clicked_label,
      };

      issue_data["description"]["vehicle_parking_usage_anpr_lp_number"] = null;
      issue_data["description"]["vehicle_parking_usage_anpr_record_url"] = null;
      if (this.issue_anpr_lp_number && this.issue_anpr_record_id) {
        issue_data["description"]["vehicle_parking_usage_anpr_lp_number"] =
          this.issue_anpr_lp_number;
        let routeUrl = this.$router.resolve({
          name: "LotLprManagement",
          query: { anpr_record_id: String(this.issue_anpr_record_id) },
        }).href;
        let pageUrl = new URL(routeUrl, window.location.origin).href;
        issue_data["description"]["vehicle_parking_usage_anpr_record_url"] =
          pageUrl;
      }

      issue_data["parking_lot_id"] = this.parkingLotData.id;
      issue_data["timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      issue_data["work_item_type"] = "Task";
      issue_data["prev_work_item_id"] = this.prev_issue_id;
      issue_data["issue_title"] = this.issue_title;
      issue_data["assigned_to_email"] = this.azureUsers.selected;

      let response = await api.createOrUpdateAccuracyTrackingIssue(issue_data);
      this.issue_spot_id = null;
      this.issue_spot_name = null;
      this.issue_camera_id = null;
      this.issue_image_path = "";
      this.prev_issue_id = null;
      this.issue_title = "";
      this.issue_last_image_path = "";
      this.issue_type = true;
      this.azureUsers.selected = "";
      let temp = this.current_viewed_camera_id;
      this.current_snapshots = {};
      await this.fetchLotSnapshot(this.clicked_label);
      this.current_viewed_camera_id = temp;
      this.creatingUpdatingIssue = false;
      this.issue_creation_dialog = false;
    },
    async add_update_note() {
      let response = await api.createOrUpdateSnapshotNote(
        this.lotId,
        this.clicked_label,
        this.note_text
      );
      this.note_dialog = false;
      if (response) {
        this.notes[this.chart.complete_labels.indexOf(this.clicked_label)] =
          this.note_text;
      }
      this.note_text = "";
    },
    get_camera_id_status(snapshot: any) {
      if (snapshot.verified_snapshot) {
        return "#37474F";
      }
      return "#DEE4E7";
    },
    show(img_path: string) {
      this.$viewerApi({
        options: {
          focus: false,
          button: false,
        },
        images: [img_path],
      });
    },
    // center_camera_footer(camera_id : string){
    //   // const footer = document.getElementById("camera_footer_list");
    //   const [el] = this.$refs[`camera_id_footer_${camera_id}`];
    //   console.log(el);
    //   // goTo(el,{
    //   //   container : footer
    //   // });
    //   goTo(el);
    // },
    render_as_offline(snapshot: any) {
      if (Object.keys(snapshot).length !== 0) {
        if (
          snapshot[Object.keys(snapshot)[0]].is_status_unknown_camera_offline ||
          snapshot[Object.keys(snapshot)[0]]
            .is_status_unknown_camera_inactive ||
          snapshot[Object.keys(snapshot)[0]]
            .is_status_unknown_edge_device_offline
        ) {
          return true;
        }
      }
      return false;
    },
    setTimeIfSameDay() {
      if (this.chart.startDate === this.chart.endDate) {
        this.chart.startTime = "00:00";
        this.chart.endTime = "23:59";
        this.chart.granularity = [
          { label: "15 Minutes", value: 60 * 15 },
          { label: "30 Minutes", value: 60 * 30 },
          { label: "Hourly", value: 60 * 60 },
        ];
      }
    },
    getLocalDate(d: Date) {
      return dayjs(d).format("YYYY-MM-DD");
    },
    openAnprRecordDetails(anprRecordId: number) {
      const routeData = this.$router.resolve({
        name: "LotLprManagement",
        query: { anpr_record_id: String(anprRecordId) },
      });
      window.open(routeData.href, "_blank");
    },
    openAnprRecordDetailsBySpot(spotId: number, start_timestamp: string) {
      let start_time = dayjs.utc(start_timestamp).local();
      start_timestamp = start_time.format("YYYY-MM-DD HH:mm");

      let end_time = dayjs(this.clicked_label);
      end_time = end_time.add(
        this.parkingLotData.anpr_matching_window_interval_minutes || 10,
        "minutes"
      );
      let end_timestamp = end_time.format("YYYY-MM-DD HH:mm");

      const routeData = this.$router.resolve({
        name: "Accuracy",
        query: {
          spot_id: String(spotId),
          start_timestamp: start_timestamp,
          end_timestamp: end_timestamp,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
  watch: {
    "chart.startDate"() {
      this.setTimeIfSameDay();
    },
    "chart.endDate"() {
      this.setTimeIfSameDay();
    },
  },
  computed: {
    ...mapGetters("user", ["isLoggedIn", "hasAccessLevelDashboardMonitoring"]),
    singleDaySelected() {
      if (this.chart.startDate === this.chart.endDate) {
        return true;
      }
      return false;
    },
    todaysDate() {
      return getTodaysDate();
    },
  },
});
